/* eslint-disable no-underscore-dangle */
import React, { useState, useContext, useEffect, useMemo } from 'react';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import config from '../data/config';
import {
  getCompanyForUser,
  login,
  refreshToken,
  register,
} from '../utils/apiCalls';
import APIError from '../utils/CustomError';

export const emptyAuthState = {
  isAuthenticated: false,
  user: {
    uuid: '',
    email: '',
    onboarded: true,
    companyName: '',
    type: 0,
  },
  accessToken: '',
  refreshToken: '',
};

const initialState = localStorage.getItem(config.authKey)
  ? JSON.parse(localStorage.getItem(config.authKey))
  : emptyAuthState;

// below needs to be outside, otherwise header wouldn't update
axios.defaults.headers.common.Authorization = `Bearer ${initialState.accessToken}`;

const AuthContext = React.createContext(initialState);

// eslint-disable-next-line react/prop-types
export const AuthProvider = ({ children }) => {
  const history = useHistory();

  const [data, setData] = useState(initialState);

  useEffect(() => {
    if (data.accessToken) {
      axios.defaults.headers.common.Authorization = `Bearer ${data.accessToken}`;
    } else {
      delete axios.defaults.headers.common.Authorization;
    }

    localStorage.setItem(config.authKey, JSON.stringify(data));
  }, [data]);

  const onRegister = async (email, password) => {
    if (email.length > 0 && password.length > 0) {
      try {
        const response = await register(email, password);
        return {
          status: true,
          error: null,
          isEmailVerified: !!response?.data?.email_verified_at,
        };
      } catch (err) {
        return {
          status: false,
          error: {
            message: err.message,
            errors: err.errors,
          },
          isEmailVerified: false,
        };
      }
    }

    return null;
  };

  const onLogin = async (email, password) => {
    if (email.length > 0 && password.length > 0) {
      let result = null;
      try {
        result = await login(email, password);
      } catch (err) {
        return {
          status: false,
          error: { message: err.message, errors: err.errors },
        };
      }

      axios.defaults.headers.common.Authorization = `Bearer ${result.data.token_data.AccessToken}`;

      let onboarded = true;

      try {
        const response = await getCompanyForUser();

        // as most fields are required, I can only check one to see if user was onboarded
        if (!response.data.first_name || response.data.first_name === '') {
          onboarded = false;
        }

        const newData = {
          isAuthenticated: true,
          user: {
            uuid: result.data.user_data.uuid ?? '',
            email,
            onboarded,
            companyName: onboarded ? response?.data?.company : '',
            type: result.data.user_data.type ?? 0,
          },
          accessToken: result.data.token_data.AccessToken,
          refreshToken: result.data.token_data.RefreshToken,
        };

        localStorage.setItem(config.authKey, JSON.stringify(newData));
        setData(newData);

        return { status: true, onboarded };
      } catch (err) {
        if (err.statusCode !== 500) {
          onboarded = false;
        }
      }
    }

    return { status: false, onboarded: false };
  };

  const logout = async () => {
    // TODO add request to logout endpoint
    setData(emptyAuthState);
    delete axios.defaults.headers.common.Authorization;
  };

  // eslint-disable-next-line no-unused-vars
  const onRefresh = async (originalRequest) => {
    try {
      delete axios.defaults.headers.common.Authorization;
      const refreshTokenResponse = await refreshToken(
        data.user.email,
        data.refreshToken,
      );

      const newData = {
        ...data,
        accessToken: refreshTokenResponse.data.token_data.AccessToken,
      };
      setData(newData);
      localStorage.setItem(config.authKey, newData);
      axios.defaults.headers.common.Authorization = `Bearer ${refreshTokenResponse.data.token_data.AccessToken}`;
      // eslint-disable-next-line no-param-reassign
      originalRequest.headers.Authorization = `Bearer ${refreshTokenResponse.data.token_data.AccessToken}`;

      return axios(originalRequest);
    } catch (err) {
      await logout();
      history.push('/login');
    }
  };

  // eslint-disable-next-line no-unused-vars
  const interceptor = useMemo(
    () =>
      axios.interceptors.response.use(
        (response) => response.data,
        async (err) => {
          const originalRequest = err.config;

          if (err?.response?.status === 444) {
            history.push({
              pathname: '/maintenance',
              data: { message: err?.response?.data?.data },
            });

            return;
          }

          if (
            err?.response?.status === 401 &&
            err?.config?.url.includes('token/refresh')
          ) {
            await logout();
            history.push('/login');
          }

          if (err?.response?.status === 401) {
            // eslint-disable-next-line consistent-return
            return onRefresh(originalRequest);
          }

          throw new APIError({
            ...err.response.data?.data,
            statusCode: err.response.status,
          });
        },
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [initialState],
  );

  return (
    <AuthContext.Provider
      value={{
        data,
        setData,
        login: onLogin,
        logout,
        register: onRegister,
      }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
