import React from 'react';
import './ThemesHeader.scss';

const ThemesHeader = () => (
  <header className="themes-header">
    <div className="header__entry header__entry--name">NAME</div>
    <div className="header__entry header__entry--available">AVAILABLE</div>
    <div className="header__entry header__entry--search">ACTIONS</div>
  </header>
);

export default ThemesHeader;
