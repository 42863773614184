import React, { useEffect, useRef } from 'react';
import './SideMenu.scss';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

const SideMenu = ({
  children,
  visibility,
  setVisibility,
  headerText,
  headerIcon,
}) => {
  const headerRef = useRef();

  useEffect(() => {
    if (visibility) {
      // eslint-disable-next-line no-unused-expressions
      headerRef?.current?.scrollIntoView();
    }
  }, [visibility]);

  return (
    <section
      className={`side-menu ${visibility ? 'side-menu--visible' : ''}`}
      tabIndex={!visibility ? -1 : undefined}>
      <header className="side-menu__header" ref={headerRef}>
        <FontAwesomeIcon icon={headerIcon} className="header__icon" />
        <h1 className="header__content">{headerText}</h1>
        <button
          className="header__close-button"
          type="button"
          onClick={() => setVisibility(false)}>
          <FontAwesomeIcon icon={faTimes} className="close-button__icon" />
        </button>
      </header>
      <main className="side-menu__content">{children}</main>
    </section>
  );
};

SideMenu.propTypes = {
  children: PropTypes.node,
  visibility: PropTypes.bool,
  setVisibility: PropTypes.func,
  headerText: PropTypes.string,
  headerIcon: PropTypes.object,
};

export default SideMenu;
