import React from 'react';
import './ActionButtons.scss';
import PropTypes from 'prop-types';
import Button from '../../../../Button/Button';

const ActionButtons = ({
  onEditClick,
  onStatusButtonClick,
  onDownloadClick,
  isActive,
  changeableStatus,
}) => (
  <div className="action-buttons">
    <Button variant="secondary" text="Edit" onClick={onEditClick} />
   {changeableStatus && <Button
      variant="primary"
      text={isActive ? 'Deactivate' : 'Activate'}
      onClick={onStatusButtonClick}
    />}
    <Button variant="secondary" text="Download" onClick={onDownloadClick} />
  </div>
);

ActionButtons.propTypes = {
  onEditClick: PropTypes.func,
  onStatusButtonClick: PropTypes.func,
  onDownloadClick: PropTypes.func,
  isActive: PropTypes.bool,
  changeableStatus: PropTypes.bool,
};

export default ActionButtons;
