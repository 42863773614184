import React from 'react';
import './ImportDropdown.scss';
import PropTypes from 'prop-types';
import SectionHeader from '../SectionHeader/SectionHeader';
import FormControl from '../FormControl/FormControl';

const ImportDropdown = ({ dbColumn, fileColumns, value, setValue }) => (
  <div className="import-dropdown">
    <SectionHeader text={dbColumn} textAlign="center" />
    <FormControl
      as="select"
      options={fileColumns}
      value={value}
      onChange={setValue}
    />
  </div>
);

ImportDropdown.propTypes = {
  dbColumn: PropTypes.string,
  fileColumns: PropTypes.arrayOf(PropTypes.object),
  value: PropTypes.string,
  setValue: PropTypes.func,
};

export default ImportDropdown;
