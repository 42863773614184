import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import './ButtonGroup.scss';
import IconToggle from '../IconToggle/IconToggle';

const ButtonGroup = ({
  label,
  hasBorder,
  buttons,
  buttonStatus,
  setButtonStatus,
  align,
  type,
  disabled,
}) => {
  const buttonsClassName = () => {
    switch (align) {
      case 'center':
        return 'button-group--centered';
      case 'right':
        return 'button-group--right';
      case 'left':
      default:
        return '';
    }
  };

  const [renderedButtons, setRenderedButtons] = useState([]);

  useEffect(() => {
    if (buttonStatus) {
      setRenderedButtons(
        buttons.map((btn) => (
          <IconToggle
            {...btn}
            key={btn.name}
            type={type}
            status={buttonStatus[btn.name]}
            setStatus={(newStatus) => {
              const status = { ...buttonStatus };

              if (type === 'radio') {
                Object.keys(status).forEach((key) => {
                  status[key] = false;
                });
              }

              status[btn.name] = newStatus;
              setButtonStatus(status);
            }}
            disabled={disabled}
          />
        )),
      );
    }
    // eslint-disable-next-line
  }, [buttonStatus]);

  return (
    <section
      className={`button-group ${hasBorder ? 'button-group--bordered' : ''}`}>
      {label && label !== '' ? (
        <span className="button-group__label">{label}</span>
      ) : null}
      <div className={`button-group__buttons ${buttonsClassName()}`}>
        {renderedButtons}
      </div>
    </section>
  );
};

ButtonGroup.propTypes = {
  label: PropTypes.string,
  hasBorder: PropTypes.bool,
  buttons: PropTypes.arrayOf(PropTypes.object),
  buttonStatus: PropTypes.object,
  setButtonStatus: PropTypes.func,
  align: PropTypes.string,
  type: PropTypes.string,
  disabled: PropTypes.bool,
};

export default ButtonGroup;
