import React from 'react';
import './BillingAddressForm.scss';
import PropTypes from 'prop-types';
import FormControl from '../../FormControl/FormControl';
import SectionHeader from '../../SectionHeader/SectionHeader';
import ContactForm from '../../ContactForm/ContactForm';

const BillingAddressForm = ({
  inputs,
  selects,
  isBillingTheSame,
  setIsBillingTheSame,
}) => (
  <div className="billing-address-form">
    <FormControl
      type="checkbox"
      value={isBillingTheSame}
      onChange={setIsBillingTheSame}
      label="BUSINESS & BILLING ADDRESS ARE THE SAME"
    />
    {!isBillingTheSame ? (
      <>
        <SectionHeader text="BILLING ADDRESS" />
        <ContactForm inputs={inputs} selects={selects} />
      </>
    ) : null}
  </div>
);

BillingAddressForm.propTypes = {
  inputs: PropTypes.arrayOf(PropTypes.object),
  selects: PropTypes.arrayOf(PropTypes.object),
  isBillingTheSame: PropTypes.bool,
  setIsBillingTheSame: PropTypes.func,
};

export default BillingAddressForm;
