import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import './Notification.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

const Notification = ({ title, message, visibility, callback }) => {
  const [isVisible, setIsVisible] = useState(visibility);

  useEffect(() => {
    setIsVisible(visibility);
  }, [visibility]);

  if (!isVisible) return null;

  return (
    <div className="notification">
      <h3 className="notification__title">{title}</h3>
      <button
        className="notification__close-button"
        type="button"
        onClick={() => {
          setIsVisible(false);
          if (callback) {
            callback();
          }
        }}>
        <FontAwesomeIcon icon={faTimes} className="notification__close-icon" />
      </button>
      <p className="notification__message">{message}</p>
    </div>
  );
};

Notification.propTypes = {
  title: PropTypes.string,
  message: PropTypes.string,
  visibility: PropTypes.bool,
  callback: PropTypes.func
};

export default Notification;
