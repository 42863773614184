import React from 'react';

export const flexDirectionOptions = [
  { value: '', text: 'choose an option' },
  { value: 'row', text: 'row' },
  { value: 'row-reverse', text: 'row reverse' },
  { value: 'column', text: 'column' },
  { value: 'column-reverse', text: 'column reverse' },
];

export const elementSizeDataList = (name) => (
  <datalist id={`datalist_${name}`}>
    <option>small</option>
    <option>medium</option>
    <option>large</option>
  </datalist>
);

export const textAlignOptions = [
  { value: '', text: 'choose an option' },
  { value: 'center', text: 'center' },
  { value: 'left', text: 'left' },
  { value: 'right', text: 'right' },
  { value: 'justify', text: 'justify' },
];

export const justifyContentOptions = [
  { value: '', text: 'choose an option' },
  { value: 'center', text: 'center' },
  { value: 'flex-start', text: 'left' },
  { value: 'flex-end', text: 'right' },
  { value: 'space-evenly', text: 'spaced evenly' },
  { value: 'space-between', text: 'space between' },
  { value: 'space-around', text: 'space around' },
];

export const flexFlowOptions = [
  { value: '', text: 'choose an option' },
  { value: 'row nowrap', text: 'row without wrapping' },
  { value: 'row wrap', text: 'row with wrapping' },
  { value: 'column nowrap', text: 'column without wrapping' },
  { value: 'column wrap', text: 'column with wrapping' },
];
