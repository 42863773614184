import React from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import { useAuth } from '../../hooks/Auth';
import PrivateRoute from '../PrivateRoute/PrivateRoute';

const AdminRoute = ({ children, ...rest }) => {
  const auth = useAuth();

  if (String(auth?.data?.user?.type) === "0") {
    return <Redirect to="/" />;
  }

  return (
    <PrivateRoute {...rest}>
      {children}
    </PrivateRoute>
  );
};

AdminRoute.propTypes = {
  children: PropTypes.node,
  rest: PropTypes.object
};

export default AdminRoute;
