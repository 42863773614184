import React from 'react';
import './UnauthorizedWrapper.scss';
import PropTypes from 'prop-types';
import logo from '../../sharelocal.svg';

const UnauthorizedWrapper = ({ children, bottomComponent }) => (
  <section className="unauthorized-section">
    <img
      className="unauthorized-section__logo"
      src={logo}
      alt="ShareLocal logo"
    />
    <main className="unauthorized-section__main">{children}</main>
    {bottomComponent}
  </section>
);

UnauthorizedWrapper.propTypes = {
  children: PropTypes.node,
  bottomComponent: PropTypes.node,
};

export default UnauthorizedWrapper;
