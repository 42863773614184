import React from 'react';
import PropTypes from 'prop-types';
import './ContactSourceIcon.scss';
import { getIcon } from '../../utils/helper';

const ContactSourceIcon = ({ source }) => (
  <div className="source">{getIcon(source)}</div>
);

ContactSourceIcon.propTypes = {
  source: PropTypes.string.isRequired,
};

export default ContactSourceIcon;
