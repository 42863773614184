import React from 'react';
import PropTypes from 'prop-types';
import './IconToggle.scss';

const IconToggle = ({
  icon,
  name,
  status,
  onClick: handleClick,
  setStatus,
  disabled,
}) => (
  // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
  <label
    className={`icon-toggle ${status ? 'icon-toggle--active' : ''}`}
    htmlFor={name}>
    <input
      type="checkbox"
      onClick={() => {
        handleClick(!status);

        setStatus(!status);
      }}
      name={name}
      className="icon-toggle__input"
      disabled={disabled}
    />
    {icon}
  </label>
);

IconToggle.propTypes = {
  icon: PropTypes.object,
  name: PropTypes.string,
  status: PropTypes.bool,
  onClick: PropTypes.func,
  setStatus: PropTypes.func,
  disabled: PropTypes.bool,
};

export default IconToggle;
