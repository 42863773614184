import { useEffect, useState } from 'react';

const getState = (info) => ({
  width: info.width ?? '',
  padding: info.padding ?? '',
  backgroundColor: info.backgroundColor ?? '',
  medium: {
    width: info?.medium?.width ?? '',
    padding: info?.medium?.padding ?? '',
  },
  large: {
    width: info?.large?.width ?? '',
    padding: info?.large?.padding ?? '',
  },
});

const useMainContainerInfo = (info) => {
  const [mainContainerInfo, setMainContainerInfo] = useState(getState(info));

  useEffect(() => {
    setMainContainerInfo(getState(info));
  }, [info]);

  const handleChange = (propertyName, value, breakpoint = '') => {
    const newInfo = { ...mainContainerInfo };

    if (breakpoint === 'medium' || breakpoint === 'large') {
      newInfo[breakpoint] = {
        ...newInfo[breakpoint],
        [propertyName]: value,
      };
    } else {
      newInfo[propertyName] = value;
    }

    setMainContainerInfo(newInfo);
  };

  const inputs = [
    {
      type: 'text',
      value: mainContainerInfo.width,
      onChange: (evt) => handleChange('width', evt.target.value),
      label: 'WIDTH',
      name: 'mainContainerWidth',
      placeholder: '100%',
    },
    {
      type: 'text',
      value: mainContainerInfo.padding,
      onChange: (evt) => handleChange('padding', evt.target.value),
      label: 'PADDING',
      name: 'mainContainerPadding',
      placeholder: '0',
    },
    {
      type: 'text',
      value: mainContainerInfo.backgroundColor,
      onChange: (evt) => handleChange('backgroundColor', evt.target.value),
      label: 'BACKGROUND COLOR',
      name: 'mainContainerBackground',
      placeholder: '#ffffff',
    },
  ];

  const mediumInputs = [
    {
      type: 'text',
      value: mainContainerInfo.medium.width,
      onChange: (evt) => handleChange('width', evt.target.value, 'medium'),
      label: 'WIDTH',
      name: 'mainContainerWidthMedium',
      placeholder: '100%',
    },
    {
      type: 'text',
      value: mainContainerInfo.medium.padding,
      onChange: (evt) => handleChange('padding', evt.target.value, 'medium'),
      label: 'PADDING',
      name: 'mainContainerPaddingMedium',
      placeholder: '0',
    },
  ];

  const largeInputs = [
    {
      type: 'text',
      value: mainContainerInfo.large.width,
      onChange: (evt) => handleChange('width', evt.target.value, 'large'),
      label: 'WIDTH',
      name: 'mainContainerWidthLarge',
      placeholder: '600px',
    },
    {
      type: 'text',
      value: mainContainerInfo.large.padding,
      onChange: (evt) => handleChange('padding', evt.target.value, 'large'),
      label: 'PADDING',
      name: 'mainContainerPaddingLarge',
      placeholder: '0.5rem 0',
    },
  ];

  return { info: mainContainerInfo, inputs, mediumInputs, largeInputs };
};

export default useMainContainerInfo;
