import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './FormControl.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAsterisk, faSortDown } from '@fortawesome/free-solid-svg-icons';

const FormControl = ({
  name,
  width,
  label,
  type,
  placeholder,
  value,
  onChange,
  icon,
  iconAlignment,
  isIconInside,
  onIconClick,
  err,
  as,
  options,
  order,
  visibility,
  min,
  max,
  onFocus,
  onBlur,
  inputMode,
  pattern,
  disabled,
  readonly,
  autocomplete = 'off',
  required = false,
  isLoading = false,
  autoCompleteComponent,
  datalist,
  className = '',
}) => {
  const [isActive, setIsActive] = useState(false);

  const getInputClassName = () => {
    if (icon && isIconInside) {
      return iconAlignment === 'left'
        ? 'main__input--icon'
        : 'main__input--icon-right';
    }

    return '';
  };

  const getFormControlClassName = () => {
    const formControlWidth = parseFloat(width);

    if (formControlWidth <= 10) return 'form-control--x-small';
    if (formControlWidth <= 15) return 'form-control--small';
    if (formControlWidth <= 25) return 'form-control--quarter';
    if (formControlWidth <= 33) return 'form-control--third';
    if (formControlWidth <= 50) return 'form-control--half';
    if (formControlWidth <= 66) return 'form-control--two-thirds';

    return 'form-control--full';
  };

  const getChosenType = () => {
    switch (as) {
      case 'input':
      default:
        return (
          <input
            className={`main__input ${getInputClassName()}`}
            type={type}
            value={value ?? ''}
            placeholder={placeholder}
            onChange={onChange}
            onFocus={(evt) => {
              setIsActive(true);
              if (onFocus) onFocus(evt);
            }}
            onBlur={(evt) => {
              setIsActive(false);
              if (onBlur) onBlur(evt);
            }}
            disabled={disabled}
            min={min}
            max={max}
            inputMode={inputMode}
            // eslint-disable-next-line no-useless-escape
            pattern={pattern ?? '.*'}
            readOnly={readonly === true}
            autoComplete={autocomplete}
            name={name}
            id={name}
            tabIndex={order}
            checked={value === 'true' || value === true}
            list={`datalist_${name}`}
          />
        );
      case 'select':
        return (
          <div className="main__select-wrapper">
            <select
              className="main__select"
              onChange={onChange}
              onFocus={(evt) => {
                setIsActive(true);
                if (onFocus) onFocus(evt);
              }}
              onBlur={(evt) => {
                setIsActive(false);
                if (onBlur) onBlur(evt);
              }}
              value={value ?? ''}
              disabled={disabled}
              readOnly={readonly === true}
              autoComplete={autocomplete}
              name={name}
              id={name}
              required={required}
              tabIndex={order}>
              {options.map((opt) => (
                <option key={opt.value} value={opt.value}>
                  {opt.text}
                </option>
              ))}
            </select>
            <div className="main__select-icon-wrapper">
              <FontAwesomeIcon
                className="main__select-icon"
                icon={faSortDown}
              />
            </div>
          </div>
        );
      case 'textarea':
        return (
          <textarea
            className="main__textarea"
            type={type}
            value={value ?? ''}
            placeholder={placeholder}
            onChange={onChange}
            onFocus={(evt) => {
              setIsActive(true);
              if (onFocus) onFocus(evt);
            }}
            onBlur={(evt) => {
              setIsActive(false);
              if (onBlur) onBlur(evt);
            }}
            disabled={disabled}
            readOnly={readonly === true}
            autoComplete={autocomplete}
            name={name}
            id={name}
            required={required}
            tabIndex={order}
          />
        );
    }
  };

  if (visibility === false) return null;

  return (
    <div
      className={`form-control ${getFormControlClassName()} ${className}`}
      style={{ order }}>
      <div
        className={`form-control__main 
        ${isActive ? ' form-control__main--active' : ''} 
        ${
          type === 'checkbox' || type === 'radio'
            ? 'form-control__main--checkbox'
            : ''
        }
        ${isLoading ? 'form-control__main--loading' : ''}
        `}>
        {isLoading ? (
          <div className="main__loading-placeholder">&nbsp;</div>
        ) : null}
        <label
          className={`main__label ${
            !label || label === '' ? 'main__label--disabled' : ''
          } ${
            type === 'checkbox' || type === 'radio'
              ? 'main__label--checkbox'
              : ''
          }`}
          htmlFor={name}>
          {label}
          {required ? (
            <FontAwesomeIcon
              icon={faAsterisk}
              className="main__required-icon"
            />
          ) : null}
        </label>
        {getChosenType()}
        {autoCompleteComponent}
        {datalist}
        {type === 'checkbox' ? (
          <span className="main__checkbox-replacement" />
        ) : null}
        {isIconInside && icon ? (
          icon(
            iconAlignment === 'left' ? 'main__icon--left' : 'main__icon--right',
            onIconClick, // wont be recognized by some
          )
        ) : (
          <></>
        )}
      </div>
      <div className="form-control__error">
        <p className="error__text">{err}</p>
      </div>
      {!isIconInside && icon ? (
        icon(
          iconAlignment === 'left'
            ? 'form-control__icon--left'
            : 'form-control__icon--right',
          onIconClick, // wont be recognized by some
        )
      ) : (
        <></>
      )}
    </div>
  );
};

FormControl.propTypes = {
  name: PropTypes.string,
  width: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  type: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
    PropTypes.object,
    PropTypes.number,
  ]),
  onChange: PropTypes.func,
  icon: PropTypes.func, // icon will either be a FontAwesomeIcon or an img tag, passing order to the rendering function
  iconAlignment: PropTypes.string,
  isIconInside: PropTypes.bool,
  onIconClick: PropTypes.func,
  err: PropTypes.string,
  as: PropTypes.string, // describes what to render: input, select or textarea,
  options: PropTypes.arrayOf(PropTypes.object),
  order: PropTypes.number,
  visibility: PropTypes.bool,
  min: PropTypes.number,
  max: PropTypes.number,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  inputMode: PropTypes.string,
  pattern: PropTypes.string,
  disabled: PropTypes.bool,
  readonly: PropTypes.bool,
  autocomplete: PropTypes.string,
  required: PropTypes.bool,
  isLoading: PropTypes.bool,
  autoCompleteComponent: PropTypes.node,
  datalist: PropTypes.node,
  className: PropTypes.string,
};

export default FormControl;
