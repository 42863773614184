import React, { useEffect, useState } from 'react';
import createPlotlyComponent from 'react-plotly.js/factory';
import Plotly from '../../../utils/custom-plotly';
import { getDashboardChart } from '../../../utils/apiCalls';
import SectionHeader from '../../SectionHeader/SectionHeader';
import Datepicker from '../Datepicker/Datepicker';
import './Chart.scss';
import Loader from '../../Loader/Loader';
import { formatDateForChart, getDatepickerMessageString } from '../../../utils/helper';
import store, { NotificationTypes } from '../../../hooks/NotificationHub';

const Plot = createPlotlyComponent(Plotly);

const Chart = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [chartData, setChartData] = useState(null);
  const [traces, setTraces] = useState([]);
  const [layout, setLayout] = useState({
    showlegend: false,
    autosize: true,
    height: undefined,
    width: undefined,
    margin: {
      l: 30,
      r: 30,
      t: 10,
      b: 120,
      pad: 4,
    },
    colorway: ['#ff0000', '#00ff00', '#0000ff', '#ff00ff', '#333333'],
  });
  const [chosenDate, setChosenDate] = useState(new Date() - 86400000 * 30);

  const getChartData = async () => {
    setIsLoading(true);

    try {
      const response = await getDashboardChart(new Date(chosenDate));
      setChartData(response.data);
    } catch (err) {
      store.pushNotification(
        'Error',
        'Could not load your chart at the moment. Please refresh the page or try again later.',
        NotificationTypes.DANGER,
      );
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getChartData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chosenDate]);

  useEffect(() => {
    if (chartData) {
      const newTraces = [];

      Object.keys(chartData).forEach((key) => {
        const x = [];
        const y = [];

        Object.keys(chartData[key]).forEach((innerKey) => {
          x.push(formatDateForChart(innerKey));
          y.push(chartData[key][innerKey]);
        });

        const trace = {
          fill: 'tonexty',
          type: 'scatter',
          mode: 'none',
          name: key,
          hoverinfo: 'x+y+name',
          x,
          y,
        };

        newTraces.push(trace);
      });

      setTraces(newTraces);

      setLayout({
        ...layout,
        xaxis: {
          range: [
            newTraces[0]?.x[0],
            newTraces[0]?.x[newTraces[0]?.x?.length - 1],
          ],
          rangemode: 'tozero',
          tickformat: !(getDatepickerMessageString(chosenDate)
            .toUpperCase()
            .includes('DAY'))
            ? '%-I:%S %p'
            : '%x %-I:%M %p',
        },
        yaxis: {
          rangemode: 'tozero',
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chartData]);

  return (
    <section className="chart-wrapper">
      <SectionHeader text="TRAFFIC SOURCES" />
      <Datepicker date={chosenDate} setDate={setChosenDate} prefix="chart" />
      <Plot data={traces} layout={layout} className="chart" useResizeHandler />
      <Loader isLoading={isLoading} />
    </section>
  );
};

export default Chart;
