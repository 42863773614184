import React from 'react';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import UserPhoto from './userPhoto';
import UserInfo from './userInfo';
import SocialLinkGroup from '../social/socialLinkGroup';
import UserAddress from './userAddress';
import { CombinedInfo } from './userStyles';

const CombinedUserInfo = ({
  isAddressBelow,
  userPhotoProps,
  userInfoProps,
  userAddressProps,
  socialLinksGroupProps,
  isAddressVisibleOnAboutMe,
  isSocialVisible,
  isUserInfoVisible,
  isAddressVisible,
  onPhotoClick,
  ...rest
}) => {
  const location = useLocation();

  return (
    <CombinedInfo {...rest} photoWidth={userPhotoProps.width}>
      <UserPhoto {...userPhotoProps} isVisible onPhotoClick={onPhotoClick} />
      <div>
        {isUserInfoVisible ? (
          <UserInfo {...userInfoProps} isVisible width="100%" />
        ) : null}
        {isSocialVisible ? (
          <SocialLinkGroup {...socialLinksGroupProps} isVisible />
        ) : null}
        {/* address displayed on the side if isAddressBelow is false or user is on about page, address is visible on that page and isAddressBelow is false */}
        {isAddressVisible &&
        (!isAddressBelow ||
          (location.pathname === '/[id]/about' &&
            isAddressVisibleOnAboutMe &&
            !isAddressBelow)) ? (
          <UserAddress {...userAddressProps} isVisible />
        ) : (
          <></>
        )}
      </div>
      {/* address displayed below if isAddresBelow is true and user is on home page or user is on about page, address is visible on that page and isAddressBelow is true */}
      {(isAddressVisible &&
        isAddressBelow &&
        location.pathname !== '/[id]/about') ||
      (location.pathname === '/[id]/about' &&
        isAddressVisibleOnAboutMe &&
        isAddressBelow) ? (
        <UserAddress {...userAddressProps} isVisible />
      ) : (
        <></>
      )}
    </CombinedInfo>
  );
};

CombinedUserInfo.propTypes = {
  isAddressBelow: PropTypes.bool,
  userPhotoProps: PropTypes.object,
  userInfoProps: PropTypes.object,
  userAddressProps: PropTypes.object,
  isAddressVisibleOnAboutMe: PropTypes.bool,
  socialLinksGroupProps: PropTypes.object,
  isSocialVisible: PropTypes.bool,
  isUserInfoVisible: PropTypes.bool,
  isAddressVisible: PropTypes.bool,
  onPhotoClick: PropTypes.func,
};

export default CombinedUserInfo;
