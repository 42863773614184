import React, { useEffect, useState } from 'react';

const getState = (info) => ({
  order: info.order ?? 1,
  padding: info.padding ?? '',
  backgroundColor: info.backgroundColor ?? '',
  // logo props
  isLogoVisible: info.isLogoVisible ?? true,
  maxWidth: info.maxWidth ?? '',
  logoSize: info.logoSize ?? '', // small, medium, large or valid css value
  // logo props
  medium: {
    order: info?.medium?.order ?? 1,
    padding: info?.medium?.padding ?? '',
    // logo props
    maxWidth: info?.medium?.maxWidth ?? '',
    logoSize: info?.medium?.logoSize ?? '',
  },
  large: {
    order: info?.large?.order ?? 1,
    padding: info?.large?.padding ?? '',
    // logo props
    maxWidth: info?.large?.maxWidth ?? '',
    logoSize: info?.large?.logoSize ?? '',
  },
});

const useHeaderInfo = (info) => {
  const [headerInfo, setHeaderInfo] = useState(getState(info));

  useEffect(() => {
    setHeaderInfo(getState(info));
  }, [info]);

  const handleChange = (propertyName, value, breakpoint = '') => {
    const newInfo = { ...headerInfo };

    if (breakpoint === 'medium' || breakpoint === 'large') {
      newInfo[breakpoint] = {
        ...newInfo[breakpoint],
        [propertyName]: value,
      };
    } else {
      newInfo[propertyName] = value;
    }

    setHeaderInfo(newInfo);
  };

  const logoSizeDatalist = (name) => (
    <datalist id={`datalist_${name}`}>
      <option>small</option>
      <option>medium</option>
      <option>large</option>
    </datalist>
  );

  const inputs = [
    {
      type: 'number',
      value: headerInfo.order,
      onChange: (evt) => handleChange('order', evt.target.value),
      label: 'ORDER',
      name: 'headerOrder',
      placeholder: '1',
    },
    {
      type: 'text',
      value: headerInfo.padding,
      onChange: (evt) => handleChange('padding', evt.target.value),
      label: 'PADDING',
      name: 'headerPadding',
      placeholder: '0.65rem 0',
    },
    {
      type: 'text',
      value: headerInfo.backgroundColor,
      onChange: (evt) => handleChange('backgroundColor', evt.target.value),
      label: 'BACKGROUND COLOR',
      name: 'headerBackground',
      placeholder: '#ffffff',
    },
    {
      type: 'checkbox',
      value: headerInfo.isLogoVisible,
      onChange: (evt) => handleChange('isLogoVisible', evt.target.checked),
      label: 'IS LOGO VISIBLE?',
      name: 'headerLogoVisible',
    },
    {
      type: 'text',
      value: headerInfo.maxWidth,
      onChange: (evt) => handleChange('maxWidth', evt.target.value),
      label: 'MAX LOGO WIDTH',
      name: 'headerMaxLogoWidth',
      placeholder: '215px',
    },
    {
      type: 'text',
      value: headerInfo.logoSize,
      onChange: (evt) => handleChange('logoSize', evt.target.value),
      label: 'LOGO SIZE',
      name: 'headerLogoSize',
      datalist: logoSizeDatalist('headerLogoSize'),
      placeholder: 'large',
    },
  ];

  const mediumInputs = [
    {
      type: 'number',
      value: headerInfo.medium.order,
      onChange: (evt) => handleChange('order', evt.target.value, 'medium'),
      label: 'ORDER',
      name: 'headerOrderMedium',
      placeholder: '1',
    },
    {
      type: 'text',
      value: headerInfo.medium.padding,
      onChange: (evt) => handleChange('padding', evt.target.value, 'medium'),
      label: 'PADDING',
      name: 'headerPaddingMedium',
      placeholder: '1.5rem 0',
    },
    {
      type: 'text',
      value: headerInfo.medium.maxWidth,
      onChange: (evt) => handleChange('maxWidth', evt.target.value, 'medium'),
      label: 'MAX LOGO WIDTH',
      name: 'headerMaxLogoWidthMedium',
      placeholder: '250px',
    },
    {
      type: 'text',
      value: headerInfo.medium.logoSize,
      onChange: (evt) => handleChange('logoSize', evt.target.value, 'medium'),
      label: 'LOGO SIZE',
      name: 'headerLogoSizeMedium',
      datalist: logoSizeDatalist('headerLogoSizeMedium'),
      placeholder: 'medium',
    },
  ];

  const largeInputs = [
    {
      type: 'number',
      value: headerInfo.large.order,
      onChange: (evt) => handleChange('order', evt.target.value, 'large'),
      label: 'ORDER',
      name: 'headerOrderLarge',
      placeholder: '1',
    },
    {
      type: 'text',
      value: headerInfo.large.padding,
      onChange: (evt) => handleChange('padding', evt.target.value, 'large'),
      label: 'PADDING',
      name: 'headerPaddingLarge',
      placeholder: '1.55rem 0',
    },
    {
      type: 'text',
      value: headerInfo.large.maxWidth,
      onChange: (evt) => handleChange('maxWidth', evt.target.value, 'large'),
      label: 'MAX LOGO WIDTH',
      name: 'headerMaxLogoWidthLarge',
      placeholder: '300px',
    },
    {
      type: 'text',
      value: headerInfo.large.logoSize,
      onChange: (evt) => handleChange('logoSize', evt.target.value, 'large'),
      label: 'LOGO SIZE',
      name: 'headerLogoSizeLarge',
      datalist: logoSizeDatalist('headerLogoSizeLarge'),
      placeholder: 'medium',
    },
  ];

  return { info: headerInfo, inputs, mediumInputs, largeInputs };
};

export default useHeaderInfo;
