import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './StatisticsIcon.scss';

const StatisticsIcon = ({ icon, color }) => (
  <div className="statistics__icon-wrapper" style={{ backgroundColor: color }}>
    <FontAwesomeIcon icon={icon} className="statistics__icon" />
  </div>
);

StatisticsIcon.propTypes = {
  icon: PropTypes.object,
  color: PropTypes.string,
};

export default StatisticsIcon;
