import React from 'react';
import PropTypes from 'prop-types';
import { Redirect, Route } from 'react-router-dom';
import { useAuth } from '../../hooks/Auth';

const UnauthenticatedRoute = ({ path, exact, children }) => {
  const auth = useAuth();

  if (auth.data.isAuthenticated) {
    return <Redirect to="/" />;
  }

  return (
    <Route path={path} exact={exact}>
      {children}
    </Route>
  );
};

UnauthenticatedRoute.propTypes = {
  path: PropTypes.string,
  exact: PropTypes.bool,
  children: PropTypes.node,
};

export default UnauthenticatedRoute;
