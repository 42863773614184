import React from 'react';
import PropTypes from 'prop-types';
import { faEdit, faUpload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './Modal.scss';
import Button from '../../Button/Button';

const Modal = ({
  modalVisible,
  setModalVisible,
  editFunc,
  uploadNewFunc,
}) => {
  return (
    modalVisible && (
      <div className="image-editor__modal">
        <div className="image-editor__modal-inner">
          <div className="image-editor__modal-close-wrapper">
            <Button
              variant="secondary"
              text="CLOSE"
              onClick={() => setModalVisible(false)}
            />
          </div>
          <div className="image-editor__modal-buttons-wrapper">
            <button
              className="image-editor__modal-button"
              type="button"
              onClick={editFunc}>
              <FontAwesomeIcon color="#ffffff" icon={faEdit} />
              <span>Edit</span>
            </button>
            <button
              type="button"
              className="image-editor__modal-button"
              onClick={uploadNewFunc}>
              <FontAwesomeIcon color="#ffffff" icon={faUpload} />
              <span>Upload new</span>
            </button>
          </div>
        </div>
      </div>
    )
  );
};

Modal.propTypes = {
  modalVisible: PropTypes.bool.isRequired,
  setModalVisible: PropTypes.func.isRequired,
  editFunc: PropTypes.func.isRequired,
  uploadNewFunc: PropTypes.func.isRequired,
};

export default Modal;
