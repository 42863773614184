import React from 'react';
import './TextareaForm.scss';
import PropTypes from 'prop-types';
import SectionHeader from '../SectionHeader/SectionHeader';
import FormControl from '../FormControl/FormControl';

const TextareaForm = ({
  field,
  setField,
  header,
  label,
  maxLength,
  required,
}) => {
  const updateField = (evt) => {
    if (evt.target.value.length <= maxLength) setField(evt.target.value);
  };

  return (
    <section className="textarea-section">
      <SectionHeader text={header} />
      <div className="textarea-section__form">
        <FormControl
          as="textarea"
          value={field}
          onChange={updateField}
          label={label}
          width="100%"
          required={required}
        />
        <p className="textarea-section__remaining-characters">
          {field.length}/{maxLength}
        </p>
      </div>
    </section>
  );
};

TextareaForm.propTypes = {
  field: PropTypes.string,
  setField: PropTypes.func,
  header: PropTypes.string,
  label: PropTypes.string,
  maxLength: PropTypes.number,
  required: PropTypes.bool,
};

export default TextareaForm;
