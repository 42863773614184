import { useEffect, useState } from 'react';
import { textAlignOptions } from '../ThemesForm';

const getState = (info) => ({
  order: info.order ?? 5,
  padding: info.padding ?? '',
  backgroundColor: info.backgroundColor ?? '',
  color: info.color ?? '',
  fontSize: info.fontSize ?? '',
  fontWeight: info.fontWeight ?? '',
  textAlign: info.textAlign ?? '',
  medium: {
    order: info?.medium?.order ?? 5,
    padding: info?.medium?.padding ?? '',
    fontSize: info?.medium?.fontSize ?? '',
    fontWeight: info?.medium?.fontWeight ?? '',
    textAlign: info?.medium?.textAlign ?? '',
  },
  large: {
    order: info?.large?.order ?? 5,
    padding: info?.large?.padding ?? '',
    fontSize: info?.large?.fontSize ?? '',
    fontWeight: info?.large?.fontWeight ?? '',
    textAlign: info?.large?.textAlign ?? '',
  },
});

const useUserAddress = (info) => {
  const [addressInfo, setAddressInfo] = useState(getState(info));

  useEffect(() => {
    setAddressInfo(getState(info));
  }, [info]);

  const handleChange = (propertyName, value, element = '', breakpoint = '') => {
    const newInfo = { ...addressInfo };

    if (breakpoint === 'medium' || breakpoint === 'large') {
      if (element && element !== '') {
        newInfo[breakpoint][element] = {
          ...newInfo[breakpoint][element],
          [propertyName]: value,
        };
      } else {
        newInfo[breakpoint] = {
          ...newInfo[breakpoint],
          [propertyName]: value,
        };
      }
    } else if (element && element !== '') {
      newInfo[element][propertyName] = value;
    } else {
      newInfo[propertyName] = value;
    }

    setAddressInfo(newInfo);
  };

  const inputs = [
    {
      type: 'number',
      value: addressInfo.order,
      onChange: (evt) => handleChange('order', evt.target.value),
      label: 'ORDER',
      name: 'addressOrder',
      placeholder: '5',
    },
    {
      type: 'text',
      value: addressInfo.padding,
      onChange: (evt) => handleChange('padding', evt.target.value),
      label: 'PADDING',
      name: 'addressPadding',
      placeholder: '0 0 0.15rem 0.95rem',
    },
    {
      type: 'text',
      value: addressInfo.backgroundColor,
      onChange: (evt) => handleChange('backgroundColor', evt.target.value),
      label: 'BACKGROUND COLOR',
      name: 'addressBackgroundColor',
      placeholder: '#ffffff',
    },
    {
      type: 'text',
      value: addressInfo.color,
      onChange: (evt) => handleChange('color', evt.target.value),
      label: 'COLOR',
      name: 'addressColor',
      placeholder: '#000000',
    },
    {
      type: 'text',
      value: addressInfo.fontSize,
      onChange: (evt) => handleChange('fontSize', evt.target.value),
      label: 'FONT SIZE',
      name: 'addressFontSize',
      placeholder: '0.8rem',
    },
    {
      type: 'text',
      value: addressInfo.fontWeight,
      onChange: (evt) => handleChange('fontWeight', evt.target.value),
      label: 'FONT WEIGHT',
      name: 'addressFontWeight',
      placeholder: '300',
    },
    {
      as: 'select',
      options: textAlignOptions,
      value: addressInfo.textAlign,
      onChange: (evt) => handleChange('textAlign', evt.target.value),
      label: 'TEXT ALIGN',
      name: 'addressTextAlign',
    },
  ];

  const mediumInputs = [
    {
      type: 'number',
      value: addressInfo.medium.order,
      onChange: (evt) =>
        handleChange('order', evt.target.value, undefined, 'medium'),
      label: 'ORDER',
      name: 'addressOrderMedium',
      placeholder: '5',
    },
    {
      type: 'text',
      value: addressInfo.medium.padding,
      onChange: (evt) =>
        handleChange('padding', evt.target.value, undefined, 'medium'),
      label: 'PADDING',
      name: 'addressPaddingMedium',
      placeholder: '0 0 0.65rem 1.1rem',
    },
    {
      type: 'text',
      value: addressInfo.medium.fontSize,
      onChange: (evt) =>
        handleChange('fontSize', evt.target.value, undefined, 'medium'),
      label: 'FONT SIZE',
      name: 'addressFontSizeMedium',
      placeholder: '0.8rem',
    },
    {
      type: 'text',
      value: addressInfo.medium.fontWeight,
      onChange: (evt) =>
        handleChange('fontWeight', evt.target.value, undefined, 'medium'),
      label: 'FONT WEIGHT',
      name: 'addressFontWeightMedium',
      placeholder: '300',
    },
    {
      as: 'select',
      options: textAlignOptions,
      value: addressInfo.medium.textAlign,
      onChange: (evt) =>
        handleChange('textAlign', evt.target.value, undefined, 'medium'),
      label: 'TEXT ALIGN',
      name: 'addressTextAlignMedium',
    },
  ];

  const largeInputs = [
    {
      type: 'number',
      value: addressInfo.large.order,
      onChange: (evt) =>
        handleChange('order', evt.target.value, undefined, 'large'),
      label: 'ORDER',
      name: 'addressOrderLarge',
      placeholder: '5',
    },
    {
      type: 'text',
      value: addressInfo.large.padding,
      onChange: (evt) =>
        handleChange('padding', evt.target.value, undefined, 'large'),
      label: 'PADDING',
      name: 'addressPaddingLarge',
      placeholder: '0 0 0.65rem 2.2rem',
    },
    {
      type: 'text',
      value: addressInfo.large.fontSize,
      onChange: (evt) =>
        handleChange('fontSize', evt.target.value, undefined, 'large'),
      label: 'FONT SIZE',
      name: 'addressFontSizeLarge',
      placeholder: '1rem',
    },
    {
      type: 'text',
      value: addressInfo.large.fontWeight,
      onChange: (evt) =>
        handleChange('fontWeight', evt.target.value, undefined, 'large'),
      label: 'FONT WEIGHT',
      name: 'addressFontWeightLarge',
      placeholder: '300',
    },
    {
      as: 'select',
      options: textAlignOptions,
      value: addressInfo.large.textAlign,
      onChange: (evt) =>
        handleChange('textAlign', evt.target.value, undefined, 'large'),
      label: 'TEXT ALIGN',
      name: 'addressTextAlignLarge',
    },
  ];

  return { info: addressInfo, inputs, mediumInputs, largeInputs };
};

export default useUserAddress;
