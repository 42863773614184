import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import ImgEditor from '../ImgEditor/ImgEditor';
import Notification from '../Notification/Notification';
import PageHeader from '../PageHeader/PageHeader';
import SideMenu from '../SideMenu/SideMenu';
import './Contacts.scss';
import ContactsList from './ContactsList/ContactsList';
import ContactsListForm from './ContactsListForm/ContactsListForm';

const Contacts = () => {
  const location = useLocation();

  const [path, setPath] = useState(null);
  const [imageUrl, setImageUrl] = useState('');
  const [menuVisibility, setMenuVisibility] = useState(false);
  const [chosenContactId, setChosenContactId] = useState('');
  const [toggleReload, setToggleReload] = useState(0);

  const [notification, setNotification] = useState(null);

  useEffect(() => {
    if (location?.state?.message) {
      setNotification(
        <Notification
          title="SUCCESS"
          message={location.state.message}
          visibility
        />,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!menuVisibility) {
      setChosenContactId('');
      setImageUrl('');
    }
  }, [menuVisibility]);

  return (
    <section className="contacts">
      {path ? (
        <ImgEditor
          path={path}
          close={() => setPath(null)}
          setImageUrl={setImageUrl}
        />
      ) : null}
      <PageHeader text="Contacts" />
      <ContactsList
        setMenuVisibility={setMenuVisibility}
        setContactId={setChosenContactId}
        toggleReload={toggleReload}
      />
      <SideMenu
        headerText={chosenContactId ? 'Edit a Contact' : 'Add a Contact'}
        headerIcon={faPlusCircle}
        visibility={menuVisibility}
        setVisibility={setMenuVisibility}>
        <ContactsListForm
          contactId={chosenContactId}
          onCancelClick={() => {
            setToggleReload(toggleReload + 1);
            setMenuVisibility(false);
            setChosenContactId('');
          }}
          setImagePath={setPath}
          imagePath={path}
          imageUrl={imageUrl}
          setImageUrl={setImageUrl}
        />
      </SideMenu>
      {notification}
    </section>
  );
};

export default Contacts;
