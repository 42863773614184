/* eslint-disable no-shadow */
import React, { useState, useEffect } from 'react';
import './App.scss';
import { Switch, Route, useLocation, useHistory } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import ReactNotification from 'react-notifications-component';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import Sidebar from '../Sidebar/Sidebar';
import Dashboard from '../Dashboard/Dashboard';
import MBCSettings from '../MBCSettings/MBCSettings';
import Account from '../Account/Account';
import PageHeader from '../PageHeader/PageHeader';
import Login, { loginBottomComponent } from '../Login/Login';
import PasswordReminder from '../PasswordReminder/PasswordReminder';
import PrivateRoute from '../PrivateRoute/PrivateRoute';
import Register from '../Register/Register';
import { getPageTitle } from '../../utils/helper';
import UsersManagement from '../Admin/UsersManagement/UsersManagement';
import Leads from '../Admin/Leads/Leads';
import Keywords from '../Admin/Keywords/Keywords';
import Themes from '../Admin/Themes/Themes';
import Contacts from '../Contacts/Contacts';
import Welcome from '../Welcome/Welcome';
import ContactImport from '../Contacts/ContactImport/ContactImport';
import PasswordChangeForm from '../PasswordChangeForm/PasswordChangeForm';
import UnauthorizedWrapper from '../UnauthorizedWrapper/UnauthorizedWrapper';
import AdminRoute from '../AdminRoute';
import UnauthenticatedRoute from '../UnauthenticatedRoute/UnauthenticatedRoute';
import 'react-notifications-component/dist/theme.css';
import MaintenancePage from '../MaintenancePage/MaintenancePage';
import { useAuth } from '../../hooks/Auth';
import MBCPage from '../MBC/MBCPage';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

const App = () => {
  const auth = useAuth();
  const location = useLocation();
  const history = useHistory();
  const [pageTitle, setPageTitle] = useState(null);

  useEffect(() => {
    if (!auth.data.user.onboarded) {
      history.push('/account');
    }
  }, []);

  useEffect(() => {
    setPageTitle(
      <Helmet>
        <title>{`${getPageTitle(location.pathname)} | Share Local`}</title>
      </Helmet>,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <>
      {pageTitle}
      <ReactNotification />
      <Switch>
        <Route path={['/mbc/user/:id', '/mbc/user/:id/about']}>
          <MBCPage />
        </Route>
        <Route path="/login">
          <UnauthorizedWrapper bottomComponent={loginBottomComponent}>
            <Login />
          </UnauthorizedWrapper>
        </Route>
        <UnauthenticatedRoute path="/register">
          <UnauthorizedWrapper>
            <Register />
          </UnauthorizedWrapper>
        </UnauthenticatedRoute>
        <UnauthenticatedRoute exact path="/password/remind">
          <UnauthorizedWrapper>
            <PasswordReminder />
          </UnauthorizedWrapper>
        </UnauthenticatedRoute>
        <Route exact path="/password/reset">
          <UnauthorizedWrapper>
            <PasswordChangeForm />
          </UnauthorizedWrapper>
        </Route>
        <Route exact path="/account/activate">
          <UnauthorizedWrapper>
            <PasswordChangeForm isActivatingAccount />
          </UnauthorizedWrapper>
        </Route>
        <Route exact path="/account/change-email">
          <UnauthorizedWrapper bottomComponent={loginBottomComponent}>
            <Login />
          </UnauthorizedWrapper>
        </Route>
        <Route path="/maintenance">
          <MaintenancePage />
        </Route>
        <Route>
          <div className="App">
            <Sidebar
              disabled={
                location.pathname === '/welcome' || !auth.data.user.onboarded
              }
            />
            <main className="App__content">
              <Switch>
                <PrivateRoute exact path="/">
                  <Dashboard />
                </PrivateRoute>
                <PrivateRoute path="/links">
                  <PageHeader text="Links" />
                </PrivateRoute>
                <PrivateRoute exact path="/mbc">
                  <MBCSettings />
                </PrivateRoute>
                <PrivateRoute exact path="/contacts">
                  <Contacts />
                </PrivateRoute>
                <PrivateRoute path="/contacts/import">
                  <ContactImport />
                </PrivateRoute>
                <AdminRoute exact path="/account">
                  <Elements stripe={stripePromise}>
                    <Account />
                  </Elements>
                </AdminRoute>
                <PrivateRoute path="/welcome">
                  <Welcome />
                </PrivateRoute>
                <AdminRoute path="/admin/users">
                  <UsersManagement />
                </AdminRoute>
                <AdminRoute path="/admin/leads">
                  <Leads />
                </AdminRoute>
                <AdminRoute path="/admin/keywords">
                  <Keywords />
                </AdminRoute>
                <AdminRoute path="/admin/themes">
                  <Themes />
                </AdminRoute>
              </Switch>
            </main>
          </div>
        </Route>
      </Switch>
    </>
  );
};

export default App;
