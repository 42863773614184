import React, { useState, useEffect } from 'react';
import Autocomplete from '../components/Autocomplete/Autocomplete';

// eslint-disable-next-line import/prefer-default-export
export const useContactFields = (
  userData,
  inputsSettings,
  isResetting,
  isLoading,
) => {
  const [currentUserInformation, setCurrentUserInformation] = useState({
    ...userData,
    err: {},
  });
  const [showAutocompleteResults, setShowAutocompleteResults] = useState({
    street: false,
    city: false,
    state: false,
    country: false,
    zipCode: false,
  });
  const [addressData, setAddressData] = useState(null);

  useEffect(() => {
    if (isResetting || userData) {
      setCurrentUserInformation({ ...userData, err: {} });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isResetting, userData]);

  useEffect(() => {
    if (addressData) {
      const label = addressData.label.split(',');
      setCurrentUserInformation({
        ...currentUserInformation,
        street: label[label.length - 1].trim(),
        state: addressData.address.state ?? '',
        country: addressData.address.country ?? '',
        zipCode: addressData.address.postalCode ?? '',
        city: addressData.address.city ?? '',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addressData]);

  const validateAndUpdateField = (key, name, event, currentState) => {
    let valid = true;
    let newState = { ...currentState };
    if (
      inputsSettings &&
      (inputsSettings[key]?.required === true ||
        (inputsSettings[key]?.required === undefined && key !== 'mobile')) &&
      event.target.value === ''
    ) {
      newState = {
        ...currentState,
      };
      newState.err[key] = `${name} cannot be empty!`;
      valid = false;
    }

    if (
      inputsSettings &&
      currentState[key] &&
      currentState[key] !== '' &&
      event.target.value !== '' &&
      inputsSettings[key]?.minLength &&
      event.target.value.replace(/\D/g, '').length <
        inputsSettings[key]?.minLength
    ) {
      newState = {
        ...currentState,
      };
      newState.err[
        key
      ] = `${name} must be at least ${inputsSettings[key]?.minLength} characters long.`;
      valid = false;
    }

    newState[key] = event.target.value;

    if (valid) newState.err[key] = '';

    setCurrentUserInformation(newState);
  };

  const clearErrorForAddressFields = (currentState) => {
    const newState = { ...currentState };

    newState.err.street = '';
    newState.err.city = '';
    newState.err.state = '';
    newState.err.country = '';
    newState.err.zipCode = '';

    setCurrentUserInformation(newState);
  };

  const inputs = [
    {
      stateKey: 'firstName',
      validationKey: 'firstName',
      name: 'First Name',
      label: 'FIRST NAME',
      type: 'text',
      value: currentUserInformation.firstName,
      onChange: (evt) =>
        validateAndUpdateField(
          'firstName',
          'First name',
          evt,
          currentUserInformation,
        ),
      err: currentUserInformation.err.firstName,
      width: inputsSettings?.firstName?.width || '24%',
      order: inputsSettings?.firstName?.order || 1,
      visibility: inputsSettings?.firstName?.visibility,
      isLoading,
      required: inputsSettings?.firstName?.required,
    },
    {
      stateKey: 'lastName',
      validationKey: 'lastName',
      name: 'Last Name',
      label: 'LAST NAME',
      type: 'text',
      value: currentUserInformation.lastName,
      onChange: (evt) =>
        validateAndUpdateField(
          'lastName',
          'Last name',
          evt,
          currentUserInformation,
        ),
      err: currentUserInformation.err.lastName,
      width: inputsSettings?.lastName?.width || '24%',
      order: inputsSettings?.lastName?.order || 1,
      visibility: inputsSettings?.lastName?.visibility,
      isLoading,
      required: inputsSettings?.lastName?.required,
    },
    {
      stateKey: 'title',
      validationKey: 'title',
      name: 'Title',
      label: 'TITLE',
      type: 'text',
      value: currentUserInformation.title,
      onChange: (evt) =>
        validateAndUpdateField('title', 'Title', evt, currentUserInformation),
      err: currentUserInformation.err.title,
      width: inputsSettings?.title?.width || '24%',
      order: inputsSettings?.title?.order || 1,
      visibility: inputsSettings?.title?.visibility,
      isLoading,
      required: inputsSettings?.title?.required,
    },
    {
      stateKey: 'company',
      validationKey: 'company',
      name: 'Company',
      label: 'COMPANY',
      type: 'text',
      value: currentUserInformation.company,
      onChange: (evt) =>
        validateAndUpdateField(
          'company',
          'Company',
          evt,
          currentUserInformation,
        ),
      err: currentUserInformation.err.company,
      width: inputsSettings?.company?.width || '24%',
      order: inputsSettings?.company?.order || 1,
      visibility: inputsSettings?.company?.visibility,
      isLoading,
      required: inputsSettings?.company?.required,
      disabled: inputsSettings?.company?.disabled ?? true,
    },
    {
      stateKey: 'phone',
      validationKey: 'phone',
      name: 'Phone number',
      label: 'PHONE NUMBER',
      type: 'text',
      value: currentUserInformation.phone,
      onChange: (evt) =>
        validateAndUpdateField(
          'phone',
          'Phone number',
          evt,
          currentUserInformation,
        ),
      err: currentUserInformation.err.phone,
      width: inputsSettings?.phone?.width || '24%',
      order: inputsSettings?.phone?.order || 1,
      visibility: inputsSettings?.phone?.visibility,
      minLength: inputsSettings?.phone?.minLength,
      isLoading,
      required: inputsSettings?.phone?.required,
    },
    {
      stateKey: 'mobile',
      validationKey: 'mobile',
      name: 'Mobile number',
      label: 'MOBILE NUMBER',
      type: 'text',
      value: currentUserInformation.mobile,
      onChange: (evt) =>
        validateAndUpdateField(
          'mobile',
          'Mobile number',
          evt,
          currentUserInformation,
        ),
      err: currentUserInformation.err.mobile,
      width: inputsSettings?.mobile?.width || '24%',
      order: inputsSettings?.mobile?.order || 1,
      visibility: inputsSettings?.mobile?.visibility ?? false,
      isLoading,
      required: inputsSettings?.mobile?.required,
    },
    {
      stateKey: 'myWebsiteUrl',
      validationKey: 'myWebsiteUrl',
      name: 'Your Site',
      label: 'YOUR SITE',
      type: 'text',
      value: currentUserInformation.myWebsiteUrl,
      onChange: (evt) =>
        validateAndUpdateField(
          'myWebsiteUrl',
          'Your site',
          evt,
          currentUserInformation,
        ),
      err: currentUserInformation.err.myWebsiteUrl,
      width: inputsSettings?.myWebsiteUrl?.width || '33%',
      order: inputsSettings?.myWebsiteUrl?.order || 1,
      visibility: inputsSettings?.myWebsiteUrl?.visibility,
      isLoading,
      required: inputsSettings?.myWebsiteUrl?.required,
    },
    {
      stateKey: 'email',
      validationKey: 'email',
      name: 'Email',
      label: 'EMAIL',
      type: 'email',
      value: currentUserInformation.email,
      onChange: (evt) =>
        validateAndUpdateField('email', 'Email', evt, currentUserInformation),
      err: currentUserInformation.err.email,
      width: inputsSettings?.email?.width || '33%',
      order: inputsSettings?.email?.order || 1,
      visibility: inputsSettings?.email?.visibility,
      isLoading,
      required: inputsSettings?.email?.required,
    },
    {
      stateKey: 'street',
      validationKey: 'street',
      name: 'Address',
      label: 'ADDRESS',
      type: 'text',
      value: currentUserInformation.street,
      onChange: (evt) =>
        validateAndUpdateField(
          'street',
          'Address',
          evt,
          currentUserInformation,
        ),
      err: currentUserInformation.err.street,
      width: inputsSettings?.street?.width || '33%',
      order: inputsSettings?.street?.order || 1,
      visibility: inputsSettings?.street?.visibility,
      isLoading,
      required: inputsSettings?.street?.required,
      autoCompleteComponent: (
        <Autocomplete
          query={currentUserInformation.street}
          setData={(data) => {
            setAddressData(data);
            setShowAutocompleteResults({});
            clearErrorForAddressFields(currentUserInformation);
          }}
          showResults={showAutocompleteResults.street}
          setShowResults={setShowAutocompleteResults}
        />
      ),
      onFocus: () => setShowAutocompleteResults({ street: true }),
      // setTimeout is needed in these onBlurs - otherwise state wouldn't update
      onBlur: () => setTimeout(() => setShowAutocompleteResults({}), 100),
    },
    {
      stateKey: 'apt',
      validationKey: 'apt',
      name: 'Apt Suite',
      label: 'APT/SUITE',
      type: 'text',
      value: currentUserInformation.apt,
      onChange: (evt) =>
        validateAndUpdateField('apt', 'Apt/Suite', evt, currentUserInformation),
      err: currentUserInformation.err.apt,
      width: inputsSettings?.apt?.width || '10%',
      order: inputsSettings?.apt?.order || 1,
      visibility: inputsSettings?.apt?.visibility,
      isLoading,
      required: inputsSettings?.apt?.required,
    },
    {
      stateKey: 'city',
      validationKey: 'city',
      name: 'City',
      label: 'CITY',
      type: 'text',
      value: currentUserInformation.city,
      onChange: (evt) =>
        validateAndUpdateField('city', 'City', evt, currentUserInformation),
      err: currentUserInformation.err.city,
      width: inputsSettings?.city?.width || '32%',
      order: inputsSettings?.city?.order || 1,
      visibility: inputsSettings?.city?.visibility,
      isLoading,
      required: inputsSettings?.city?.required,
      autoCompleteComponent: (
        <Autocomplete
          query={currentUserInformation.city}
          setData={(data) => {
            setAddressData(data);
            setShowAutocompleteResults({});
            clearErrorForAddressFields(currentUserInformation);
          }}
          showResults={showAutocompleteResults.city}
          setShowResults={setShowAutocompleteResults}
        />
      ),
      onFocus: () => setShowAutocompleteResults({ city: true }),
      onBlur: () => setTimeout(() => setShowAutocompleteResults({}), 100),
    },
    {
      stateKey: 'state',
      validationKey: 'state',
      name: 'State',
      label: 'STATE',
      type: 'text',
      value: currentUserInformation.state,
      onChange: (evt) =>
        validateAndUpdateField('state', 'State', evt, currentUserInformation),
      err: currentUserInformation.err.state,
      width: inputsSettings?.state?.width || '15%',
      order: inputsSettings?.state?.order || 1,
      visibility: inputsSettings?.state?.visibility,
      isLoading,
      required: inputsSettings?.state?.required,
      autoCompleteComponent: (
        <Autocomplete
          query={currentUserInformation.state}
          setData={(data) => {
            setAddressData(data);
            setShowAutocompleteResults({});
            clearErrorForAddressFields(currentUserInformation);
          }}
          showResults={showAutocompleteResults.state}
          setShowResults={setShowAutocompleteResults}
        />
      ),
      onFocus: () => setShowAutocompleteResults({ state: true }),
      onBlur: () => setTimeout(() => setShowAutocompleteResults({}), 100),
    },
    {
      stateKey: 'country',
      validationKey: 'country',
      name: 'Country',
      label: 'COUNTRY',
      value: currentUserInformation.country,
      width: inputsSettings?.country?.width || '15%',
      onChange: (evt) => {
        validateAndUpdateField(
          'country',
          'Country',
          evt,
          currentUserInformation,
        );
        setCurrentUserInformation({
          ...currentUserInformation,
          country: evt.target.value,
        });
      },
      err: currentUserInformation.err.country,
      order: inputsSettings?.country?.order || 1,
      visibility: inputsSettings?.country?.visibility,
      isLoading,
      required: inputsSettings?.country?.required,
      autoCompleteComponent: (
        <Autocomplete
          query={currentUserInformation.country}
          setData={(data) => {
            setAddressData(data);
            setShowAutocompleteResults({});
            clearErrorForAddressFields(currentUserInformation);
          }}
          showResults={showAutocompleteResults.country}
          setShowResults={setShowAutocompleteResults}
        />
      ),
      onFocus: () => setShowAutocompleteResults({ country: true }),
      onBlur: () => setTimeout(() => setShowAutocompleteResults({}), 100),
    },
    {
      stateKey: 'zipCode',
      validationKey: 'zipCode',
      name: 'ZIP Code',
      label: 'ZIP CODE',
      value: currentUserInformation.zipCode,
      width: inputsSettings?.zipCode?.width || '15%',
      onChange: (evt) => {
        validateAndUpdateField(
          'zipCode',
          'ZIP Code',
          evt,
          currentUserInformation,
        );
        setCurrentUserInformation({
          ...currentUserInformation,
          zipCode: evt.target.value,
        });
      },
      err: currentUserInformation.err.zipCode,
      order: inputsSettings?.zipCode?.order || 1,
      visibility: inputsSettings?.zipCode?.visibility,
      isLoading,
      required: inputsSettings?.zipCode?.required,
      autoCompleteComponent: (
        <Autocomplete
          query={currentUserInformation.zipCode}
          setData={(data) => {
            setAddressData(data);
            setShowAutocompleteResults({});
            clearErrorForAddressFields(currentUserInformation);
          }}
          showResults={showAutocompleteResults.zipCode}
          setShowResults={setShowAutocompleteResults}
        />
      ),
      onFocus: () => setShowAutocompleteResults({ zipCode: true }),
      onBlur: () => setTimeout(() => setShowAutocompleteResults({}), 100),
    },
  ];

  const validateOnSubmit = () => {
    let valid = true;
    let errorMessages = [];
    const newState = { ...currentUserInformation };

    inputs.forEach((inp) => {
      if (inputsSettings[inp.validationKey]?.visibility) {
        if (inp.required && inp.value === '') {
          valid = false;
          newState.err[inp.stateKey] = `${inp.name} cannot be empty!`;
        }

        if (
          inp.value !== '' &&
          inputsSettings[inp.validationKey]?.minLength &&
          inp.value.replace(/\D/g, '').length <
            inputsSettings[inp.validationKey]?.minLength
        ) {
          valid = false;
          newState.err[inp.stateKey] = `${inp.name} must be at least ${
            inputsSettings[inp.validationKey]?.minLength
          } characters long.`;
        }

        errorMessages = Object.values(newState.err).filter((err) => !!err);
        setCurrentUserInformation(newState);
      }
    });

    return [valid, errorMessages];
  };

  return {
    inputs,
    contactInfo: currentUserInformation,
    addressData,
    validateOnSubmit,
  };
};
