export const mbcInputSettings = {
  email: { required: true },
  phone: { required: true, minLength: 10 },
  firstName: { required: true },
  lastName: { required: true },
  title: { required: true },
  company: { required: true },
  street: { required: true },
  apt: { required: true },
  city: { required: true },
  state: { required: true },
  country: { required: true },
  zipCode: { required: true },
  aboutMeCopyTxt: { required: true },
  photoUrl: { required: true },
  logoUrl: { required: true },
};

export const emptyMBCState = {
  uuid: '',
  ssoId: 0,
  firstName: '',
  lastName: '',
  title: '',
  company: '',
  phone: '',
  street: '',
  apt: '',
  city: '',
  state: '',
  country: '',
  zipCode: '',
  email: '',
  myWebsiteUrl: '',
  aboutMeCopyTxt: '',
  photoUrl: '',
  logoUrl: '',
  footer: '',
  facebookUrl: '',
  twitterUrl: '',
  instagramUrl: '',
  linkedinUrl: '',
  mbcThemeId: '',
};

export const mapResponseToMBCState = (data, auth) => ({
  uuid: data.uuid ?? '',
  firstName: data.first_name ?? '',
  lastName: data.last_name ?? '',
  title: data.title ?? '',
  email: data.email ?? '',
  company: data.company ?? auth.data.user.companyName ?? '',
  phone: data.phone ?? '',
  street: data.street ?? '',
  apt: data.apt ?? '',
  city: data.city ?? '',
  state: data.state ?? '',
  country: data.country ?? '',
  zipCode: data.zip_code ?? '',
  aboutMeCopyTxt: data.about ?? '',
  footer: data.footer ?? '',
  photoUrl: data.photo_url ?? '',
  logoUrl: data.logo_url ?? '',
  facebookUrl: data.facebook_url ?? '',
  twitterUrl: data.twitter_url ?? '',
  instagramUrl: data.instagram_url ?? '',
  linkedinUrl: data.linkedin_url ?? '',
  myWebsiteUrl: data.website ?? '',
  mbcThemeId: data.mbc_theme_id ?? '',
});
