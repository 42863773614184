import React from 'react';
import PropTypes from 'prop-types';
import { Text } from './aboutMeStyles';

const AboutMeText = ({ AboutMeCopyText, ...rest }) => (
  <Text {...rest}>
    <h2>About Me</h2>
    <p>{AboutMeCopyText}</p>
  </Text>
);

AboutMeText.propTypes = {
  AboutMeCopyText: PropTypes.string,
};

export default AboutMeText;
