import React from 'react';
import './Leads.scss';
import { Route, Switch } from 'react-router-dom';
import PageHeader from '../../PageHeader/PageHeader';
import LeadsTable from './LeadsTable/LeadTable'

const Leads = () => {
  return (
    <section className="leads">
      <PageHeader text="Leads" />
      <Switch>
        <Route exact path="/admin/leads">
          <LeadsTable />
        </Route>
      </Switch>
    </section>
  );
};

export default Leads;
