import React from 'react';
import PropTypes from 'prop-types';
import { Social } from './socialLinkStyles';

const SocialLink = ({ imgSrc, href, site, ...rest }) => {
  if (!href) return null;

  return (
    <Social {...rest} href={href} target="_blank" rel="noopener noreferrer">
      <img src={imgSrc} alt={site} />
    </Social>
  );
};

SocialLink.propTypes = {
  imgSrc: PropTypes.string,
  href: PropTypes.string,
  site: PropTypes.string,
};

export default SocialLink;
