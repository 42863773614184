import React from 'react';
import PropTypes from 'prop-types';
import { UserSection } from './userStyles';

const UserInfo = ({ FirstName, LastName, Title, ...rest }) => (
  <UserSection {...rest}>
    <h1>{`${FirstName ?? ''} ${LastName ?? ''}`}</h1>
    <span>{Title ?? ''}</span>
  </UserSection>
);

UserInfo.propTypes = {
  FirstName: PropTypes.string,
  LastName: PropTypes.string,
  Title: PropTypes.string,
};

export default UserInfo;
