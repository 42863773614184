import React from 'react';
import './UsersTableEntry.scss';
import PropTypes from 'prop-types';
import { faUserShield } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const UsersTableEntry = ({
  id,
  uuid,
  email,
  firstName,
  lastName,
  isAdmin,
  status,
  actions,
}) => (
  <div className="users-table__entry">
    <div className="entry__column entry__column--id">{id}</div>
    <div className="entry__column entry__column--is_admin">
      <>
        {typeof isAdmin === 'boolean' && isAdmin && (
          <FontAwesomeIcon
            style={{ fontSize: '1rem' }}
            color="#212223"
            icon={faUserShield}
          />
        )}
        {typeof isAdmin !== 'boolean' && isAdmin}
      </>
    </div>
    <div className="entry__column entry__column--uuid">{uuid}</div>
    <div className="entry__column entry__column--email">{email}</div>
    <div className="entry__column entry__column--first_name">{firstName}</div>
    <div className="entry__column entry__column--last_name">{lastName}</div>
    <div className="entry__column entry__column--status">{status}</div>
    <div className="entry__column entry__column--actions">{actions}</div>
  </div>
);

UsersTableEntry.propTypes = {
  id: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
    PropTypes.object,
  ]),
  uuid: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  email: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  firstName: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  lastName: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  isAdmin: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.bool,
  ]),
  status: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  actions: PropTypes.oneOfType([PropTypes.object, PropTypes.node]),
};

export default UsersTableEntry;
