/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import './PreviewContainer.scss';
import PropTypes from 'prop-types';
import { createComponentProps } from '../helper';
import Header from '../shared/header';
import Footer from '../shared/footer';
import Loader from '../../Loader/Loader';
import UserPhoto from '../user/userPhoto';
import UserInfo from '../user/userInfo';
import UserAddress from '../user/userAddress';
import SocialLinkGroup from '../social/socialLinkGroup';
import CombinedUserInfo from '../user/combinedUserInfo';
import ButtonGroup from '../buttons/buttonGroup';
import AboutMeText from '../about/aboutMeText';
import SendModal from '../sendModal/sendModal';

const PreviewContainer = ({
  user,
  theme,
  onPhotoClick,
  previewRefresh,
  currentPage = 'home',
  onAboutClick,
  onBackButtonClick,
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [props, setProps] = useState(null);
  const [visibility, setVisibility] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    if (user && theme) {
      setProps(
        createComponentProps(
          currentPage === 'home' ? theme.home : theme.about,
          theme,
          user,
          user.id,
          setIsModalOpen,
        ),
      );
      setVisibility(currentPage === 'home' ? theme.home : theme.about);
    }
  }, [user, theme, previewRefresh, currentPage]);

  useEffect(() => {
    if (props) {
      setTimeout(() => setIsLoading(false), 500);
    }
  }, [props]);

  return (
    <div className="preview-container">
      {isLoading && <Loader isLoading={isLoading} />}
      {props && (
        <>
          <Header
            {...props.headerProps}
            onBackButtonClick={onBackButtonClick}
            currentPage={currentPage}
          />
          <UserPhoto
            {...props.userPhotoProps}
            isVisible={visibility?.isUserPhotoVisible}
            onPhotoClick={onPhotoClick}
          />
          <UserInfo
            {...props.userInfoProps}
            isVisible={visibility?.isUserInfoVisible}
          />
          <UserAddress
            {...props.userAddressProps}
            isVisible={visibility?.isUserAddressVisible}
          />
          <SocialLinkGroup
            {...props.socialLinksGroupProps}
            isVisible={visibility?.isSocialVisible}
          />
          <CombinedUserInfo
            {...props.combinedUserInfoProps}
            onPhotoClick={onPhotoClick}
          />
          <ButtonGroup
            {...props.buttonGroupProps}
            isVisible={visibility?.isButtonGroupVisible}
            onAboutClick={onAboutClick}
          />
          <AboutMeText
            {...props.aboutMeProps}
            isVisible={visibility?.isAboutMeVisible}
          />
          <Footer {...props.footerProps} />
          {isModalOpen ? <SendModal {...props.modalProps} /> : null}
        </>
      )}
    </div>
  );
};

PreviewContainer.propTypes = {
  user: PropTypes.object,
  theme: PropTypes.object,
  onPhotoClick: PropTypes.func,
  previewRefresh: PropTypes.number,
  onAboutClick: PropTypes.func,
  onBackButtonClick: PropTypes.func,
};

export default PreviewContainer;
