import { useEffect, useState } from 'react';

const getState = (info) => ({
  name: info.name ?? '',
  isEnabled: info.isEnabled ?? false,
  fontFamily: info.fontFamily ?? '',
  fontUrl: info.fontUrl ?? '',
});

const useGeneralInfo = (info) => {
  const [generalInfo, setGeneralInfo] = useState(getState(info));

  useEffect(() => {
    setGeneralInfo(getState(info));
  }, [info]);

  const handleChange = (propertyName, value) => {
    const newInfo = { ...generalInfo };
    newInfo[propertyName] = value;

    setGeneralInfo(newInfo);
  };

  const inputs = [
    {
      type: 'text',
      value: generalInfo.name,
      onChange: (evt) => handleChange('name', evt.target.value),
      label: 'THEME NAME',
      name: 'themeName',
    },
    {
      type: 'checkbox',
      value: generalInfo.isEnabled,
      onChange: (evt) => handleChange('isEnabled', evt.target.checked),
      label: 'IS ENABLED?',
      name: 'themeIsEnabled',
    },
    {
      type: 'text',
      value: generalInfo.fontFamily,
      onChange: (evt) => handleChange('fontFamily', evt.target.value),
      label: 'FONT FAMILY',
      name: 'themeFontFamily',
    },
    {
      type: 'text',
      value: generalInfo.fontUrl,
      onChange: (evt) => handleChange('fontUrl', evt.target.value),
      label: 'FONT URL',
      name: 'themeFontUrl',
    },
  ];

  return { data: generalInfo, inputs };
};

export default useGeneralInfo;
