import { useState, useEffect, useCallback } from 'react';

const useEyeDropper = (canvas, context) => {
  const [color, setColor] = useState(null);

  const getPixelColor = (x, y, ctx) => {
    const pixelData = ctx.getImageData(x, y, 1, 1);
    return `rgba(${pixelData.data[0]},${pixelData.data[1]},${pixelData.data[2]},1)`;
  };

  const handleMouseClick = useCallback(
    (evt) => {
      const pointer = canvas.getPointer(evt);

      setColor(getPixelColor(pointer.x, pointer.y, context));
    },
    [context, canvas],
  );

  useEffect(() => {
    if (canvas) {
      canvas.upperCanvasEl.addEventListener('click', handleMouseClick, false);
      canvas.upperCanvasEl.addEventListener('touch', handleMouseClick, false);
    }
  }, [canvas, handleMouseClick]);

  return color;
};

export default useEyeDropper;
