import React, { useEffect, useMemo, useState } from 'react';
import './Statistics.scss';
import {
  faUserPlus,
  faMousePointer,
  faDownload,
  faUsers,
} from '@fortawesome/free-solid-svg-icons';
import store, { NotificationTypes } from '../../../hooks/NotificationHub';
import SectionHeader from '../../SectionHeader/SectionHeader';
import StatisticsEntry from './StatisticsEntry/StatisticsEntry';
import Datepicker from '../Datepicker/Datepicker';
import { getDashboardStatistics } from '../../../utils/apiCalls';
import Loader from '../../Loader/Loader';

const Statistics = () => {
  const [chosenDate, setChosenDate] = useState(new Date() - 86400000 * 30);
  const [statistics, setStatistics] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  const getData = async () => {
    setIsLoading(true);

    try {
      const response = await getDashboardStatistics(new Date(chosenDate));
      setStatistics(response.data);
    } catch (err) {
      store.pushNotification(
        'Error',
        'Could not load your statistics at the moment. Please refresh the page or try again later.',
        NotificationTypes.DANGER,
      );
    }

    setIsLoading(false);
  };

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chosenDate]);

  const entries = useMemo(
    () => [
      {
        text: 'NEW CONTACTS',
        currentValue: statistics?.new_contacts,
        icon: faUserPlus,
        color: '#f25f1f',
      },
      {
        text: 'TOTAL CLICKS',
        currentValue: statistics?.total_clicks,
        icon: faMousePointer,
        color: '#166e9e',
      },
      {
        text: 'TOTAL DOWNLOADS',
        currentValue: statistics?.total_downloads,
        icon: faDownload,
        color: '#9a4f9d',
      },
      {
        text: 'TOTAL CONTACTS',
        currentValue: statistics?.total_contacts,
        icon: faUsers,
        color: '#457f3d',
      },
    ],
    [statistics],
  );

  return (
    <section className="statistics">
      <SectionHeader text="TOP STATISTICS" />
      <Datepicker
        date={chosenDate}
        setDate={setChosenDate}
        prefix="statistics"
      />
      {entries.map((entry) => (
        <StatisticsEntry {...entry} key={entry.text} />
      ))}
      <Loader isLoading={isLoading} />
    </section>
  );
};

export default Statistics;
