/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import PropTypes from 'prop-types';
import './SectionHeader.scss';

const SectionHeader = ({ text, icon, textAlign, onIconClick }) => (
  <header className={`section-header section-header--${textAlign || 'left'}`}>
    <h2 className="section-header__text">{text}</h2>
    <div
      className={`section-header__icon ${
        onIconClick ? 'section-header__icon--clickable' : ''
      }`}
      onClick={() => {
        if (onIconClick) onIconClick();
      }}>
      {icon}
    </div>
  </header>
);

SectionHeader.propTypes = {
  text: PropTypes.string,
  icon: PropTypes.object, // optional, needed in billing form,
  textAlign: PropTypes.string,
  onIconClick: PropTypes.func,
};

export default SectionHeader;
