import React from 'react';
import PropTypes from 'prop-types';
import Layout from './shared/layout';
import UserPhoto from './user/userPhoto';
import UserInfo from './user/userInfo';
import UserAddress from './user/userAddress';
import SocialLinkGroup from './social/socialLinkGroup';
import CombinedUserInfo from './user/combinedUserInfo';
import ButtonGroup from './buttons/buttonGroup';
import AboutMeText from './about/aboutMeText';
import SendModal from './sendModal/sendModal';
import Loader from '../Loader/Loader';
import { defaultStyles } from './defaultStyles';

const MBC = ({
  isLoading,
  isFirstLoad,
  visibility,
  layoutProps,
  userInfoProps,
  userAddressProps,
  userPhotoProps,
  socialLinksGroupProps,
  combinedUserInfoProps,
  buttonGroupProps,
  aboutMeProps,
  modalProps,
  isModalOpen,
}) => {
  if (isLoading && isFirstLoad) {
    // default loader to display on page refresh
    return (
      <Layout
        {...defaultStyles.components[0]}
        headerProps={{
          ...defaultStyles.components.filter((c) => c.name === 'Header')[0],
        }}
        footerProps={{
          ...defaultStyles.components.filter((c) => c.name === 'Footer')[0],
        }}>
        <Loader isLoading={isLoading} />
      </Layout>
    );
  }

  return (
    <Layout {...layoutProps}>
      {isLoading ? (
        <Loader isLoading={isLoading} isShowingProgress={false} />
      ) : null}
      <UserPhoto
        {...userPhotoProps}
        isVisible={visibility?.isUserPhotoVisible}
      />
      <UserInfo {...userInfoProps} isVisible={visibility?.isUserInfoVisible} />
      <UserAddress
        {...userAddressProps}
        isVisible={visibility?.isUserAddressVisible}
      />
      <SocialLinkGroup
        {...socialLinksGroupProps}
        isVisible={visibility?.isSocialVisible}
      />
      <CombinedUserInfo {...combinedUserInfoProps} />
      <ButtonGroup
        {...buttonGroupProps}
        isVisible={visibility?.isButtonGroupVisible}
      />
      <AboutMeText {...aboutMeProps} />
      {isModalOpen ? <SendModal {...modalProps} /> : <></>}
    </Layout>
  );
};

MBC.propTypes = {
  isLoading: PropTypes.bool,
  isFirstLoad: PropTypes.bool,
  visibility: PropTypes.object,
  layoutProps: PropTypes.object,
  userInfoProps: PropTypes.object,
  userAddressProps: PropTypes.object,
  userPhotoProps: PropTypes.object,
  socialLinksGroupProps: PropTypes.object,
  combinedUserInfoProps: PropTypes.object,
  buttonGroupProps: PropTypes.object,
  aboutMeProps: PropTypes.object,
  modalProps: PropTypes.object,
  isModalOpen: PropTypes.bool,
};

export default MBC;
