import React from 'react';
import './ContactForm.scss';
import PropTypes from 'prop-types';
import SectionHeader from '../SectionHeader/SectionHeader';
import FormControl from '../FormControl/FormControl';
import MaskedFormControl from '../MaskedFormControl/MaskedFormControl';

const ContactForm = ({ inputs }) => (
  <section className="contact">
    <SectionHeader text="CONTACT INFO" />
    <div className="contact__form">
      {inputs.map((inp) => {
        if (inp.name === 'Phone number' || inp.name === 'Mobile number') {
          return (
            <MaskedFormControl
              mask="(999)999-9999"
              maskChar="_"
              {...inp}
              key={inp.name}
              as="input"
              pattern="\([\d]{3}\)[\d]{3}-[\d]{4}"
            />
          );
        }
        return <FormControl {...inp} key={inp.name} as="input" />;
      })}
    </div>
  </section>
);

ContactForm.propTypes = {
  inputs: PropTypes.arrayOf(PropTypes.object),
  selects: PropTypes.arrayOf(PropTypes.object),
};

export default ContactForm;
