import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import {
  importContactsFile,
  importContactsMapping,
} from '../../../utils/apiCalls';
import Button from '../../Button/Button';
import PageHeader from '../../PageHeader/PageHeader';
import SectionHeader from '../../SectionHeader/SectionHeader';
import './ContactImport.scss';
import ImportDropdown from '../../ImportDropdown/ImportDropdown';
import store, { NotificationTypes } from '../../../hooks/NotificationHub';

const ContactImport = () => {
  const { state } = useLocation();
  const history = useHistory();

  const [filepath, setFilepath] = useState('');
  const [columns, setColumns] = useState([]);
  const [fileColumns, setFileColumns] = useState([]);
  const [mapping, setMapping] = useState({});
  const [options, setOptions] = useState([]);

  const getMapping = async (file) => {
    try {
      const response = await importContactsFile(file);

      setColumns(response?.data?.columns);
      setFileColumns(response?.data?.fileColumns);
      setFilepath(response?.data?.filePath);
    } catch (err) {
      store.pushNotification(
        'Error',
        'Could not perform contacts import. Please try again later.',
        NotificationTypes.DANGER,
      );
    }
  };

  const handleSubmit = async () => {
    let isEveryMappingEmpty = true;

    Object.keys(mapping).forEach((key) => {
      if (mapping[key] !== '') {
        isEveryMappingEmpty = false;
      }
    });

    if (isEveryMappingEmpty) {
      store.pushNotification(
        'Error',
        'Please set mapping for columns before importing.',
        NotificationTypes.DANGER,
      );

      return;
    }

    try {
      const response = await importContactsMapping(filepath, mapping);

      store.pushNotification(
        'Success',
        response.data.message,
        NotificationTypes.SUCCESS,
      );
      history.push('/contacts');
    } catch (err) {
      store.pushNotification(
        'Error',
        'Could not perform contacts import. Please try again later.',
        NotificationTypes.DANGER,
      );
    }
  };

  useEffect(() => {
    if (!state?.file) {
      history.goBack();
    } else {
      getMapping(state.file);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const newMapping = {};
    columns.forEach((col) => {
      newMapping[col] = '';
    });

    setMapping(newMapping);

    const newOptions = [];
    Object.keys(fileColumns).forEach((key) =>
      newOptions.push({
        value: fileColumns[key],
        text: fileColumns[key],
      }),
    );

    setOptions(newOptions.reverse());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileColumns]);

  return (
    <section className="contacts-import">
      <PageHeader text="Contacts" />
      <main className="contacts-import__main">
        <SectionHeader text="IMPORT CONTACTS" />
        {columns.map((col) => (
          <ImportDropdown
            dbColumn={col}
            key={col}
            fileColumns={options}
            value={mapping[col]}
            setValue={(evt) =>
              setMapping({ ...mapping, [col]: evt.target.value })
            }
          />
        ))}
        <div className="main__button-wrapper">
          <Button variant="primary" text="IMPORT" onClick={handleSubmit} />
        </div>
      </main>
    </section>
  );
};

export default ContactImport;
