import React from 'react';
import './ListHeader.scss';

const ListHeader = () => (
  <header className="list__header">
    <div className="header__col header__col--checkbox">
      <input type="checkbox" className="col__input" />
    </div>
    <div className="header__col header__col--mobile">Mobile #</div>
    <div className="header__col header__col--firstName">First name</div>
    <div className="header__col header__col--lastName">Last name</div>
    <div className="header__col header__col--email">Email</div>
    <div className="header__col header__col--source">Source</div>
    <div className="header__col header__col--src">Src</div>
    <div className="header__col header__col--visitDate">Visit date</div>
    <div className="header__col header__col--visitTime">Visit time</div>
    <div className="header__col header__col--edit" />
  </header>
);

export default ListHeader;
