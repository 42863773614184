import React from 'react';
import './ThemesFormPreview.scss';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import ThemePreview from '../../../ThemePreview/ThemePreview';
import Button from '../../../Button/Button';

const ThemesFormPreview = ({
  themeObject,
  onHeaderClick,
  onFooterClick,
  onInfoClick,
  onPhotoClick,
  onAddressClick,
  onSocialsClick,
  onButtonsClick,
  onModalClick,
  onCombinedInfoClick,
  onAboutClick,
}) => {
  const history = useHistory();

  return (
    <div className="themes-form-preview">
      <aside className="themes-form-preview__buttons">
        <Button variant="secondary" text="HEADER" onClick={onHeaderClick} />
        <Button variant="secondary" text="USER PHOTO" onClick={onPhotoClick} />
        <Button variant="secondary" text="USER INFO" onClick={onInfoClick} />
        <Button variant="secondary" text="SOCIALS" onClick={onSocialsClick} />
        <Button
          variant="secondary"
          text="USER ADDRESS"
          onClick={onAddressClick}
        />
        <Button variant="secondary" text="BUTTONS" onClick={onButtonsClick} />
        <Button variant="secondary" text="FOOTER" onClick={onFooterClick} />
        <Button variant="secondary" text="ABOUT ME" onClick={onAboutClick} />
        <Button variant="secondary" text="MODAL" onClick={onModalClick} />
        <Button
          variant="secondary"
          text="COMBINED INFO"
          onClick={onCombinedInfoClick}
        />
      </aside>
      <main className="themes-form-preview__content">
        <Button
          variant="primary"
          text="BACK TO DASHBOARD"
          onClick={() => history.push('/admin/themes')}
        />
        <ThemePreview themeSettings={themeObject} />
      </main>
    </div>
  );
};

ThemesFormPreview.propTypes = {
  themeObject: PropTypes.object,
  onHeaderClick: PropTypes.func,
  onFooterClick: PropTypes.func,
  onInfoClick: PropTypes.func,
  onPhotoClick: PropTypes.func,
  onAddressClick: PropTypes.func,
  onSocialsClick: PropTypes.func,
  onButtonsClick: PropTypes.func,
  onModalClick: PropTypes.func,
  onCombinedInfoClick: PropTypes.func,
  onAboutClick: PropTypes.func,
};

export default ThemesFormPreview;
