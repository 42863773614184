import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import React, { useEffect, useState } from 'react';
import ContactsList from '../../Contacts/ContactsList/ContactsList';
import ContactsListForm from '../../Contacts/ContactsListForm/ContactsListForm';
import ImgEditor from '../../ImgEditor/ImgEditor';
import SectionHeader from '../../SectionHeader/SectionHeader';
import SideMenu from '../../SideMenu/SideMenu';
import Datepicker from '../Datepicker/Datepicker';
import './RecentContacts.scss';

const RecentContacts = () => {
  const [chosenDate, setChosenDate] = useState(new Date() - 86400000 * 30);

  const [path, setPath] = useState(null);
  const [menuVisibility, setMenuVisibility] = useState(false);
  const [chosenContactId, setChosenContactId] = useState('');
  const [toggleReload, setToggleReload] = useState(0);
  const [imageUrl, setImageUrl] = useState('');

  useEffect(() => {
    if (!menuVisibility) {
      setChosenContactId('');
    }
  }, [menuVisibility]);

  return (
    <section className="recent-contacts">
      {path ? (
        <ImgEditor
          path={path}
          close={() => setPath(null)}
          setImageUrl={setImageUrl}
        />
      ) : null}
      <SectionHeader text="RECENT CONTACTS" />
      <Datepicker
        date={chosenDate}
        setDate={setChosenDate}
        prefix="recent-contacts"
      />
      <ContactsList
        setMenuVisibility={setMenuVisibility}
        setContactId={setChosenContactId}
        toggleReload={toggleReload}
        isOnDashboard
        dateFilter={new Date(chosenDate)}
      />
      <SideMenu
        headerText="Edit a Contact"
        headerIcon={faPlusCircle}
        visibility={menuVisibility}
        setVisibility={setMenuVisibility}>
        <ContactsListForm
          contactId={chosenContactId}
          onCancelClick={() => {
            setToggleReload(toggleReload + 1);
            setMenuVisibility(false);
            setChosenContactId('');
          }}
          setImagePath={setPath}
          imagePath={path}
          imageUrl={imageUrl}
          setImageUrl={setImageUrl}
        />
      </SideMenu>
    </section>
  );
};

export default RecentContacts;
