import { useEffect, useState } from 'react';
import { textAlignOptions } from '../ThemesForm';

const getState = (info) => ({
  width: info.width ?? '',
  padding: info.padding ?? '',
  backgroundColor: info.backgroundColor ?? '',
  color: info.color ?? '',
  boxShadow: info.boxShadow ?? '',
  overlayOpacity: info.overlayOpacity ?? '',
  borderRadius: info.borderRadius ?? '',
  closeButtonColor: info.closeButtonColor ?? '',
  formPadding: info.formPadding ?? '',
  header: {
    padding: info?.header?.padding ?? '',
    margin: info?.header?.margin ?? '',
    fontSize: info?.header?.fontSize ?? '',
    fontWeight: info?.header?.fontWeight ?? '',
  },
  input: {
    mobileNumberWidth: info?.input?.mobileNumberWidth ?? '',
    firstNameWidth: info?.input?.firstNameWidth ?? '',
    lastNameWidth: info?.input?.lastNameWidth ?? '',
    padding: info?.input?.padding ?? '',
    margin: info?.input?.margin ?? '',
    fontSize: info?.input?.fontSize ?? '',
    borderStyle: info?.input?.borderStyle ?? '',
    borderWidth: info?.input?.borderWidth ?? '',
    borderColor: info?.input?.borderColor ?? '',
    borderRadius: info?.input?.borderRadius ?? '',
    invalidColor: info?.input?.invalidColor ?? '',
  },
  textArea: {
    width: info?.textArea?.width ?? '',
    minHeight: info?.textArea?.minHeight ?? '',
    padding: info?.textArea?.padding ?? '',
    margin: info?.textArea?.margin ?? '',
    fontSize: info?.textArea?.fontSize ?? '',
    borderStyle: info?.textArea?.borderStyle ?? '',
    borderWidth: info?.textArea?.borderWidth ?? '',
    borderColor: info?.textArea?.borderColor ?? '',
    borderRadius: info?.textArea?.borderRadius ?? '',
    invalidColor: info?.textArea?.invalidColor ?? '',
  },
  smallText: {
    width: info?.smallText?.width ?? '',
    fontSize: info?.smallText?.fontSize ?? '',
    textAlign: info?.smallText?.textAlign ?? '',
  },
  submitButton: {
    width: info?.submitButton?.width ?? '',
    padding: info?.submitButton?.padding ?? '',
    margin: info?.submitButton?.margin ?? '',
    color: info?.submitButton?.color ?? '',
    fontSize: info?.submitButton?.fontSize ?? '',
    backgroundColor: info?.submitButton?.backgroundColor ?? '',
    borderStyle: info?.submitButton?.borderStyle ?? '',
    borderWidth: info?.submitButton?.borderWidth ?? '',
    borderColor: info?.submitButton?.borderColor ?? '',
  },
  medium: {
    width: info?.medium?.width ?? '',
    padding: info?.medium?.padding ?? '',
    input: {
      mobileNumberWidth: info?.medium?.input?.mobileNumberWidth ?? '',
      firstNameWidth: info?.medium?.input?.firstNameWidth ?? '',
      lastNameWidth: info?.medium?.input?.lastNameWidth ?? '',
      padding: info?.medium?.input?.padding ?? '',
      margin: info?.medium?.input?.margin ?? '',
      fontSize: info?.medium?.input?.fontSize ?? '',
    },
    textArea: {
      width: info?.medium?.textArea?.width ?? '',
      minHeight: info?.medium?.textArea?.minHeight ?? '',
      padding: info?.medium?.textArea?.padding ?? '',
      margin: info?.medium?.textArea?.margin ?? '',
      fontSize: info?.medium?.textArea?.fontSize ?? '',
    },
    smallText: {
      width: info?.medium?.smallText?.width ?? '',
      fontSize: info?.medium?.smallText?.fontSize ?? '',
      textAlign: info?.medium?.smallText?.textAlign ?? '',
    },
  },
  large: {
    width: info?.large?.width ?? '',
    padding: info?.large?.padding ?? '',
    input: {
      mobileNumberWidth: info?.large?.input?.mobileNumberWidth ?? '',
      firstNameWidth: info?.large?.input?.firstNameWidth ?? '',
      lastNameWidth: info?.large?.input?.lastNameWidth ?? '',
      padding: info?.large?.input?.padding ?? '',
      margin: info?.large?.input?.margin ?? '',
      fontSize: info?.large?.input?.fontSize ?? '',
    },
    textArea: {
      width: info?.large?.textArea?.width ?? '',
      minHeight: info?.large?.textArea?.minHeight ?? '',
      padding: info?.large?.textArea?.padding ?? '',
      margin: info?.large?.textArea?.margin ?? '',
      fontSize: info?.large?.textArea?.fontSize ?? '',
    },
    smallText: {
      width: info?.large?.smallText?.width ?? '',
      fontSize: info?.large?.smallText?.fontSize ?? '',
      textAlign: info?.large?.smallText?.textAlign ?? '',
    },
  },
});

const useModalInfo = (info) => {
  const [modalInfo, setModalInfo] = useState(getState(info));

  useEffect(() => {
    setModalInfo(getState(info));
  }, [info]);

  const handleChange = (propertyName, value, element = '', breakpoint = '') => {
    const newInfo = { ...modalInfo };

    if (breakpoint === 'medium' || breakpoint === 'large') {
      if (element && element !== '') {
        newInfo[breakpoint][element] = {
          ...newInfo[breakpoint][element],
          [propertyName]: value,
        };
      } else {
        newInfo[breakpoint] = {
          ...newInfo[breakpoint],
          [propertyName]: value,
        };
      }
    } else if (element && element !== '') {
      newInfo[element][propertyName] = value;
    } else {
      newInfo[propertyName] = value;
    }

    setModalInfo(newInfo);
  };

  const inputs = [
    {
      type: 'text',
      value: modalInfo.width,
      onChange: (evt) => handleChange('width', evt.target.value),
      label: 'WIDTH',
      name: 'modalWidth',
      placeholder: '90%',
    },
    {
      type: 'text',
      value: modalInfo.padding,
      onChange: (evt) => handleChange('padding', evt.target.value),
      label: 'PADDING',
      name: 'modalPadding',
      placeholder: '1.875rem',
    },
    {
      type: 'text',
      value: modalInfo.backgroundColor,
      onChange: (evt) => handleChange('backgroundColor', evt.target.value),
      label: 'BACKGROUND COLOR',
      name: 'modalBackgroundColor',
      placeholder: '#fefefe',
    },
    {
      type: 'text',
      value: modalInfo.color,
      onChange: (evt) => handleChange('color', evt.target.value),
      label: 'COLOR',
      name: 'modalColor',
      placeholder: '#000000',
    },
    {
      type: 'text',
      value: modalInfo.boxShadow,
      onChange: (evt) => handleChange('boxShadow', evt.target.value),
      label: 'BOX SHADOW',
      name: 'modalBoxShadow',
      placeholder: '0 4px 8px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19)',
    },
    {
      type: 'text',
      value: modalInfo.overlayOpacity,
      onChange: (evt) => handleChange('overlayOpacity', evt.target.value),
      label: 'OVERLAY OPACITY',
      name: 'modalOverlayOpacity',
      placeholder: '60%',
    },
    {
      type: 'text',
      value: modalInfo.borderRadius,
      onChange: (evt) => handleChange('borderRadius', evt.target.value),
      label: 'BORDER RADIUS',
      name: 'modalBorderRadius',
      placeholder: '1.1rem',
    },
    {
      type: 'text',
      value: modalInfo.closeButtonColor,
      onChange: (evt) => handleChange('closeButtonColor', evt.target.value),
      label: 'CLOSE BUTTON COLOR',
      name: 'modalCloseButtonColor',
      placeholder: '#000000',
    },
    {
      type: 'text',
      value: modalInfo.formPadding,
      onChange: (evt) => handleChange('formPadding', evt.target.value),
      label: 'FORM PADDING',
      name: 'modalFormPadding',
      placeholder: '1.875rem',
    },

    {
      type: 'text',
      value: modalInfo.header.padding,
      onChange: (evt) => handleChange('padding', evt.target.value, 'header'),
      label: 'HEADER PADDING',
      name: 'modalHeaderPadding',
      placeholder: '0',
    },
    {
      type: 'text',
      value: modalInfo.header.margin,
      onChange: (evt) => handleChange('margin', evt.target.value, 'header'),
      label: 'HEADER MARGIN',
      name: 'modalHeaderMargin',
      placeholder: '0 0 1.25rem 0',
    },
    {
      type: 'text',
      value: modalInfo.header.fontSize,
      onChange: (evt) => handleChange('fontSize', evt.target.value, 'header'),
      label: 'HEADER FONT SIZE',
      name: 'modalHeaderFontSize',
      placeholder: '2rem',
    },
    {
      type: 'text',
      value: modalInfo.header.fontWeight,
      onChange: (evt) => handleChange('fontWeight', evt.target.value, 'header'),
      label: 'HEADER FONT WEIGHT',
      name: 'modalHeaderFontWeight',
      placeholder: '800',
    },

    {
      type: 'text',
      value: modalInfo.input.mobileNumberWidth,
      onChange: (evt) =>
        handleChange('mobileNumberWidth', evt.target.value, 'input'),
      label: 'MOBILE NUMBER FIELD WIDTH',
      name: 'modalMobileWidth',
      placeholder: '100%',
    },
    {
      type: 'text',
      value: modalInfo.input.firstNameWidth,
      onChange: (evt) =>
        handleChange('firstNameWidth', evt.target.value, 'input'),
      label: 'FIRST NAME FIELD WIDTH',
      name: 'modalFirstNameWidth',
      placeholder: '100%',
    },
    {
      type: 'text',
      value: modalInfo.input.lastNameWidth,
      onChange: (evt) =>
        handleChange('lastNameWidth', evt.target.value, 'input'),
      label: 'LAST NAME FIELD WIDTH',
      name: 'modalLastNameWidth',
      placeholder: '100%',
    },
    {
      type: 'text',
      value: modalInfo.input.padding,
      onChange: (evt) => handleChange('padding', evt.target.value, 'input'),
      label: 'INPUT PADDING',
      name: 'modalInputPadding',
      placeholder: '0.8rem 0.7rem',
    },
    {
      type: 'text',
      value: modalInfo.input.margin,
      onChange: (evt) => handleChange('margin', evt.target.value, 'input'),
      label: 'INPUT MARGIN',
      name: 'modalInputMargin',
      placeholder: '0 0 0.5rem 0',
    },
    {
      type: 'text',
      value: modalInfo.input.fontSize,
      onChange: (evt) => handleChange('fontSize', evt.target.value, 'input'),
      label: 'INPUT FONT SIZE',
      name: 'modalInputFontSize',
      placeholder: '1rem',
    },
    {
      type: 'text',
      value: modalInfo.input.borderStyle,
      onChange: (evt) => handleChange('borderStyle', evt.target.value, 'input'),
      label: 'INPUT BORDER STYLE',
      name: 'modalInputBorderStyle',
      placeholder: 'solid',
    },
    {
      type: 'text',
      value: modalInfo.input.borderWidth,
      onChange: (evt) => handleChange('borderWidth', evt.target.value, 'input'),
      label: 'INPUT BORDER WIDTH',
      name: 'modalInputBorderWidth',
      placeholder: '1px',
    },
    {
      type: 'text',
      value: modalInfo.input.color,
      onChange: (evt) => handleChange('color', evt.target.value, 'input'),
      label: 'INPUT BORDER COLOR',
      name: 'modalInputBorderColor',
      placeholder: 'rgba(0,0,0,0.4)',
    },
    {
      type: 'text',
      value: modalInfo.input.radius,
      onChange: (evt) => handleChange('radius', evt.target.value, 'input'),
      label: 'INPUT BORDER RADIUS',
      name: 'modalInputBorderRadius',
      placeholder: '0.6rem',
    },
    {
      type: 'text',
      value: modalInfo.input.invalidColor,
      onChange: (evt) =>
        handleChange('invalidColor', evt.target.value, 'input'),
      label: 'INPUT INVALID COLOR',
      name: 'modalInputColor',
      placeholder: '#ff0000',
    },

    {
      type: 'text',
      value: modalInfo.textArea.width,
      onChange: (evt) => handleChange('width', evt.target.value, 'textArea'),
      label: 'TEXTAREA WIDTH',
      name: 'modalTextareaWidth',
      placeholder: '100%',
    },
    {
      type: 'text',
      value: modalInfo.textArea.minHeight,
      onChange: (evt) =>
        handleChange('minHeight', evt.target.value, 'textArea'),
      label: 'TEXTAREA PADDING',
      name: 'modalTextareaMinHeight',
      placeholder: '3.4rem',
    },
    {
      type: 'text',
      value: modalInfo.textArea.padding,
      onChange: (evt) => handleChange('padding', evt.target.value, 'textArea'),
      label: 'TEXTAREA PADDING',
      name: 'modalTextareaPadding',
      placeholder: '0.65rem',
    },
    {
      type: 'text',
      value: modalInfo.textArea.margin,
      onChange: (evt) => handleChange('margin', evt.target.value, 'textArea'),
      label: 'TEXTAREA MARGIN',
      name: 'modalTextareaMargin',
      placeholder: '0 0 0.5rem 0',
    },
    {
      type: 'text',
      value: modalInfo.textArea.fontSize,
      onChange: (evt) => handleChange('fontSize', evt.target.value, 'textArea'),
      label: 'TEXTAREA FONT SIZE',
      name: 'modalTextareaFontSize',
      placeholder: '1rem',
    },
    {
      type: 'text',
      value: modalInfo.textArea.borderStyle,
      onChange: (evt) =>
        handleChange('borderStyle', evt.target.value, 'textArea'),
      label: 'TEXTAREA BORDER STYLE',
      name: 'modalTextareaBorderStyle',
      placeholder: 'solid',
    },
    {
      type: 'text',
      value: modalInfo.textArea.borderWidth,
      onChange: (evt) =>
        handleChange('borderWidth', evt.target.value, 'textArea'),
      label: 'TEXTAREA BORDER WIDTH',
      name: 'modalTextareaBorderWidth',
      placeholder: '1px',
    },
    {
      type: 'text',
      value: modalInfo.textArea.color,
      onChange: (evt) => handleChange('color', evt.target.value, 'textArea'),
      label: 'TEXTAREA BORDER COLOR',
      name: 'modalTextareaBorderColor',
      placeholder: 'rgba(0,0,0,0.4)',
    },
    {
      type: 'text',
      value: modalInfo.textArea.borderRadius,
      onChange: (evt) =>
        handleChange('borderRadius', evt.target.value, 'textArea'),
      label: 'TEXTAREA BORDER RADIUS',
      name: 'modalTextareaBorderRadius',
      placeholder: '0.6rem',
    },
    {
      type: 'text',
      value: modalInfo.textArea.invalidColor,
      onChange: (evt) =>
        handleChange('invalidColor', evt.target.value, 'textArea'),
      label: 'TEXTAREA INVALID COLOR',
      name: 'modalTextareaColor',
      placeholder: '#ff0000',
    },

    {
      type: 'text',
      value: modalInfo.smallText.width,
      onChange: (evt) => handleChange('width', evt.target.value, 'smallText'),
      label: 'SMALL TEXT WIDTH',
      name: 'modalSmallTextWidth',
      placeholder: '100%',
    },
    {
      type: 'text',
      value: modalInfo.smallText.fontSize,
      onChange: (evt) =>
        handleChange('fontSize', evt.target.value, 'smallText'),
      label: 'SMALL TEXT FONT SIZE',
      name: 'modalSmallTextFontSize',
      placeholder: '0.6rem',
    },
    {
      as: 'select',
      options: textAlignOptions,
      value: modalInfo.smallText.textAlign,
      onChange: (evt) =>
        handleChange('textAlign', evt.target.value, 'smallText'),
      label: 'SMALL TEXT TEXT ALIGN',
      name: 'modalSmallTextTextAlign',
    },

    {
      type: 'text',
      value: modalInfo.submitButton.width,
      onChange: (evt) =>
        handleChange('width', evt.target.value, 'submitButton'),
      label: 'SUBMIT BUTTON WIDTH',
      name: 'modalSubmitButtonWidth',
      placeholder: '50%',
    },
    {
      type: 'text',
      value: modalInfo.submitButton.padding,
      onChange: (evt) =>
        handleChange('padding', evt.target.value, 'submitButton'),
      label: 'SUBMIT BUTTON PADDING',
      name: 'modalSubmitButtonPadding',
      placeholder: '1.1rem',
    },
    {
      type: 'text',
      value: modalInfo.submitButton.margin,
      onChange: (evt) =>
        handleChange('margin', evt.target.value, 'submitButton'),
      label: 'SUBMIT BUTTON MARGIN',
      name: 'modalSubmitButtonMargin',
      placeholder: '1.1rem auto auto auto',
    },
    {
      type: 'text',
      value: modalInfo.submitButton.color,
      onChange: (evt) =>
        handleChange('color', evt.target.value, 'submitButton'),
      label: 'SUBMIT BUTTON COLOR',
      name: 'modalSubmitButtonColor',
      placeholder: '#ffffff',
    },
    {
      type: 'text',
      value: modalInfo.submitButton.fontSize,
      onChange: (evt) =>
        handleChange('fontSize', evt.target.value, 'submitButton'),
      label: 'SUBMIT BUTTON FONT SIZE',
      name: 'modalSubmitButtonFontSize',
      placeholder: '1.2rem',
    },
    {
      type: 'text',
      value: modalInfo.submitButton.backgroundColor,
      onChange: (evt) =>
        handleChange('backgroundColor', evt.target.value, 'submitButton'),
      label: 'SUBMIT BUTTON BACKGROUND COLOR',
      name: 'modalSubmitButtonBackgroundColor',
      placeholder: '#323232',
    },
    {
      type: 'text',
      value: modalInfo.submitButton.borderStyle,
      onChange: (evt) =>
        handleChange('borderStyle', evt.target.value, 'submitButton'),
      label: 'SUBMIT BUTTON BORDER STYLE',
      name: 'modalSubmitButtonBorderStyle',
      placeholder: 'solid',
    },
    {
      type: 'text',
      value: modalInfo.submitButton.borderWidth,
      onChange: (evt) =>
        handleChange('borderWidth', evt.target.value, 'submitButton'),
      label: 'SUBMIT BUTTON BORDER WIDTH',
      name: 'modalSubmitButtonBorderWidth',
      placeholder: '0',
    },
    {
      type: 'text',
      value: modalInfo.submitButton.color,
      onChange: (evt) =>
        handleChange('color', evt.target.value, 'submitButton'),
      label: 'SUBMIT BUTTON BORDER COLOR',
      name: 'modalSubmitButtonBorderColor',
      placeholder: 'transparent',
    },
  ];
  const mediumInputs = [
    {
      type: 'text',
      value: modalInfo.medium.width,
      onChange: (evt) => handleChange('width', evt.target.value, 'medium'),
      label: 'WIDTH',
      name: 'modalWidthMedium',
      placeholder: '90%',
    },
    {
      type: 'text',
      value: modalInfo.medium.padding,
      onChange: (evt) => handleChange('padding', evt.target.value, 'medium'),
      label: 'PADDING',
      name: 'modalPaddingMedium',
      placeholder: '1.875rem',
    },

    {
      type: 'text',
      value: modalInfo.medium.input.mobileNumberWidth,
      onChange: (evt) =>
        handleChange('mobileNumberWidth', evt.target.value, 'input', 'medium'),
      label: 'MOBILE NUMBER FIELD WIDTH',
      name: 'modalMobileWidthMedium',
      placeholder: '100%',
    },
    {
      type: 'text',
      value: modalInfo.medium.input.firstNameWidth,
      onChange: (evt) =>
        handleChange('firstNameWidth', evt.target.value, 'input', 'medium'),
      label: 'FIRST NAME FIELD WIDTH',
      name: 'modalFirstNameWidthMedium',
      placeholder: '100%',
    },
    {
      type: 'text',
      value: modalInfo.medium.input.lastNameWidth,
      onChange: (evt) =>
        handleChange('lastNameWidth', evt.target.value, 'input', 'medium'),
      label: 'LAST NAME FIELD WIDTH',
      name: 'modalLastNameWidthMedium',
      placeholder: '100%',
    },
    {
      type: 'text',
      value: modalInfo.medium.input.padding,
      onChange: (evt) =>
        handleChange('padding', evt.target.value, 'input', 'medium'),
      label: 'INPUT PADDING',
      name: 'modalInputPaddingMedium',
      placeholder: '0.9rem',
    },
    {
      type: 'text',
      value: modalInfo.medium.input.margin,
      onChange: (evt) =>
        handleChange('margin', evt.target.value, 'input', 'medium'),
      label: 'INPUT MARGIN',
      name: 'modalInputMarginMedium',
      placeholder: '0 0 0.5rem 0',
    },
    {
      type: 'text',
      value: modalInfo.medium.input.fontSize,
      onChange: (evt) =>
        handleChange('fontSize', evt.target.value, 'input', 'medium'),
      label: 'INPUT FONT SIZE',
      name: 'modalInputFontSizeMedium',
      placeholder: '0.75rem',
    },

    {
      type: 'text',
      value: modalInfo.medium.textArea.width,
      onChange: (evt) =>
        handleChange('width', evt.target.value, 'textArea', 'medium'),
      label: 'TEXTAREA WIDTH',
      name: 'modalTextareaWidthMedium',
      placeholder: '100%',
    },
    {
      type: 'text',
      value: modalInfo.medium.textArea.minHeight,
      onChange: (evt) =>
        handleChange('minHeight', evt.target.value, 'textArea', 'medium'),
      label: 'TEXTAREA MIN HEIGHT',
      name: 'modalTextareaMinHeightMedium',
      placeholder: '3.4rem',
    },
    {
      type: 'text',
      value: modalInfo.medium.textArea.padding,
      onChange: (evt) =>
        handleChange('padding', evt.target.value, 'textArea', 'medium'),
      label: 'TEXTAREA PADDING',
      name: 'modalTextareaPaddingMedium',
      placeholder: '0.9rem',
    },
    {
      type: 'text',
      value: modalInfo.medium.textArea.margin,
      onChange: (evt) =>
        handleChange('margin', evt.target.value, 'textArea', 'medium'),
      label: 'TEXTAREA MARGIN',
      name: 'modalTextareaMarginMedium',
      placeholder: '0 0 0.5rem 0',
    },
    {
      type: 'text',
      value: modalInfo.medium.textArea.fontSize,
      onChange: (evt) =>
        handleChange('fontSize', evt.target.value, 'textArea', 'medium'),
      label: 'TEXTAREA FONT SIZE',
      name: 'modalTextareaFontSizeMedium',
      placeholder: '1rem',
    },

    {
      type: 'text',
      value: modalInfo.medium.smallText.width,
      onChange: (evt) =>
        handleChange('width', evt.target.value, 'smallText', 'medium'),
      label: 'SMALL TEXT WIDTH',
      name: 'modalSmallTextWidthMedium',
      placeholder: '100%',
    },
    {
      type: 'text',
      value: modalInfo.medium.smallText.fontSize,
      onChange: (evt) =>
        handleChange('fontSize', evt.target.value, 'smallText', 'medium'),
      label: 'SMALL TEXT FONT SIZE',
      name: 'modalSmallTextFontSizeMedium',
      placeholder: '0.75rem',
    },
    {
      as: 'select',
      options: textAlignOptions,
      value: modalInfo.medium.smallText.textAlign,
      onChange: (evt) =>
        handleChange('textAlign', evt.target.value, 'smallText', 'medium'),
      label: 'SMALL TEXT TEXT ALIGN',
      name: 'modalSmallTextTextAlignMedium',
    },
  ];
  const largeInputs = [
    {
      type: 'text',
      value: modalInfo.large.width,
      onChange: (evt) => handleChange('width', evt.target.value, 'large'),
      label: 'WIDTH',
      name: 'modalWidthLarge',
      placeholder: '90%',
    },
    {
      type: 'text',
      value: modalInfo.large.padding,
      onChange: (evt) => handleChange('padding', evt.target.value, 'large'),
      label: 'PADDING',
      name: 'modalPaddingLarge',
      placeholder: '1.875rem',
    },

    {
      type: 'text',
      value: modalInfo.large.input.mobileNumberWidth,
      onChange: (evt) =>
        handleChange('mobileNumberWidth', evt.target.value, 'input', 'large'),
      label: 'MOBILE NUMBER FIELD WIDTH',
      name: 'modalMobileWidthLarge',
      placeholder: '100%',
    },
    {
      type: 'text',
      value: modalInfo.large.input.firstNameWidth,
      onChange: (evt) =>
        handleChange('firstNameWidth', evt.target.value, 'input', 'large'),
      label: 'FIRST NAME FIELD WIDTH',
      name: 'modalFirstNameWidthLarge',
      placeholder: '100%',
    },
    {
      type: 'text',
      value: modalInfo.large.input.lastNameWidth,
      onChange: (evt) =>
        handleChange('lastNameWidth', evt.target.value, 'input', 'large'),
      label: 'LAST NAME FIELD WIDTH',
      name: 'modalLastNameWidthLarge',
      placeholder: '100%',
    },
    {
      type: 'text',
      value: modalInfo.large.input.padding,
      onChange: (evt) =>
        handleChange('padding', evt.target.value, 'input', 'large'),
      label: 'INPUT PADDING',
      name: 'modalInputPaddingLarge',
      placeholder: '0.9rem',
    },
    {
      type: 'text',
      value: modalInfo.large.input.margin,
      onChange: (evt) =>
        handleChange('margin', evt.target.value, 'input', 'large'),
      label: 'INPUT MARGIN',
      name: 'modalInputMarginLarge',
      placeholder: '0 0 0.5rem 0',
    },
    {
      type: 'text',
      value: modalInfo.large.input.fontSize,
      onChange: (evt) =>
        handleChange('fontSize', evt.target.value, 'input', 'large'),
      label: 'INPUT FONT SIZE',
      name: 'modalInputFontSizeLarge',
      placeholder: '1.15rem',
    },

    {
      type: 'text',
      value: modalInfo.large.textArea.width,
      onChange: (evt) =>
        handleChange('width', evt.target.value, 'textArea', 'large'),
      label: 'TEXTAREA WIDTH',
      name: 'modalTextareaWidthLarge',
      placeholder: '100',
    },
    {
      type: 'text',
      value: modalInfo.large.textArea.minHeight,
      onChange: (evt) =>
        handleChange('minHeight', evt.target.value, 'textArea', 'large'),
      label: 'TEXTAREA MIN HEIGHT',
      name: 'modalTextareaMinHeightLarge',
      placeholder: '3.4rem',
    },
    {
      type: 'text',
      value: modalInfo.large.textArea.padding,
      onChange: (evt) =>
        handleChange('padding', evt.target.value, 'textArea', 'large'),
      label: 'TEXTAREA PADDING',
      name: 'modalTextareaPaddingLarge',
      placeholder: '0.9rem',
    },
    {
      type: 'text',
      value: modalInfo.large.textArea.margin,
      onChange: (evt) =>
        handleChange('margin', evt.target.value, 'textArea', 'large'),
      label: 'TEXTAREA MARGIN',
      name: 'modalTextareaMarginLarge',
      placeholder: '0 0 0.5rem 0',
    },
    {
      type: 'text',
      value: modalInfo.large.textArea.fontSize,
      onChange: (evt) =>
        handleChange('fontSize', evt.target.value, 'textArea', 'large'),
      label: 'TEXTAREA FONT SIZE',
      name: 'modalTextareaFontSizeLarge',
      placeholder: '1rem',
    },

    {
      type: 'text',
      value: modalInfo.large.smallText.width,
      onChange: (evt) =>
        handleChange('width', evt.target.value, 'smallText', 'large'),
      label: 'SMALL TEXT WIDTH',
      name: 'modalSmallTextWidthLarge',
      placeholder: '100%',
    },
    {
      type: 'text',
      value: modalInfo.large.smallText.fontSize,
      onChange: (evt) =>
        handleChange('fontSize', evt.target.value, 'smallText', 'large'),
      label: 'SMALL TEXT FONT SIZE',
      name: 'modalSmallTextFontSizeLarge',
      placeholder: '0.75rem',
    },
    {
      as: 'select',
      options: textAlignOptions,
      value: modalInfo.large.smallText.textAlign,
      onChange: (evt) =>
        handleChange('textAlign', evt.target.value, 'smallText', 'large'),
      label: 'SMALL TEXT TEXT ALIGN',
      name: 'modalSmallTextTextAlignLarge',
    },
  ];

  return { info: modalInfo, inputs, mediumInputs, largeInputs };
};

export default useModalInfo;
