import React from 'react';
import { useLocation, Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { FixedLink, Button } from './sharedStyles';

const BackButton = ({ userId, handleClick, currentPage = 'home' }) => {
  const location = useLocation();

  if (!location.pathname.includes('about') && currentPage !== 'about') {
    return null;
  }

  return (
    <FixedLink>
      {!handleClick ? (
        <Link to={`/mbc/user/${userId}`}>
          <a>
            <Button src="/images/MBC/back-icon.png" alt="back button" />
          </a>
        </Link>
      ) : (
        <Button
          style={{ cursor: 'pointer' }}
          src="/images/MBC/back-icon.png"
          alt="back button"
          onClick={handleClick}
        />
      )}
    </FixedLink>
  );
};

BackButton.propTypes = {
  userId: PropTypes.string,
  handleClick: PropTypes.func,
  currentPage: PropTypes.string,
};

export default BackButton;
