class APIError extends Error {
  constructor(data) {
    super(data.message);
    this.message = data.message;
    this.errors = data.errors;
    this.statusCode = data.statusCode;
  }
}

export default APIError;
