import React, { useEffect, useState } from 'react';
import { flexFlowOptions, textAlignOptions } from '../ThemesForm';

const getButtonsState = (info) => ({
  call: {
    isVisible: info?.call?.isVisible ?? true,
    order: info?.call?.order ?? 1,
    flexFlow: info?.call?.flexFlow ?? '',
    padding: info?.call?.padding ?? '',
    margin: info?.call?.margin ?? '',
    shape: info?.call?.shape ?? 'rectangle',
    backgroundColor: info?.call?.backgroundColor ?? '',
    color: info?.call?.color ?? '',
    text: {
      isVisible: info?.call?.text?.isVisible ?? true,
      content: info?.call?.text?.content ?? 'Call Me',
      width: info?.call?.text?.width ?? '',
      margin: info?.call?.text?.margin ?? '',
      fontSize: info?.call?.text?.fontSize ?? '',
      fontWeight: info?.call?.text?.fontWeight ?? '',
      textAlign: info?.call?.text?.textAlign ?? '',
    },
    icon: {
      shape: info?.call?.icon?.shape ?? 'rectangle',
      isVisible: info?.call?.icon?.isVisible ?? true,
      url: info?.call?.icon?.url ?? '/images/MBC/phone-icon.png',
      size: info?.call?.icon?.size ?? 'small',
      backgroundColor: info?.call?.icon?.backgroundColor ?? '',
      fill: info?.call?.icon?.fill ?? '#333333',
    },
  },
  text: {
    isVisible: info?.text?.isVisible ?? true,
    order: info?.text?.order ?? 1,
    flexFlow: info?.text?.flexFlow ?? '',
    padding: info?.text?.padding ?? '',
    margin: info?.text?.margin ?? '',
    shape: info?.text?.shape ?? 'rectangle',
    backgroundColor: info?.text?.backgroundColor ?? '',
    color: info?.text?.color ?? '',
    text: {
      isVisible: info?.text?.text?.isVisible ?? true,
      content: info?.text?.text?.content ?? 'Text Me',
      width: info?.text?.text?.width ?? '',
      margin: info?.text?.text?.margin ?? '',
      fontSize: info?.text?.text?.fontSize ?? '',
      fontWeight: info?.text?.text?.fontWeight ?? '',
      textAlign: info?.text?.text?.textAlign ?? '',
    },
    icon: {
      shape: info?.text?.icon?.shape ?? 'rectangle',
      isVisible: info?.icon?.icon?.isVisible ?? true,
      url: info?.text?.icon?.url ?? '/images/MBC/text-icon.png',
      size: info?.text?.text?.size ?? 'small',
      backgroundColor: info?.text?.icon?.backgroundColor ?? '',
      fill: info?.text?.icon?.fill ?? '#333333',
    },
  },
  email: {
    isVisible: info?.email?.isVisible ?? true,
    order: info?.email?.order ?? 1,
    flexFlow: info?.email?.flexFlow ?? '',
    padding: info?.email?.padding ?? '',
    margin: info?.email?.margin ?? '',
    shape: info?.email?.shape ?? 'rectangle',
    backgroundColor: info?.email?.backgroundColor ?? '',
    color: info?.email?.color ?? '',
    text: {
      isVisible: info?.email?.text?.isVisible ?? true,
      content: info?.email?.text?.content ?? 'Email Me',
      width: info?.email?.text?.width ?? '',
      margin: info?.email?.text?.margin ?? '',
      fontSize: info?.email?.text?.fontSize ?? '',
      fontWeight: info?.email?.text?.fontWeight ?? '',
      textAlign: info?.email?.text?.textAlign ?? '',
    },
    icon: {
      shape: info?.email?.icon?.shape ?? 'rectangle',
      isVisible: info?.email?.icon?.isVisible ?? true,
      url: info?.email?.icon?.url ?? '/images/MBC/email-icon.png',
      size: info?.email?.icon?.size ?? 'small',
      backgroundColor: info?.email?.icon?.backgroundColor ?? '',
      fill: info?.email?.icon?.fill ?? '#333333',
    },
  },
  about: {
    isVisible: info?.about?.isVisible ?? true,
    order: info?.about?.order ?? 1,
    flexFlow: info?.about?.flexFlow ?? '',
    padding: info?.about?.padding ?? '',
    margin: info?.about?.margin ?? '',
    shape: info?.about?.shape ?? 'rectangle',
    backgroundColor: info?.about?.backgroundColor ?? '',
    color: info?.about?.color ?? '',
    text: {
      isVisible: info?.about?.text?.isVisible ?? true,
      content: info?.about?.text?.content ?? 'About Me',
      width: info?.about?.text?.width ?? '',
      margin: info?.about?.text?.margin ?? '',
      fontSize: info?.about?.text?.fontSize ?? '',
      fontWeight: info?.about?.text?.fontWeight ?? '',
      textAlign: info?.about?.text?.textAlign ?? '',
    },
    icon: {
      shape: info?.about?.icon?.shape ?? 'rectangle',
      isVisible: info?.about?.icon?.isVisible ?? true,
      url: info?.about?.icon?.url ?? '/images/MBC/about-icon.png',
      size: info?.about?.icon?.size ?? 'small',
      backgroundColor: info?.about?.icon?.backgroundColor ?? '',
      fill: info?.about?.icon?.fill ?? '#333333',
    },
  },
  download: {
    isVisible: info?.download?.isVisible ?? true,
    order: info?.download?.order ?? 1,
    flexFlow: info?.download?.flexFlow ?? '',
    padding: info?.download?.padding ?? '',
    margin: info?.download?.margin ?? '',
    shape: info?.download?.shape ?? 'rectangle',
    backgroundColor: info?.download?.backgroundColor ?? '',
    color: info?.download?.color ?? '',
    text: {
      isVisible: info?.download?.text?.isVisible ?? true,
      content: info?.download?.text?.content ?? 'Download',
      width: info?.download?.text?.width ?? '',
      margin: info?.download?.text?.margin ?? '',
      fontSize: info?.download?.text?.fontSize ?? '',
      fontWeight: info?.download?.text?.fontWeight ?? '',
      textAlign: info?.download?.text?.textAlign ?? '',
    },
    icon: {
      shape: info?.download?.icon?.shape ?? 'rectangle',
      isVisible: info?.download?.icon?.isVisible ?? true,
      url: info?.download?.icon?.url ?? '/images/MBC/download-icon.png',
      size: info?.download?.icon?.size ?? 'small',
      backgroundColor: info?.download?.icon?.backgroundColor ?? '',
      fill: info?.download?.icon?.fill ?? '#333333',
    },
  },
  send: {
    isVisible: info?.send?.isVisible ?? true,
    order: info?.send?.order ?? 1,
    flexFlow: info?.send?.flexFlow ?? '',
    padding: info?.send?.padding ?? '',
    margin: info?.send?.margin ?? '',
    shape: info?.send?.shape ?? 'rectangle',
    backgroundColor: info?.send?.backgroundColor ?? '',
    color: info?.send?.color ?? '',
    text: {
      isVisible: info?.send?.text?.isVisible ?? true,
      content: info?.send?.text?.content ?? 'Send',
      width: info?.send?.text?.width ?? '',
      margin: info?.send?.text?.margin ?? '',
      fontSize: info?.send?.text?.fontSize ?? '',
      fontWeight: info?.send?.text?.fontWeight ?? '',
      textAlign: info?.send?.text?.textAlign ?? '',
    },
    icon: {
      shape: info?.send?.icon?.shape ?? 'rectangle',
      isVisible: info?.send?.icon?.isVisible ?? true,
      url: info?.send?.icon?.url ?? '/images/MBC/send-icon.png',
      size: info?.send?.icon?.size ?? 'small',
      backgroundColor: info?.send?.icon?.backgroundColor ?? '',
      fill: info?.send?.icon?.fill ?? '#333333',
    },
  },
  medium: {
    call: {
      order: info?.medium?.call?.order ?? 1,
      flexFlow: info?.medium?.call?.flexFlow ?? '',
      padding: info?.medium?.call?.padding ?? '',
      margin: info?.medium?.call?.margin ?? '',
      text: {
        width: info?.medium?.call?.text?.width ?? '',
        margin: info?.medium?.call?.text?.margin ?? '',
        fontSize: info?.medium?.call?.text?.fontSize ?? '',
        fontWeight: info?.medium?.call?.text?.fontWeight ?? '',
        textAlign: info?.medium?.call?.text?.textAlign ?? '',
      },
      icon: {
        size: info?.medium?.call?.icon?.size ?? 'small',
      },
    },
    text: {
      order: info?.medium?.text?.order ?? 1,
      flexFlow: info?.medium?.text?.flexFlow ?? '',
      padding: info?.medium?.text?.padding ?? '',
      margin: info?.medium?.text?.margin ?? '',
      text: {
        width: info?.medium?.text?.text?.width ?? '',
        margin: info?.medium?.text?.text?.margin ?? '',
        fontSize: info?.medium?.text?.text?.fontSize ?? '',
        fontWeight: info?.medium?.text?.text?.fontWeight ?? '',
        textAlign: info?.medium?.text?.text?.textAlign ?? '',
      },
      icon: {
        size: info?.medium?.text?.icon?.size ?? 'small',
      },
    },
    email: {
      order: info?.medium?.email?.order ?? 1,
      flexFlow: info?.medium?.email?.flexFlow ?? '',
      padding: info?.medium?.email?.padding ?? '',
      margin: info?.medium?.email?.margin ?? '',
      text: {
        width: info?.medium?.email?.text?.width ?? '',
        margin: info?.medium?.email?.text?.margin ?? '',
        fontSize: info?.medium?.email?.text?.fontSize ?? '',
        fontWeight: info?.medium?.email?.text?.fontWeight ?? '',
        textAlign: info?.medium?.email?.text?.textAlign ?? '',
      },
      icon: {
        size: info?.medium?.email?.icon?.size ?? 'small',
      },
    },
    about: {
      order: info?.medium?.about?.order ?? 1,
      flexFlow: info?.medium?.about?.flexFlow ?? '',
      padding: info?.medium?.about?.padding ?? '',
      margin: info?.medium?.about?.margin ?? '',
      text: {
        width: info?.medium?.about?.text?.width ?? '',
        margin: info?.medium?.about?.text?.margin ?? '',
        fontSize: info?.medium?.about?.text?.fontSize ?? '',
        fontWeight: info?.medium?.about?.text?.fontWeight ?? '',
        textAlign: info?.medium?.about?.text?.textAlign ?? '',
      },
      icon: {
        size: info?.medium?.about?.icon?.size ?? 'small',
      },
    },
    download: {
      order: info?.medium?.download?.order ?? 1,
      flexFlow: info?.medium?.download?.flexFlow ?? '',
      padding: info?.medium?.download?.padding ?? '',
      margin: info?.medium?.download?.margin ?? '',
      text: {
        width: info?.medium?.download?.text?.width ?? '',
        margin: info?.medium?.download?.text?.margin ?? '',
        fontSize: info?.medium?.download?.text?.fontSize ?? '',
        fontWeight: info?.medium?.download?.text?.fontWeight ?? '',
        textAlign: info?.medium?.download?.text?.textAlign ?? '',
      },
      icon: {
        size: info?.medium?.download?.icon?.size ?? 'small',
      },
    },
    send: {
      order: info?.medium?.send?.order ?? 1,
      flexFlow: info?.medium?.send?.flexFlow ?? '',
      padding: info?.medium?.send?.padding ?? '',
      margin: info?.medium?.send?.margin ?? '',
      text: {
        width: info?.medium?.send?.text?.width ?? '',
        margin: info?.medium?.send?.text?.margin ?? '',
        fontSize: info?.medium?.send?.text?.fontSize ?? '',
        fontWeight: info?.medium?.send?.text?.fontWeight ?? '',
        textAlign: info?.medium?.send?.text?.textAlign ?? '',
      },
      icon: {
        size: info?.medium?.send?.icon?.size ?? 'small',
      },
    },
  },
  large: {
    call: {
      order: info?.large?.call?.order ?? 1,
      flexFlow: info?.large?.call?.flexFlow ?? '',
      padding: info?.large?.call?.padding ?? '',
      margin: info?.large?.call?.margin ?? '',
      text: {
        width: info?.large?.call?.text?.width ?? '',
        margin: info?.large?.call?.text?.margin ?? '',
        fontSize: info?.large?.call?.text?.fontSize ?? '',
        fontWeight: info?.large?.call?.text?.fontWeight ?? '',
        textAlign: info?.large?.call?.text?.textAlign ?? '',
      },
      icon: {
        size: info?.large?.call?.icon?.size ?? 'small',
      },
    },
    text: {
      order: info?.large?.text?.order ?? 1,
      flexFlow: info?.large?.text?.flexFlow ?? '',
      padding: info?.large?.text?.padding ?? '',
      margin: info?.large?.text?.margin ?? '',
      text: {
        width: info?.large?.text?.text?.width ?? '',
        margin: info?.large?.text?.text?.margin ?? '',
        fontSize: info?.large?.text?.text?.fontSize ?? '',
        fontWeight: info?.large?.text?.text?.fontWeight ?? '',
        textAlign: info?.large?.text?.text?.textAlign ?? '',
      },
      icon: {
        size: info?.large?.text?.icon?.size ?? 'small',
      },
    },
    email: {
      order: info?.large?.email?.order ?? 1,
      flexFlow: info?.large?.email?.flexFlow ?? '',
      padding: info?.large?.email?.padding ?? '',
      margin: info?.large?.email?.margin ?? '',
      text: {
        width: info?.large?.email?.text?.width ?? '',
        margin: info?.large?.email?.text?.margin ?? '',
        fontSize: info?.large?.email?.text?.fontSize ?? '',
        fontWeight: info?.large?.email?.text?.fontWeight ?? '',
        textAlign: info?.large?.email?.text?.textAlign ?? '',
      },
      icon: {
        size: info?.large?.email?.icon?.size ?? 'small',
      },
    },
    about: {
      order: info?.large?.about?.order ?? 1,
      flexFlow: info?.large?.about?.flexFlow ?? '',
      padding: info?.large?.about?.padding ?? '',
      margin: info?.large?.about?.margin ?? '',
      text: {
        width: info?.large?.about?.text?.width ?? '',
        margin: info?.large?.about?.text?.margin ?? '',
        fontSize: info?.large?.about?.text?.fontSize ?? '',
        fontWeight: info?.large?.about?.text?.fontWeight ?? '',
        textAlign: info?.large?.about?.text?.textAlign ?? '',
      },
      icon: {
        size: info?.large?.about?.icon?.size ?? 'small',
      },
    },
    download: {
      order: info?.large?.download?.order ?? 1,
      flexFlow: info?.large?.download?.flexFlow ?? '',
      padding: info?.large?.download?.padding ?? '',
      margin: info?.large?.download?.margin ?? '',
      text: {
        width: info?.large?.download?.text?.width ?? '',
        margin: info?.large?.download?.text?.margin ?? '',
        fontSize: info?.large?.download?.text?.fontSize ?? '',
        fontWeight: info?.large?.download?.text?.fontWeight ?? '',
        textAlign: info?.large?.download?.text?.textAlign ?? '',
      },
      icon: {
        size: info?.large?.download?.icon?.size ?? 'small',
      },
    },
    send: {
      order: info?.large?.send?.order ?? 1,
      flexFlow: info?.large?.send?.flexFlow ?? '',
      padding: info?.large?.send?.padding ?? '',
      margin: info?.large?.send?.margin ?? '',
      text: {
        width: info?.large?.send?.text?.width ?? '',
        margin: info?.large?.send?.text?.margin ?? '',
        fontSize: info?.large?.send?.text?.fontSize ?? '',
        fontWeight: info?.large?.send?.text?.fontWeight ?? '',
        textAlign: info?.large?.send?.text?.textAlign ?? '',
      },
      icon: {
        size: info?.large?.send?.icon?.size ?? 'small',
      },
    },
  },
});

const getGroupState = (info) => ({
  order: info?.group?.order ?? 6,
  padding: info?.group?.padding ?? '',
  columnNumber: info?.group?.columnNumber ?? 2,
  backgroundColor: info?.group?.backgroundColor ?? '',
  medium: {
    order: info?.medium?.group?.order ?? 6,
    padding: info?.medium?.group?.padding ?? '',
  },
  large: {
    order: info?.large?.group?.order ?? 6,
    padding: info?.large?.group?.padding ?? '',
  },
});

const useButtons = (info) => {
  const [buttonsInfo, setButtonsInfo] = useState(getButtonsState(info));

  const [buttonGroupInfo, setButtonGroupInfo] = useState(getGroupState(info));

  useEffect(() => {
    setButtonsInfo(getButtonsState(info));
    setButtonGroupInfo(getGroupState(info));
  }, [info]);

  const handleGroupChange = (propertyName, value, breakpoint) => {
    const newInfo = { ...buttonGroupInfo };
    if (breakpoint === 'medium' || breakpoint === 'large') {
      newInfo[breakpoint][propertyName] = value;
    } else {
      newInfo[propertyName] = value;
    }

    setButtonGroupInfo(newInfo);
  };

  const handleButtonChange = (
    propertyName,
    value,
    element = '',
    subelement = '',
    breakpoint = '',
  ) => {
    const newInfo = { ...buttonsInfo };

    if (breakpoint === 'medium' || breakpoint === 'large') {
      if (subelement && subelement !== '' && element && element !== '') {
        newInfo[breakpoint][element][subelement] = {
          ...newInfo[breakpoint][element][subelement],
          [propertyName]: value,
        };
      } else if (element && element !== '') {
        newInfo[breakpoint][element] = {
          ...newInfo[breakpoint][element],
          [propertyName]: value,
        };
      } else {
        newInfo[breakpoint] = {
          ...newInfo[breakpoint],
          [propertyName]: value,
        };
      }
    } else if (subelement && subelement !== '' && element && element !== '') {
      newInfo[element][subelement] = {
        ...newInfo[element][subelement],
        [propertyName]: value,
      };
    } else if (element && element !== '') {
      newInfo[element][propertyName] = value;
    } else {
      newInfo[propertyName] = value;
    }

    setButtonsInfo(newInfo);
  };

  const groupInputs = [
    {
      type: 'number',
      value: buttonGroupInfo.order,
      onChange: (evt) => handleGroupChange('order', evt.target.value),
      label: 'ORDER',
      name: 'buttonGroupOrder',
      placeholder: '6',
    },
    {
      type: 'text',
      value: buttonGroupInfo.padding,
      onChange: (evt) => handleGroupChange('padding', evt.target.value),
      label: 'PADDING',
      name: 'buttonGroupPadding',
      placeholder: '0.95rem 0 0 0',
    },
    {
      type: 'number',
      value: buttonGroupInfo.columnNumber,
      onChange: (evt) => handleGroupChange('columnNumber', evt.target.value),
      label: 'COLUMN NUMBER',
      name: 'buttonGroupColumnNumber',
      placeholder: '2',
    },
    {
      type: 'text',
      value: buttonGroupInfo.backgroundColor,
      onChange: (evt) => handleGroupChange('backgroundColor', evt.target.value),
      label: 'BACKGROUND COLOR',
      name: 'buttonGroupBackgroundColor',
      placeholder: '#ffffff',
    },
  ];

  const mediumGroupInputs = [
    {
      type: 'number',
      value: buttonGroupInfo.medium.order,
      onChange: (evt) => handleGroupChange('order', evt.target.value, 'medium'),
      label: 'ORDER',
      name: 'buttonGroupOrderMedium',
      placeholder: '6',
    },
    {
      type: 'text',
      value: buttonGroupInfo.medium.padding,
      onChange: (evt) =>
        handleGroupChange('padding', evt.target.value, 'medium'),
      label: 'PADDING',
      name: 'buttonGroupPaddingMedium',
      placeholder: '0.95rem 0 0 0',
    },
  ];

  const largeGroupInputs = [
    {
      type: 'number',
      value: buttonGroupInfo.large.order,
      onChange: (evt) => handleGroupChange('order', evt.target.value, 'large'),
      label: 'ORDER',
      name: 'buttonGroupOrder',
      placeholder: '6',
    },
    {
      type: 'text',
      value: buttonGroupInfo.large.padding,
      onChange: (evt) =>
        handleGroupChange('padding', evt.target.value, 'large'),
      label: 'PADDING',
      name: 'buttonGroupPadding',
      placeholder: '0.95rem 0 0 0',
    },
  ];

  const callInputs = [
    {
      type: 'checkbox',
      value: buttonsInfo.call.isVisible,
      onChange: (evt) =>
        handleButtonChange('isVisible', evt.target.checked, 'call'),
      label: 'IS VISIBLE?',
      name: 'callIsVisible',
    },
    {
      type: 'number',
      value: buttonsInfo.call.order,
      onChange: (evt) => handleButtonChange('order', evt.target.value, 'call'),
      label: 'ORDER',
      name: 'callOrder',
      placeholder: '1',
    },
    {
      as: 'select',
      options: flexFlowOptions,
      value: buttonsInfo.call.flexFlow,
      onChange: (evt) =>
        handleButtonChange('flexFlow', evt.target.value, 'call'),
      label: (
        <abbr title="Horizontal - row, vertical - column">
          DIRECTION &amp; WRAPPING
        </abbr>
      ),
      name: 'callFlexFlow',
    },
    {
      type: 'text',
      value: buttonsInfo.call.padding,
      onChange: (evt) =>
        handleButtonChange('padding', evt.target.value, 'call'),
      label: 'PADDING',
      name: 'callPadding',
      placeholder: '1rem 0 1rem 0.75rem',
    },
    {
      type: 'text',
      value: buttonsInfo.call.margin,
      onChange: (evt) => handleButtonChange('margin', evt.target.value, 'call'),
      label: 'MARGIN',
      name: 'callMargin',
      placeholder: '0 0 0.15rem 0',
    },
    {
      type: 'text',
      value: buttonsInfo.call.shape,
      onChange: (evt) => handleButtonChange('shape', evt.target.value, 'call'),
      label: 'SHAPE',
      name: 'callShape',
      placeholder: 'rectangle',
    },
    {
      type: 'text',
      value: buttonsInfo.call.backgroundColor,
      onChange: (evt) =>
        handleButtonChange('backgroundColor', evt.target.value, 'call'),
      label: 'BACKGROUND COLOR',
      name: 'callBackgroundColor',
      placeholder: '#323232',
    },
    {
      type: 'text',
      value: buttonsInfo.call.color,
      onChange: (evt) => handleButtonChange('color', evt.target.value, 'call'),
      label: 'COLOR',
      name: 'callColor',
      placeholder: '#fdfdfd',
    },

    {
      type: 'checkbox',
      value: buttonsInfo.call.text.isVisible,
      onChange: (evt) =>
        handleButtonChange('isVisible', evt.target.checked, 'call', 'text'),
      label: 'IS TEXT VISIBLE?',
      name: 'callTextIsVisible',
    },
    {
      type: 'text',
      value: buttonsInfo.call.text.content,
      onChange: (evt) =>
        handleButtonChange('content', evt.target.value, 'call', 'text'),
      label: 'TEXT CONTENT',
      name: 'callTextContent',
      placeholder: 'Call Me',
    },
    {
      type: 'text',
      value: buttonsInfo.call.text.width,
      onChange: (evt) =>
        handleButtonChange('width', evt.target.value, 'call', 'text'),
      label: 'TEXT WIDTH',
      name: 'callTextWidth',
      placeholder: '60%',
    },
    {
      type: 'text',
      value: buttonsInfo.call.text.margin,
      onChange: (evt) =>
        handleButtonChange('margin', evt.target.value, 'call', 'text'),
      label: 'TEXT MARGIN',
      name: 'callTextMargin',
      placeholder: '0.3rem 0 0 0',
    },
    {
      type: 'text',
      value: buttonsInfo.call.text.fontSize,
      onChange: (evt) =>
        handleButtonChange('fontSize', evt.target.value, 'call', 'text'),
      label: 'FONT SIZE',
      name: 'callTextFontSize',
      placeholder: '0.9rem',
    },
    {
      type: 'text',
      value: buttonsInfo.call.text.fontWeight,
      onChange: (evt) =>
        handleButtonChange('fontWeight', evt.target.value, 'call', 'text'),
      label: 'FONT WEIGHT',
      name: 'callTextFontWeight',
      placeholder: '600',
    },
    {
      as: 'select',
      options: textAlignOptions,
      value: buttonsInfo.call.text.textAlign,
      onChange: (evt) =>
        handleButtonChange('textAlign', evt.target.value, 'call', 'text'),
      label: 'TEXT ALIGN',
      name: 'callTextTextAlign',
    },

    {
      type: 'text',
      value: buttonsInfo.call.icon.shape,
      onChange: (evt) =>
        handleButtonChange('shape', evt.target.value, 'call', 'icon'),
      label: 'SHAPE',
      name: 'callIconShape',
      placeholder: 'rectangle',
    },
    {
      type: 'checkbox',
      value: buttonsInfo.call.icon.isVisible,
      onChange: (evt) =>
        handleButtonChange('isVisible', evt.target.value, 'call', 'icon'),
      label: 'IS ICON VISIBLE?',
      name: 'callIconIsVisible',
    },
    {
      type: 'text',
      value: buttonsInfo.call.icon.url,
      onChange: (evt) =>
        handleButtonChange('url', evt.target.value, 'call', 'icon'),
      label: 'ICON URL',
      name: 'callIconUrl',
      placeholder: '/images/MBC/phone-icon.png',
    },
    {
      type: 'text',
      value: buttonsInfo.call.icon.size,
      onChange: (evt) =>
        handleButtonChange('size', evt.target.value, 'call', 'icon'),
      label: 'SIZE',
      name: 'callIconSize',
      placeholder: 'small',
    },
    {
      type: 'text',
      value: buttonsInfo.call.icon.backgroundColor,
      onChange: (evt) =>
        handleButtonChange('backgroundColor', evt.target.value, 'call', 'icon'),
      label: 'BACKGROUND COLOR',
      name: 'callIconBackgroundColor',
      placeholder: '#323232',
    },
    {
      type: 'text',
      value: buttonsInfo.call.icon.fill,
      onChange: (evt) =>
        handleButtonChange('fill', evt.target.value, 'call', 'icon'),
      label: 'FILL (if icon is an svg)',
      name: 'callIconFill',
      placeholder: '#333333',
    },
  ];
  const textInputs = [
    {
      type: 'checkbox',
      value: buttonsInfo.text.isVisible,
      onChange: (evt) =>
        handleButtonChange('isVisible', evt.target.checked, 'text'),
      label: 'IS VISIBLE?',
      name: 'textIsVisible',
    },
    {
      type: 'number',
      value: buttonsInfo.text.order,
      onChange: (evt) => handleButtonChange('order', evt.target.value, 'text'),
      label: 'ORDER',
      name: 'textOrder',
      placeholder: '1',
    },
    {
      as: 'select',
      options: flexFlowOptions,
      value: buttonsInfo.text.flexFlow,
      onChange: (evt) =>
        handleButtonChange('flexFlow', evt.target.value, 'text'),
      label: (
        <abbr title="Horizontal - row, vertical - column">
          DIRECTION &amp; WRAPPING
        </abbr>
      ),
      name: 'textFlexFlow',
    },
    {
      type: 'text',
      value: buttonsInfo.text.padding,
      onChange: (evt) =>
        handleButtonChange('padding', evt.target.value, 'text'),
      label: 'PADDING',
      name: 'textPadding',
      placeholder: '1rem 0 1rem 0.75rem',
    },
    {
      type: 'text',
      value: buttonsInfo.text.margin,
      onChange: (evt) => handleButtonChange('margin', evt.target.value, 'text'),
      label: 'MARGIN',
      name: 'textMargin',
      placeholder: '0 0 0.15rem 0',
    },
    {
      type: 'text',
      value: buttonsInfo.text.shape,
      onChange: (evt) => handleButtonChange('shape', evt.target.value, 'text'),
      label: 'SHAPE',
      name: 'textShape',
      placeholder: 'rectangle',
    },
    {
      type: 'text',
      value: buttonsInfo.text.backgroundColor,
      onChange: (evt) =>
        handleButtonChange('backgroundColor', evt.target.value, 'text'),
      label: 'BACKGROUND COLOR',
      name: 'textBackgroundColor',
      placeholder: '#323232',
    },
    {
      type: 'text',
      value: buttonsInfo.text.color,
      onChange: (evt) => handleButtonChange('color', evt.target.value, 'text'),
      label: 'COLOR',
      name: 'textColor',
      placeholder: '#fdfdfd',
    },

    {
      type: 'checkbox',
      value: buttonsInfo.text.text.isVisible,
      onChange: (evt) =>
        handleButtonChange('isVisible', evt.target.checked, 'text', 'text'),
      label: 'IS TEXT VISIBLE?',
      name: 'textTextIsVisible',
    },
    {
      type: 'text',
      value: buttonsInfo.text.text.content,
      onChange: (evt) =>
        handleButtonChange('content', evt.target.value, 'text', 'text'),
      label: 'TEXT CONTENT',
      name: 'textTextContent',
      placeholder: 'Text Me',
    },
    {
      type: 'text',
      value: buttonsInfo.text.text.width,
      onChange: (evt) =>
        handleButtonChange('width', evt.target.value, 'text', 'text'),
      label: 'TEXT WIDTH',
      name: 'textTextWidth',
      placeholder: '60%',
    },
    {
      type: 'text',
      value: buttonsInfo.text.text.margin,
      onChange: (evt) =>
        handleButtonChange('margin', evt.target.value, 'text', 'text'),
      label: 'TEXT MARGIN',
      name: 'textTextMargin',
      placeholder: '0.3rem 0 0 0',
    },
    {
      type: 'text',
      value: buttonsInfo.text.text.fontSize,
      onChange: (evt) =>
        handleButtonChange('fontSize', evt.target.value, 'text', 'text'),
      label: 'FONT SIZE',
      name: 'textTextFontSize',
      placeholder: '0.9rem',
    },
    {
      type: 'text',
      value: buttonsInfo.text.text.fontWeight,
      onChange: (evt) =>
        handleButtonChange('fontWeight', evt.target.value, 'text', 'text'),
      label: 'FONT WEIGHT',
      name: 'textTextFontWeight',
      placeholder: '600',
    },
    {
      as: 'select',
      options: textAlignOptions,
      value: buttonsInfo.text.text.textAlign,
      onChange: (evt) =>
        handleButtonChange('textAlign', evt.target.value, 'text', 'text'),
      label: 'TEXT ALIGN',
      name: 'textTextTextAlign',
    },

    {
      type: 'text',
      value: buttonsInfo.text.icon.shape,
      onChange: (evt) =>
        handleButtonChange('shape', evt.target.value, 'text', 'icon'),
      label: 'SHAPE',
      name: 'textIconShape',
      placeholder: 'rectangle',
    },
    {
      type: 'checkbox',
      value: buttonsInfo.text.icon.isVisible,
      onChange: (evt) =>
        handleButtonChange('isVisible', evt.target.value, 'text', 'icon'),
      label: 'IS ICON VISIBLE?',
      name: 'textIconIsVisible',
    },
    {
      type: 'text',
      value: buttonsInfo.text.icon.url,
      onChange: (evt) =>
        handleButtonChange('url', evt.target.value, 'text', 'icon'),
      label: 'ICON URL',
      name: 'textIconUrl',
      placeholder: '/images/MBC/text-icon.png',
    },
    {
      type: 'text',
      value: buttonsInfo.text.icon.size,
      onChange: (evt) =>
        handleButtonChange('size', evt.target.value, 'text', 'icon'),
      label: 'SIZE',
      name: 'textIconSize',
      placeholder: 'small',
    },
    {
      type: 'text',
      value: buttonsInfo.text.icon.backgroundColor,
      onChange: (evt) =>
        handleButtonChange('backgroundColor', evt.target.value, 'text', 'icon'),
      label: 'BACKGROUND COLOR',
      name: 'textIconBackgroundColor',
      placeholder: '#323232',
    },
    {
      type: 'text',
      value: buttonsInfo.text.icon.fill,
      onChange: (evt) =>
        handleButtonChange('fill', evt.target.value, 'text', 'icon'),
      label: 'FILL (if icon is an svg)',
      name: 'textIconFill',
      placeholder: '#333333',
    },
  ];
  const emailInputs = [
    {
      type: 'checkbox',
      value: buttonsInfo.email.isVisible,
      onChange: (evt) =>
        handleButtonChange('isVisible', evt.target.checked, 'email'),
      label: 'IS VISIBLE?',
      name: 'emailIsVisible',
    },
    {
      type: 'number',
      value: buttonsInfo.email.order,
      onChange: (evt) => handleButtonChange('order', evt.target.value, 'email'),
      label: 'ORDER',
      name: 'emailOrder',
      placeholder: '1',
    },
    {
      as: 'select',
      options: flexFlowOptions,
      value: buttonsInfo.email.flexFlow,
      onChange: (evt) =>
        handleButtonChange('flexFlow', evt.target.value, 'email'),
      label: (
        <abbr title="Horizontal - row, vertical - column">
          DIRECTION &amp; WRAPPING
        </abbr>
      ),
      name: 'emailFlexFlow',
    },
    {
      type: 'text',
      value: buttonsInfo.email.padding,
      onChange: (evt) =>
        handleButtonChange('padding', evt.target.value, 'email'),
      label: 'PADDING',
      name: 'emailPadding',
      placeholder: '1rem 0 1rem 0.75rem',
    },
    {
      type: 'text',
      value: buttonsInfo.email.margin,
      onChange: (evt) =>
        handleButtonChange('margin', evt.target.value, 'email'),
      label: 'MARGIN',
      name: 'emailMargin',
      placeholder: '0 0 0.15rem 0',
    },
    {
      type: 'text',
      value: buttonsInfo.email.shape,
      onChange: (evt) => handleButtonChange('shape', evt.target.value, 'email'),
      label: 'SHAPE',
      name: 'emailShape',
      placeholder: 'rectangle',
    },
    {
      type: 'text',
      value: buttonsInfo.email.backgroundColor,
      onChange: (evt) =>
        handleButtonChange('backgroundColor', evt.target.value, 'email'),
      label: 'BACKGROUND COLOR',
      name: 'emailBackgroundColor',
      placeholder: '#323232',
    },
    {
      type: 'text',
      value: buttonsInfo.email.color,
      onChange: (evt) => handleButtonChange('color', evt.target.value, 'email'),
      label: 'COLOR',
      name: 'emailColor',
      placeholder: '#fdfdfd',
    },

    {
      type: 'checkbox',
      value: buttonsInfo.email.text.isVisible,
      onChange: (evt) =>
        handleButtonChange('isVisible', evt.target.checked, 'email', 'text'),
      label: 'IS TEXT VISIBLE?',
      name: 'emailTextIsVisible',
    },
    {
      type: 'text',
      value: buttonsInfo.email.text.content,
      onChange: (evt) =>
        handleButtonChange('content', evt.target.value, 'email', 'text'),
      label: 'TEXT CONTENT',
      name: 'emailTextContent',
      placeholder: 'Email Me',
    },
    {
      type: 'text',
      value: buttonsInfo.email.text.width,
      onChange: (evt) =>
        handleButtonChange('width', evt.target.value, 'email', 'text'),
      label: 'TEXT WIDTH',
      name: 'emailTextWidth',
      placeholder: '60%',
    },
    {
      type: 'text',
      value: buttonsInfo.email.text.margin,
      onChange: (evt) =>
        handleButtonChange('margin', evt.target.value, 'email', 'text'),
      label: 'TEXT MARGIN',
      name: 'emailTextMargin',
      placeholder: '0.3rem 0 0 0',
    },
    {
      type: 'text',
      value: buttonsInfo.email.text.fontSize,
      onChange: (evt) =>
        handleButtonChange('fontSize', evt.target.value, 'email', 'text'),
      label: 'FONT SIZE',
      name: 'emailTextFontSize',
      placeholder: '0.9rem',
    },
    {
      type: 'text',
      value: buttonsInfo.email.text.fontWeight,
      onChange: (evt) =>
        handleButtonChange('fontWeight', evt.target.value, 'email', 'text'),
      label: 'FONT WEIGHT',
      name: 'emailTextFontWeight',
      placeholder: '600',
    },
    {
      as: 'select',
      options: textAlignOptions,
      value: buttonsInfo.email.text.textAlign,
      onChange: (evt) =>
        handleButtonChange('textAlign', evt.target.value, 'email', 'text'),
      label: 'TEXT ALIGN',
      name: 'emailTextTextAlign',
    },

    {
      type: 'text',
      value: buttonsInfo.email.icon.shape,
      onChange: (evt) =>
        handleButtonChange('shape', evt.target.value, 'email', 'icon'),
      label: 'SHAPE',
      name: 'emailIconShape',
      placeholder: 'rectangle',
    },
    {
      type: 'checkbox',
      value: buttonsInfo.email.icon.isVisible,
      onChange: (evt) =>
        handleButtonChange('isVisible', evt.target.value, 'email', 'icon'),
      label: 'IS ICON VISIBLE?',
      name: 'emailIconIsVisible',
    },
    {
      type: 'text',
      value: buttonsInfo.email.icon.url,
      onChange: (evt) =>
        handleButtonChange('url', evt.target.value, 'email', 'icon'),
      label: 'ICON URL',
      name: 'emailIconUrl',
      placeholder: '/images/MBC/email-icon.png',
    },
    {
      type: 'text',
      value: buttonsInfo.email.icon.size,
      onChange: (evt) =>
        handleButtonChange('size', evt.target.value, 'email', 'icon'),
      label: 'SIZE',
      name: 'emailIconSize',
      placeholder: 'small',
    },
    {
      type: 'text',
      value: buttonsInfo.email.icon.backgroundColor,
      onChange: (evt) =>
        handleButtonChange(
          'backgroundColor',
          evt.target.value,
          'email',
          'icon',
        ),
      label: 'BACKGROUND COLOR',
      name: 'emailIconBackgroundColor',
      placeholder: '#323232',
    },
    {
      type: 'text',
      value: buttonsInfo.email.icon.fill,
      onChange: (evt) =>
        handleButtonChange('fill', evt.target.value, 'email', 'icon'),
      label: 'FILL (if icon is an svg)',
      name: 'emailIconFill',
      placeholder: '#333333',
    },
  ];
  const aboutInputs = [
    {
      type: 'checkbox',
      value: buttonsInfo.about.isVisible,
      onChange: (evt) =>
        handleButtonChange('isVisible', evt.target.checked, 'about'),
      label: 'IS VISIBLE?',
      name: 'aboutIsVisible',
    },
    {
      type: 'number',
      value: buttonsInfo.about.order,
      onChange: (evt) => handleButtonChange('order', evt.target.value, 'about'),
      label: 'ORDER',
      name: 'aboutOrder',
      placeholder: '1',
    },
    {
      as: 'select',
      options: flexFlowOptions,
      value: buttonsInfo.about.flexFlow,
      onChange: (evt) =>
        handleButtonChange('flexFlow', evt.target.value, 'about'),
      label: (
        <abbr title="Horizontal - row, vertical - column">
          DIRECTION &amp; WRAPPING
        </abbr>
      ),
      name: 'aboutFlexFlow',
    },
    {
      type: 'text',
      value: buttonsInfo.about.padding,
      onChange: (evt) =>
        handleButtonChange('padding', evt.target.value, 'about'),
      label: 'PADDING',
      name: 'aboutPadding',
      placeholder: '1rem 0 1rem 0.75rem',
    },
    {
      type: 'text',
      value: buttonsInfo.about.margin,
      onChange: (evt) =>
        handleButtonChange('margin', evt.target.value, 'about'),
      label: 'MARGIN',
      name: 'aboutMargin',
      placeholder: '0 0 0.15rem 0',
    },
    {
      type: 'text',
      value: buttonsInfo.about.shape,
      onChange: (evt) => handleButtonChange('shape', evt.target.value, 'about'),
      label: 'SHAPE',
      name: 'aboutShape',
      placeholder: 'rectangle',
    },
    {
      type: 'text',
      value: buttonsInfo.about.backgroundColor,
      onChange: (evt) =>
        handleButtonChange('backgroundColor', evt.target.value, 'about'),
      label: 'BACKGROUND COLOR',
      name: 'aboutBackgroundColor',
      placeholder: '#323232',
    },
    {
      type: 'text',
      value: buttonsInfo.about.color,
      onChange: (evt) => handleButtonChange('color', evt.target.value, 'about'),
      label: 'COLOR',
      name: 'aboutColor',
      placeholder: '#fdfdfd',
    },

    {
      type: 'checkbox',
      value: buttonsInfo.about.text.isVisible,
      onChange: (evt) =>
        handleButtonChange('isVisible', evt.target.checked, 'about', 'text'),
      label: 'IS TEXT VISIBLE?',
      name: 'aboutTextIsVisible',
    },
    {
      type: 'text',
      value: buttonsInfo.about.text.content,
      onChange: (evt) =>
        handleButtonChange('content', evt.target.value, 'about', 'text'),
      label: 'TEXT CONTENT',
      name: 'aboutTextContent',
      placeholder: 'About Me',
    },
    {
      type: 'text',
      value: buttonsInfo.about.text.width,
      onChange: (evt) =>
        handleButtonChange('width', evt.target.value, 'about', 'text'),
      label: 'TEXT WIDTH',
      name: 'aboutTextWidth',
      placeholder: '60%',
    },
    {
      type: 'text',
      value: buttonsInfo.about.text.margin,
      onChange: (evt) =>
        handleButtonChange('margin', evt.target.value, 'about', 'text'),
      label: 'TEXT MARGIN',
      name: 'aboutTextMargin',
      placeholder: '0.3rem 0 0 0',
    },
    {
      type: 'text',
      value: buttonsInfo.about.text.fontSize,
      onChange: (evt) =>
        handleButtonChange('fontSize', evt.target.value, 'about', 'text'),
      label: 'FONT SIZE',
      name: 'aboutTextFontSize',
      placeholder: '0.9rem',
    },
    {
      type: 'text',
      value: buttonsInfo.about.text.fontWeight,
      onChange: (evt) =>
        handleButtonChange('fontWeight', evt.target.value, 'about', 'text'),
      label: 'FONT WEIGHT',
      name: 'aboutTextFontWeight',
      placeholder: '600',
    },
    {
      as: 'select',
      options: textAlignOptions,
      value: buttonsInfo.about.text.textAlign,
      onChange: (evt) =>
        handleButtonChange('textAlign', evt.target.value, 'about', 'text'),
      label: 'TEXT ALIGN',
      name: 'aboutTextTextAlign',
    },

    {
      type: 'text',
      value: buttonsInfo.about.icon.shape,
      onChange: (evt) =>
        handleButtonChange('shape', evt.target.value, 'about', 'icon'),
      label: 'SHAPE',
      name: 'aboutIconShape',
      placeholder: 'rectangle',
    },
    {
      type: 'checkbox',
      value: buttonsInfo.about.icon.isVisible,
      onChange: (evt) =>
        handleButtonChange('isVisible', evt.target.value, 'about', 'icon'),
      label: 'IS ICON VISIBLE?',
      name: 'aboutIconIsVisible',
    },
    {
      type: 'text',
      value: buttonsInfo.about.icon.url,
      onChange: (evt) =>
        handleButtonChange('url', evt.target.value, 'about', 'icon'),
      label: 'ICON URL',
      name: 'aboutIconUrl',
      placeholder: '/images/MBC/about-icon.png',
    },
    {
      type: 'text',
      value: buttonsInfo.about.icon.size,
      onChange: (evt) =>
        handleButtonChange('size', evt.target.value, 'about', 'icon'),
      label: 'SIZE',
      name: 'aboutIconSize',
      placeholder: 'small',
    },
    {
      type: 'text',
      value: buttonsInfo.about.icon.backgroundColor,
      onChange: (evt) =>
        handleButtonChange(
          'backgroundColor',
          evt.target.value,
          'about',
          'icon',
        ),
      label: 'BACKGROUND COLOR',
      name: 'aboutIconBackgroundColor',
      placeholder: '#323232',
    },
    {
      type: 'text',
      value: buttonsInfo.about.icon.fill,
      onChange: (evt) =>
        handleButtonChange('fill', evt.target.value, 'about', 'icon'),
      label: 'FILL (if icon is an svg)',
      name: 'aboutIconFill',
      placeholder: '#333333',
    },
  ];
  const downloadInputs = [
    {
      type: 'checkbox',
      value: buttonsInfo.download.isVisible,
      onChange: (evt) =>
        handleButtonChange('isVisible', evt.target.checked, 'download'),
      label: 'IS VISIBLE?',
      name: 'downloadIsVisible',
    },
    {
      type: 'number',
      value: buttonsInfo.download.order,
      onChange: (evt) =>
        handleButtonChange('order', evt.target.value, 'download'),
      label: 'ORDER',
      name: 'downloadOrder',
      placeholder: '1',
    },
    {
      as: 'select',
      options: flexFlowOptions,
      value: buttonsInfo.download.flexFlow,
      onChange: (evt) =>
        handleButtonChange('flexFlow', evt.target.value, 'download'),
      label: (
        <abbr title="Horizontal - row, vertical - column">
          DIRECTION &amp; WRAPPING
        </abbr>
      ),
      name: 'downloadFlexFlow',
    },
    {
      type: 'text',
      value: buttonsInfo.download.padding,
      onChange: (evt) =>
        handleButtonChange('padding', evt.target.value, 'download'),
      label: 'PADDING',
      name: 'downloadPadding',
      placeholder: '1rem 0 1rem 0.75rem',
    },
    {
      type: 'text',
      value: buttonsInfo.download.margin,
      onChange: (evt) =>
        handleButtonChange('margin', evt.target.value, 'download'),
      label: 'MARGIN',
      name: 'downloadMargin',
      placeholder: '0 0 0.15rem 0',
    },
    {
      type: 'text',
      value: buttonsInfo.download.shape,
      onChange: (evt) =>
        handleButtonChange('shape', evt.target.value, 'download'),
      label: 'SHAPE',
      name: 'downloadShape',
      placeholder: 'rectangle',
    },
    {
      type: 'text',
      value: buttonsInfo.download.backgroundColor,
      onChange: (evt) =>
        handleButtonChange('backgroundColor', evt.target.value, 'download'),
      label: 'BACKGROUND COLOR',
      name: 'downloadBackgroundColor',
      placeholder: '#323232',
    },
    {
      type: 'text',
      value: buttonsInfo.download.color,
      onChange: (evt) =>
        handleButtonChange('color', evt.target.value, 'download'),
      label: 'COLOR',
      name: 'downloadColor',
      placeholder: '#fdfdfd',
    },

    {
      type: 'checkbox',
      value: buttonsInfo.download.text.isVisible,
      onChange: (evt) =>
        handleButtonChange('isVisible', evt.target.checked, 'download', 'text'),
      label: 'IS TEXT VISIBLE?',
      name: 'downloadTextIsVisible',
    },
    {
      type: 'text',
      value: buttonsInfo.download.text.content,
      onChange: (evt) =>
        handleButtonChange('content', evt.target.value, 'download', 'text'),
      label: 'TEXT CONTENT',
      name: 'downloadTextContent',
      placeholder: 'Download',
    },
    {
      type: 'text',
      value: buttonsInfo.download.text.width,
      onChange: (evt) =>
        handleButtonChange('width', evt.target.value, 'download', 'text'),
      label: 'TEXT WIDTH',
      name: 'downloadTextWidth',
      placeholder: '60%',
    },
    {
      type: 'text',
      value: buttonsInfo.download.text.margin,
      onChange: (evt) =>
        handleButtonChange('margin', evt.target.value, 'download', 'text'),
      label: 'TEXT MARGIN',
      name: 'downloadTextMargin',
      placeholder: '0.3rem 0 0 0',
    },
    {
      type: 'text',
      value: buttonsInfo.download.text.fontSize,
      onChange: (evt) =>
        handleButtonChange('fontSize', evt.target.value, 'download', 'text'),
      label: 'FONT SIZE',
      name: 'downloadTextFontSize',
      placeholder: '0.9rem',
    },
    {
      type: 'text',
      value: buttonsInfo.download.text.fontWeight,
      onChange: (evt) =>
        handleButtonChange('fontWeight', evt.target.value, 'download', 'text'),
      label: 'FONT WEIGHT',
      name: 'downloadTextFontWeight',
      placeholder: '600',
    },
    {
      as: 'select',
      options: textAlignOptions,
      value: buttonsInfo.download.text.textAlign,
      onChange: (evt) =>
        handleButtonChange('textAlign', evt.target.value, 'download', 'text'),
      label: 'TEXT ALIGN',
      name: 'downloadTextTextAlign',
    },

    {
      type: 'text',
      value: buttonsInfo.download.icon.shape,
      onChange: (evt) =>
        handleButtonChange('shape', evt.target.value, 'download', 'icon'),
      label: 'SHAPE',
      name: 'downloadIconShape',
      placeholder: 'rectangle',
    },
    {
      type: 'checkbox',
      value: buttonsInfo.download.icon.isVisible,
      onChange: (evt) =>
        handleButtonChange('isVisible', evt.target.value, 'download', 'icon'),
      label: 'IS ICON VISIBLE?',
      name: 'downloadIconIsVisible',
    },
    {
      type: 'text',
      value: buttonsInfo.download.icon.url,
      onChange: (evt) =>
        handleButtonChange('url', evt.target.value, 'download', 'icon'),
      label: 'ICON URL',
      name: 'downloadIconUrl',
      placeholder: '/images/MBC/download-icon.png',
    },
    {
      type: 'text',
      value: buttonsInfo.download.icon.size,
      onChange: (evt) =>
        handleButtonChange('size', evt.target.value, 'download', 'icon'),
      label: 'SIZE',
      name: 'downloadIconSize',
      placeholder: 'small',
    },
    {
      type: 'text',
      value: buttonsInfo.download.icon.backgroundColor,
      onChange: (evt) =>
        handleButtonChange(
          'backgroundColor',
          evt.target.value,
          'download',
          'icon',
        ),
      label: 'BACKGROUND COLOR',
      name: 'downloadIconBackgroundColor',
      placeholder: '#323232',
    },
    {
      type: 'text',
      value: buttonsInfo.download.icon.fill,
      onChange: (evt) =>
        handleButtonChange('fill', evt.target.value, 'download', 'icon'),
      label: 'FILL (if icon is an svg)',
      name: 'downloadIconFill',
      placeholder: '#333333',
    },
  ];
  const sendInputs = [
    {
      type: 'checkbox',
      value: buttonsInfo.send.isVisible,
      onChange: (evt) =>
        handleButtonChange('isVisible', evt.target.checked, 'send'),
      label: 'IS VISIBLE?',
      name: 'sendIsVisible',
    },
    {
      type: 'number',
      value: buttonsInfo.send.order,
      onChange: (evt) => handleButtonChange('order', evt.target.value, 'send'),
      label: 'ORDER',
      name: 'sendOrder',
      placeholder: '1',
    },
    {
      as: 'select',
      options: flexFlowOptions,
      value: buttonsInfo.send.flexFlow,
      onChange: (evt) =>
        handleButtonChange('flexFlow', evt.target.value, 'send'),
      label: (
        <abbr title="Horizontal - row, vertical - column">
          DIRECTION &amp; WRAPPING
        </abbr>
      ),
      name: 'sendFlexFlow',
    },
    {
      type: 'text',
      value: buttonsInfo.send.padding,
      onChange: (evt) =>
        handleButtonChange('padding', evt.target.value, 'send'),
      label: 'PADDING',
      name: 'sendPadding',
      placeholder: '1rem 0 1rem 0.75rem',
    },
    {
      type: 'text',
      value: buttonsInfo.send.margin,
      onChange: (evt) => handleButtonChange('margin', evt.target.value, 'send'),
      label: 'MARGIN',
      name: 'sendMargin',
      placeholder: '0 0 0.15rem 0',
    },
    {
      type: 'text',
      value: buttonsInfo.send.shape,
      onChange: (evt) => handleButtonChange('shape', evt.target.value, 'send'),
      label: 'SHAPE',
      name: 'sendShape',
      placeholder: 'rectangle',
    },
    {
      type: 'text',
      value: buttonsInfo.send.backgroundColor,
      onChange: (evt) =>
        handleButtonChange('backgroundColor', evt.target.value, 'send'),
      label: 'BACKGROUND COLOR',
      name: 'sendBackgroundColor',
      placeholder: '#323232',
    },
    {
      type: 'text',
      value: buttonsInfo.send.color,
      onChange: (evt) => handleButtonChange('color', evt.target.value, 'send'),
      label: 'COLOR',
      name: 'sendColor',
      placeholder: '#fdfdfd',
    },

    {
      type: 'checkbox',
      value: buttonsInfo.send.text.isVisible,
      onChange: (evt) =>
        handleButtonChange('isVisible', evt.target.checked, 'send', 'text'),
      label: 'IS TEXT VISIBLE?',
      name: 'sendTextIsVisible',
    },
    {
      type: 'text',
      value: buttonsInfo.send.text.content,
      onChange: (evt) =>
        handleButtonChange('content', evt.target.value, 'send', 'text'),
      label: 'TEXT CONTENT',
      name: 'sendTextContent',
      placeholder: 'Send',
    },
    {
      type: 'text',
      value: buttonsInfo.send.text.width,
      onChange: (evt) =>
        handleButtonChange('width', evt.target.value, 'send', 'text'),
      label: 'TEXT WIDTH',
      name: 'sendTextWidth',
      placeholder: '60%',
    },
    {
      type: 'text',
      value: buttonsInfo.send.text.margin,
      onChange: (evt) =>
        handleButtonChange('margin', evt.target.value, 'send', 'text'),
      label: 'TEXT MARGIN',
      name: 'sendTextMargin',
      placeholder: '0.3rem 0 0 0',
    },
    {
      type: 'text',
      value: buttonsInfo.send.text.fontSize,
      onChange: (evt) =>
        handleButtonChange('fontSize', evt.target.value, 'send', 'text'),
      label: 'FONT SIZE',
      name: 'sendTextFontSize',
      placeholder: '0.9rem',
    },
    {
      type: 'text',
      value: buttonsInfo.send.text.fontWeight,
      onChange: (evt) =>
        handleButtonChange('fontWeight', evt.target.value, 'send', 'text'),
      label: 'FONT WEIGHT',
      name: 'sendTextFontWeight',
      placeholder: '600',
    },
    {
      as: 'select',
      options: textAlignOptions,
      value: buttonsInfo.send.text.textAlign,
      onChange: (evt) =>
        handleButtonChange('textAlign', evt.target.value, 'send', 'text'),
      label: 'TEXT ALIGN',
      name: 'sendTextTextAlign',
    },

    {
      type: 'text',
      value: buttonsInfo.send.icon.shape,
      onChange: (evt) =>
        handleButtonChange('shape', evt.target.value, 'send', 'icon'),
      label: 'SHAPE',
      name: 'sendIconShape',
      placeholder: 'rectangle',
    },
    {
      type: 'checkbox',
      value: buttonsInfo.send.icon.isVisible,
      onChange: (evt) =>
        handleButtonChange('isVisible', evt.target.value, 'send', 'icon'),
      label: 'IS ICON VISIBLE?',
      name: 'sendIconIsVisible',
    },
    {
      type: 'text',
      value: buttonsInfo.send.icon.url,
      onChange: (evt) =>
        handleButtonChange('url', evt.target.value, 'send', 'icon'),
      label: 'ICON URL',
      name: 'sendIconUrl',
      placeholder: '/images/MBC/send-icon.png',
    },
    {
      type: 'text',
      value: buttonsInfo.send.icon.size,
      onChange: (evt) =>
        handleButtonChange('size', evt.target.value, 'send', 'icon'),
      label: 'SIZE',
      name: 'sendIconSize',
      placeholder: 'small',
    },
    {
      type: 'text',
      value: buttonsInfo.send.icon.backgroundColor,
      onChange: (evt) =>
        handleButtonChange('backgroundColor', evt.target.value, 'send', 'icon'),
      label: 'BACKGROUND COLOR',
      name: 'sendIconBackgroundColor',
      placeholder: '#323232',
    },
    {
      type: 'text',
      value: buttonsInfo.send.icon.fill,
      onChange: (evt) =>
        handleButtonChange('fill', evt.target.value, 'send', 'icon'),
      label: 'FILL (if icon is an svg)',
      name: 'sendIconFill',
      placeholder: '#333333',
    },
  ];

  const callInputsMedium = [
    {
      type: 'number',
      value: buttonsInfo.medium.call.order,
      onChange: (evt) =>
        handleButtonChange(
          'order',
          evt.target.value,
          'call',
          undefined,
          'medium',
        ),
      label: 'ORDER',
      name: 'callOrderMedium',
      placeholder: '1',
    },
    {
      as: 'select',
      options: flexFlowOptions,
      value: buttonsInfo.medium.call.flexFlow,
      onChange: (evt) =>
        handleButtonChange(
          'flexFlow',
          evt.target.value,
          'call',
          undefined,
          'medium',
        ),
      label: (
        <abbr title="Horizontal - row, vertical - column">
          DIRECTION &amp; WRAPPING
        </abbr>
      ),
      name: 'callFlexFlowMedium',
    },
    {
      type: 'text',
      value: buttonsInfo.medium.call.padding,
      onChange: (evt) =>
        handleButtonChange(
          'padding',
          evt.target.value,
          'call',
          undefined,
          'medium',
        ),
      label: 'PADDING',
      name: 'callPaddingMedium',
      placeholder: '1rem 0 1rem 0.75rem',
    },
    {
      type: 'text',
      value: buttonsInfo.medium.call.margin,
      onChange: (evt) =>
        handleButtonChange(
          'margin',
          evt.target.value,
          'call',
          undefined,
          'medium',
        ),
      label: 'MARGIN',
      name: 'callMarginMedium',
      placeholder: '0 0 0.3rem 0',
    },

    {
      type: 'text',
      value: buttonsInfo.medium.call.text.width,
      onChange: (evt) =>
        handleButtonChange('width', evt.target.value, 'call', 'text', 'medium'),
      label: 'TEXT WIDTH',
      name: 'callTextWidthMedium',
      placeholder: '60%',
    },
    {
      type: 'text',
      value: buttonsInfo.medium.call.text.margin,
      onChange: (evt) =>
        handleButtonChange(
          'margin',
          evt.target.value,
          'call',
          'text',
          'medium',
        ),
      label: 'TEXT MARGIN',
      name: 'callTextMarginMedium',
      placeholder: '0.3rem 0 0 0',
    },
    {
      type: 'text',
      value: buttonsInfo.medium.call.text.fontSize,
      onChange: (evt) =>
        handleButtonChange(
          'fontSize',
          evt.target.value,
          'call',
          'text',
          'medium',
        ),
      label: 'FONT SIZE',
      name: 'callTextFontSizeMedium',
      placeholder: '1.05rem',
    },
    {
      type: 'text',
      value: buttonsInfo.medium.call.text.fontWeight,
      onChange: (evt) =>
        handleButtonChange(
          'fontWeight',
          evt.target.value,
          'call',
          'text',
          'medium',
        ),
      label: 'FONT WEIGHT',
      name: 'callTextFontWeightMedium',
      placeholder: '600',
    },
    {
      as: 'select',
      options: textAlignOptions,
      value: buttonsInfo.medium.call.text.textAlign,
      onChange: (evt) =>
        handleButtonChange(
          'textAlign',
          evt.target.value,
          'call',
          'text',
          'medium',
        ),
      label: 'TEXT ALIGN',
      name: 'callTextTextAlignMedium',
    },

    {
      type: 'text',
      value: buttonsInfo.medium.call.icon.size,
      onChange: (evt) =>
        handleButtonChange('size', evt.target.value, 'call', 'icon', 'medium'),
      label: 'SIZE',
      name: 'callIconSizeMedium',
      placeholder: 'small',
    },
  ];
  const textInputsMedium = [
    {
      type: 'number',
      value: buttonsInfo.medium.text.order,
      onChange: (evt) =>
        handleButtonChange(
          'order',
          evt.target.value,
          'text',
          undefined,
          'medium',
        ),
      label: 'ORDER',
      name: 'textOrderMedium',
      placeholder: '1',
    },
    {
      as: 'select',
      options: flexFlowOptions,
      value: buttonsInfo.medium.text.flexFlow,
      onChange: (evt) =>
        handleButtonChange(
          'flexFlow',
          evt.target.value,
          'text',
          undefined,
          'medium',
        ),
      label: (
        <abbr title="Horizontal - row, vertical - column">
          DIRECTION &amp; WRAPPING
        </abbr>
      ),
      name: 'textFlexFlowMedium',
    },
    {
      type: 'text',
      value: buttonsInfo.medium.text.padding,
      onChange: (evt) =>
        handleButtonChange(
          'padding',
          evt.target.value,
          'text',
          undefined,
          'medium',
        ),
      label: 'PADDING',
      name: 'textPaddingMedium',
      placeholder: '1rem 0 1rem 0.75rem',
    },
    {
      type: 'text',
      value: buttonsInfo.medium.text.margin,
      onChange: (evt) =>
        handleButtonChange(
          'margin',
          evt.target.value,
          'text',
          undefined,
          'medium',
        ),
      label: 'MARGIN',
      name: 'textMarginMedium',
      placeholder: '0 0 0.3rem 0',
    },

    {
      type: 'text',
      value: buttonsInfo.medium.text.text.width,
      onChange: (evt) =>
        handleButtonChange('width', evt.target.value, 'text', 'text', 'medium'),
      label: 'TEXT WIDTH',
      name: 'textTextWidthMedium',
      placeholder: '60%',
    },
    {
      type: 'text',
      value: buttonsInfo.medium.text.text.margin,
      onChange: (evt) =>
        handleButtonChange(
          'margin',
          evt.target.value,
          'text',
          'text',
          'medium',
        ),
      label: 'TEXT MARGIN',
      name: 'textTextMarginMedium',
      placeholder: '0.3rem 0 0 0',
    },
    {
      type: 'text',
      value: buttonsInfo.medium.text.text.fontSize,
      onChange: (evt) =>
        handleButtonChange(
          'fontSize',
          evt.target.value,
          'text',
          'text',
          'medium',
        ),
      label: 'FONT SIZE',
      name: 'textTextFontSizeMedium',
      placeholder: '1.05rem',
    },
    {
      type: 'text',
      value: buttonsInfo.medium.text.text.fontWeight,
      onChange: (evt) =>
        handleButtonChange(
          'fontWeight',
          evt.target.value,
          'text',
          'text',
          'medium',
        ),
      label: 'FONT WEIGHT',
      name: 'textTextFontWeightMedium',
      placeholder: '600',
    },
    {
      as: 'select',
      options: textAlignOptions,
      value: buttonsInfo.medium.text.text.textAlign,
      onChange: (evt) =>
        handleButtonChange(
          'textAlign',
          evt.target.value,
          'text',
          'text',
          'medium',
        ),
      label: 'TEXT ALIGN',
      name: 'textTextTextAlignMedium',
    },

    {
      type: 'text',
      value: buttonsInfo.medium.text.icon.size,
      onChange: (evt) =>
        handleButtonChange('size', evt.target.value, 'text', 'icon', 'medium'),
      label: 'SIZE',
      name: 'textIconSizeMedium',
      placeholder: 'small',
    },
  ];
  const emailInputsMedium = [
    {
      type: 'number',
      value: buttonsInfo.medium.email.order,
      onChange: (evt) =>
        handleButtonChange(
          'order',
          evt.target.value,
          'email',
          undefined,
          'medium',
        ),
      label: 'ORDER',
      name: 'emailOrderMedium',
      placeholder: '1',
    },
    {
      as: 'select',
      options: flexFlowOptions,
      value: buttonsInfo.medium.email.flexFlow,
      onChange: (evt) =>
        handleButtonChange(
          'flexFlow',
          evt.target.value,
          'email',
          undefined,
          'medium',
        ),
      label: (
        <abbr title="Horizontal - row, vertical - column">
          DIRECTION &amp; WRAPPING
        </abbr>
      ),
      name: 'emailFlexFlowMedium',
    },
    {
      type: 'text',
      value: buttonsInfo.medium.email.padding,
      onChange: (evt) =>
        handleButtonChange(
          'padding',
          evt.target.value,
          'email',
          undefined,
          'medium',
        ),
      label: 'PADDING',
      name: 'emailPaddingMedium',
      placeholder: '1rem 0 1rem 0.75rem',
    },
    {
      type: 'text',
      value: buttonsInfo.medium.email.margin,
      onChange: (evt) =>
        handleButtonChange(
          'margin',
          evt.target.value,
          'email',
          undefined,
          'medium',
        ),
      label: 'MARGIN',
      name: 'emailMarginMedium',
      placeholder: '0 0 0.3rem 0',
    },

    {
      type: 'text',
      value: buttonsInfo.medium.email.text.width,
      onChange: (evt) =>
        handleButtonChange(
          'width',
          evt.target.value,
          'email',
          'text',
          'medium',
        ),
      label: 'TEXT WIDTH',
      name: 'emailTextWidthMedium',
      placeholder: '60%',
    },
    {
      type: 'text',
      value: buttonsInfo.medium.email.text.margin,
      onChange: (evt) =>
        handleButtonChange(
          'margin',
          evt.target.value,
          'email',
          'text',
          'medium',
        ),
      label: 'TEXT MARGIN',
      name: 'emailTextMarginMedium',
      placeholder: '0.3rem 0 0 0',
    },
    {
      type: 'text',
      value: buttonsInfo.medium.email.text.fontSize,
      onChange: (evt) =>
        handleButtonChange(
          'fontSize',
          evt.target.value,
          'email',
          'text',
          'medium',
        ),
      label: 'FONT SIZE',
      name: 'emailTextFontSizeMedium',
      placeholder: '1.05rem',
    },
    {
      type: 'text',
      value: buttonsInfo.medium.email.text.fontWeight,
      onChange: (evt) =>
        handleButtonChange(
          'fontWeight',
          evt.target.value,
          'email',
          'text',
          'medium',
        ),
      label: 'FONT WEIGHT',
      name: 'emailTextFontWeightMedium',
      placeholder: '600',
    },
    {
      as: 'select',
      options: textAlignOptions,
      value: buttonsInfo.medium.email.text.textAlign,
      onChange: (evt) =>
        handleButtonChange(
          'textAlign',
          evt.target.value,
          'email',
          'text',
          'medium',
        ),
      label: 'TEXT ALIGN',
      name: 'emailTextTextAlignMedium',
    },

    {
      type: 'text',
      value: buttonsInfo.medium.email.icon.size,
      onChange: (evt) =>
        handleButtonChange('size', evt.target.value, 'email', 'icon', 'medium'),
      label: 'SIZE',
      name: 'emailIconSizeMedium',
      placeholder: 'small',
    },
  ];
  const aboutInputsMedium = [
    {
      type: 'number',
      value: buttonsInfo.medium.about.order,
      onChange: (evt) =>
        handleButtonChange(
          'order',
          evt.target.value,
          'about',
          undefined,
          'medium',
        ),
      label: 'ORDER',
      name: 'aboutOrderMedium',
      placeholder: '1',
    },
    {
      as: 'select',
      options: flexFlowOptions,
      value: buttonsInfo.medium.about.flexFlow,
      onChange: (evt) =>
        handleButtonChange(
          'flexFlow',
          evt.target.value,
          'about',
          undefined,
          'medium',
        ),
      label: (
        <abbr title="Horizontal - row, vertical - column">
          DIRECTION &amp; WRAPPING
        </abbr>
      ),
      name: 'aboutFlexFlowMedium',
    },
    {
      type: 'text',
      value: buttonsInfo.medium.about.padding,
      onChange: (evt) =>
        handleButtonChange(
          'padding',
          evt.target.value,
          'about',
          undefined,
          'medium',
        ),
      label: 'PADDING',
      name: 'aboutPaddingMedium',
      placeholder: '1rem 0 1rem 0.75rem',
    },
    {
      type: 'text',
      value: buttonsInfo.medium.about.margin,
      onChange: (evt) =>
        handleButtonChange(
          'margin',
          evt.target.value,
          'about',
          undefined,
          'medium',
        ),
      label: 'MARGIN',
      name: 'aboutMarginMedium',
      placeholder: '0 0 0.3rem 0',
    },

    {
      type: 'text',
      value: buttonsInfo.medium.about.text.width,
      onChange: (evt) =>
        handleButtonChange(
          'width',
          evt.target.value,
          'about',
          'text',
          'medium',
        ),
      label: 'TEXT WIDTH',
      name: 'aboutTextWidthMedium',
      placeholder: '60%',
    },
    {
      type: 'text',
      value: buttonsInfo.medium.about.text.margin,
      onChange: (evt) =>
        handleButtonChange(
          'margin',
          evt.target.value,
          'about',
          'text',
          'medium',
        ),
      label: 'TEXT MARGIN',
      name: 'aboutTextMarginMedium',
      placeholder: '0.3rem 0 0 0',
    },
    {
      type: 'text',
      value: buttonsInfo.medium.about.text.fontSize,
      onChange: (evt) =>
        handleButtonChange(
          'fontSize',
          evt.target.value,
          'about',
          'text',
          'medium',
        ),
      label: 'FONT SIZE',
      name: 'aboutTextFontSizeMedium',
      placeholder: '1.05rem',
    },
    {
      type: 'text',
      value: buttonsInfo.medium.about.text.fontWeight,
      onChange: (evt) =>
        handleButtonChange(
          'fontWeight',
          evt.target.value,
          'about',
          'text',
          'medium',
        ),
      label: 'FONT WEIGHT',
      name: 'aboutTextFontWeightMedium',
      placeholder: '600',
    },
    {
      as: 'select',
      options: textAlignOptions,
      value: buttonsInfo.medium.about.text.textAlign,
      onChange: (evt) =>
        handleButtonChange(
          'textAlign',
          evt.target.value,
          'about',
          'text',
          'medium',
        ),
      label: 'TEXT ALIGN',
      name: 'aboutTextTextAlignMedium',
    },

    {
      type: 'text',
      value: buttonsInfo.medium.about.icon.size,
      onChange: (evt) =>
        handleButtonChange('size', evt.target.value, 'about', 'icon', 'medium'),
      label: 'SIZE',
      name: 'aboutIconSizeMedium',
      placeholder: 'small',
    },
  ];
  const downloadInputsMedium = [
    {
      type: 'number',
      value: buttonsInfo.medium.download.order,
      onChange: (evt) =>
        handleButtonChange(
          'order',
          evt.target.value,
          'download',
          undefined,
          'medium',
        ),
      label: 'ORDER',
      name: 'downloadOrderMedium',
      placeholder: '1',
    },
    {
      as: 'select',
      options: flexFlowOptions,
      value: buttonsInfo.medium.download.flexFlow,
      onChange: (evt) =>
        handleButtonChange(
          'flexFlow',
          evt.target.value,
          'download',
          undefined,
          'medium',
        ),
      label: (
        <abbr title="Horizontal - row, vertical - column">
          DIRECTION &amp; WRAPPING
        </abbr>
      ),
      name: 'downloadFlexFlowMedium',
    },
    {
      type: 'text',
      value: buttonsInfo.medium.download.padding,
      onChange: (evt) =>
        handleButtonChange(
          'padding',
          evt.target.value,
          'download',
          undefined,
          'medium',
        ),
      label: 'PADDING',
      name: 'downloadPaddingMedium',
      placeholder: '1rem 0 1rem 0.75rem',
    },
    {
      type: 'text',
      value: buttonsInfo.medium.download.margin,
      onChange: (evt) =>
        handleButtonChange(
          'margin',
          evt.target.value,
          'download',
          undefined,
          'medium',
        ),
      label: 'MARGIN',
      name: 'downloadMarginMedium',
      placeholder: '0 0 0.3rem 0',
    },

    {
      type: 'text',
      value: buttonsInfo.medium.download.text.width,
      onChange: (evt) =>
        handleButtonChange(
          'width',
          evt.target.value,
          'download',
          'text',
          'medium',
        ),
      label: 'TEXT WIDTH',
      name: 'downloadTextWidthMedium',
      placeholder: '60%',
    },
    {
      type: 'text',
      value: buttonsInfo.medium.download.text.margin,
      onChange: (evt) =>
        handleButtonChange(
          'margin',
          evt.target.value,
          'download',
          'text',
          'medium',
        ),
      label: 'TEXT MARGIN',
      name: 'downloadTextMarginMedium',
      placeholder: '0.3rem 0 0 0',
    },
    {
      type: 'text',
      value: buttonsInfo.medium.download.text.fontSize,
      onChange: (evt) =>
        handleButtonChange(
          'fontSize',
          evt.target.value,
          'download',
          'text',
          'medium',
        ),
      label: 'FONT SIZE',
      name: 'downloadTextFontSizeMedium',
    },
    {
      type: 'text',
      value: buttonsInfo.medium.download.text.fontWeight,
      onChange: (evt) =>
        handleButtonChange(
          'fontWeight',
          evt.target.value,
          'download',
          'text',
          'medium',
        ),
      label: 'FONT WEIGHT',
      name: 'downloadTextFontWeightMedium',
      placeholder: '600',
    },
    {
      as: 'select',
      options: textAlignOptions,
      value: buttonsInfo.medium.download.text.textAlign,
      onChange: (evt) =>
        handleButtonChange(
          'textAlign',
          evt.target.value,
          'download',
          'text',
          'medium',
        ),
      label: 'TEXT ALIGN',
      name: 'downloadTextTextAlignMedium',
    },

    {
      type: 'text',
      value: buttonsInfo.medium.download.icon.size,
      onChange: (evt) =>
        handleButtonChange(
          'size',
          evt.target.value,
          'download',
          'icon',
          'medium',
        ),
      label: 'SIZE',
      name: 'downloadIconSizeMedium',
      placeholder: 'small',
    },
  ];
  const sendInputsMedium = [
    {
      type: 'number',
      value: buttonsInfo.medium.send.order,
      onChange: (evt) =>
        handleButtonChange(
          'order',
          evt.target.value,
          'send',
          undefined,
          'medium',
        ),
      label: 'ORDER',
      name: 'sendOrderMedium',
      placeholder: '1',
    },
    {
      as: 'select',
      options: flexFlowOptions,
      value: buttonsInfo.medium.send.flexFlow,
      onChange: (evt) =>
        handleButtonChange(
          'flexFlow',
          evt.target.value,
          'send',
          undefined,
          'medium',
        ),
      label: (
        <abbr title="Horizontal - row, vertical - column">
          DIRECTION &amp; WRAPPING
        </abbr>
      ),
      name: 'sendFlexFlowMedium',
    },
    {
      type: 'text',
      value: buttonsInfo.medium.send.padding,
      onChange: (evt) =>
        handleButtonChange(
          'padding',
          evt.target.value,
          'send',
          undefined,
          'medium',
        ),
      label: 'PADDING',
      name: 'sendPaddingMedium',
      placeholder: '1rem 0 1rem 0.75rem',
    },
    {
      type: 'text',
      value: buttonsInfo.medium.send.margin,
      onChange: (evt) =>
        handleButtonChange(
          'margin',
          evt.target.value,
          'send',
          undefined,
          'medium',
        ),
      label: 'MARGIN',
      name: 'sendMarginMedium',
      placeholder: '0 0 0.3rem 0',
    },

    {
      type: 'text',
      value: buttonsInfo.medium.send.text.width,
      onChange: (evt) =>
        handleButtonChange('width', evt.target.value, 'send', 'text', 'medium'),
      label: 'TEXT WIDTH',
      name: 'sendTextWidthMedium',
      placeholder: '60%',
    },
    {
      type: 'text',
      value: buttonsInfo.medium.send.text.margin,
      onChange: (evt) =>
        handleButtonChange(
          'margin',
          evt.target.value,
          'send',
          'text',
          'medium',
        ),
      label: 'TEXT MARGIN',
      name: 'sendTextMarginMedium',
      placeholder: '0.3rem 0 0 0',
    },
    {
      type: 'text',
      value: buttonsInfo.medium.send.text.fontSize,
      onChange: (evt) =>
        handleButtonChange(
          'fontSize',
          evt.target.value,
          'send',
          'text',
          'medium',
        ),
      label: 'FONT SIZE',
      name: 'sendTextFontSizeMedium',
      placeholder: '0.3rem 0 0 0',
    },
    {
      type: 'text',
      value: buttonsInfo.medium.send.text.fontWeight,
      onChange: (evt) =>
        handleButtonChange(
          'fontWeight',
          evt.target.value,
          'send',
          'text',
          'medium',
        ),
      label: 'FONT WEIGHT',
      name: 'sendTextFontWeightMedium',
      placeholder: '600',
    },
    {
      as: 'select',
      options: textAlignOptions,
      value: buttonsInfo.medium.send.text.textAlign,
      onChange: (evt) =>
        handleButtonChange(
          'textAlign',
          evt.target.value,
          'send',
          'text',
          'medium',
        ),
      label: 'TEXT ALIGN',
      name: 'sendTextTextAlignMedium',
    },

    {
      type: 'text',
      value: buttonsInfo.medium.send.icon.size,
      onChange: (evt) =>
        handleButtonChange('size', evt.target.value, 'send', 'icon', 'medium'),
      label: 'SIZE',
      name: 'sendIconSizeMedium',
      placeholder: 'small',
    },
  ];

  const callInputsLarge = [
    {
      type: 'number',
      value: buttonsInfo.large.call.order,
      onChange: (evt) =>
        handleButtonChange(
          'order',
          evt.target.value,
          'call',
          undefined,
          'large',
        ),
      label: 'ORDER',
      name: 'callOrderLarge',
      placeholder: '1',
    },
    {
      as: 'select',
      options: flexFlowOptions,
      value: buttonsInfo.large.call.flexFlow,
      onChange: (evt) =>
        handleButtonChange(
          'flexFlow',
          evt.target.value,
          'call',
          undefined,
          'large',
        ),
      label: (
        <abbr title="Horizontal - row, vertical - column">
          DIRECTION &amp; WRAPPING
        </abbr>
      ),
      name: 'callFlexFlowLarge',
    },
    {
      type: 'text',
      value: buttonsInfo.large.call.padding,
      onChange: (evt) =>
        handleButtonChange(
          'padding',
          evt.target.value,
          'call',
          undefined,
          'large',
        ),
      label: 'PADDING',
      name: 'callPaddingLarge',
      placeholder: '1rem 0 1rem 0.75rem',
    },
    {
      type: 'text',
      value: buttonsInfo.large.call.margin,
      onChange: (evt) =>
        handleButtonChange(
          'margin',
          evt.target.value,
          'call',
          undefined,
          'large',
        ),
      label: 'MARGIN',
      name: 'callMarginLarge',
      placeholder: '0 0 0.3rem 0',
    },

    {
      type: 'text',
      value: buttonsInfo.large.call.text.width,
      onChange: (evt) =>
        handleButtonChange('width', evt.target.value, 'call', 'text', 'large'),
      label: 'TEXT WIDTH',
      name: 'callTextWidthLarge',
      placeholder: '60%',
    },
    {
      type: 'text',
      value: buttonsInfo.large.call.text.margin,
      onChange: (evt) =>
        handleButtonChange('margin', evt.target.value, 'call', 'text', 'large'),
      label: 'TEXT MARGIN',
      name: 'callTextMarginLarge',
      placeholder: '0.3rem 0 0 0',
    },
    {
      type: 'text',
      value: buttonsInfo.large.call.text.fontSize,
      onChange: (evt) =>
        handleButtonChange(
          'fontSize',
          evt.target.value,
          'call',
          'text',
          'large',
        ),
      label: 'FONT SIZE',
      name: 'callTextFontSizeLarge',
      placeholder: '1.2rem',
    },
    {
      type: 'text',
      value: buttonsInfo.large.call.text.fontWeight,
      onChange: (evt) =>
        handleButtonChange(
          'fontWeight',
          evt.target.value,
          'call',
          'text',
          'large',
        ),
      label: 'FONT WEIGHT',
      name: 'callTextFontWeightLarge',
      placeholder: '600',
    },
    {
      as: 'select',
      options: textAlignOptions,
      value: buttonsInfo.large.call.text.textAlign,
      onChange: (evt) =>
        handleButtonChange(
          'textAlign',
          evt.target.value,
          'call',
          'text',
          'large',
        ),
      label: 'TEXT ALIGN',
      name: 'callTextTextAlignLarge',
    },

    {
      type: 'text',
      value: buttonsInfo.large.call.icon.size,
      onChange: (evt) =>
        handleButtonChange('size', evt.target.value, 'call', 'icon', 'large'),
      label: 'SIZE',
      name: 'callIconFontWeightLarge',
      placeholder: 'small',
    },
  ];
  const textInputsLarge = [
    {
      type: 'number',
      value: buttonsInfo.large.text.order,
      onChange: (evt) =>
        handleButtonChange(
          'order',
          evt.target.value,
          'text',
          undefined,
          'large',
        ),
      label: 'ORDER',
      name: 'textOrderLarge',
      placeholder: '1',
    },
    {
      as: 'select',
      options: flexFlowOptions,
      value: buttonsInfo.large.text.flexFlow,
      onChange: (evt) =>
        handleButtonChange(
          'flexFlow',
          evt.target.value,
          'text',
          undefined,
          'large',
        ),
      label: (
        <abbr title="Horizontal - row, vertical - column">
          DIRECTION &amp; WRAPPING
        </abbr>
      ),
      name: 'textFlexFlowLarge',
    },
    {
      type: 'text',
      value: buttonsInfo.large.text.padding,
      onChange: (evt) =>
        handleButtonChange(
          'padding',
          evt.target.value,
          'text',
          undefined,
          'large',
        ),
      label: 'PADDING',
      name: 'textPaddingLarge',
      placeholder: '1rem 0 1rem 0.75rem',
    },
    {
      type: 'text',
      value: buttonsInfo.large.text.margin,
      onChange: (evt) =>
        handleButtonChange(
          'margin',
          evt.target.value,
          'text',
          undefined,
          'large',
        ),
      label: 'MARGIN',
      name: 'textMarginLarge',
      placeholder: '0 0 0.3rem 0',
    },

    {
      type: 'text',
      value: buttonsInfo.large.text.text.width,
      onChange: (evt) =>
        handleButtonChange('width', evt.target.value, 'text', 'text', 'large'),
      label: 'TEXT WIDTH',
      name: 'textTextWidthLarge',
      placeholder: '60%',
    },
    {
      type: 'text',
      value: buttonsInfo.large.text.text.margin,
      onChange: (evt) =>
        handleButtonChange('margin', evt.target.value, 'text', 'text', 'large'),
      label: 'TEXT MARGIN',
      name: 'textTextMarginLarge',
      placeholder: '0.3rem 0 0 0',
    },
    {
      type: 'text',
      value: buttonsInfo.large.text.text.fontSize,
      onChange: (evt) =>
        handleButtonChange(
          'fontSize',
          evt.target.value,
          'text',
          'text',
          'large',
        ),
      label: 'FONT SIZE',
      name: 'textTextFontSizeLarge',
      placeholder: '1.2rem',
    },
    {
      type: 'text',
      value: buttonsInfo.large.text.text.fontWeight,
      onChange: (evt) =>
        handleButtonChange(
          'fontWeight',
          evt.target.value,
          'text',
          'text',
          'large',
        ),
      label: 'FONT WEIGHT',
      name: 'textTextFontWeightLarge',
      placeholder: '600',
    },
    {
      as: 'select',
      options: textAlignOptions,
      value: buttonsInfo.large.text.text.textAlign,
      onChange: (evt) =>
        handleButtonChange(
          'textAlign',
          evt.target.value,
          'text',
          'text',
          'large',
        ),
      label: 'TEXT ALIGN',
      name: 'textTextTextAlignLarge',
    },

    {
      type: 'text',
      value: buttonsInfo.large.text.icon.size,
      onChange: (evt) =>
        handleButtonChange('size', evt.target.value, 'text', 'icon', 'large'),
      label: 'SIZE',
      name: 'textIconFontWeightLarge',
      placeholder: 'small',
    },
  ];
  const emailInputsLarge = [
    {
      type: 'number',
      value: buttonsInfo.large.email.order,
      onChange: (evt) =>
        handleButtonChange(
          'order',
          evt.target.value,
          'email',
          undefined,
          'large',
        ),
      label: 'ORDER',
      name: 'emailOrderLarge',
      placeholder: '1',
    },
    {
      as: 'select',
      options: flexFlowOptions,
      value: buttonsInfo.large.email.flexFlow,
      onChange: (evt) =>
        handleButtonChange(
          'flexFlow',
          evt.target.value,
          'email',
          undefined,
          'large',
        ),
      label: (
        <abbr title="Horizontal - row, vertical - column">
          DIRECTION &amp; WRAPPING
        </abbr>
      ),
      name: 'emailFlexFlowLarge',
    },
    {
      type: 'text',
      value: buttonsInfo.large.email.padding,
      onChange: (evt) =>
        handleButtonChange(
          'padding',
          evt.target.value,
          'email',
          undefined,
          'large',
        ),
      label: 'PADDING',
      name: 'emailPaddingLarge',
      placeholder: '1rem 0 1rem 0.75rem',
    },
    {
      type: 'text',
      value: buttonsInfo.large.email.margin,
      onChange: (evt) =>
        handleButtonChange(
          'margin',
          evt.target.value,
          'email',
          undefined,
          'large',
        ),
      label: 'MARGIN',
      name: 'emailMarginLarge',
      placeholder: '0 0 0.3rem 0',
    },

    {
      type: 'text',
      value: buttonsInfo.large.email.text.width,
      onChange: (evt) =>
        handleButtonChange('width', evt.target.value, 'email', 'text', 'large'),
      label: 'TEXT WIDTH',
      name: 'emailTextWidthLarge',
      placeholder: '60%',
    },
    {
      type: 'text',
      value: buttonsInfo.large.email.text.margin,
      onChange: (evt) =>
        handleButtonChange(
          'margin',
          evt.target.value,
          'email',
          'text',
          'large',
        ),
      label: 'TEXT MARGIN',
      name: 'emailTextMarginLarge',
      placeholder: '0.3rem 0 0 0',
    },
    {
      type: 'text',
      value: buttonsInfo.large.email.text.fontSize,
      onChange: (evt) =>
        handleButtonChange(
          'fontSize',
          evt.target.value,
          'email',
          'text',
          'large',
        ),
      label: 'FONT SIZE',
      name: 'emailTextFontSizeLarge',
      placeholder: '1.2rem',
    },
    {
      type: 'text',
      value: buttonsInfo.large.email.text.fontWeight,
      onChange: (evt) =>
        handleButtonChange(
          'fontWeight',
          evt.target.value,
          'email',
          'text',
          'large',
        ),
      label: 'FONT WEIGHT',
      name: 'emailTextFontWeightLarge',
      placeholder: '600',
    },
    {
      as: 'select',
      options: textAlignOptions,
      value: buttonsInfo.large.email.text.textAlign,
      onChange: (evt) =>
        handleButtonChange(
          'textAlign',
          evt.target.value,
          'email',
          'text',
          'large',
        ),
      label: 'TEXT ALIGN',
      name: 'emailTextTextAlignLarge',
    },

    {
      type: 'text',
      value: buttonsInfo.large.email.icon.size,
      onChange: (evt) =>
        handleButtonChange('size', evt.target.value, 'email', 'icon', 'large'),
      label: 'SIZE',
      name: 'emailIconFontWeightLarge',
      placeholder: 'small',
    },
  ];
  const aboutInputsLarge = [
    {
      type: 'number',
      value: buttonsInfo.large.about.order,
      onChange: (evt) =>
        handleButtonChange(
          'order',
          evt.target.value,
          'about',
          undefined,
          'large',
        ),
      label: 'ORDER',
      name: 'aboutOrderLarge',
      placeholder: '1',
    },
    {
      as: 'select',
      options: flexFlowOptions,
      value: buttonsInfo.large.about.flexFlow,
      onChange: (evt) =>
        handleButtonChange(
          'flexFlow',
          evt.target.value,
          'about',
          undefined,
          'large',
        ),
      label: (
        <abbr title="Horizontal - row, vertical - column">
          DIRECTION &amp; WRAPPING
        </abbr>
      ),
      name: 'aboutFlexFlowLarge',
    },
    {
      type: 'text',
      value: buttonsInfo.large.about.padding,
      onChange: (evt) =>
        handleButtonChange(
          'padding',
          evt.target.value,
          'about',
          undefined,
          'large',
        ),
      label: 'PADDING',
      name: 'aboutPaddingLarge',
      placeholder: '1rem 0 1rem 0.75rem',
    },
    {
      type: 'text',
      value: buttonsInfo.large.about.margin,
      onChange: (evt) =>
        handleButtonChange(
          'margin',
          evt.target.value,
          'about',
          undefined,
          'large',
        ),
      label: 'MARGIN',
      name: 'aboutMarginLarge',
      placeholder: '0 0 0.3rem 0',
    },

    {
      type: 'text',
      value: buttonsInfo.large.about.text.width,
      onChange: (evt) =>
        handleButtonChange('width', evt.target.value, 'about', 'text', 'large'),
      label: 'TEXT WIDTH',
      name: 'aboutTextWidthLarge',
      placeholder: '60%',
    },
    {
      type: 'text',
      value: buttonsInfo.large.about.text.margin,
      onChange: (evt) =>
        handleButtonChange(
          'margin',
          evt.target.value,
          'about',
          'text',
          'large',
        ),
      label: 'TEXT MARGIN',
      name: 'aboutTextMarginLarge',
      placeholder: '0.3rem 0 0 0',
    },
    {
      type: 'text',
      value: buttonsInfo.large.about.text.fontSize,
      onChange: (evt) =>
        handleButtonChange(
          'fontSize',
          evt.target.value,
          'about',
          'text',
          'large',
        ),
      label: 'FONT SIZE',
      name: 'aboutTextFontSizeLarge',
      placeholder: '1.2rem',
    },
    {
      type: 'text',
      value: buttonsInfo.large.about.text.fontWeight,
      onChange: (evt) =>
        handleButtonChange(
          'fontWeight',
          evt.target.value,
          'about',
          'text',
          'large',
        ),
      label: 'FONT WEIGHT',
      name: 'aboutTextFontWeightLarge',
      placeholder: '600',
    },
    {
      as: 'select',
      options: textAlignOptions,
      value: buttonsInfo.large.about.text.textAlign,
      onChange: (evt) =>
        handleButtonChange(
          'textAlign',
          evt.target.value,
          'about',
          'text',
          'large',
        ),
      label: 'TEXT ALIGN',
      name: 'aboutTextTextAlignLarge',
    },

    {
      type: 'text',
      value: buttonsInfo.large.about.icon.size,
      onChange: (evt) =>
        handleButtonChange('size', evt.target.value, 'about', 'icon', 'large'),
      label: 'SIZE',
      name: 'aboutIconFontWeightLarge',
      placeholder: 'small',
    },
  ];
  const downloadInputsLarge = [
    {
      type: 'number',
      value: buttonsInfo.large.download.order,
      onChange: (evt) =>
        handleButtonChange(
          'order',
          evt.target.value,
          'download',
          undefined,
          'large',
        ),
      label: 'ORDER',
      name: 'downloadOrderLarge',
      placeholder: '1',
    },
    {
      as: 'select',
      options: flexFlowOptions,
      value: buttonsInfo.large.download.flexFlow,
      onChange: (evt) =>
        handleButtonChange(
          'flexFlow',
          evt.target.value,
          'download',
          undefined,
          'large',
        ),
      label: (
        <abbr title="Horizontal - row, vertical - column">
          DIRECTION &amp; WRAPPING
        </abbr>
      ),
      name: 'downloadFlexFlowLarge',
    },
    {
      type: 'text',
      value: buttonsInfo.large.download.padding,
      onChange: (evt) =>
        handleButtonChange(
          'padding',
          evt.target.value,
          'download',
          undefined,
          'large',
        ),
      label: 'PADDING',
      name: 'downloadPaddingLarge',
      placeholder: '1rem 0 1rem 0.75rem',
    },
    {
      type: 'text',
      value: buttonsInfo.large.download.margin,
      onChange: (evt) =>
        handleButtonChange(
          'margin',
          evt.target.value,
          'download',
          undefined,
          'large',
        ),
      label: 'MARGIN',
      name: 'downloadMarginLarge',
      placeholder: '0 0 0.3rem 0',
    },

    {
      type: 'text',
      value: buttonsInfo.large.download.text.width,
      onChange: (evt) =>
        handleButtonChange(
          'width',
          evt.target.value,
          'download',
          'text',
          'large',
        ),
      label: 'TEXT WIDTH',
      name: 'downloadTextWidthLarge',
      placeholder: '60%',
    },
    {
      type: 'text',
      value: buttonsInfo.large.download.text.margin,
      onChange: (evt) =>
        handleButtonChange(
          'margin',
          evt.target.value,
          'download',
          'text',
          'large',
        ),
      label: 'TEXT MARGIN',
      name: 'downloadTextMarginLarge',
      placeholder: '0.3rem 0 0 0',
    },
    {
      type: 'text',
      value: buttonsInfo.large.download.text.fontSize,
      onChange: (evt) =>
        handleButtonChange(
          'fontSize',
          evt.target.value,
          'download',
          'text',
          'large',
        ),
      label: 'FONT SIZE',
      name: 'downloadTextFontSizeLarge',
      placeholder: '1.2rem',
    },
    {
      type: 'text',
      value: buttonsInfo.large.download.text.fontWeight,
      onChange: (evt) =>
        handleButtonChange(
          'fontWeight',
          evt.target.value,
          'download',
          'text',
          'large',
        ),
      label: 'FONT WEIGHT',
      name: 'downloadTextFontWeightLarge',
      placeholder: '600',
    },
    {
      as: 'select',
      options: textAlignOptions,
      value: buttonsInfo.large.download.text.textAlign,
      onChange: (evt) =>
        handleButtonChange(
          'textAlign',
          evt.target.value,
          'download',
          'text',
          'large',
        ),
      label: 'TEXT ALIGN',
      name: 'downloadTextTextAlignLarge',
    },

    {
      type: 'text',
      value: buttonsInfo.large.download.icon.size,
      onChange: (evt) =>
        handleButtonChange(
          'size',
          evt.target.value,
          'download',
          'icon',
          'large',
        ),
      label: 'SIZE',
      name: 'downloadIconFontWeightLarge',
      placeholder: 'small',
    },
  ];
  const sendInputsLarge = [
    {
      type: 'number',
      value: buttonsInfo.large.send.order,
      onChange: (evt) =>
        handleButtonChange(
          'order',
          evt.target.value,
          'send',
          undefined,
          'large',
        ),
      label: 'ORDER',
      name: 'sendOrderLarge',
      placeholder: '1',
    },
    {
      as: 'select',
      options: flexFlowOptions,
      value: buttonsInfo.large.send.flexFlow,
      onChange: (evt) =>
        handleButtonChange(
          'flexFlow',
          evt.target.value,
          'send',
          undefined,
          'large',
        ),
      label: (
        <abbr title="Horizontal - row, vertical - column">
          DIRECTION &amp; WRAPPING
        </abbr>
      ),
      name: 'sendFlexFlowLarge',
    },
    {
      type: 'text',
      value: buttonsInfo.large.send.padding,
      onChange: (evt) =>
        handleButtonChange(
          'padding',
          evt.target.value,
          'send',
          undefined,
          'large',
        ),
      label: 'PADDING',
      name: 'sendPaddingLarge',
      placeholder: '1rem 0 1rem 0.75rem',
    },
    {
      type: 'text',
      value: buttonsInfo.large.send.margin,
      onChange: (evt) =>
        handleButtonChange(
          'margin',
          evt.target.value,
          'send',
          undefined,
          'large',
        ),
      label: 'MARGIN',
      name: 'sendMarginLarge',
      placeholder: '0 0 0.3rem 0',
    },

    {
      type: 'text',
      value: buttonsInfo.large.send.text.width,
      onChange: (evt) =>
        handleButtonChange('width', evt.target.value, 'send', 'text', 'large'),
      label: 'TEXT WIDTH',
      name: 'sendTextWidthLarge',
      placeholder: '60%',
    },
    {
      type: 'text',
      value: buttonsInfo.large.send.text.margin,
      onChange: (evt) =>
        handleButtonChange('margin', evt.target.value, 'send', 'text', 'large'),
      label: 'TEXT MARGIN',
      name: 'sendTextMarginLarge',
      placeholder: '0.3rem 0 0 0',
    },
    {
      type: 'text',
      value: buttonsInfo.large.send.text.fontSize,
      onChange: (evt) =>
        handleButtonChange(
          'fontSize',
          evt.target.value,
          'send',
          'text',
          'large',
        ),
      label: 'FONT SIZE',
      name: 'sendTextFontSizeLarge',
      placeholder: '1.2rem',
    },
    {
      type: 'text',
      value: buttonsInfo.large.send.text.fontWeight,
      onChange: (evt) =>
        handleButtonChange(
          'fontWeight',
          evt.target.value,
          'send',
          'text',
          'large',
        ),
      label: 'FONT WEIGHT',
      name: 'sendTextFontWeightLarge',
      placeholder: '600',
    },
    {
      as: 'select',
      options: textAlignOptions,
      value: buttonsInfo.large.send.text.textAlign,
      onChange: (evt) =>
        handleButtonChange(
          'textAlign',
          evt.target.value,
          'send',
          'text',
          'large',
        ),
      label: 'TEXT ALIGN',
      name: 'sendTextTextAlignLarge',
    },

    {
      type: 'text',
      value: buttonsInfo.large.send.icon.size,
      onChange: (evt) =>
        handleButtonChange('size', evt.target.value, 'send', 'icon', 'large'),
      label: 'SIZE',
      name: 'sendIconFontWeightLarge',
      placeholder: 'small',
    },
  ];

  return {
    buttons: {
      info: buttonsInfo,
      call: {
        callInputs,
        callInputsMedium,
        callInputsLarge,
      },
      text: {
        textInputs,
        textInputsMedium,
        textInputsLarge,
      },
      email: {
        emailInputs,
        emailInputsMedium,
        emailInputsLarge,
      },
      about: {
        aboutInputs,
        aboutInputsMedium,
        aboutInputsLarge,
      },
      download: {
        downloadInputs,
        downloadInputsMedium,
        downloadInputsLarge,
      },
      send: {
        sendInputs,
        sendInputsMedium,
        sendInputsLarge,
      },
    },
    group: {
      info: buttonGroupInfo,
      groupInputs,
      mediumGroupInputs,
      largeGroupInputs,
    },
  };
};

export default useButtons;
