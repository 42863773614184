/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import './BillingForm.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCcMastercard, faCcVisa } from '@fortawesome/free-brands-svg-icons';
import { faLock } from '@fortawesome/free-solid-svg-icons';
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
} from '@stripe/react-stripe-js';
import PropTypes from 'prop-types';
import SectionHeader from '../../SectionHeader/SectionHeader';
import FormControl from '../../FormControl/FormControl';
import Button from '../../Button/Button';

const CARD_OPTIONS = {
  iconStyle: 'solid',
  showIcon: false,
  style: {
    base: {
      iconColor: '#212223',
      color: '#212223',
      fontWeight: 600,
      fontFamily: 'Montserrat, sans-serif',
      fontSize: '16px',
      lineHeight: '19px',
      fontSmoothing: 'antialiased',
      ':-webkit-autofill': { color: '#65686b' },
      '::placeholder': { color: '#65686b' },
    },
    invalid: {
      iconColor: '#c12e42',
      color: '#c12e42',
    },
  },
};

const FormWithData = ({
  isCardSet,
  setIsCardSet,
  cardLast4,
  cardExpiryMonth,
  cardExpiryYear,
  cardBrand = 'visa',
}) => (
  <>
    <FormControl
      type="text"
      value={`**** **** **** ${cardLast4}`}
      as="input"
      label="CREDIT CARD NUMBER"
      name="Credit Card Number"
      icon={(className) => (
        <FontAwesomeIcon
          className={`main__icon ${className}`}
          icon={cardBrand === 'visa' ? faCcVisa : faCcMastercard}
        />
      )}
      iconAlignment="right"
      isIconInside
      width="100%"
      disabled
    />
    <FormControl
      type="text"
      value={`${cardExpiryMonth}/${cardExpiryYear}`}
      as="input"
      label="EXP DATE"
      name="Exp Date"
      width="33%"
      disabled
    />
    <FormControl
      type="text"
      value="***"
      as="input"
      label="CVC CODE"
      name="Cvc Code"
      width="33%"
      disabled
    />
    <Button
      variant="primary"
      text="CHANGE"
      onClick={() => setIsCardSet(!isCardSet)}
    />
  </>
);

const StripeForm = ({ cardBrand }) => (
  <>
    <div className="form__entry">
      <p className="entry__label">CREDIT CARD NUMBER</p>
      <CardNumberElement
        options={CARD_OPTIONS}
        className="entry__stripe-input"
      />
      <FontAwesomeIcon
        icon={cardBrand === 'visa' || !cardBrand ? faCcVisa : faCcMastercard}
        className="entry__icon"
      />
    </div>
    <div className="form__entry">
      <p className="entry__label">EXP DATE</p>
      <CardExpiryElement
        options={CARD_OPTIONS}
        className="entry__stripe-input"
      />
    </div>
    <div className="form__entry">
      <p className="entry__label">CVC CODE</p>
      <CardCvcElement
        options={{ ...CARD_OPTIONS, placeholder: '' }}
        className="entry__stripe-input"
      />
    </div>
  </>
);

const BillingForm = ({
  cardLast4,
  cardExpiryYear,
  cardExpiryMonth,
  cardBrand,
}) => {
  const [isCardSet, setIsCardSet] = useState(!!cardLast4);

  useEffect(() => {
    setIsCardSet(!!cardLast4);
  }, [cardLast4]);

  return (
    <section className="billing">
      <SectionHeader text="BILLING" icon={<FontAwesomeIcon icon={faLock} />} />
      <div className="billing__form">
        {isCardSet ? (
          <FormWithData
            isCardSet={isCardSet}
            setIsCardSet={setIsCardSet}
            cardLast4={cardLast4}
            cardExpiryMonth={cardExpiryMonth}
            cardExpiryYear={cardExpiryYear}
            cardBrand={cardBrand}
          />
        ) : (
          <StripeForm cardBrand={cardBrand} />
        )}
      </div>
    </section>
  );
};

BillingForm.propTypes = {
  cardLast4: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  cardExpiryYear: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  cardExpiryMonth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  cardBrand: PropTypes.string,
};

export default BillingForm;
