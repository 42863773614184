import React, { useEffect, useState } from 'react';
import './LeadTable.scss';
import { useHistory, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import Loader from '../../../Loader/Loader';
import SectionHeader from '../../../SectionHeader/SectionHeader';
import LeadTableEntry from './LeadsTableEntry/LeadTableEntry';
import { getLeadsList } from '../../../../utils/apiCalls';
import SortButtons from '../../../SortButtons/SortButtons';
import ListPagination from '../../../Contacts/ContactsList/ListPagination/ListPagination';
import ListControls from '../../../Contacts/ContactsList/ListControls/ListControls';
import store, { NotificationTypes } from '../../../../hooks/NotificationHub';
import {
  formatDateTime,
  formatNumber,
  prepareErrorMessage,
} from '../../../../utils/helper';

const LeadsTable = () => {
  const [isLoading, setIsLoading] = useState(true);

  const [leadsData, setLeadsData] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [recordsToShow, setRecordsToShow] = useState(10);
  const [sortCol, setSortCol] = useState('id');
  const [sortDirection, setSortDirection] = useState('asc');
  const [paginationResponse, setPaginationResponse] = useState();

  const [pageCount, setPageCount] = useState(
    Math.ceil(leadsData?.length / recordsToShow),
  );
  const [currentPage, setCurrentPage] = useState(0);

  const getLeads = async (page) => {
    setIsLoading(true);

    try {
      const { data } = await getLeadsList(
        recordsToShow,
        (page ?? currentPage) + 1,
        searchQuery,
        sortCol,
        sortDirection,
      );

      setLeadsData(data.data);
      setPageCount(Math.ceil(data.data.length / recordsToShow));
      if (data?.meta?.pagination) {
        setPaginationResponse(data.meta.pagination);
      } else {
        setPaginationResponse(null);
      }
    } catch (err) {
      store.pushNotification(
        err.message ?? 'Error',
        prepareErrorMessage(err.errors) ??
          'Could not load leads. Please refresh the page or try again later.',
        NotificationTypes.DANGER,
      );
    }
    setIsLoading(false);
  };

  const upClick = (colNumber) => {
    setSortCol(colNumber);
    setSortDirection('asc');
  };

  const downClick = (colNumber) => {
    setSortCol(colNumber);
    setSortDirection('desc');
  };

  useEffect(() => {
    getLeads();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isLoading) {
      setPageCount(Math.ceil(leadsData?.length / recordsToShow));
      setCurrentPage(0);

      getLeads();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recordsToShow]);

  useEffect(() => {
    if (!isLoading) {
      getLeads();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, sortCol, sortDirection]);

  useEffect(() => {
    if (!isLoading) {
      setCurrentPage(0);
      getLeads(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchQuery]);

  return (
    <section className="leads-table">
      <SectionHeader text="DATABASE" />
      <main className="leads-table__content">
        <ListControls
          onSearchChange={(evt) => setSearchQuery(evt.target.value)}
          searchValue={searchQuery}
        />
        <header className="content__header">
          <LeadTableEntry
            id="#"
            userName={
              <>
                <span className="content__header-text">User</span>
                <SortButtons
                  dark
                  vCenter
                  onUpClick={() => upClick('user_name')}
                  onDownClick={() => downClick('user_name')}
                  upActive={sortCol === 'user_name' && sortDirection === 'asc'}
                  downActive={
                    sortCol === 'user_name' && sortDirection === 'desc'
                  }
                />
              </>
            }
            mobile={
              <>
                <span className="content__header-text">Phone</span>
                <SortButtons
                  dark
                  vCenter
                  onUpClick={() => upClick('phone')}
                  onDownClick={() => downClick('phone')}
                  upActive={sortCol === 'phone' && sortDirection === 'asc'}
                  downActive={sortCol === 'phone' && sortDirection === 'desc'}
                />
              </>
            }
            contactName={
              <>
                <span className="content__header-text">Contact name</span>
                <SortButtons
                  dark
                  vCenter
                  onUpClick={() => upClick('contact_name')}
                  onDownClick={() => downClick('contact_name')}
                  upActive={
                    sortCol === 'contact_name' && sortDirection === 'asc'
                  }
                  downActive={
                    sortCol === 'contact_name' && sortDirection === 'desc'
                  }
                />
              </>
            }
            contactEmail={
              <>
                <span className="content__header-text">Contact email</span>
                <SortButtons
                  dark
                  vCenter
                  onUpClick={() => upClick('contact_email')}
                  onDownClick={() => downClick('contact_email')}
                  upActive={
                    sortCol === 'contact_email' && sortDirection === 'asc'
                  }
                  downActive={
                    sortCol === 'contact_email' && sortDirection === 'desc'
                  }
                />
              </>
            }
            source={
              <>
                <span className="content__header-text">Source</span>
                <SortButtons
                  dark
                  vCenter
                  onUpClick={() => upClick('source')}
                  onDownClick={() => downClick('source')}
                  upActive={sortCol === 'source' && sortDirection === 'asc'}
                  downActive={sortCol === 'source' && sortDirection === 'desc'}
                />
              </>
            }
            visitDateTime={
              <>
                <span className="content__header-text">Visit date & time</span>
                <SortButtons
                  dark
                  vCenter
                  onUpClick={() => upClick('visit_date_time')}
                  onDownClick={() => downClick('visit_date_time')}
                  upActive={
                    sortCol === 'visit_date_time' && sortDirection === 'asc'
                  }
                  downActive={
                    sortCol === 'visit_date_time' && sortDirection === 'desc'
                  }
                />
              </>
            }
            actions={
              <>
                <span className="content__header-text">Actions</span>
                <SortButtons
                  dark
                  vCenter
                  onUpClick={() => upClick('client_name')}
                  onDownClick={() => downClick('client_name')}
                  upActive={
                    sortCol === 'client_name' && sortDirection === 'asc'
                  }
                  downActive={
                    sortCol === 'client_name' && sortDirection === 'desc'
                  }
                />
              </>
            }
          />
        </header>
        <section className="content__list">
          {leadsData.length > 0 ? (
            leadsData.map((lead) => (
              <LeadTableEntry
                key={lead.client_uuid}
                userName={
                  lead.user_first_name
                    ? `${lead.user_first_name} ${lead.user_last_name}`
                    : 'unknown'
                }
                mobile={formatNumber(lead.client_phone)}
                contactName={
                  lead.client_first_name
                    ? `${lead.client_first_name} ${lead.client_last_name}`
                    : 'unknown'
                }
                contactEmail={lead.client_email}
                source={lead.source}
                visitDateTime={formatDateTime(lead.visit_date, lead.visit_time)}
                actions={
                  <button
                    type="button"
                    className="actions__button"
                    onClick={() => console.log('Info about contact')}>
                    <FontAwesomeIcon
                      icon={faInfoCircle}
                      className="button__icon"
                    />
                  </button>
                }
              />
            ))
          ) : (
            <p className="list__empty-message">There is no data to show</p>
          )}
          <Loader isLoading={isLoading} />
        </section>
      </main>
      {!isLoading && (
        <div style={{ marginLeft: 'auto', order: 2 }}>
          <ListPagination
            rowAmount={recordsToShow.toString()}
            setRowAmount={(evt) => setRecordsToShow(evt.target.value)}
            pageCount={paginationResponse?.total_pages || pageCount}
            totalRecords={paginationResponse?.total || leadsData.length}
            firstRecord={currentPage * recordsToShow + 1}
            lastRecord={
              currentPage + 1 === paginationResponse?.total_pages
                ? paginationResponse?.total
                : paginationResponse?.count * (currentPage + 1)
            }
            onPreviousClick={() => {
              if (currentPage + 1 > 1) {
                setCurrentPage((prev) => prev - 1);
              }
            }}
            onNextClick={() => {
              if (
                currentPage + 1 <
                (paginationResponse?.total_pages || pageCount)
              ) {
                setCurrentPage((prev) => prev + 1);
              }
            }}
            selectOptions={[10, 25, 50]}
          />
        </div>
      )}
    </section>
  );
};

export default LeadsTable;
