import React from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import { useAuth } from '../../hooks/Auth';
import Button from '../Button/Button';
import './Welcome.scss';

const Welcome = () => {
  const auth = useAuth();
  const history = useHistory();

  if (auth.data.user.onboarded) {
    return <Redirect to="/" />;
  }

  return (
    <section className="welcome">
      <img src="/images/welcome.svg" className="welcome__image" alt="welcome" />
      <h1 className="welcome__header">WELCOME!</h1>
      <p className="welcome__text">Let&apos;s get you started!</p>
      <Button
        variant="primary"
        text="GET ME STARTED!"
        onClick={() =>
          history.push({ pathname: '/account', state: { onboarding: true } })
        }
      />
    </section>
  );
};

export default Welcome;
