import React, { useEffect, useState } from 'react';
import './ColorSelector.scss';
import PropTypes from 'prop-types';
import { faEyeDropper } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ColorInput from './ColorInput/ColorInput';
import {
  disabledBackgroundColors,
  backgroundColors,
} from '../../../data/ImgEditor';
import useEyeDropper from '../../../hooks/EyeDropper';
import { rgbaToHex } from '../../../utils/helper';

const ColorSelector = ({
  chosenElement,
  setChosenElement,
  currentColor,
  setCurrentColor,
  setCurrentDrawingMode,
  disabled,
  canvas,
}) => {
  const [isEyeDropperActive, setIsEyeDropperActive] = useState(false);
  const [canvasClassName, setCanvasClassName] = useState('');
  const eyeDropperColor = useEyeDropper(canvas, canvas?.getContext('2d'));

  useEffect(() => {
    if (canvas && canvasClassName === '') {
      setCanvasClassName(canvas.upperCanvasEl.className);
    }
  }, [canvas, canvasClassName]);

  useEffect(() => {
    if (isEyeDropperActive) {
      setCurrentColor(rgbaToHex(eyeDropperColor).color);
      setIsEyeDropperActive(false);
    }
    // eslint-disable-next-line
  }, [eyeDropperColor]);

  useEffect(() => {
    if (canvas) {
      // eslint-disable-next-line no-param-reassign
      canvas.upperCanvasEl.className = `${canvasClassName} ${
        isEyeDropperActive ? 'canvas--active' : ''
      }`;
    }
  }, [isEyeDropperActive, canvas, canvasClassName]);

  return (
    <section className="color-selector">
      <div className="color-selector__button-group">
        <button
          className={`color-selector__button ${
            chosenElement === 'text' ? 'color-selector__button--active' : ''
          }`}
          type="button"
          disabled={disabled}
          onClick={() => setChosenElement('text')}>
          TEXT COLOR
        </button>
        <button
          className={`color-selector__button ${
            chosenElement === 'background'
              ? 'color-selector__button--active'
              : ''
          }`}
          type="button"
          disabled={disabled}
          onClick={() => setChosenElement('background')}>
          BACKGROUND COLOR
        </button>
      </div>
      <div className="color-selector__colors">
        <button
          type="button"
          onClick={() => {
            setCurrentDrawingMode('NORMAL');
            setIsEyeDropperActive(!isEyeDropperActive);
          }}
          className="colors__eye-dropper"
          disabled={disabled}>
          <FontAwesomeIcon icon={faEyeDropper} className="colors__icon" />
        </button>
        <ColorInput
          value={currentColor === 'rgba(0,0,0,0)' ? '#ffffff' : currentColor}
          isEditable
          setValue={(color) => setCurrentColor(color)}
          disabled={disabled}
        />
        {/* input below resets to default value of #000000 for text and rgba(0,0,0,0) for background */}
        <ColorInput
          value="#ffffff"
          isEditable={false}
          setValue={() => {}}
          onClick={() => {
            if (chosenElement === 'text') {
              setCurrentColor('#000000');
            } else {
              setCurrentColor('rgba(0,0,0,0)');
            }
          }}
          isReset
          disabled={disabled}
        />
        {disabled
          ? disabledBackgroundColors.map((bgColor, index) => (
              <ColorInput
                value={bgColor}
                disabled={disabled}
                setValue={() => {}}
                key={`${bgColor}_${index * 2}`}
                isEditable={false}
              />
            ))
          : backgroundColors.map((bgColor, index) => (
              <ColorInput
                value={bgColor}
                isEditable={false}
                setValue={() => {}}
                key={`${bgColor}_${index * 2}`}
                onClick={() => setCurrentColor(bgColor)}
              />
            ))}
      </div>
    </section>
  );
};

ColorSelector.propTypes = {
  chosenElement: PropTypes.string,
  setChosenElement: PropTypes.func,
  currentColor: PropTypes.string,
  setCurrentColor: PropTypes.func,
  setCurrentDrawingMode: PropTypes.func,
  disabled: PropTypes.bool,
  canvas: PropTypes.object,
};

export default ColorSelector;
