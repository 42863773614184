import {
  faChevronLeft,
  faChevronRight,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import './ListPagination.scss';
import PropTypes from 'prop-types';

const ListPagination = ({
  rowAmount,
  setRowAmount,
  totalRecords,
  firstRecord,
  lastRecord,
  onPreviousClick,
  onNextClick,
  selectOptions = [0, 10, 25, 50, 100],
}) => {
  return (
    <nav className="list__pagination">
      <div className="pagination__rows">
        <label htmlFor="rows-select" className="rows__label">
          Rows Per Page:
        </label>
        <select
          className="rows__select"
          onChange={setRowAmount}
          value={rowAmount}>
          {selectOptions.map((opt) =>
            opt === 0 ? (
              <option key={opt}>All</option>
            ) : (
              <option key={opt}>{opt}</option>
            ),
          )}
        </select>
      </div>
      <div className="pagination__pages">
        {firstRecord}-{lastRecord} of {totalRecords}
      </div>
      <ul className="pagination__nav">
        <li className="nav__entry">
          <button
            className="entry__button"
            type="button"
            onClick={onPreviousClick}>
            <FontAwesomeIcon icon={faChevronLeft} className="nav__icon" />
          </button>
        </li>
        <li className="nav__entry">
          <button className="entry__button" type="button" onClick={onNextClick}>
            <FontAwesomeIcon icon={faChevronRight} className="nav__icon" />
          </button>
        </li>
      </ul>
    </nav>
  );
};

ListPagination.propTypes = {
  rowAmount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  setRowAmount: PropTypes.func,
  totalRecords: PropTypes.number,
  firstRecord: PropTypes.number,
  lastRecord: PropTypes.number,
  onPreviousClick: PropTypes.func,
  onNextClick: PropTypes.func,
  selectOptions: PropTypes.arrayOf(PropTypes.number),
};

export default ListPagination;
