export const defaultStyles = {
  fontFamily: "'Mulish', sans-serif",
  fontUrl: 'https://fonts.googleapis.com/css2?family=Mulish&display=swap',
  home: {
    isHeaderVisible: true,
    isFooterVisible: true,
    isUserPhotoVisible: true,
    isUserInfoVisible: true,
    isUserAddressVisible: true,
    isSocialVisible: true,
    isButtonGroupVisible: true,
    isAboutMeVisible: false,
    isCombinedInfoVisible: false,
  },
  about: {
    isHeaderVisible: true,
    isFooterVisible: true,
    isUserPhotoVisible: true,
    isUserInfoVisible: true,
    isUserAddressVisible: false,
    isSocialVisible: true,
    isButtonGroupVisible: false,
    isAboutMeVisible: true,
    isCombinedInfoVisible: false,
  },
  components: [
    {
      id: 1,
      name: 'MainContainer',
      padding: '0',
      width: '100%',
      backgroundColor: '#ffffff',
      medium: {
        width: '100%',
        padding: '0',
      },
      large: {
        width: '600px',
        padding: '0.5rem 0',
      },
    },
    {
      id: 2,
      name: 'Header',
      order: 1,
      height: '10%',
      backgroundColor: '#ffffff',
      padding: '0.65rem 0',
      logoSize: 'large',
      isLogoVisible: true,
      maxWidth: '215px',
      medium: {
        order: 1,
        logoSize: 'medium',
        padding: '1.5rem 0',
        maxWidth: '250px',
      },
      large: {
        order: 1,
        logoSize: 'medium',
        padding: '1.55rem 0',
        maxWidth: '300px',
      },
    },
    {
      id: 3,
      name: 'Footer',
      order: 7,
      height: '7.5%',
      backgroundColor: '#ffffff',
      padding: '1.25rem',
      link: {
        color: '#159ff7',
        fontSize: '1.4rem',
        textAlign: 'center',
        isVisible: true,
      },
      legal: {
        color: '#7f7f7f',
        fontSize: '0.5rem',
        textAlign: 'center',
        isVisible: true,
      },
      medium: {
        order: 7,
        padding: '1.25rem',
        link: {
          fontSize: '1.4rem',
          textAlign: 'center',
        },
        legal: {
          fontSize: '0.5rem',
          textAlign: 'center',
        },
      },
      large: {
        order: 7,
        padding: '1.25rem',
        link: {
          fontSize: '1.4rem',
          textAlign: 'center',
        },
        legal: {
          fontSize: '0.5rem',
          textAlign: 'center',
        },
      },
    },
    {
      id: 4,
      name: 'SocialLink',
      site: 'Facebook',
      isVisible: true,
      imgSrc: '/images/facebook.png',
      order: 1,
      padding: '0.3rem',
      margin: '0 0.5rem 0 0',
      medium: {
        order: 1,
        padding: '0.3rem',
        margin: '0 0.5rem 0 0',
      },
      large: {
        order: 1,
        padding: '0.3rem',
        margin: '0 0.5rem 0 0',
      },
    },
    {
      id: 5,
      name: 'SocialLink',
      site: 'Twitter',
      isVisible: true,
      imgSrc: '/images/twitter.png',
      order: 2,
      padding: '0.3rem',
      margin: '0 0.5rem 0 0',
      medium: {
        order: 1,
        padding: '0.3rem',
        margin: '0 0.5rem 0 0',
      },
      large: {
        order: 1,
        padding: '0.3rem',
        margin: '0 0.5rem 0 0',
      },
    },
    {
      id: 6,
      name: 'SocialLink',
      site: 'Instagram',
      isVisible: true,
      imgSrc: '/images/instagram.png',
      order: 3,
      padding: '0.3rem',
      margin: '0 0.5rem 0 0',
      medium: {
        order: 1,
        padding: '0.3rem',
        margin: '0 0.5rem 0 0',
      },
      large: {
        order: 1,
        padding: '0.3rem',
        margin: '0 0.5rem 0 0',
      },
    },
    {
      id: 7,
      name: 'SocialLink',
      site: 'LinkedIn',
      isVisible: true,
      imgSrc: '/images/linkedin.png',
      order: 4,
      padding: '0.3rem',
      margin: '0 0.5rem 0 0',
      medium: {
        order: 1,
        padding: '0.3rem',
        margin: '0 0.5rem 0 0',
      },
      large: {
        order: 1,
        padding: '0.3rem',
        margin: '0 0.5rem 0 0',
      },
    },
    {
      id: 8,
      name: 'SocialLinkGroup',
      order: 4,
      height: '10%',
      width: '100%',
      linkHeight: '30px',
      backgroundColor: '#ffffff',
      padding: '0.3rem 0 0.3rem 0.95rem',
      flexFlow: 'row nowrap',
      justifyContent: 'flex-start',
      medium: {
        order: 4,
        padding: '0.3rem 0 0.3rem 1.5rem',
        flexFlow: 'row nowrap',
        justifyContent: 'flex-start',
        width: '100%',
        linkHeight: '35px',
      },
      large: {
        order: 4,
        padding: '0.3rem 0 0.3rem 2.2rem',
        flexFlow: 'row nowrap',
        justifyContent: 'flex-start',
        width: '100%',
        linkHeight: '40px',
      },
    },
    {
      id: 9,
      name: 'UserInfo',
      order: 3,
      height: '17.5%',
      backgroundColor: '#ffffff',
      width: '100%',
      padding: '0.65rem 0 0.125rem 0.95rem',
      color: '#000000',
      title: {
        fontSize: '1.8rem',
        lineHeight: '2.2rem',
        fontWeight: '600',
        padding: '0',
        textAlign: 'left',
      },
      subtitle: {
        fontSize: '1rem',
        lineHeight: '1.6rem',
        fontWeight: '300',
        padding: '0 0 0 0',
        textAlign: 'left',
      },
      medium: {
        order: 3,
        width: '100%',
        padding: '1.2rem 0 0.3rem 0.95rem',
        title: {
          fontSize: '2.3rem',
          lineHeight: '2.5rem',
          fontWeight: '600',
          padding: '0',
          textAlign: 'left',
        },
        subtitle: {
          fontSize: '1rem',
          lineHeight: '1.6rem',
          fontWeight: '300',
          padding: '0.5rem 0 0 0',
          textAlign: 'left',
        },
      },
      large: {
        order: 3,
        width: '100%',
        padding: '0.75rem 0 0.3rem 2.15rem',
        title: {
          fontSize: '2.2rem',
          lineHeight: '2.8rem',
          fontWeight: '600',
          padding: '0',
          textAlign: 'left',
        },
        subtitle: {
          fontSize: '1rem',
          lineHeight: '1.6rem',
          fontWeight: '300',
          padding: '0.5rem 0 0 0',
          textAlign: 'left',
        },
      },
    },
    {
      id: 10,
      name: 'UserPhoto',
      order: 2,
      height: '25%',
      backgroundColor: '#159ff7',
      backgroundImage: 'url(/images/background.png)',
      justifyContent: 'center',
      padding: '0.95rem 0 1rem 0',
      borderWidth: '3px 0 3px 0',
      width: '100%',
      borderStyle: 'solid solid solid solid',
      borderColor: '#d1d5d8 transparent #d1d5d8 transparent',
      image: {
        size: 'medium',
        boxShadow: '0px 0px 9px 4px rgba(0, 0, 0, 0.4)',
        shape: 'rectangle',
        borderWidth: '4px 4px 4px 4px',
        borderStyle: 'solid solid solid solid',
        borderColor:
          'rgba(255, 255, 255, 0.35) rgba(255, 255, 255, 0.35) rgba(255, 255, 255, 0.35) rgba(255, 255, 255, 0.35)',
        maxWidth: '165px',
      },
      medium: {
        order: 2,
        justifyContent: 'center',
        padding: '1.55rem 0 1.375rem 0',
        width: '100%',
        image: {
          size: 'medium',
          shape: 'rectangle',
          maxWidth: '190px',
        },
      },
      large: {
        order: 2,
        justifyContent: 'center',
        padding: '1.55rem 0 1.06rem 0',
        width: '100%',
        image: {
          size: 'medium',
          shape: 'rectangle',
          maxWidth: '250px',
        },
      },
    },
    {
      id: 11,
      name: 'UserAddress',
      order: 5,
      height: '5%',
      backgroundColor: '#ffffff',
      padding: '0 0 0.15rem 0.95rem',
      fontSize: '0.8rem',
      textAlign: 'left',
      fontWeight: '300',
      color: '#000000',
      medium: {
        order: 5,
        padding: '0 0 0.65rem 1.1rem',
        fontSize: '0.8rem',
        textAlign: 'left',
        fontWeight: '300',
      },
      large: {
        order: 5,
        padding: '0 0 0.65rem 2.2rem',
        fontSize: '1rem',
        textAlign: 'left',
        fontWeight: '300',
      },
    },
    {
      id: 12,
      name: 'Button',
      order: 1,
      shape: 'rectangle',
      backgroundColor: '#323232',
      color: '#fdfdfd',
      action: 'call',
      href: 'tel:123456789',
      flexFlow: 'row wrap',
      isVisible: true,
      padding: '1rem 0 1rem 0.75rem',
      margin: '0 0 0.15rem 0',
      text: {
        content: 'Call Me',
        fontSize: '0.9rem',
        fontWeight: '600',
        textAlign: 'left',
        width: '60%',
        margin: '0.3rem 0 0 0',
        isVisible: true,
      },
      icon: {
        shape: 'rectangle',
        backgroundColor: '#323232',
        isVisible: true,
        size: 'small',
        url: '/images/phone-icon.png',
      },
      medium: {
        order: 1,
        flexFlow: 'row wrap',
        padding: '1rem 0 1rem 0.75rem',
        margin: '0 0 0.3rem 0',
        text: {
          fontSize: '1.05rem',
          fontWeight: '600',
          textAlign: 'left',
          width: '60%',
          margin: '0.3rem 0 0 0',
        },
        icon: {
          size: 'small',
        },
      },
      large: {
        order: 1,
        flexFlow: 'row wrap',
        padding: '1rem 0 1rem 0.75rem',
        margin: '0 0 0.3rem 0',
        text: {
          fontSize: '1.2rem',
          fontWeight: '600',
          textAlign: 'left',
          width: '60%',
          margin: '0.3rem 0 0 0',
        },
        icon: {
          size: 'small',
        },
      },
    },
    {
      id: 13,
      name: 'Button',
      order: 2,
      shape: 'rectangle',
      backgroundColor: '#323232',
      color: '#fdfdfd',
      action: 'text',
      href: 'sms:123456789',
      flexFlow: 'row wrap',
      isVisible: true,
      padding: '1rem 0 1rem 0.75rem',
      margin: '0 0 0.15rem 0',
      text: {
        content: 'Text Me',
        fontSize: '0.9rem',
        fontWeight: '600',
        textAlign: 'left',
        width: '60%',
        margin: '0.3rem 0 0 0',
        isVisible: true,
      },
      icon: {
        shape: 'rectangle',
        backgroundColor: '#323232',
        isVisible: true,
        size: 'small',
        url: '/images/text-icon.png',
      },
      medium: {
        order: 2,
        flexFlow: 'row wrap',
        padding: '1rem 0 1rem 0.75rem',
        margin: '0 0 0.3rem 0',
        text: {
          fontSize: '1.05rem',
          fontWeight: '600',
          textAlign: 'left',
          width: '60%',
          margin: '0.3rem 0 0 0',
        },
        icon: {
          size: 'small',
        },
      },
      large: {
        order: 2,
        flexFlow: 'row wrap',
        padding: '1rem 0 1rem 0.75rem',
        margin: '0 0 0.3rem 0',
        text: {
          fontSize: '1.2rem',
          fontWeight: '600',
          textAlign: 'left',
          width: '60%',
          margin: '0.3rem 0 0 0',
        },
        icon: {
          size: 'small',
        },
      },
    },
    {
      id: 14,
      name: 'Button',
      order: 3,
      shape: 'rectangle',
      backgroundColor: '#323232',
      color: '#fdfdfd',
      action: 'email',
      href: 'mailto:123456789@email.com',
      flexFlow: 'row wrap',
      isVisible: true,
      padding: '1rem 0 1rem 0.75rem',
      margin: '0 0 0.15rem 0',
      text: {
        content: 'Email Me',
        fontSize: '0.9rem',
        fontWeight: '600',
        textAlign: 'left',
        width: '60%',
        margin: '0.3rem 0 0 0',
        isVisible: true,
      },
      icon: {
        shape: 'rectangle',
        backgroundColor: '#323232',
        isVisible: true,
        size: 'small',
        url: '/images/email-icon.png',
      },
      medium: {
        order: 3,
        flexFlow: 'row wrap',
        padding: '1rem 0 1rem 0.75rem',
        margin: '0 0 0.3rem 0',
        text: {
          fontSize: '1.05rem',
          fontWeight: '600',
          textAlign: 'left',
          width: '60%',
          margin: '0.3rem 0 0 0',
        },
        icon: {
          size: 'small',
        },
      },
      large: {
        order: 3,
        flexFlow: 'row wrap',
        padding: '1rem 0 1rem 0.75rem',
        margin: '0 0 0.3rem 0',
        text: {
          fontSize: '1.2rem',
          fontWeight: '600',
          textAlign: 'left',
          width: '60%',
          margin: '0.3rem 0 0 0',
        },
        icon: {
          size: 'small',
        },
      },
    },
    {
      id: 15,
      name: 'Button',
      order: 4,
      shape: 'rectangle',
      backgroundColor: '#323232',
      color: '#fdfdfd',
      action: 'about',
      href: '/about',
      flexFlow: 'row wrap',
      isVisible: true,
      padding: '1rem 0 1rem 0.75rem',
      margin: '0 0 0.15rem 0',
      text: {
        content: 'About Me',
        fontSize: '0.9rem',
        fontWeight: '600',
        textAlign: 'left',
        width: '60%',
        margin: '0.3rem 0 0 0',
        isVisible: true,
      },
      icon: {
        shape: 'rectangle',
        backgroundColor: '#323232',
        isVisible: true,
        size: 'small',
        url: '/images/about-icon.png',
      },
      medium: {
        order: 4,
        flexFlow: 'row wrap',
        padding: '1rem 0 1rem 0.75rem',
        margin: '0 0 0.3rem 0',
        text: {
          fontSize: '1.05rem',
          fontWeight: '600',
          textAlign: 'left',
          width: '60%',
          margin: '0.3rem 0 0 0',
        },
        icon: {
          size: 'small',
        },
      },
      large: {
        order: 4,
        flexFlow: 'row wrap',
        padding: '1rem 0 1rem 0.75rem',
        margin: '0 0 0.3rem 0',
        text: {
          fontSize: '1.2rem',
          fontWeight: '600',
          textAlign: 'left',
          width: '60%',
          margin: '0.3rem 0 0 0',
        },
        icon: {
          size: 'small',
        },
      },
    },
    {
      id: 16,
      name: 'Button',
      order: 5,
      shape: 'rectangle',
      backgroundColor: '#159ff7',
      color: '#fdfdfd',
      action: 'download',
      href: 'download',
      flexFlow: 'row wrap',
      isVisible: true,
      padding: '1rem 0 1rem 0.75rem',
      margin: '0 0 0.15rem 0',
      text: {
        content: 'Download',
        fontSize: '0.9rem',
        fontWeight: '600',
        textAlign: 'left',
        width: '60%',
        margin: '0.3rem 0 0 0',
        isVisible: true,
      },
      icon: {
        shape: 'rectangle',
        backgroundColor: '#159ff7',
        isVisible: true,
        size: 'small',
        url: '/images/download-icon.png',
      },
      medium: {
        order: 5,
        flexFlow: 'row wrap',
        padding: '1rem 0 1rem 0.75rem',
        margin: '0 0 0.3rem 0',
        text: {
          fontSize: '1.05rem',
          fontWeight: '600',
          textAlign: 'left',
          width: '60%',
          margin: '0.3rem 0 0 0',
        },
        icon: {
          size: 'small',
        },
      },
      large: {
        order: 5,
        flexFlow: 'row wrap',
        padding: '1rem 0 1rem 0.75rem',
        margin: '0 0 0.3rem 0',
        text: {
          fontSize: '1.2rem',
          fontWeight: '600',
          textAlign: 'left',
          width: '60%',
          margin: '0.3rem 0 0 0',
        },
        icon: {
          size: 'small',
        },
      },
    },
    {
      id: 17,
      name: 'Button',
      order: 6,
      shape: 'rectangle',
      backgroundColor: '#159ff7',
      color: '#fdfdfd',
      action: 'send',
      href: 'send',
      flexFlow: 'row wrap',
      isVisible: true,
      padding: '1rem 0 1rem 0.75rem',
      margin: '0 0 0.15rem 0',
      text: {
        content: 'Send',
        fontSize: '0.9rem',
        fontWeight: '600',
        textAlign: 'left',
        width: '60%',
        margin: '0.3rem 0 0 0',
        isVisible: true,
      },
      icon: {
        shape: 'rectangle',
        backgroundColor: '#159ff7',
        isVisible: true,
        size: 'small',
        url: '/images/send-icon.png',
      },
      medium: {
        order: 6,
        flexFlow: 'row wrap',
        padding: '1rem 0 1rem 0.75rem',
        margin: '0 0 0.3rem 0',
        text: {
          fontSize: '1.05rem',
          fontWeight: '600',
          textAlign: 'left',
          width: '60%',
          margin: '0.3rem 0 0 0',
        },
        icon: {
          size: 'small',
        },
      },
      large: {
        order: 6,
        flexFlow: 'row wrap',
        padding: '1rem 0 1rem 0.75rem',
        margin: '0 0 0.3rem 0',
        text: {
          fontSize: '1.2rem',
          fontWeight: '600',
          textAlign: 'left',
          width: '60%',
          margin: '0.3rem 0 0 0',
        },
        icon: {
          size: 'small',
        },
      },
    },
    {
      id: 18,
      name: 'ButtonGroup',
      order: 6,
      height: '32.5%',
      padding: '0.95rem 0 0 0',
      columnNumber: 2,
      backgroundColor: '#ffffff',
      medium: {
        order: 6,
        padding: '0.95rem 0 0 0',
      },
      large: {
        order: 6,
        padding: '0.95rem 0 0 0',
      },
    },
    {
      id: 19,
      name: 'AboutMeText',
      order: 5,
      padding: '0.75rem 0.95rem 0.125rem 0.95rem',
      title: {
        fontSize: '1.3rem',
        textAlign: 'left',
        padding: '0',
        lineHeight: '1.7rem',
        fontWeight: '600',
      },
      text: {
        fontSize: '1rem',
        textAlign: 'left',
        padding: '0 0 0 0',
        lineHeight: '1.5rem',
        fontWeight: '400',
      },
      medium: {
        order: 5,
        padding: '1.1rem 0.95rem 0 0.95rem',
        title: {
          fontSize: '1.4rem',
          textAlign: 'left',
          padding: '0',
          lineHeight: '1.8rem',
          fontWeight: '600',
        },
        text: {
          fontSize: '1rem',
          textAlign: 'left',
          padding: '0 0 0 0',
          lineHeight: '1.5rem',
          fontWeight: '400',
        },
      },
      large: {
        order: 5,
        padding: '1.25rem 2.2rem 0 2.2rem',
        title: {
          fontSize: '1.7rem',
          textAlign: 'left',
          padding: '0',
          lineHeight: '2.2rem',
          fontWeight: '600',
        },
        text: {
          fontSize: '1rem',
          textAlign: 'left',
          padding: '0 0 0 0',
          lineHeight: '1.5rem',
          fontWeight: '400',
        },
      },
    },
    {
      id: 20,
      name: 'SendModal',
      overlayOpacity: '60%',
      borderRadius: '1.1rem',
      backgroundColor: '#fefefe',
      width: '90%',
      padding: '1.875rem',
      color: '#000000',
      boxShadow:
        '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
      header: {
        fontSize: '2rem',
        fontWeight: 800,
        padding: '0',
        margin: '0 0 1.25rem 0',
      },
      closeButtonColor: '#000000',
      formPadding: '0',
      input: {
        borderWidth: '1px 1px 1px 1px',
        borderStyle: 'solid solid solid solid',
        borderColor:
          'rgba(0, 0, 0, 0.4) rgba(0, 0, 0, 0.4) rgba(0, 0, 0, 0.4) rgba(0, 0, 0, 0.4)',
        borderRadius: '0.6rem',
        padding: '0.8rem 0.7rem',
        fontSize: '1rem',
        margin: '0 0 0.5rem 0',
        mobileNumberWidth: '100%',
        firstNameWidth: '49%',
        lastNameWidth: '49%',
        invalidColor: '#ff0000',
      },
      textArea: {
        borderWidth: '1px 1px 1px 1px',
        borderStyle: 'solid solid solid solid',
        borderColor:
          'rgba(0, 0, 0, 0.4) rgba(0, 0, 0, 0.4) rgba(0, 0, 0, 0.4) rgba(0, 0, 0, 0.4)',
        borderRadius: '0.6rem',
        padding: '0.65rem',
        minHeight: '3.4rem',
        fontSize: '1rem',
        margin: '0 0 0.5rem 0',
        width: '100%',
        invalidColor: '#ff0000',
      },
      smallText: {
        fontSize: '0.6rem',
        width: '100%',
        textAlign: 'left',
      },
      submitButton: {
        backgroundColor: '#323232',
        color: '#ffffff',
        fontSize: '1.2rem',
        margin: '1.1rem auto auto auto',
        padding: '1.1rem',
        width: '50%',
        borderWidth: '0 0 0 0',
        borderStyle: 'solid solid solid solid',
        borderColor: 'transparent transparent transparent transparent',
      },
      medium: {
        width: '90%',
        padding: '1.875rem',
        input: {
          padding: '0.9rem',
          fontSize: '1.15rem',
          margin: '0 0 0.5rem 0',
          mobileNumberWidth: '100%',
          firstNameWidth: '49%',
          lastNameWidth: '49%',
        },
        textArea: {
          padding: '0.9rem',
          minHeight: '3.4rem',
          fontSize: '1rem',
          margin: '0 0 0.5rem 0',
          width: '100%',
        },
        smallText: {
          fontSize: '0.75rem',
          width: '100%',
          textAlign: 'left',
        },
      },
      large: {
        width: '90%',
        padding: '1.875rem',
        input: {
          padding: '0.9rem',
          fontSize: '1.15rem',
          margin: '0 0 0.5rem 0',
          mobileNumberWidth: '100%',
          firstNameWidth: '49%',
          lastNameWidth: '49%',
        },
        textArea: {
          padding: '0.9rem',
          minHeight: '3.4rem',
          fontSize: '1rem',
          margin: '0 0 0.5rem 0',
          width: '100%',
        },
        smallText: {
          fontSize: '0.75rem',
          width: '100%',
          textAlign: 'left',
        },
      },
    },
    {
      id: 21,
      name: 'CombinedUserInfo',
      isAddressBelow: true,
      isAddressVisibleOnAboutMe: false,
      height: '50%',
      flexDirection: 'row-reverse',
      order: 2,
      backgroundColor: '#eeeeee',
      isOverPhoto: true,
      overPhotoPosition: 'top',
      isSocialVisible: false,
      isUserInfoVisible: true,
      isAddressVisible: false,
    },
  ],
};

export const getDefaultStylesForComponent = (name) => {
  const styles = defaultStyles.components.filter((obj) => obj.name === name);

  if (Array.isArray(styles)) return styles[0];

  return styles;
};
