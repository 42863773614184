import React, { useEffect, useState } from 'react';
import './FileInput.scss';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import Button from '../Button/Button';

const FileInput = ({
  inputRef,
  id,
  fileExtensions,
  buttonText,
  redirectUrl,
}) => {
  const history = useHistory();
  const [isFileChosen, setIsFileChosen] = useState(false);

  useEffect(() => {
    if (inputRef) {
      if (inputRef?.current?.files[0]) {
        history.push({
          pathname: redirectUrl,
          state: { file: inputRef.current.files[0] },
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFileChosen]);

  return (
    <div className="file-input__wrapper">
      <Button
        variant="secondary"
        text={buttonText}
        onClick={() => inputRef?.current.click()}
      />
      <input
        id={id}
        type="file"
        ref={inputRef}
        className="file-input"
        onChange={() => setIsFileChosen(true)}
        accept={fileExtensions.join(',')}
      />
    </div>
  );
};

FileInput.propTypes = {
  inputRef: PropTypes.object,
  id: PropTypes.string,
  fileExtensions: PropTypes.arrayOf(PropTypes.string),
  buttonText: PropTypes.string,
  redirectUrl: PropTypes.string,
};

export default FileInput;
