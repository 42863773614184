import {
  faHome,
  faListUl,
  faUserCircle,
  faUserFriends,
  faCog,
  faUsers,
  faAddressBook,
  faKey,
  faPalette,
} from '@fortawesome/free-solid-svg-icons';

export const links = [
  { href: '/', icon: faHome, text: 'Dashboard' },
  // TODO re-add this later
  // { href: '/links', icon: faListUl, text: 'Links' },
  { href: '/mbc', icon: faUserCircle, text: 'Mobile Business Card' },
  { href: '/contacts', icon: faUserFriends, text: 'Contacts' },
];

export const adminLinks = [
  { href: '/admin/users', icon: faUsers, text: 'Users' },
  { href: '/account', icon: faCog, text: 'Account Settings' },
  // TODO re-add these later
  // { href: '/admin/leads', icon: faAddressBook, text: 'Leads' },
  // { href: '/admin/keywords', icon: faKey, text: 'Keywords' },
  { href: '/admin/themes', icon: faPalette, text: 'Themes' },
];
