import axios from 'axios';

const baseUrl = process.env.REACT_APP_BASE_API_URL;

const mbcAxios = axios.create();

delete mbcAxios.defaults.headers.common.Authorization;

mbcAxios.interceptors.response.use(
  (response) => response.data,
  (err) => err,
);

export const getCurrentUser = async (id, isOnPreview) => {
  return mbcAxios.get(
    `${baseUrl}/users/${id}${isOnPreview ? '?preview=1' : ''}`,
  );
};

// just a temporary method until a way to create MBC links is figured out
export const getCurrentUserForPreview = async (uuid) => {
  return mbcAxios.get(`${baseUrl}/users/${uuid}`);
};

export const getVcardForUser = async (uuid) => {
  return mbcAxios.post(`${baseUrl}/users/${uuid}/download-card`);
};

export const getCurrentTheme = async (uuid) => {
  return mbcAxios.get(`${baseUrl}/mbc-themes/${uuid}`);
};

export const sendModalForm = async (
  uuid,
  firstName,
  lastName,
  phone,
  message,
  source,
) => {
  const response = await mbcAxios.post(`${baseUrl}/sms/send`, {
    user_id: uuid,
    first_name: firstName,
    last_name: lastName,
    phone,
    message,
    source: source === 'undefined' || !source ? 'form' : source,
  });
  return response;
};
