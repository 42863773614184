import React from 'react';
import './KeywordTableEntry.scss';
import PropTypes from 'prop-types';

const KeywordsTableEntry = ({
  id,
  email,
  keyword,
  userName,
  shortCode,
  actions,
}) => (
  <div className="list__entry keywords-table__entry">
    <div className="entry__column entry__column--id">{id}</div>
    <div className="entry__column entry__column--keyword">
      {keyword} {typeof shortCode === 'string' ? `(${shortCode})` : shortCode}
    </div>
    <div className="entry__column entry__column--user_name">{userName}</div>
    <div className="entry__column entry__column--email">{email}</div>
    <div className="entry__column entry__column--actions">{actions}</div>
  </div>
);

KeywordsTableEntry.propTypes = {
  id: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
    PropTypes.object,
  ]),
  shortCode: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  keyword: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  email: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  userName: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  actions: PropTypes.oneOfType([PropTypes.object, PropTypes.node]),
};

export default KeywordsTableEntry;
