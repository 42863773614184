import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import SectionHeader from '../../../SectionHeader/SectionHeader';
import ThemesEntry from './ThemesEntry/ThemesEntry';
import ThemesHeader from './ThemesHeader/ThemesHeader';
import ListPagination from '../../../Contacts/ContactsList/ListPagination/ListPagination';
import ListControls from '../../../Contacts/ContactsList/ListControls/ListControls';
import './ThemesList.scss';
import { getMBCThemes } from '../../../../utils/apiCalls';
import Loader from '../../../Loader/Loader';
import store, { NotificationTypes } from '../../../../hooks/NotificationHub';

const ThemesList = () => {
  const history = useHistory();

  const [isLoading, setIsLoading] = useState(true);

  const [themeData, setThemeData] = useState([]);
  const [searchInputValue, setSearchInputValue] = useState('');
  const [searchQuery, setSearchQuery] = useState('');
  const [pageCount, setPageCount] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [firstRecordNum, setFirstRecordNum] = useState(1);
  const [lastRecordNum, setLastRecordNum] = useState(1);
  const [totalRecords, setTotalRecords] = useState(10);
  const [rowAmount, setRowAmount] = useState('10');

  const getThemes = async () => {
    setIsLoading(true);

    try {
      const response = await getMBCThemes(
        currentPage,
        rowAmount === 'All' ? totalRecords : rowAmount,
        searchQuery,
      );
      const pagination = response?.data?.meta?.pagination;

      const firstRecord = (pagination.current_page - 1) * pagination.count + 1;

      setThemeData(response?.data?.data);
      setPageCount(pagination.total_pages);
      setCurrentPage(pagination.current_page);
      setFirstRecordNum(firstRecord);
      setLastRecordNum(firstRecord + pagination.count - 1);
      setTotalRecords(pagination?.total);
    } catch (err) {
      store.pushNotification(
        'Error',
        'Could not load themes at the moment. Please refresh the page or try again later.',
        NotificationTypes.DANGER,
      );
    }

    setIsLoading(false);
  };

  useEffect(() => {
    getThemes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, rowAmount]);

  useEffect(() => {
    const timeout = setTimeout(() => getThemes(), 300);

    return () => {
      clearTimeout(timeout);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchQuery]);

  useEffect(() => {
    if(searchInputValue.length >= 3) {
      setSearchQuery(searchInputValue);
    } else if(searchInputValue && searchInputValue.length < 3) {
      setSearchQuery('');
    }
  }, [searchInputValue]);

  return (
    <main className="themes-list">
      <SectionHeader text="AVAILABLE THEMES" />
      <ListControls
        onAddClick={() => history.push('/admin/themes/add')}
        searchValue={searchInputValue}
        onSearchChange={(evt) => setSearchInputValue(evt.target.value)}
      />
      <ThemesHeader />
      <div className="themes-list__content">
        <Loader isLoading={isLoading} />
        {themeData.map((theme) => (
          <ThemesEntry
            {...theme}
            isAvailable={theme.status === 'Enabled'}
            key={theme.uuid}
          />
        ))}
      </div>
      <ListPagination
        firstRecord={firstRecordNum}
        lastRecord={lastRecordNum}
        totalRecords={totalRecords}
        onNextClick={() =>
          setCurrentPage(currentPage < pageCount ? pageCount + 1 : currentPage)
        }
        onPreviousClick={() =>
          setCurrentPage(currentPage > 1 ? currentPage - 1 : currentPage)
        }
        setRowAmount={(evt) => setRowAmount(evt.target.value)}
      />
    </main>
  );
};

export default ThemesList;
