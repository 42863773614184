import React, { useState, useEffect } from 'react';
import './MBCSettings.scss';
import PageHeader from '../PageHeader/PageHeader';
import ContactForm from '../ContactForm/ContactForm';
import SocialForm from '../SocialForm/SocialForm';
import IdCodeForm from './IdCodeForm/IdCodeForm';
import Button from '../Button/Button';
import ThemeSelector from './ThemeSelector/ThemeSelector';
import { useContactFields } from '../../hooks/ContactForm';
import { useSocialFields } from '../../hooks/SocialForm';
import {
  prepareErrorMessage,
  prepareUserDataToSubmit,
} from '../../utils/helper';
import {
  updateUserMBCData,
  getKeywordsForUser,
  reserveKeywordForUser,
  getUserMBCData,
  getCompanyMBCThemes,
} from '../../utils/apiCalls';
import TextareaForm from '../TextareaForm/TextareaForm';
import {
  emptyMBCState,
  mapResponseToMBCState,
  mbcInputSettings,
} from '../../data/MBC';
import { useAuth } from '../../hooks/Auth';
import Loader from '../Loader/Loader';
import store, { NotificationTypes } from '../../hooks/NotificationHub';

const MBCSettings = () => {
  const auth = useAuth();

  const [userData, setUserData] = useState(emptyMBCState);
  const [isResetting, setIsResetting] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const contactFields = useContactFields(
    userData,
    mbcInputSettings,
    isResetting,
    isLoading,
  );
  const socialFields = useSocialFields(userData, isResetting, isLoading);
  const [footerField, setFooterField] = useState(userData.footer);
  const [bioField, setBioField] = useState(userData.aboutMeCopyTxt);
  const [mobileIdField, setMobileIdField] = useState('');

  const [previewRefresh, setPreviewRefresh] = useState(0);
  const [availableThemes, setAvailableThemes] = useState([]);
  const [themeId, setThemeId] = useState(
    userData.mbcThemeId || availableThemes[0],
  );

  // we're getting all keywords that are reserved for the user, but by default only one will be used
  const [keywords, setKeywords] = useState([]);

  const getKeywords = async () => {
    try {
      const response = await getKeywordsForUser();

      setKeywords(keywords.concat(response.data));
      setMobileIdField(response?.data?.keyword ?? '');
      setIsLoading(false);
    } catch (err) {
      store.pushNotification('Error', err.message, NotificationTypes.DANGER);
    }
  };

  const getUserInfo = async () => {
    // get user's MBC data
    try {
      const response = await getUserMBCData();

      setUserData(mapResponseToMBCState(response.data ?? {}, auth));
      if (response?.data?.mbc_theme_id) {
        setThemeId(response?.data?.mbc_theme_id);
      }
    } catch (err) {
      store.pushNotification(
        'Error',
        err.message ?? 'Could not load user data. Please try again later.',
        NotificationTypes.DANGER,
      );
    }
  };

  const getAvailableThemes = async () => {
    try {
      const response = await getCompanyMBCThemes();
      setAvailableThemes(response.data);

      if (!themeId || typeof themeId === 'number') {
        setThemeId(response?.data[0]?.uuid);
      }
    } catch (err) {
      store.pushNotification(
        'Error',
        err.message ??
          'Could not load available themes. Please try again later.',
        NotificationTypes.DANGER,
      );
    }
  };

  useEffect(() => {
    const func = async () => {
      await getAvailableThemes();
      getUserInfo();
      getKeywords();
    };

    func();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (userData && availableThemes && availableThemes.length > 0) {
      if (!themeId || typeof themeId === 'number') {
        setThemeId(availableThemes[0]?.uuid);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData, availableThemes]);

  useEffect(() => {
    setFooterField(userData?.footer);
    setBioField(userData?.aboutMeCopyTxt);
  }, [userData]);

  useEffect(() => {
    setFooterField(userData?.footer);
    setBioField(userData?.aboutMeCopyTxt);
    setMobileIdField(keywords?.keyword ?? '');

    const timeout = setTimeout(() => setIsResetting(false), 100);
    return () => clearTimeout(timeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isResetting]);

  const handleKeywordSubmit = async () => {
    // if user already has that keyword there's no need to access backend
    if (
      keywords.keyword !== mobileIdField &&
      mobileIdField.trim().length !== 0
    ) {
      try {
        const response = await reserveKeywordForUser(mobileIdField);

        setKeywords([...keywords, { keyword: mobileIdField }]);
        store.pushNotification(
          'Success',
          response.data.message,
          NotificationTypes.SUCCESS,
        );
      } catch (err) {
        const message = prepareErrorMessage(err.errors);

        store.pushNotification(
          err.message ?? 'Error',
          message ?? 'Could not save your keyword. Please try again later.',
          NotificationTypes.DANGER,
        );
      }
    }
  };

  const handleSubmit = async (evt) => {
    evt.preventDefault();
    setIsSubmitting(true);
    const [
      contactFieldsValid,
      contactFieldsErrors,
    ] = contactFields.validateOnSubmit();

    if (!contactFieldsValid) {
      store.pushNotification(
        'Error',
        <span style={{ whiteSpace: 'break-spaces' }}>
          {contactFieldsErrors.join('\n\n')}
        </span>,
        NotificationTypes.DANGER,
      );
      return;
    }

    const data = prepareUserDataToSubmit(
      contactFields.contactInfo,
      socialFields.socialLinks,
      bioField,
      footerField,
      themeId,
    );

    try {
      const response = await updateUserMBCData(data.dataToSend);
      setUserData(mapResponseToMBCState(response.data, auth));

      await handleKeywordSubmit();

      store.pushNotification(
        'Success',
        'MBC data saved successfully.',
        NotificationTypes.SUCCESS,
      );

      setPreviewRefresh(previewRefresh + 1);
    } catch (err) {
      const message = prepareErrorMessage(err.errors);
      store.pushNotification(
        err?.message ?? 'Error',
        message ?? 'Could not save your MBC data. Please try again later.',
        NotificationTypes.DANGER,
      );
    }

    setIsSubmitting(false);
  };

  return (
    <section className="mbc">
      <PageHeader text="Mobile Business Card" />
      <main className="mbc__main">
        <form className="main__form" onSubmit={handleSubmit}>
          <ContactForm
            inputs={contactFields.inputs}
            selects={contactFields.selects}
          />
          <SocialForm inputs={socialFields.inputs} />
          {/* form below is for footer text */}
          <TextareaForm
            field={footerField}
            setField={setFooterField}
            maxLength={200}
            header="FOOTER"
            label="FOOTER TEXT"
          />
          {/* form below is for bio field */}
          <TextareaForm
            field={bioField}
            setField={setBioField}
            maxLength={200}
            header="BIO"
            label="ABOUT ME"
            required
          />
          <IdCodeForm mobileId={mobileIdField} setMobileId={setMobileIdField} />
          <div className="mbc__button-wrapper">
            <Button
              text="CANCEL"
              onClick={() => setIsResetting(true)}
              variant="secondary"
            />
            <Button
              text="SAVE"
              type="submit"
              variant="primary"
              disabled={isSubmitting}
              state={isSubmitting ? 'loading' : ''}
            />
          </div>
        </form>
      </main>
      <aside className="mbc__aside">
        <ThemeSelector
          themeId={themeId}
          setThemeId={setThemeId}
          availableThemes={availableThemes}
          previewRefresh={previewRefresh}
        />
      </aside>
      <Loader isLoading={isLoading} isShowingProgress={false} />
    </section>
  );
};

export default MBCSettings;
