import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useAuth } from '../../../hooks/Auth';
import './ThemeSelector.scss';
import ThemePreview from '../../ThemePreview/ThemePreview';
import ThemeTab from './ThemeTabs/ThemeTab';
import ImgEditor from '../../ImgEditor/ImgEditor';
import PhotoSelector from './PhotoSelector/PhotoSelector';
import Button from '../../Button/Button';
import Loader from '../../Loader/Loader';
import PreviewModal from '../PreviewModal';
import store, { NotificationTypes } from '../../../hooks/NotificationHub';
import { getCurrentTheme, getCurrentUser } from '../../../utils/mbcApiCalls';
import Modal from '../../ImgEditor/Modal';
import { remapUserProperties } from '../../MBC/helper';

const ThemeSelector = ({
  previewRefresh,
  themeId,
  setThemeId,
  availableThemes,
}) => {
  const auth = useAuth();

  const [user, setUser] = useState(null);
  const [theme, setTheme] = useState(null);
  const [fileInputRef, setFileInputRef] = useState(null);
  const [isEditorVisible, setIsEditorVisible] = useState(false);
  const [path, setPath] = useState('');
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [previewModalVisible, setPreviewModalVisible] = useState(false);
  const [renderedTabs, setRenderedTabs] = useState(null);

  const getUser = async () => {
    try {
      const response = await getCurrentUser(auth.data.user.uuid, true);

      setUser(remapUserProperties(response.data));
    } catch (err) {
      store.pushNotification(
        'Error',
        'Could not load user data for preview. Please try again later.',
        NotificationTypes.DANGER,
      );
    }
  };

  const getTheme = async () => {
    try {
      const response = await getCurrentTheme(themeId);

      setTheme(response.data);
    } catch (err) {
      store.pushNotification(
        'Error',
        'Could not load theme preview. Please try again later.',
        NotificationTypes.DANGER,
      );
    }
  };

  useEffect(() => {
    getUser();
  }, [previewRefresh, isEditorVisible]);

  useEffect(() => {
    setRenderedTabs(
      availableThemes.map((t, index) => (
        <ThemeTab
          text={`DESIGN ${index + 1}`}
          onClick={() => setThemeId(t.uuid)}
          isActive={t.uuid === themeId}
          key={t.uuid}
        />
      )),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [themeId, availableThemes]);

  useEffect(() => {
    if (themeId) {
      getTheme();
    }
  }, [themeId]);

  return (
    <section className="theme-selector">
      {isEditorVisible && path !== '' ? (
        <ImgEditor
          path={path}
          close={() => {
            setIsEditorVisible(false);
            setPath('');
          }}
          type="photo"
        />
      ) : null}

      <PhotoSelector
        setFileInputRef={setFileInputRef}
        onClick={(filepath) => {
          setIsEditorVisible(true);
          setPath(filepath);
        }}
        hasClosed={!isEditorVisible && path === ''}
      />

      <Modal
        editFunc={() => {
          setPath(user.PhotoUrl);
          setEditModalVisible(false);
          setIsEditorVisible(true);
        }}
        modalVisible={editModalVisible}
        setModalVisible={setEditModalVisible}
        uploadNewFunc={() => {
          fileInputRef.current.click();
          setEditModalVisible(false);
        }}
      />

      <aside className="theme-selector__tabs">{renderedTabs}</aside>
      <main className="theme-selector__preview">
        <ThemePreview
          previewUser={user}
          themeSettings={theme?.settings}
          previewRefresh={previewRefresh}
          onPhotoClick={() => {
            if (!user.PhotoUrl) {
              fileInputRef.current.click();
            } else {
              setEditModalVisible(true);
            }
          }}
        />
        <span className="preview__mbc-link">
          <Button
            disabled={!themeId || !auth?.data?.user?.uuid}
            variant="primary"
            text="OPEN PREVIEW"
            onClick={() => setPreviewModalVisible(true)}
          />
        </span>
      </main>
      <Loader isLoading={availableThemes.length === 0} />
      <PreviewModal
        previewUser={user}
        themeSettings={theme?.settings}
        userId={auth.data.user.uuid}
        close={setPreviewModalVisible}
        visible={previewModalVisible}
      />
    </section>
  );
};

ThemeSelector.propTypes = {
  previewRefresh: PropTypes.number,
  themeId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  setThemeId: PropTypes.func,
  availableThemes: PropTypes.arrayOf(PropTypes.object),
};

export default ThemeSelector;
