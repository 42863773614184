export const availableFonts = ['Arial', 'Verdana', 'Courier New'];

export const disabledBackgroundColors = [
  '#ffffff',
  '#000000',
  '#f0f0f0',
  '#bfbfbf',
  '#fcfcfc',
  '#ffffff',
  '#ededed',
  '#d1d1d1',
  '#bababa',
];

export const backgroundColors = [
  '#ffffff',
  '#000000',
  '#3e98ed',
  '#9bbe56',
  '#fbc965',
  '#fb8d3f',
  '#ea4b5a',
  '#ce156b',
  '#a11db8',
];

export const acceptedFileExtensions = ['.png', '.jpg', '.jpeg'];

export const DRAWING_MODES = {
  NORMAL: 'NORMAL',
  TEXT: 'TEXT',
};

export const POSITION_ORIGIN = {
  LEFT: 'left',
  RIGHT: 'right',
  CENTER: 'center',
  TOP: 'top',
  BOTTOM: 'bottom',
};

export const TEXT_OBJECT_TYPE = 'i-text';

export const TEXT_STYLES = {
  FONT_STYLE: 'fontStyle',
  FONT_WEIGHT: 'fontWeight',
  TEXT_DECORATION: 'textDecoration',
  FONT_FAMILY: 'fontFamily',
  FONT_SIZE: 'fontSize',
  FILL: 'fill',
  BACKGROUND: 'textBackgroundColor',
};

export const INITIAL_COLOR = '#000000';

export const INITIAL_OPACITY = 100;

export const INITIAL_FONT_SIZE = '44';

export const INITIAL_TEXT_ALIGNMENT = 'left';

export const INITIAL_WEIGHT_STYLE_BUTTONS_STATE = {
  bold: false,
  italic: false,
};

export const INITIAL_LINE_BUTTONS_STATE = {
  underline: false,
  striketrough: false,
};

export const INITIAL_ALIGN_BUTTONS_STATE = {
  left: false,
  right: false,
  center: false,
  justify: false,
};

export const INITIAL_CHOSEN_ELEMENT = 'text';

export const ELEMENT_TYPES = {
  TEXT: 'text',
  BACKGROUND: 'background',
};
