import React from 'react';
import './Dashboard.scss';
import PageHeader from '../PageHeader/PageHeader';
import Statistics from './Statistics/Statistics';
import Chart from './Chart/Chart';
import RecentContacts from './RecentContacts/RecentContacts';

const Dashboard = () => {
  return (
    <section className="dashboard">
      <PageHeader text="Dashboard" />
      <Statistics />
      <Chart />
      <RecentContacts />
    </section>
  );
};

export default Dashboard;
