import React from 'react';
import './Loader.scss';
import PropTypes from 'prop-types';
import { animated, useTransition } from 'react-spring';

const Loader = ({ isLoading, isShowingProgress = true }) => {
  const transition = useTransition(isLoading, null, {
    from: { opacity: 1 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  });

  return transition.map(
    ({ item, key, props }) =>
      item && (
        <animated.div className="loader" style={props} key={key}>
          {isShowingProgress ? (
            <>
              <div className="loader__dot" />
              <div className="loader__dot" />
              <div className="loader__dot" />
              <div className="loader__dot" />
            </>
          ) : null}
        </animated.div>
      ),
  );
};

Loader.propTypes = {
  isLoading: PropTypes.bool,
  isShowingProgress: PropTypes.bool,
};

export default Loader;
