import React from 'react';
import { saveAs } from 'file-saver';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFileImport,
  faKeyboard,
  faPaperPlane,
  faQuestionCircle,
  faSms,
} from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import { addBillingPrefixToState } from '../data/Account';
import { autocompleteAddress } from './apiCalls';
import { number } from 'prop-types';

export const base64ToBlob = (data) => {
  const rImageType = /data:(image\/.+);base64,/;
  let mimeString = '';
  let raw;
  let i;

  raw = data.replace(rImageType, (header, imageType) => {
    mimeString = imageType;
    return '';
  });

  raw = atob(raw);

  const rawLength = raw.length;
  const uInt8Array = new Uint8Array(rawLength);

  for (i = 0; i < rawLength; i += 1) {
    uInt8Array[i] = raw.charCodeAt(i);
  }

  return new Blob([uInt8Array], { type: mimeString });
};

export const isValidHexColor = (color) => {
  const s = new Option().style;
  s.color = color;

  return s.color !== '' && color.length === 7;
};

export const toBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = () => reject();
  });

export const saveBlobToURL = (blob) => {
  return URL.createObjectURL(blob);
};

// assuming the hexColor is full length (no shortcuts like #fff)
export const hexToRgba = (hexColor, alpha = 1) => {
  const r = parseInt(hexColor.slice(1, 3), 16);
  const g = parseInt(hexColor.slice(3, 5), 16);
  const b = parseInt(hexColor.slice(5, 7), 16);

  return `rgba(${r},${g},${b},${alpha})`;
};

export const rgbaToHex = (rgba) => {
  if (rgba.charAt(0) === '#') return { color: rgba, opacity: 1 };

  const values = rgba.split('(')[1].split(')')[0].split(',');

  const r = parseInt(values[0], 10);
  const g = parseInt(values[1], 10);
  const b = parseInt(values[2], 10);
  const a = parseFloat(values[3], 10);

  return {
    color: `#${(r < 10 ? '0' : '') + r.toString(16)}${
      (g < 10 ? '0' : '') + g.toString(16)
    }${(b < 10 ? '0' : '') + b.toString(16)}`,
    opacity: a,
  };
};

export const downloadImage = (editorRef) => {
  const editor = editorRef.current.getInstance();

  let imageName = editor.getImageName();
  const blob = base64ToBlob(editor.toDataURL());
  const type = blob.type.split('/')[1];

  if (imageName.split('.').pop() !== type) {
    imageName += `.${type}`;
  }

  saveAs(blob, imageName);
};

export const getFileResolution = (file) => {
  return new Promise((resolve, reject) => {
    const img = new Image();

    img.onload = () =>
      resolve({
        height: img.naturalHeight,
        width: img.naturalWidth,
      });
    img.onerror = reject;
    img.src = URL.createObjectURL(file);
  });
};

export const validatePassword = (newPassword) => {
  if (newPassword.length < 8)
    return 'Password must be at least 8 characters long';

  const letters = [
    'a',
    'b',
    'c',
    'd',
    'e',
    'f',
    'g',
    'h',
    'i',
    'j',
    'k',
    'l',
    'm',
    'n',
    'o',
    'p',
    'q',
    'r',
    's',
    't',
    'u',
    'v',
    'w',
    'x',
    'y',
    'z',
  ];

  const numbers = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];

  const specialCharacters = [
    '!',
    '@',
    '#',
    '$',
    '%',
    '^',
    '&',
    '*',
    '(',
    ')',
    '-',
    '_',
    '=',
    '+',
    '~',
    '`',
    "'",
    '"',
    '\\',
    ',',
    '<',
    '.',
    '>',
    '/',
    '?',
    ';',
    ':',
    '[',
    '{',
    ']',
    '}',
  ];

  if (
    !letters.some((letter) => newPassword.includes(letter)) ||
    !letters.some((letter) => newPassword.includes(letter.toUpperCase())) ||
    !numbers.some((num) => newPassword.includes(num)) ||
    !specialCharacters.some((char) => newPassword.includes(char))
  ) {
    return 'Password must contain at least one letter (lower and upper case), a number and a special character';
  }

  return '';
};

export const getPageTitle = (pathname) => {
  // this url ends in user uuid, that's why it cannot be in the switch
  if (pathname.includes('/admin/users/edit')) {
    return 'Edit user';
  }

  if (pathname.includes('/admin/themes/add')) {
    return 'Add theme';
  }

  if (pathname.includes('/admin/themes/edit')) {
    return 'Edit theme';
  }

  switch (pathname) {
    case '/welcome':
      return 'Welcome';
    case '/login':
      return 'Login';
    case '/register':
      return 'Register';
    case '/password/remind':
      return 'Password reminder';
    case '/password/reset':
    case '/account/activate':
      return 'Password change';
    case '/':
      return 'Dashboard';
    case '/links':
      return 'Links';
    case '/mbc':
      return 'MBC';
    case '/contacts':
      return 'Contacts';
    case '/account':
      return 'Account';
    case '/admin/users':
      return 'Users management';
    case '/admin/users/add':
      return 'Add user';
    case '/admin/users/import':
      return 'Import users';
    case '/admin/leads':
      return 'Leads';
    case '/admin/keywords':
      return 'Keywords';
    case '/admin/themes':
      return 'Themes';
    default:
      return '';
  }
};

export const camelCaseToSnakeCase = (obj) => {
  const newObj = { ...obj };

  Object.keys(newObj).forEach((key) => {
    const newKey = key.replace(
      /[A-Z]/g,
      (letter) => `_${letter.toLowerCase()}`,
    );

    if (newKey !== key) {
      newObj[newKey] = newObj[key];
      delete newObj[key];
    }
  });

  return newObj;
};

// used for MBC and account pages
export const prepareErrorMessage = (errors) => {
  if (!errors || errors.length === 0) return null;

  let message = '';

  Object.keys(errors).forEach((key) => {
    message += `${errors[key]}\n`;
  });

  // removing last new line character
  message = message.substring(0, message.length - 1);

  return message;
};

export const prepareCompanyDataToSubmit = (
  contactInfo,
  socialInfo,
  billingInfo,
  logoUrl,
) => {
  const newCompanyData = {
    ...contactInfo,
    ...socialInfo,
    ...addBillingPrefixToState(billingInfo),
    logoUrl,
    billing: {
      ...billingInfo,
    },
  };

  const toSend = camelCaseToSnakeCase(newCompanyData);
  toSend.address = toSend.street;
  delete toSend.street;

  return { toSend, newCompanyData };
};

export const prepareContactDataToSubmit = (
  contactInfo,
  socialInfo,
  message,
  photoUrl,
) => {
  const newContactData = {
    ...contactInfo,
    ...socialInfo,
    message,
    photoUrl,
  };

  const toSend = camelCaseToSnakeCase(newContactData);
  toSend.website = toSend.my_website_url;

  delete toSend.visit_date;
  delete toSend.visit_time;
  delete toSend.err;
  delete toSend.my_website_url;

  return { toSend, newContactData };
};

export const mapResponseToContactState = (data) => ({
  firstName: data.first_name ?? '',
  lastName: data.last_name ?? '',
  company: data.company ?? '',
  title: data.title ?? '',
  phone: data.phone ?? '',
  mobile: data.mobile ?? '',
  street: data.street ?? '',
  myWebsiteUrl: data.website ?? data.my_website_url ?? '',
  apt: data.apt ?? '',
  city: data.city ?? '',
  state: data.state ?? '',
  country: data.country ?? '',
  zipCode: data.zip_code ?? '',
  email: data.email ?? '',
  facebookUrl: data.facebook_url ?? '',
  twitterUrl: data.twitter_url ?? '',
  instagramUrl: data.instagram_url ?? '',
  linkedinUrl: data.linkedin_url ?? '',
  uuid: data.uuid ?? '',
  message: data.message ?? '',
  photoUrl: data.photo_url ?? '',
});

export const prepareUserDataToSubmit = (
  contactInfo,
  socialLinks,
  bio,
  footer,
  mbcThemeId,
  billing = {},
) => {
  const newUserData = {
    ...contactInfo,
    ...socialLinks,
    aboutMeCopyTxt: bio,
    footer,
    mbcThemeId,
    ...addBillingPrefixToState(billing),
  };

  const dataToSend = camelCaseToSnakeCase(newUserData);

  dataToSend.website = dataToSend.my_website_url;
  dataToSend.about = dataToSend.about_me_copy_txt;

  delete dataToSend.err;
  delete dataToSend.my_website_url;
  delete dataToSend.about_me_copy_txt;

  return { newUserData, dataToSend };
};

export const readTextFromFile = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = () => resolve(reader.result);
    reader.onerror = () => reject(reader.error);

    reader.readAsText(file);
  });

export const parseThemeInfo = (theme) => {
  if (!theme) return null;

  const themeObject = theme.settings;
  const components = themeObject.components ?? [];

  const generalInfo = {
    name: theme.name ?? '',
    isEnabled: theme.status === 'Enabled',
    fontFamily: themeObject.fontFamily ?? '',
    fontUrl: themeObject.fontUrl ?? '',
  };

  const visibility = {
    home: themeObject.home ?? {},
    about: themeObject.about ?? {},
  };

  const mainContainerInfo =
    components.filter((el) => el.name === 'MainContainer')[0] ?? {};
  const headerInfo = components.filter((el) => el.name === 'Header')[0] ?? {};
  const footerInfo = components.filter((el) => el.name === 'Footer')[0] ?? {};
  const facebook =
    components.filter(
      (el) => el.name === 'SocialLink' && el.site === 'Facebook',
    )[0] ?? {};
  const twitter =
    components.filter(
      (el) => el.name === 'SocialLink' && el.site === 'Twitter',
    )[0] ?? {};
  const instagram =
    components.filter(
      (el) => el.name === 'SocialLink' && el.site === 'Instagram',
    )[0] ?? {};
  const linkedin =
    components.filter(
      (el) => el.name === 'SocialLink' && el.site === 'LinkedIn',
    )[0] ?? {};
  const socialsGroup =
    components.filter((el) => el.name === 'SocialLinkGroup')[0] ?? {};
  const socialsInfo = {
    ...socialsGroup,
    facebook,
    twitter,
    instagram,
    linkedin,
    medium: {
      ...socialsGroup.medium,
      ...facebook.medium,
      ...twitter.medium,
      ...instagram.medium,
      ...linkedin.medium,
    },
    large: {
      ...socialsGroup.large,
      ...facebook.large,
      ...twitter.large,
      ...instagram.large,
      ...linkedin.large,
    },
  };

  const userInfo = components.filter((el) => el.name === 'UserInfo')[0];
  const userPhotoInfo = components.filter((el) => el.name === 'UserPhoto')[0];
  const userAddressInfo = components.filter(
    (el) => el.name === 'UserAddress',
  )[0];

  const call =
    components.filter(
      (el) => el.name === 'Button' && el.action === 'call',
    )[0] ?? {};
  const text =
    components.filter(
      (el) => el.name === 'Button' && el.action === 'text',
    )[0] ?? {};
  const email =
    components.filter(
      (el) => el.name === 'Button' && el.action === 'email',
    )[0] ?? {};
  const about =
    components.filter(
      (el) => el.name === 'Button' && el.action === 'about',
    )[0] ?? {};
  const download =
    components.filter(
      (el) => el.name === 'Button' && el.action === 'download',
    )[0] ?? {};
  const send =
    components.filter(
      (el) => el.name === 'Button' && el.action === 'send',
    )[0] ?? {};
  const buttonGroup =
    components.filter((el) => el.name === 'ButtonGroup')[0] ?? {};

  const buttonInfo = {
    call,
    text,
    email,
    about,
    download,
    send,
    group: buttonGroup,
    medium: {
      call: call.medium,
      text: text.medium,
      email: email.medium,
      about: about.medium,
      download: download.medium,
      send: send.medium,
      group: buttonGroup.medium,
    },
    large: {
      call: call.large,
      text: text.large,
      email: email.large,
      about: about.large,
      download: download.large,
      send: send.large,
      group: buttonGroup.large,
    },
  };

  const aboutMeInfo = components.filter((el) => el.name === 'AboutMeText')[0];
  const modalInfo = components.filter((el) => el.name === 'SendModal')[0];
  const combinedInfo = components.filter(
    (el) => el.name === 'CombinedUserInfo',
  )[0];

  return {
    generalInfo,
    visibility,
    mainContainerInfo,
    headerInfo,
    footerInfo,
    socialsInfo,
    userInfo,
    userPhotoInfo,
    userAddressInfo,
    buttonInfo,
    aboutMeInfo,
    modalInfo,
    combinedInfo,
  };
};

export const getDatepickerMessageString = (date) => {
  let result = '';

  const hours = Math.floor(Math.abs(new Date() - date) / (60 * 60 * 1000));
  const days = Math.floor(hours / 24);

  if (hours > 0 && hours % 24 !== 0) {
    result = `LAST ${
      days > 0 && hours > 24
        ? `${days === 1 ? 'DAY' : `${days} DAYS`} AND `
        : ''
    }
          ${days > 0 && hours > 24 ? hours - 24 * days : hours}
          ${
            (days > 0 && hours > 24 ? hours - 24 * days : hours) === 1
              ? 'HOUR'
              : 'HOURS'
          }`;
  } else if (hours === 24) {
    result = 'LAST 24 HOURS';
  } else if (days > 0 || (hours > 24 && hours % 24 === 0)) {
    result = `LAST ${days} DAYS`;
  } else {
    result = 'LAST 0 hours';
  }
  return result;
};

export const formatDateForChart = (dateString) => {
  const parts = dateString.split(' ');

  const date = parts[0];
  const dateParts = date.split('/');

  const day = parseInt(dateParts[0], 10);
  const month = parseInt(dateParts[1], 10) - 1;
  const years = parseInt(`20${dateParts[2]}`, 10);

  let hours = 0;
  let minutes = 0;

  if (parts.length > 1) {
    const time = parts[1].split(':');

    hours = parseInt(time[0], 10);
    minutes = parseInt(time[1], 10);

    if (parts[2] === 'pm') {
      hours += 12;
    }
  }

  return new Date(years, month, day, hours, minutes);
};

export const formatNumber = (numberString) => {
  if (numberString?.trim()?.length === 0 || !numberString) return '';

  if (
    numberString.includes('(') &&
    numberString.includes(')') &&
    numberString.includes('-')
  )
    return numberString;

  let result = '(';

  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < 10; i++) {
    result += numberString?.charAt(i) ?? ' ';

    if (i === 2) {
      result += ')';
    } else if (i === 5) {
      result += '-';
    }
  }

  return result;
};

export const formatDateTime = (dateString, timeString) => {
  // assuming dateString is in 'dd/mm/yy' format and timeString is in 'hh:mm:ss [am/pm]' format

  const dateParts = dateString.split('/');
  const timeParts = timeString.split(' ')[0].split(':');
  const amPm = timeString.split(' ')[1];

  const date = new Date(
    `20${dateParts[2]}`,
    parseInt(dateParts[1], 10) - 1,
    dateParts[0],
    amPm === 'pm' ? timeParts[0] + 12 : timeParts[0],
    timeParts[1],
  );

  return moment(date).format('DD/MM/YY LT');
};

export const getIcon = (source) => {
  switch (source) {
    case 'facebook':
      return (
        <img
          src="/images/social/facebook.png"
          alt="Facebook"
          className="source__icon"
        />
      );
    case 'twitter':
      return (
        <img
          src="/images/social/twitter.png"
          alt="Twitter"
          className="source__icon"
        />
      );
    case 'instagram':
      return (
        <img
          src="/images/social/instagram.png"
          alt="Instagram"
          className="source__icon"
        />
      );
    case 'linkedin':
      return (
        <img
          src="/images/social/linkedin.png"
          alt="Linkedin"
          className="source__icon"
        />
      );
    case 'sms':
      return (
        <div className="source__icon source__icon--sms">
          <FontAwesomeIcon icon={faSms} />
        </div>
      );
    case 'keyboard':
      return (
        <div className="source__icon source__icon--keyboard">
          <FontAwesomeIcon icon={faKeyboard} />
        </div>
      );
    case 'form':
      return (
        <div className="source__icon source__icon--form">
          <FontAwesomeIcon icon={faPaperPlane} />
        </div>
      );
    case 'import':
      return (
        <div className="source__icon source__icon--import">
          <FontAwesomeIcon icon={faFileImport} />
        </div>
      );
    default:
      return (
        <FontAwesomeIcon
          icon={faQuestionCircle}
          className="source__icon source__icon--unknown"
        />
      );
  }
};

export const openUrlInNewTab = (url) => {
  const a = document.createElement('a');
  a.target = '_blank';
  a.rel = 'noopener noreferrer';
  a.href = url;
  a.click();
};
