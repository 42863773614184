import React, { useEffect, useState } from 'react';
import './Tabs.scss';
import PropTypes from 'prop-types';
import SectionHeader from '../SectionHeader/SectionHeader';

const Tabs = ({ tabCount, tabContent, tabNames, headerText, tabRef }) => {
  const [currentTab, setCurrentTab] = useState(0);
  const [tabSelectors, setTabSelectors] = useState(null);

  useEffect(() => {
    if (!tabContent) return;

    const tabs = [];

    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < tabCount; i++) {
      tabs.push(
        <button
          type="button"
          className={`selectors__entry ${
            i === currentTab ? 'selectors__entry--active' : ''
          }`}
          onClick={() => setCurrentTab(i)}
          key={tabNames[i]}>
          {tabNames[i]}
        </button>,
      );
    }

    setTabSelectors(tabs);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTab]);

  return (
    <section className="tabs" ref={tabRef}>
      <aside className="tabs__selector">
        <SectionHeader text={headerText} />
        {tabSelectors}
      </aside>
      <main className="tabs__content">{tabContent[currentTab]}</main>
    </section>
  );
};

Tabs.propTypes = {
  tabCount: PropTypes.number,
  tabContent: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.arrayOf(PropTypes.object),
    ]),
  ),
  tabNames: PropTypes.arrayOf(PropTypes.string),
  headerText: PropTypes.string,
  tabRef: PropTypes.object,
};

export default Tabs;
