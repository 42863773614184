import React, { useEffect, useRef, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import FormControl from '../../../FormControl/FormControl';
import './ThemesForm.scss';
import useGeneralInfo from '../../../../hooks/ThemesForm/GeneralInfo';
import useVisibility from '../../../../hooks/ThemesForm/Visibility';
import useMainContainerInfo from '../../../../hooks/ThemesForm/MainContainer';
import useHeaderInfo from '../../../../hooks/ThemesForm/Header';
import useFooterInfo from '../../../../hooks/ThemesForm/Footer';
import useSocialsInfo from '../../../../hooks/ThemesForm/Socials';
import useUserInfo from '../../../../hooks/ThemesForm/UserInfo';
import Tabs from '../../../Tabs/Tabs';
import useUserPhoto from '../../../../hooks/ThemesForm/UserPhoto';
import useUserAddress from '../../../../hooks/ThemesForm/UserAddress';
import useButtons from '../../../../hooks/ThemesForm/Buttons';
import useAboutMe from '../../../../hooks/ThemesForm/AboutMe';
import useModalInfo from '../../../../hooks/ThemesForm/Modal';
import useCombinedInfo from '../../../../hooks/ThemesForm/CombinedInfo';
import {
  createMBCTheme,
  getDataForMBCTheme,
  updateMBCTheme,
} from '../../../../utils/apiCalls';
import { parseThemeInfo, prepareErrorMessage } from '../../../../utils/helper';
import Button from '../../../Button/Button';
import ThemesFormPreview from '../ThemesFormPreview/ThemesFormPreview';
import Loader from '../../../Loader/Loader';
import store, { NotificationTypes } from '../../../../hooks/NotificationHub';

const empty = {};
const emptyVisibility = { home: {}, about: {} };

const ThemesForm = () => {
  const prepareTabsForComponent = (hook, setStateFunc) => {
    const newTabs = [];

    newTabs.push(
      hook.inputs.map((inp) => <FormControl {...inp} key={inp.name} />),
    );
    newTabs.push(
      hook.mediumInputs.map((inp) => <FormControl {...inp} key={inp.name} />),
    );
    newTabs.push(
      hook.largeInputs.map((inp) => <FormControl {...inp} key={inp.name} />),
    );

    setStateFunc(newTabs);
  };

  const history = useHistory();
  const params = useParams();
  const location = useLocation();

  const headerRef = useRef();
  const footerRef = useRef();
  const photoRef = useRef();
  const infoRef = useRef();
  const socialsRef = useRef();
  const addressRef = useRef();
  const buttonsRef = useRef();
  const modalRef = useRef();
  const combinedRef = useRef();
  const aboutRef = useRef();

  const [isLoading, setIsLoading] = useState(true);
  const [themeInfo, setThemeInfo] = useState({});

  const generalInfo = useGeneralInfo(themeInfo.generalInfo ?? empty);
  const visibilityInfo = useVisibility(themeInfo.visibility ?? emptyVisibility);
  const mainContainerInfo = useMainContainerInfo(
    themeInfo.mainContainerInfo ?? empty,
  );
  const headerInfo = useHeaderInfo(themeInfo.headerInfo ?? empty);
  const footerInfo = useFooterInfo(themeInfo.footerInfo ?? empty);
  const socialsInfo = useSocialsInfo(themeInfo.socialsInfo ?? empty);
  const userInfo = useUserInfo(themeInfo.userInfo ?? empty);
  const userPhoto = useUserPhoto(themeInfo.userPhotoInfo ?? empty);
  const userAddress = useUserAddress(themeInfo.userAddressInfo ?? empty);
  const buttons = useButtons(themeInfo.buttonInfo ?? empty);
  const about = useAboutMe(themeInfo.aboutMeInfo ?? empty);
  const modal = useModalInfo(themeInfo.modalInfo ?? empty);
  const combined = useCombinedInfo(themeInfo.combinedInfo ?? empty);

  const [jsonPreview, setJsonPreview] = useState({});
  const [componentsArray, setComponentsArray] = useState([]);
  const [visibilityTabs, setVisibilityTabs] = useState([]);
  const [mainContainerTabs, setMainContainerTabs] = useState([]);
  const [headerTabs, setHeaderTabs] = useState([]);
  const [footerTabs, setFooterTabs] = useState([]);
  const [socialsTabs, setSocialsTabs] = useState([]);
  const [userTabs, setUserTabs] = useState([]);
  const [userPhotoTabs, setUserPhotoTabs] = useState([]);
  const [userAddressTabs, setUserAddressTabs] = useState([]);
  const [buttonGroupTabs, setButtonGroupTabs] = useState([]);
  const [callTabs, setCallTabs] = useState([]);
  const [textTabs, setTextTabs] = useState([]);
  const [emailTabs, setEmailTabs] = useState([]);
  const [aboutTabs, setAboutTabs] = useState([]);
  const [downloadTabs, setDownloadTabs] = useState([]);
  const [sendTabs, setSendTabs] = useState([]);
  const [allButtonTabs, setAllButtonTabs] = useState([]);
  const [aboutMeTabs, setAboutMeTabs] = useState([]);
  const [modalTabs, setModalTabs] = useState([]);
  const [combinedTabs, setCombinedTabs] = useState([]);

  useEffect(() => {
    const newTabs = [];

    newTabs.push(
      visibilityInfo.homeInputs.map((inp) => (
        <FormControl {...inp} key={inp.name} />
      )),
    );

    newTabs.push(
      visibilityInfo.aboutInputs.map((inp) => (
        <FormControl {...inp} key={inp.name} />
      )),
    );

    setVisibilityTabs(newTabs);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visibilityInfo.aboutVisibility, visibilityInfo.homeVisibility]);

  useEffect(() => {
    prepareTabsForComponent(mainContainerInfo, setMainContainerTabs);
    prepareTabsForComponent(headerInfo, setHeaderTabs);
    prepareTabsForComponent(footerInfo, setFooterTabs);
    prepareTabsForComponent(socialsInfo, setSocialsTabs);
    prepareTabsForComponent(userInfo, setUserTabs);
    prepareTabsForComponent(userPhoto, setUserPhotoTabs);
    prepareTabsForComponent(userAddress, setUserAddressTabs);
    prepareTabsForComponent(
      {
        inputs: buttons.group.groupInputs,
        mediumInputs: buttons.group.mediumGroupInputs,
        largeInputs: buttons.group.largeGroupInputs,
      },
      setButtonGroupTabs,
    );
    prepareTabsForComponent(
      {
        inputs: buttons.buttons.call.callInputs,
        mediumInputs: buttons.buttons.call.callInputsMedium,
        largeInputs: buttons.buttons.call.callInputsLarge,
      },
      setCallTabs,
    );
    prepareTabsForComponent(
      {
        inputs: buttons.buttons.text.textInputs,
        mediumInputs: buttons.buttons.text.textInputsMedium,
        largeInputs: buttons.buttons.text.textInputsLarge,
      },
      setTextTabs,
    );
    prepareTabsForComponent(
      {
        inputs: buttons.buttons.email.emailInputs,
        mediumInputs: buttons.buttons.email.emailInputsMedium,
        largeInputs: buttons.buttons.email.emailInputsLarge,
      },
      setEmailTabs,
    );
    prepareTabsForComponent(
      {
        inputs: buttons.buttons.about.aboutInputs,
        mediumInputs: buttons.buttons.about.aboutInputsMedium,
        largeInputs: buttons.buttons.about.aboutInputsLarge,
      },
      setAboutTabs,
    );
    prepareTabsForComponent(
      {
        inputs: buttons.buttons.download.downloadInputs,
        mediumInputs: buttons.buttons.download.downloadInputsMedium,
        largeInputs: buttons.buttons.download.downloadInputsLarge,
      },
      setDownloadTabs,
    );
    prepareTabsForComponent(
      {
        inputs: buttons.buttons.send.sendInputs,
        mediumInputs: buttons.buttons.send.sendInputsMedium,
        largeInputs: buttons.buttons.send.sendInputsLarge,
      },
      setSendTabs,
    );
    prepareTabsForComponent(about, setAboutMeTabs);
    prepareTabsForComponent(modal, setModalTabs);
    prepareTabsForComponent(
      {
        inputs: combined.inputs,
        mediumInputs: [],
        largeInputs: [],
      },
      setCombinedTabs,
    );

    const components = [];

    components.push({
      name: 'MainContainer',
      ...mainContainerInfo.info,
    });

    components.push({
      name: 'Header',
      ...headerInfo.info,
    });

    components.push({
      name: 'Footer',
      ...footerInfo.info,
    });

    components.push({
      name: 'SocialLink',
      site: 'Facebook',
      ...socialsInfo.info.facebook,
      medium: { ...socialsInfo.info.medium.facebook },
      large: { ...socialsInfo.info.large.facebook },
    });
    components.push({
      name: 'SocialLink',
      site: 'Twitter',
      ...socialsInfo.info.twitter,
      medium: { ...socialsInfo.info.medium.twitter },
      large: { ...socialsInfo.info.large.twitter },
    });
    components.push({
      name: 'SocialLink',
      site: 'Instagram',
      ...socialsInfo.info.instagram,
      medium: { ...socialsInfo.info.medium.instagram },
      large: { ...socialsInfo.info.large.instagram },
    });
    components.push({
      name: 'SocialLink',
      site: 'LinkedIn',
      ...socialsInfo.info.linkedin,
      medium: { ...socialsInfo.info.medium.linkedin },
      large: { ...socialsInfo.info.large.linkedin },
    });
    // needed to deep clone the object (spread syntax only does shallow copy)
    const socialsGroup = JSON.parse(JSON.stringify(socialsInfo.info));
    delete socialsGroup.facebook;
    delete socialsGroup.twitter;
    delete socialsGroup.instagram;
    delete socialsGroup.linkedin;
    delete socialsGroup.medium.facebook;
    delete socialsGroup.medium.twitter;
    delete socialsGroup.medium.instagram;
    delete socialsGroup.medium.linkedin;
    delete socialsGroup.large.facebook;
    delete socialsGroup.large.twitter;
    delete socialsGroup.large.instagram;
    delete socialsGroup.large.linkedin;
    components.push({
      name: 'SocialLinkGroup',
      ...socialsGroup,
    });

    components.push({
      name: 'UserInfo',
      ...userInfo.info,
    });

    components.push({
      name: 'UserPhoto',
      ...userPhoto.info,
    });

    components.push({
      name: 'UserAddress',
      ...userAddress.info,
    });

    components.push({
      name: 'ButtonGroup',
      ...buttons.group.info,
    });

    components.push({
      name: 'Button',
      action: 'call',
      ...buttons.buttons.info.call,
      medium: { ...buttons.buttons.info.medium.call },
      large: { ...buttons.buttons.info.large.call },
    });

    components.push({
      name: 'Button',
      action: 'text',
      ...buttons.buttons.info.text,
      medium: { ...buttons.buttons.info.medium.text },
      large: { ...buttons.buttons.info.large.text },
    });

    components.push({
      name: 'Button',
      action: 'email',
      ...buttons.buttons.info.email,
      medium: { ...buttons.buttons.info.medium.email },
      large: { ...buttons.buttons.info.large.email },
    });

    components.push({
      name: 'Button',
      action: 'about',
      ...buttons.buttons.info.about,
      medium: { ...buttons.buttons.info.medium.about },
      large: { ...buttons.buttons.info.large.about },
    });

    components.push({
      name: 'Button',
      action: 'download',
      ...buttons.buttons.info.download,
      medium: { ...buttons.buttons.info.medium.download },
      large: { ...buttons.buttons.info.large.download },
    });

    components.push({
      name: 'Button',
      action: 'send',
      ...buttons.buttons.info.send,
      medium: { ...buttons.buttons.info.medium.send },
      large: { ...buttons.buttons.info.large.send },
    });

    components.push({
      name: 'AboutMeText',
      ...about.info,
    });

    components.push({
      name: 'SendModal',
      ...modal.info,
    });

    components.push({
      name: 'CombinedUserInfo',
      ...combined.info,
    });

    setComponentsArray(components);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    mainContainerInfo.info,
    headerInfo.info,
    footerInfo.info,
    socialsInfo.info,
    userInfo.info,
    userPhoto.info,
    userAddress.info,
    buttons.buttons.info,
    buttons.group.info,
    about.info,
    modal.info,
    combined.info,
  ]);

  useEffect(() => {
    const buttonTabs = [];

    buttonTabs.push(
      <Tabs
        headerText="Call"
        tabCount={3}
        tabContent={callTabs}
        tabNames={['Standard', 'Medium', 'Large']}
      />,
    );
    buttonTabs.push(
      <Tabs
        headerText="Text"
        tabCount={3}
        tabContent={textTabs}
        tabNames={['Standard', 'Medium', 'Large']}
      />,
    );
    buttonTabs.push(
      <Tabs
        headerText="Email"
        tabCount={3}
        tabContent={emailTabs}
        tabNames={['Standard', 'Medium', 'Large']}
      />,
    );
    buttonTabs.push(
      <Tabs
        headerText="About"
        tabCount={3}
        tabContent={aboutTabs}
        tabNames={['Standard', 'Medium', 'Large']}
      />,
    );
    buttonTabs.push(
      <Tabs
        headerText="Download"
        tabCount={3}
        tabContent={downloadTabs}
        tabNames={['Standard', 'Medium', 'Large']}
      />,
    );
    buttonTabs.push(
      <Tabs
        headerText="Send"
        tabCount={3}
        tabContent={sendTabs}
        tabNames={['Standard', 'Medium', 'Large']}
      />,
    );

    setAllButtonTabs(buttonTabs);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callTabs, textTabs, emailTabs, aboutMeTabs, downloadTabs, sendTabs]);

  useEffect(() => {
    setJsonPreview({
      ...jsonPreview,
      ...generalInfo.data,
      home: {
        ...jsonPreview.home,
        ...visibilityInfo.homeVisibility,
      },
      about: {
        ...jsonPreview.about,
        ...visibilityInfo.aboutVisibility,
      },
      components: componentsArray,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    generalInfo.data,
    visibilityInfo.homeVisibility,
    visibilityInfo.aboutVisibility,
    componentsArray,
  ]);

  useEffect(() => {
    const getTheme = async () => {
      setIsLoading(true);
      try {
        const response = await getDataForMBCTheme(params.uuid);

        setThemeInfo(parseThemeInfo(response.data));
      } catch (err) {
        store.pushNotification(
          'Error',
          'Could not load theme data at the moment. Please try again later.',
          NotificationTypes.DANGER,
        );
        history.push('/admin/themes');
      }
      setIsLoading(false);
    };

    if (params.uuid) {
      getTheme();
    }

    setIsLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const submitTheme = async () => {
    if (generalInfo.data.name === '') return;

    setIsLoading(true);

    try {
      if (location.pathname.includes('edit')) {
        await updateMBCTheme(
          params.uuid,
          generalInfo.data.name,
          generalInfo.data.isEnabled,
          jsonPreview,
        );
      } else {
        await createMBCTheme(
          generalInfo.data.name,
          generalInfo.data.isEnabled,
          jsonPreview,
        );
      }

      store.pushNotification(
        'Success',
        'Data saved successfully.',
        NotificationTypes.SUCCESS,
      );
      history.push('/admin/themes');
    } catch (err) {
      store.pushNotification(
        err.message ?? 'Error',
        prepareErrorMessage(err.errors) ??
          'Could not save theme data. Please try again later.',
        NotificationTypes.DANGER,
      );
    }

    setIsLoading(false);
  };

  return (
    <section className="themes-form__wrapper">
      <form className="themes-form">
        <main className="themes-form__tabs">
          {generalInfo.inputs.map((inp) => (
            <FormControl {...inp} key={inp.name} />
          ))}
          <Tabs
            headerText="VISIBILITY"
            tabCount={2}
            tabContent={visibilityTabs}
            tabNames={['Home', 'About']}
          />
          <Tabs
            headerText="Main Container"
            tabCount={3}
            tabContent={mainContainerTabs}
            tabNames={['Standard', 'Medium', 'Large']}
          />
          <Tabs
            tabRef={headerRef}
            headerText="Header"
            tabCount={3}
            tabContent={headerTabs}
            tabNames={['Standard', 'Medium', 'Large']}
          />
          <Tabs
            tabRef={footerRef}
            headerText="Footer"
            tabCount={3}
            tabContent={footerTabs}
            tabNames={['Standard', 'Medium', 'Large']}
          />
          <Tabs
            tabRef={socialsRef}
            headerText="Socials"
            tabCount={3}
            tabContent={socialsTabs}
            tabNames={['Standard', 'Medium', 'Large']}
          />
          <Tabs
            tabRef={infoRef}
            headerText="User Info"
            tabCount={3}
            tabContent={userTabs}
            tabNames={['Standard', 'Medium', 'Large']}
          />
          <Tabs
            tabRef={photoRef}
            headerText="User Photo"
            tabCount={3}
            tabContent={userPhotoTabs}
            tabNames={['Standard', 'Medium', 'Large']}
          />
          <Tabs
            tabRef={addressRef}
            headerText="User Address"
            tabCount={3}
            tabContent={userAddressTabs}
            tabNames={['Standard', 'Medium', 'Large']}
          />
          <Tabs
            tabRef={buttonsRef}
            headerText="Button Group"
            tabCount={3}
            tabContent={buttonGroupTabs}
            tabNames={['Standard', 'Medium', 'Large']}
          />
          <Tabs
            headerText="Buttons"
            tabCount={6}
            tabContent={allButtonTabs}
            tabNames={['Call', 'Text', 'Email', 'About', 'Download', 'Send']}
          />
          <Tabs
            tabRef={aboutRef}
            headerText="About Me"
            tabCount={3}
            tabContent={aboutMeTabs}
            tabNames={['Standard', 'Medium', 'Large']}
          />
          <Tabs
            tabRef={modalRef}
            headerText="Modal"
            tabCount={3}
            tabContent={modalTabs}
            tabNames={['Standard', 'Medium', 'Large']}
          />
          <Tabs
            tabRef={combinedRef}
            headerText="Combined Info"
            tabCount={1}
            tabContent={combinedTabs}
            tabNames={['Standard']}
          />
        </main>
        <aside className="themes-form__preview">
          <ThemesFormPreview
            themeObject={jsonPreview}
            onHeaderClick={() => headerRef?.current?.scrollIntoView()}
            onFooterClick={() => footerRef?.current?.scrollIntoView()}
            onPhotoClick={() => photoRef?.current?.scrollIntoView()}
            onInfoClick={() => infoRef?.current?.scrollIntoView()}
            onSocialsClick={() => socialsRef?.current?.scrollIntoView()}
            onAddressClick={() => addressRef?.current?.scrollIntoView()}
            onButtonsClick={() => buttonsRef?.current?.scrollIntoView()}
            onCombinedInfoClick={() => combinedRef?.current?.scrollIntoView()}
            onModalClick={() => modalRef?.current?.scrollIntoView()}
            onAboutClick={() => aboutRef?.current?.scrollIntoView()}
          />
        </aside>
        <div className="themes-form__buttons">
          <Button
            variant="secondary"
            text="CANCEL"
            onClick={() => history.goBack()}
          />
          <Button
            variant="primary"
            text="SAVE"
            onClick={() => submitTheme()}
            disabled={isLoading}
            state={isLoading ? 'loading' : ''}
          />
        </div>
        <Loader isLoading={isLoading} />
      </form>
    </section>
  );
};

export default ThemesForm;
