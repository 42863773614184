import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import Header from './header';
import GlobalStyle from '../globalStyles';
import Footer from './footer';
import { MainContainer } from './sharedStyles';

const Layout = ({
  children,
  fontFamily,
  fontUrl,
  headerProps,
  footerProps,
  FullName,
  CompanyName,
  Title,
  ...rest
}) => {
  const [helmet, setHelmet] = useState(null);
  const [title, setTitle] = useState('');

  useEffect(() => {
    if (FullName && CompanyName) {
      setTitle(`${FullName} | ${CompanyName}`);
    }
  }, [FullName, CompanyName]);

  useEffect(() => {
    setHelmet(
      <Helmet>
        <title>{title}</title>
        <meta name="viewport" content="initial-scale=1.0, width=device-width" />
        <meta
          name="description"
          content={`Mobile Business Card for ${FullName}, ${Title} at ${CompanyName}.`}
        />
        <meta
          name="keywords"
          content={`Mobile Business Card,${FullName},${Title},${CompanyName}`}
        />
        <link rel="stylesheet" type="text/css" href={fontUrl} />
      </Helmet>,
    );
  }, [title, fontUrl]);

  return (
    <MainContainer {...rest}>
      {helmet}
      <GlobalStyle fontfamily={fontFamily} />
      <Header {...headerProps} />
      {children}
      <Footer {...footerProps} />
    </MainContainer>
  );
};

Layout.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.element,
    PropTypes.node,
  ]),
  fontFamily: PropTypes.string,
  fontUrl: PropTypes.string,
  headerProps: PropTypes.object,
  footerProps: PropTypes.object,
  FullName: PropTypes.string,
  CompanyName: PropTypes.string,
  Title: PropTypes.string,
};

export default Layout;
