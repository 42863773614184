import React from 'react';
import PropTypes from 'prop-types';
import './ThemeTab.scss';

const ThemeTab = ({ text, onClick, isActive }) => (
  <button
    type="button"
    className={`theme-tab ${isActive ? 'theme-tab--active' : ''}`}
    onClick={onClick}>
    {text}
  </button>
);

ThemeTab.propTypes = {
  text: PropTypes.string,
  onClick: PropTypes.func,
  isActive: PropTypes.bool,
};

export default ThemeTab;
