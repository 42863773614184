import React from 'react';
import PropTypes from 'prop-types';
import './Button.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

const Button = ({
  text,
  onClick,
  variant,
  type = 'button',
  state,
  disabled = false,
}) => {
  const getClassName = () => {
    switch (variant) {
      case 'primary':
      default:
        return 'button--primary';
      case 'secondary':
        return 'button--secondary';
    }
  };

  return (
    <button
      disabled={disabled}
      // eslint-disable-next-line react/button-has-type
      type={type}
      className={`button ${getClassName()} ${
        state === 'loading' ? 'button--loading' : ''
      }`}
      onClick={onClick}>
      {state === 'loading' ? (
        <FontAwesomeIcon icon={faSpinner} className="button--loading__icon" />
      ) : null}
      {text}
    </button>
  );
};

Button.propTypes = {
  text: PropTypes.string,
  onClick: PropTypes.func,
  variant: PropTypes.string,
  type: PropTypes.string,
  state: PropTypes.string,
  disabled: PropTypes.bool,
};

export default Button;
