/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react';
import './SortButtons.scss';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSortDown, faSortUp } from '@fortawesome/free-solid-svg-icons';

const SortButtons = ({ onUpClick, onDownClick, upActive, downActive, dark, vCenter }) => {
  const [clickStatus, setClickStatus] = useState(false);

  return (
    <div
      className={`sort-buttons ${vCenter ? 'sort-buttons--v-center' : ''}`}
      onClick={() => {
        if (clickStatus) {
          onDownClick();
        } else {
          onUpClick();
        }

        setClickStatus(!clickStatus);
      }}>
      <FontAwesomeIcon
        icon={faSortUp}
        className={`sort-buttons__btn ${dark ? 'sort-buttons__btn--dark' : ''} ${
          upActive ? 'sort-buttons__btn--active' : ''
        }`}
      />
      <FontAwesomeIcon
        icon={faSortDown}
        className={`sort-buttons__btn ${dark ? 'sort-buttons__btn--dark' : ''} ${
          downActive ? 'sort-buttons__btn--active' : ''
        }`}
      />
    </div>
  );
};

SortButtons.propTypes = {
  onUpClick: PropTypes.func,
  onDownClick: PropTypes.func,
  upActive: PropTypes.bool,
  downActive: PropTypes.bool,
  dark: PropTypes.bool,
  vCenter: PropTypes.bool,
};

export default SortButtons;
