import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import './PreviewModal.scss';
import Button from '../../Button/Button';
import PreviewContainer from '../../MBC/PreviewContainer/PreviewContainer';
import { getCurrentUser } from '../../../utils/mbcApiCalls';
import { remapUserProperties } from '../../MBC/helper';

const PreviewModal = ({
  previewUser,
  themeSettings,
  userId,
  close,
  visible,
}) => {
  const [user, setUser] = useState(previewUser);
  const [currentPage, setCurrentPage] = useState('home');

  useEffect(() => {
    const getUser = async () => {
      let chosenUser = await getCurrentUser(userId, true);
      chosenUser = remapUserProperties(chosenUser.data);

      setUser(chosenUser);
    };

    if (!previewUser && userId) {
      getUser();
    }
  }, [userId]);

  useEffect(() => {
    setUser(previewUser);
  }, [previewUser]);

  if (!userId || !visible) {
    return null;
  }

  return (
    <div className="preview-modal__background">
      <div className="preview-modal">
        <div className="preview-modal__button-wrapper">
          <Button variant="primary" text="CLOSE" onClick={() => close(false)} />
        </div>
        <PreviewContainer
          user={user}
          theme={themeSettings}
          currentPage={currentPage}
          onAboutClick={() => setCurrentPage('about')}
          onBackButtonClick={() => setCurrentPage('home')}
        />
      </div>
    </div>
  );
};

PreviewModal.propTypes = {
  previewUser: PropTypes.object,
  themeSettings: PropTypes.object,
  userId: PropTypes.string,
  visible: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
};

export default PreviewModal;
