import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { breakpoints } from '../cssVariables';
import { getDefaultStylesForComponent } from '../defaultStyles';

const ButtonGroupDefaults = getDefaultStylesForComponent('ButtonGroup');

export const ButtonContainer = styled.div`
  display: ${(props) => (props.isVisible ? 'flex' : 'none')};
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;
  order: ${(props) => props.order || ButtonGroupDefaults.order};

  width: 100%;
  flex: 0 1 100%;
  height: ${(props) => props.height || ButtonGroupDefaults.height};
  padding: ${(props) => props.padding || ButtonGroupDefaults.padding};

  background-color: ${(props) =>
    props.backgroundColor || ButtonGroupDefaults.backgroundColor};

  @media (min-width: ${breakpoints.medium}) {
    order: ${(props) => props.medium.order || ButtonGroupDefaults.medium.order};

    padding: ${(props) =>
      props.medium.padding || ButtonGroupDefaults.medium.padding};
  }

  @media (min-width: ${breakpoints.large}) {
    order: ${(props) => props.large.order || ButtonGroupDefaults.large.padding};

    padding: ${(props) =>
      props.large.padding || ButtonGroupDefaults.large.order};
  }
`;

const ButtonDefaults = getDefaultStylesForComponent('Button');

export const StyledButton = styled.a`
  display: ${(props) => (props.isVisible ? 'flex' : 'none')};
  flex-flow: ${(props) => props.flexFlow || ButtonDefaults.flexFlow};
  justify-content: space-evenly;
  align-items: center;
  order: ${(props) => props.order || ButtonDefaults.order};

  flex: 0 0
    ${(props) =>
      props.shape === 'circle'
        ? `${parseFloat(props.width || ButtonDefaults.width) - 1}%`
        : props.width || ButtonDefaults.width};
  width: ${(props) => props.width || ButtonDefaults.width};
  height: ${(props) =>
    props.shape === 'circle'
      ? `${Math.floor(
          ((parseFloat(props.width || ButtonDefaults.width) - 1) / 100) *
            props.parentWidth,
        )}px`
      : 'auto'};
  padding: ${(props) => props.padding || ButtonDefaults.padding};
  margin: ${(props) => props.margin || ButtonDefaults.margin};

  background-color: ${(props) =>
    props.backgroundColor || ButtonDefaults.backgroundColor};

  color: ${(props) => props.color || ButtonDefaults.color};
  text-decoration: none;

  border-radius: ${(props) => (props.shape === 'circle' ? '100%' : '0')};

  cursor: pointer;

  & img,
  & div {
    display: ${(props) => (props.icon.isVisible ? 'block' : 'none')};

    width: ${(props) =>
      (props.icon.size === 'small' && '17.5%') ||
      (props.icon.size === 'medium' && '35%') ||
      (props.icon.size === 'large' && '70%') ||
      props.icon.size ||
      '60%'};

    background-color: ${(props) =>
      props.icon.backgroundColor || ButtonDefaults.icon.backgroundColor};

    border-radius: ${(props) => (props.icon.shape === 'circle' ? '100%' : '0')};
  }

  & p {
    display: ${(props) => (props.text.isVisible ? 'block' : 'none')};
    width: ${(props) => props.text.width || ButtonDefaults.text.width};
    margin: ${(props) => props.text.margin || ButtonDefaults.text.margin};

    font-size: ${(props) =>
      props.text.fontSize || ButtonDefaults.text.fontSize};
    font-weight: ${(props) =>
      props.text.fontWeight || ButtonDefaults.text.fontWeight};
    text-align: ${(props) =>
      props.text.textAlign || ButtonDefaults.text.textAlign};
  }

  & div {
    display: flex;
    justify-content: center;
    align-items: center;

    height: 100%;
    width: 100%;

    & svg {
      height: 80%;
      width: auto;

      & g {
        fill: ${(props) => props.icon.fill || 'black'};
      }
    }
  }

  @media (min-width: ${breakpoints.medium}) {
    order: ${(props) => props.medium.order || ButtonDefaults.medium.order};
    flex-flow: ${(props) =>
      props.medium.flexFlow || ButtonDefaults.medium.flexFlow};

    padding: ${(props) =>
      props.medium.padding || ButtonDefaults.medium.padding};
    margin: ${(props) => props.medium.margin || ButtonDefaults.medium.margin};

    & p {
      width: ${(props) =>
        props.medium.text.width || ButtonDefaults.medium.text.width};
      margin: ${(props) =>
        props.medium.text.margin || ButtonDefaults.medium.text.margin};

      font-size: ${(props) =>
        props.medium.text.fontSize || ButtonDefaults.medium.text.fontSize};
      font-weight: ${(props) =>
        props.medium.text.fontWeight || ButtonDefaults.medium.text.fontWeight};
      text-align: ${(props) =>
        props.medium.text.textAlign || ButtonDefaults.medium.text.textAlign};
    }

    & img,
    & div {
      width: ${(props) =>
        (props.medium.icon.size === 'small' && '15%') ||
        (props.medium.icon.size === 'medium' && '35%') ||
        (props.medium.icon.size === 'large' && '75%') ||
        props.medium.icon.size ||
        '60%'};
    }
  }

  @media (min-width: ${breakpoints.large}), (orientation: landscape) {
    order: ${(props) => props.large.order || ButtonDefaults.large.order};
    flex-flow: ${(props) =>
      props.large.flexFlow || ButtonDefaults.large.flexFlow};

    padding: ${(props) => props.large.padding || ButtonDefaults.large.padding};
    margin: ${(props) => props.large.margin || ButtonDefaults.large.margin};

    & p {
      width: ${(props) =>
        props.large.text.width || ButtonDefaults.large.text.width};
      margin: ${(props) =>
        props.large.text.margin || ButtonDefaults.large.text.margin};

      font-size: ${(props) =>
        props.large.text.fontSize || ButtonDefaults.large.text.fontSize};
      font-weight: ${(props) =>
        props.large.text.fontWeight || ButtonDefaults.large.text.fontWeight};
      text-align: ${(props) =>
        props.large.text.textAlign || ButtonDefaults.large.text.textAlign};
    }

    & img,
    & div {
      width: ${(props) =>
        (props.large.icon.size === 'small' && '15%') ||
        (props.large.icon.size === 'medium' && '35%') ||
        (props.large.icon.size === 'large' && '65%') ||
        props.large.icon.size ||
        '55%'};
    }
  }
`;

export const StyledLink = styled(
  ({ isVisible, flexFlow, backgroundColor, parentWidth, ...rest }) => (
    <Link {...rest} />
  ),
)`
  display: ${(props) => (props.isVisible ? 'flex' : 'none')};
  flex-flow: ${(props) => props.flexFlow || ButtonDefaults.flexFlow};
  justify-content: space-evenly;
  align-items: center;
  order: ${(props) => props.order || ButtonDefaults.order};

  flex: 0 0
    ${(props) =>
      props.shape === 'circle'
        ? `${parseFloat(props.width || ButtonDefaults.width) - 1}%`
        : props.width || ButtonDefaults.width};
  width: ${(props) => props.width || ButtonDefaults.width};
  height: ${(props) =>
    props.shape === 'circle'
      ? `${Math.floor(
          ((parseFloat(props.width || ButtonDefaults.width) - 1) / 100) *
            props.parentWidth,
        )}px`
      : 'auto'};
  padding: ${(props) => props.padding || ButtonDefaults.padding};
  margin: ${(props) => props.margin || ButtonDefaults.margin};

  background-color: ${(props) =>
    props.backgroundColor || ButtonDefaults.backgroundColor};

  color: ${(props) => props.color || ButtonDefaults.color};
  text-decoration: none;

  border-radius: ${(props) => (props.shape === 'circle' ? '100%' : '0')};

  cursor: pointer;

  & img,
  & div {
    display: ${(props) => (props.icon.isVisible ? 'block' : 'none')};

    width: ${(props) =>
      (props.icon.size === 'small' && '17.5%') ||
      (props.icon.size === 'medium' && '35%') ||
      (props.icon.size === 'large' && '70%') ||
      props.icon.size ||
      '60%'};

    background-color: ${(props) =>
      props.icon.backgroundColor || ButtonDefaults.icon.backgroundColor};

    border-radius: ${(props) => (props.icon.shape === 'circle' ? '100%' : '0')};
  }

  & p {
    display: ${(props) => (props.text.isVisible ? 'block' : 'none')};
    width: ${(props) => props.text.width || ButtonDefaults.text.width};
    margin: ${(props) => props.text.margin || ButtonDefaults.text.margin};

    font-size: ${(props) =>
      props.text.fontSize || ButtonDefaults.text.fontSize};
    font-weight: ${(props) =>
      props.text.fontWeight || ButtonDefaults.text.fontWeight};
    text-align: ${(props) =>
      props.text.textAlign || ButtonDefaults.text.textAlign};
  }

  & div {
    display: flex;
    justify-content: center;
    align-items: center;

    height: 100%;
    width: 100%;

    & svg {
      height: 80%;
      width: auto;

      & g {
        fill: ${(props) => props.icon.fill || 'black'};
      }
    }
  }

  @media (min-width: ${breakpoints.medium}) {
    order: ${(props) => props.medium.order || ButtonDefaults.medium.order};
    flex-flow: ${(props) =>
      props.medium.flexFlow || ButtonDefaults.medium.flexFlow};

    padding: ${(props) =>
      props.medium.padding || ButtonDefaults.medium.padding};
    margin: ${(props) => props.medium.margin || ButtonDefaults.medium.margin};

    & p {
      width: ${(props) =>
        props.medium.text.width || ButtonDefaults.medium.text.width};
      margin: ${(props) =>
        props.medium.text.margin || ButtonDefaults.medium.text.margin};

      font-size: ${(props) =>
        props.medium.text.fontSize || ButtonDefaults.medium.text.fontSize};
      font-weight: ${(props) =>
        props.medium.text.fontWeight || ButtonDefaults.medium.text.fontWeight};
      text-align: ${(props) =>
        props.medium.text.textAlign || ButtonDefaults.medium.text.textAlign};
    }

    & img,
    & div {
      width: ${(props) =>
        (props.medium.icon.size === 'small' && '15%') ||
        (props.medium.icon.size === 'medium' && '35%') ||
        (props.medium.icon.size === 'large' && '75%') ||
        props.medium.icon.size ||
        '60%'};
    }
  }

  @media (min-width: ${breakpoints.large}), (orientation: landscape) {
    order: ${(props) => props.large.order || ButtonDefaults.large.order};
    flex-flow: ${(props) =>
      props.large.flexFlow || ButtonDefaults.large.flexFlow};

    padding: ${(props) => props.large.padding || ButtonDefaults.large.padding};
    margin: ${(props) => props.large.margin || ButtonDefaults.large.margin};

    & p {
      width: ${(props) =>
        props.large.text.width || ButtonDefaults.large.text.width};
      margin: ${(props) =>
        props.large.text.margin || ButtonDefaults.large.text.margin};

      font-size: ${(props) =>
        props.large.text.fontSize || ButtonDefaults.large.text.fontSize};
      font-weight: ${(props) =>
        props.large.text.fontWeight || ButtonDefaults.large.text.fontWeight};
      text-align: ${(props) =>
        props.large.text.textAlign || ButtonDefaults.large.text.textAlign};
    }

    & img,
    & div {
      width: ${(props) =>
        (props.large.icon.size === 'small' && '15%') ||
        (props.large.icon.size === 'medium' && '35%') ||
        (props.large.icon.size === 'large' && '65%') ||
        props.large.icon.size ||
        '55%'};
    }
  }
`;
