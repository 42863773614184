export const emptyAccountState = {
  company: {
    firstName: '',
    lastName: '',
    company: '',
    phone: '',
    street: '',
    apt: '',
    city: '',
    state: '',
    country: '',
    zipCode: '',
    email: '',
    facebookUrl: '',
    twitterUrl: '',
    instagramUrl: '',
    linkedinUrl: '',
  },
  billing: {
    firstName: '',
    lastName: '',
    company: '',
    phone: '',
    street: '',
    apt: '',
    city: '',
    state: '',
    country: '',
    zipCode: '',
    email: '',
  },
};

export const mapResponseToAccountState = (data) => ({
  company: {
    firstName: data.first_name ?? '',
    lastName: data.last_name ?? '',
    company: data.company ?? '',
    phone: data.phone ?? '',
    street: data.address ?? '',
    apt: data.apt ?? '',
    city: data.city ?? '',
    state: data.state ?? '',
    country: data.country ?? '',
    zipCode: data.zip_code ?? '',
    email: data.email ?? '',
    facebookUrl: data.facebook_url ?? '',
    twitterUrl: data.twitter_url ?? '',
    instagramUrl: data.instagram_url ?? '',
    linkedinUrl: data.linkedin_url ?? '',
    uuid: data.uuid ?? '',
  },
  billing: {
    firstName: data.billing_first_name ?? '',
    lastName: data.billing_last_name ?? '',
    phone: data.billing_phone ?? '',
    street: data.billing_address ?? '',
    apt: data.billing_apt ?? '',
    city: data.billing_city ?? '',
    state: data.billing_state ?? '',
    country: data.billing_country ?? '',
    zipCode: data.billing_zip_code ?? '',
    email: data.billing_email ?? '',
  },
});

export const addBillingPrefixToState = (billingState) => {
  const newState = {};

  Object.keys(billingState).forEach((key) => {
    const newKey = `billing${key.charAt(0).toUpperCase()}${key.slice(1)}`;
    newState[newKey] = billingState[key];
  });

  newState.billingAddress = newState.billingStreet;
  delete newState.billingStreet;

  return newState;
};

export const mapBillingStateToRequest = (billingState) => ({
  billing_first_name: billingState.firstName,
  billing_last_name: billingState.lastName,
  billing_phone: billingState.phone,
  billing_address: billingState.street,
  billing_apt: billingState.apt,
  billing_city: billingState.city,
  billing_state: billingState.state,
  billing_country: billingState.country,
  billing_zip_code: billingState.zipCode,
  billing_email: billingState.email,
});

export const accountContactFormSettings = {
  firstName: {
    visibility: true,
    order: 1,
    width: '33%',
    required: true,
  },
  lastName: { visibility: true, order: 2, width: '33%', required: true },
  phone: {
    visibility: true,
    order: 3,
    width: '25%',
    minLength: 10,
    required: true,
  },
  company: { visibility: true, order: 4, width: '50%', required: true },
  email: { visibility: true, order: 5, width: '50%', required: true },
  street: { visibility: true, order: 6, width: '50%', required: true },
  apt: { visibility: true, order: 7, width: '10%', required: true },
  city: { visibility: true, order: 8, width: '33%', required: true },
  state: { visibility: true, order: 9, width: '15%', required: true },
  country: { visibility: true, order: 9, width: '15%', required: true },
  zipCode: { visibility: true, order: 10, width: '15%', required: true },

  title: { visibility: false },
  myWebsiteUrl: { visibility: false },
};

export const accountBillingAddressSettings = (hasSeparateBillingAddress) => ({
  firstName: {
    visibility: true,
    order: 11,
    width: '33%',
    required: hasSeparateBillingAddress,
  },
  lastName: {
    visibility: true,
    order: 12,
    width: '33%',
    required: hasSeparateBillingAddress,
  },
  phone: {
    visibility: true,
    order: 13,
    width: '25%',
    minLength: 10,
    required: hasSeparateBillingAddress,
  },
  email: {
    visibility: true,
    order: 14,
    width: '33%',
    required: hasSeparateBillingAddress,
  },
  street: {
    visibility: true,
    order: 15,
    width: '66%',
    required: hasSeparateBillingAddress,
  },
  apt: {
    visibility: true,
    order: 16,
    width: '15%',
    required: hasSeparateBillingAddress,
  },
  city: {
    visibility: true,
    order: 17,
    width: '50%',
    required: hasSeparateBillingAddress,
  },
  state: {
    visibility: true,
    order: 18,
    width: '25%',
    required: hasSeparateBillingAddress,
  },
  country: {
    visibility: true,
    order: 19,
    width: '15%',
    required: hasSeparateBillingAddress,
  },
  zipCode: {
    visibility: true,
    order: 20,
    width: '15%',
    required: hasSeparateBillingAddress,
  },

  title: { visibility: false },
  company: { visibility: false },
  myWebsiteUrl: { visibility: false },
});
