import React, { useEffect, useState } from 'react';
import './SubNavigation.scss';
import { faUserShield, faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useLocation } from 'react-router-dom';
import SidebarLink from '../SidebarLink/SidebarLink';
import { adminLinks } from '../../../data/Sidebar';

const SubNavigation = () => {
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    const isMenuAdminActive = location.pathname.includes('admin') || location.pathname === '/account';
    setIsOpen(isMenuAdminActive);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  return (
    <section
      className={`sub-navigation ${
        isOpen ? 'sub-navigation--active' : ''
      }`}>
      <SidebarLink
        text="Admin"
        icon={faUserShield}
        href="#"
        onClick={() => setIsOpen(!isOpen)}
      />
      <span
        className={`sub-navigation__icon-wrapper ${
          isOpen ? 'sub-navigation__icon-wrapper--open' : ''
        }`}>
        <FontAwesomeIcon
          icon={faChevronDown}
          className="sub-navigation__icon"
        />
      </span>
      <ul
        className={`sub-navigation__nav ${
          isOpen ? 'sub-navigation__nav--open' : ''
        }`}>
        {adminLinks.map((link) => (
          <SidebarLink {...link} key={link.href} />
        ))}
      </ul>
    </section>
  );
};

export default SubNavigation;
