/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import './ThemePreview.scss';
import store, { NotificationTypes } from '../../hooks/NotificationHub';
import PreviewContainer from '../MBC/PreviewContainer/PreviewContainer';
import { remapUserProperties } from '../MBC/helper';
import { getCurrentUser } from '../../utils/mbcApiCalls';
import { useAuth } from '../../hooks/Auth';

const ThemePreview = ({
  previewUser,
  themeSettings,
  onPhotoClick,
  previewRefresh,
}) => {
  const auth = useAuth();
  const [user, setUser] = useState(previewUser);

  useEffect(() => {
    const getUser = async () => {
      try {
        const response = await getCurrentUser(auth.data.user.uuid, true);

        setUser(remapUserProperties(response.data));
      } catch (err) {
        store.pushNotification(
          'Error',
          'Could not load user data for preview. Please try again later.',
          NotificationTypes.DANGER,
        );
      }
    };

    if (!previewUser) {
      getUser();
    }
  }, []);

  useEffect(() => {
    setUser(previewUser);
  }, [previewUser]);

  return (
    <div className="theme-preview__wrapper">
      <img
        src="/images/iphone.png"
        alt="Iphone background"
        className="theme-preview__background"
      />
      <div className="theme-preview">
        <PreviewContainer
          key={previewRefresh}
          theme={themeSettings}
          user={user}
          previewRefresh={previewRefresh}
          onPhotoClick={onPhotoClick}
        />
      </div>
    </div>
  );
};

ThemePreview.propTypes = {
  previewUser: PropTypes.object,
  themeSettings: PropTypes.object,
  onPhotoClick: PropTypes.func,
  previewRefresh: PropTypes.number,
};

export default ThemePreview;
