import styled from 'styled-components';
import { breakpoints } from '../cssVariables';
import { getDefaultStylesForComponent } from '../defaultStyles';

const UserPhotoDefaults = getDefaultStylesForComponent('UserPhoto');

export const ImageContainer = styled.div`
  display: ${(props) => (!props.isVisible && 'none') || 'flex'};
  justify-content: ${(props) =>
    props.justifyContent || UserPhotoDefaults.justifyContent};
  align-items: center;
  flex-flow: row nowrap;
  order: ${(props) => props.order || UserPhotoDefaults.order};

  width: ${(props) => props.width || UserPhotoDefaults.width};
  min-width: ${(props) => props.width || UserPhotoDefaults.width};
  height: ${(props) => props.height || UserPhotoDefaults.height};
  flex: 0 0 ${(props) => props.width || UserPhotoDefaults.width};
  padding: ${(props) => props.padding || UserPhotoDefaults.padding};

  background-image: ${(props) => props.backgroundImage || ''};
  background-repeat: repeat;
  background-color: ${(props) =>
    props.backgroundColor || UserPhotoDefaults.backgroundColor};

  border-width: ${(props) =>
    props.borderWidth || UserPhotoDefaults.borderWidth};
  border-style: ${(props) =>
    props.borderStyle || UserPhotoDefaults.borderStyle};
  border-color: ${(props) =>
    props.borderColor || UserPhotoDefaults.borderColor};

  & img {
    pointer-events: ${(props) => (props.isOnClickPresent ? 'all' : 'none')};
    cursor: ${(props) => props.isOnClickPresent && 'pointer'};

    &:first-of-type {
      width: ${(props) =>
        (props.image.size === 'small' && '35%') ||
        (props.image.size === 'medium' && '55%') ||
        (props.image.size === 'large' && '85%') ||
        props.image.size ||
        '80%'};
      max-width: ${(props) =>
        props.image.maxWidth || UserPhotoDefaults.image.maxWidth};
      height: auto;
      border-radius: ${(props) =>
        props.image.shape === 'circle' ? '100%' : '0'};

      border-width: ${(props) =>
        props.image.borderWidth || UserPhotoDefaults.image.borderWidth};
      border-style: ${(props) =>
        props.image.borderStyle || UserPhotoDefaults.image.borderStyle};
      border-color: ${(props) =>
        props.image.borderColor || UserPhotoDefaults.image.borderWidth};

      box-shadow: ${(props) => props.image.boxShadow};
    }

    &:nth-of-type(2) {
      position: absolute;
      width: ${(props) => props.width || UserPhotoDefaults.width};
      max-width: calc(
        ${(props) => props.image.maxWidth || UserPhotoDefaults.image.maxWidth} -
          40px
      );
    }
  }

  @media (min-width: ${breakpoints.medium}) {
    order: ${(props) => props.medium.order || UserPhotoDefaults.medium.order};
    justify-content: ${(props) =>
      props.medium.justifyContent || UserPhotoDefaults.medium.justifyContent};

    padding: ${(props) =>
      props.medium.padding || UserPhotoDefaults.medium.padding};
    width: ${(props) => props.medium.width || UserPhotoDefaults.medium.width};

    & img {
      &:first-of-type {
        width: ${(props) =>
          (props.medium.image.size === 'small' && '36%') ||
          (props.medium.image.size === 'medium' && '66%') ||
          (props.medium.image.size === 'large' && '96%') ||
          props.medium.image.size ||
          '90%'};
        max-width: ${(props) =>
          props.medium.image.maxWidth ||
          UserPhotoDefaults.medium.image.maxWidth};

        border-radius: ${(props) =>
          props.medium.image.shape === 'circle' ? '100%' : '0'};
      }
    }
  }

  @media (min-width: ${breakpoints.large}), (orientation: landscape) {
    order: ${(props) => props.large.order || UserPhotoDefaults.large.order};
    justify-content: ${(props) =>
      props.large.justifyContent || UserPhotoDefaults.large.justifyContent};

    padding: ${(props) =>
      props.large.padding || UserPhotoDefaults.large.padding};
    width: ${(props) => props.large.width || UserPhotoDefaults.large.width};

    & img {
      &:first-of-type {
        width: ${(props) =>
          (props.large.image.size === 'small' && '36%') ||
          (props.large.image.size === 'medium' && '66%') ||
          (props.large.image.size === 'large' && '96%') ||
          props.large.image.size ||
          '90%'};
        max-width: ${(props) =>
          props.large.image.maxWidth || UserPhotoDefaults.large.image.maxWidth};

        border-radius: ${(props) =>
          props.large.image.shape === 'circle' ? '100%' : '0'};
      }
    }
  }
`;

const UserInfoDefaults = getDefaultStylesForComponent('UserInfo');

export const UserSection = styled.div`
  display: ${(props) => (!props.isVisible && 'none') || 'flex'};
  flex-flow: column nowrap;
  justify-content: center;
  order: ${(props) => props.order || UserInfoDefaults.order};

  width: ${(props) => props.width || UserInfoDefaults.width};
  min-width: ${(props) => props.width || UserInfoDefaults.width};
  flex: 0 1 ${(props) => props.width || UserInfoDefaults.width};
  height: ${(props) => props.heigh || UserInfoDefaults.height};
  padding: ${(props) => props.padding || UserInfoDefaults.padding};

  background-color: ${(props) =>
    props.backgroundColor || UserInfoDefaults.backgroundColor};

  & * {
    width: 100%;
  }

  & h1 {
    margin: 0;
    padding: ${(props) =>
      props.title.padding || UserInfoDefaults.title.padding};

    font-size: ${(props) =>
      props.title.fontSize || UserInfoDefaults.title.fontSize};
    line-height: ${(props) =>
      props.title.lineHeight || UserInfoDefaults.title.lineHeight};
    font-weight: ${(props) =>
      props.title.fontWeight || UserInfoDefaults.title.fontWeight};
    text-align: ${(props) =>
      props.title.textAlign || UserInfoDefaults.title.textAlign};
  }

  & span {
    margin: 0;
    padding: ${(props) =>
      props.subtitle.padding || UserInfoDefaults.subtitle.padding};

    font-size: ${(props) =>
      props.subtitle.fontSize || UserInfoDefaults.subtitle.fontSize};
    line-height: ${(props) =>
      props.subtitle.lineHeight || UserInfoDefaults.subtitle.lineHeight};
    font-weight: ${(props) =>
      props.subtitle.fontWeight || UserInfoDefaults.subtitle.fontWeight};
    text-align: ${(props) =>
      props.subtitle.textAlign || UserInfoDefaults.subtitle.textAlign};
  }

  @media (min-width: ${breakpoints.medium}) {
    order: ${(props) => props.medium.order || UserInfoDefaults.medium.order};

    width: ${(props) => props.medium.width || UserInfoDefaults.medium.width};
    padding: ${(props) =>
      props.medium.padding || UserInfoDefaults.medium.padding};

    & h1 {
      padding: ${(props) =>
        props.medium.title.padding || UserInfoDefaults.medium.title.padding};

      font-size: ${(props) =>
        props.medium.title.fontSize || UserInfoDefaults.medium.title.fontSize};
      line-height: ${(props) =>
        props.medium.title.lineHeight ||
        UserInfoDefaults.medium.title.lineHeight};
      font-weight: ${(props) =>
        props.medium.title.fontWeight ||
        UserInfoDefaults.medium.title.fontWeight};
      text-align: ${(props) =>
        props.medium.title.textAlign ||
        UserInfoDefaults.medium.title.textAlign};
    }

    & span {
      padding: ${(props) =>
        props.medium.subtitle.padding ||
        UserInfoDefaults.medium.subtitle.padding};

      font-size: ${(props) =>
        props.medium.subtitle.fontSize ||
        UserInfoDefaults.medium.subtitle.fontSize};
      line-height: ${(props) =>
        props.medium.subtitle.lineHeight ||
        UserInfoDefaults.medium.subtitle.lineHeight};
      font-weight: ${(props) =>
        props.medium.subtitle.fontWeight ||
        UserInfoDefaults.medium.subtitle.fontWeight};
      text-align: ${(props) =>
        props.medium.subtitle.textAlign ||
        UserInfoDefaults.medium.subtitle.textAlign};
    }
  }

  @media (min-width: ${breakpoints.large}), (orientation: landscape) {
    order: ${(props) => props.large.order || UserInfoDefaults.large.order};

    width: ${(props) => props.large.width || UserInfoDefaults.large.width};
    padding: ${(props) =>
      props.large.padding || UserInfoDefaults.large.padding};

    & h1 {
      padding: ${(props) =>
        props.large.title.padding || UserInfoDefaults.large.title.padding};

      font-size: ${(props) =>
        props.large.title.fontSize || UserInfoDefaults.large.title.fontSize};
      line-height: ${(props) =>
        props.large.title.lineHeight ||
        UserInfoDefaults.large.title.lineHeight};
      font-weight: ${(props) =>
        props.large.title.fontWeight ||
        UserInfoDefaults.large.title.fontWeight};
      text-align: ${(props) =>
        props.large.title.textAlign || UserInfoDefaults.large.title.textAlign};
    }

    & span {
      padding: ${(props) =>
        props.large.subtitle.padding ||
        UserInfoDefaults.large.subtitle.padding};

      font-size: ${(props) =>
        props.large.subtitle.fontSize ||
        UserInfoDefaults.large.subtitle.fontSize};
      line-height: ${(props) =>
        props.large.subtitle.lineHeight ||
        UserInfoDefaults.large.subtitle.lineHeight};
      font-weight: ${(props) =>
        props.large.subtitle.fontWeight ||
        UserInfoDefaults.large.subtitle.fontWeight};
      text-align: ${(props) =>
        props.large.subtitle.textAlign ||
        UserInfoDefaults.large.subtitle.textAlign};
    }
  }
`;

const UserAddressDefaults = getDefaultStylesForComponent('UserAddress');

export const Address = styled.p`
  display: ${(props) => (!props.isVisible && 'none') || 'block'};
  order: ${(props) => props.order || UserAddressDefaults.order};

  width: 100%;
  flex: 0 1 100%;
  margin: 0;
  height: ${(props) => props.height || UserAddressDefaults.height};
  padding: ${(props) => props.padding || UserAddressDefaults.padding};

  background-color: ${(props) =>
    props.backgroundColor || UserAddressDefaults.backgroundColor};

  text-align: ${(props) => props.textAlign || UserAddressDefaults.textAlign};
  font-size: ${(props) => props.fontSize || UserAddressDefaults.fontSize};
  font-weight: ${(props) => props.fontWeight || UserAddressDefaults.fontWeight};

  @media (min-width: ${breakpoints.medium}) {
    order: ${(props) => props.medium.order || UserAddressDefaults.medium.order};

    padding: ${(props) =>
      props.medium.padding || UserAddressDefaults.medium.padding};

    font-size: ${(props) =>
      props.medium.fontSize || UserAddressDefaults.medium.fontSize};
    text-align: ${(props) =>
      props.medium.textAlign || UserAddressDefaults.medium.textAlign};
    font-weight: ${(props) =>
      props.medium.fontWeight || UserAddressDefaults.medium.fontWeight};
  }

  @media (min-width: ${breakpoints.large}), (orientation: landscape) {
    order: ${(props) => props.large.order || UserAddressDefaults.large.order};

    padding: ${(props) =>
      props.large.padding || UserAddressDefaults.large.padding};

    font-size: ${(props) =>
      props.large.fontSize || UserAddressDefaults.large.fontSize};
    text-align: ${(props) =>
      props.large.textAlign || UserAddressDefaults.large.textAlign};
    font-weight: ${(props) =>
      props.large.fontWeight || UserAddressDefaults.large.fontWeight};
  }
`;

const CombinedInfoDefaults = getDefaultStylesForComponent('CombinedUserInfo');

export const CombinedInfo = styled.div`
  position: relative;
  display: ${(props) => (!props.isVisible && 'none') || 'flex'};
  flex-flow: ${(props) =>
    props.flexDirection || CombinedInfoDefaults.flexDirection} wrap;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  order: ${(props) => props.order || CombinedInfoDefaults.order};

  flex: 0 0 100%;
  min-height: ${(props) => props.height || CombinedInfoDefaults.height};
  height: ${(props) => props.height || CombinedInfoDefaults.height};

  background-color: ${(props) =>
    props.backgroundColor || CombinedInfoDefaults.backgroundColor};

  & > div:last-of-type {
    position: ${(props) => (props.isOverPhoto ? 'absolute' : 'static')};
    height: 90%;

    ${(props) =>
      (props.isOverPhoto &&
        props.overPhotoPosition === 'bottom' &&
        'bottom: 0; left: 50%; transform: translate(-50%); height: auto;') ||
      (props.isOverPhoto &&
        props.overPhotoPosition === 'top' &&
        'top: 0;  left: 50%; transform: translate(-50%); height: auto;') ||
      ''}

    width: ${(props) =>
      props.photoWidth === '100%'
        ? '100%'
        : `calc(100% - ${props.photoWidth})`};
  }
`;
