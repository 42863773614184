import React from 'react';
import './Datepicker.scss';
import PropTypes from 'prop-types';
import Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import { getDatepickerMessageString } from '../../../utils/helper';

const Datepicker = ({ date, setDate, prefix }) => {
  const handleClick = async () => {
    const picker = document.querySelector(
      `.${prefix}-datepicker__input > input`,
    );

    picker.click();
  };

  return (
    <div className="datepicker-wrapper">
      <button className="datepicker" type="button" onClick={handleClick}>
        <span className="datepicker__text">
          {getDatepickerMessageString(date)}
        </span>
        <FontAwesomeIcon icon={faCalendarAlt} className="datepicker__icon" />
      </button>

      <Datetime
        isValidDate={(current) => current < new Date()}
        value={date}
        onChange={(newDate) => {
          if (newDate < new Date()) {
            setDate(newDate);
          }
        }}
        className={`${prefix}-datepicker__input datepicker__input`}
      />
    </div>
  );
};

Datepicker.propTypes = {
  date: PropTypes.oneOfType([PropTypes.object, PropTypes.number]),
  setDate: PropTypes.func,
  prefix: PropTypes.string,
};

export default Datepicker;
