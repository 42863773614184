import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import './PhotoSelector.scss';
import { acceptedFileExtensions } from '../../../../data/ImgEditor';
import {
  DEFAULT_SIZE_ALERT,
  DEFAULT_RESOLUTION_ALERT,
} from '../../../../data/DefaultMessages';
import { getFileResolution } from '../../../../utils/helper';

const PhotoSelector = ({ onClick, hasClosed, setFileInputRef }) => {
  const fileInput = useRef(null);
  const [isSet, setIsSet] = useState('');

  useEffect(() => {
    if (
      isSet &&
      fileInput.current.value &&
      fileInput.current.value !== '' &&
      hasClosed
    ) {
      if (fileInput.current.files[0].size / (1024 * 1024) > 10) {
        // eslint-disable-next-line no-alert
        alert(DEFAULT_SIZE_ALERT);
        setIsSet(false);
        fileInput.current.value = '';
        fileInput.current.files = null;
      } else {
        getFileResolution(fileInput.current.files[0])
          .then((res) => res)
          .then((resolution) => {
            if (resolution.width > 1920 || resolution.height > 1080) {
              // eslint-disable-next-line no-alert
              alert(DEFAULT_RESOLUTION_ALERT);
              setIsSet(false);
              fileInput.current.value = '';
              fileInput.current.files = null;
            } else {
              onClick(fileInput.current.files[0]);
            }
          });
      }
    }

    // eslint-disable-next-line
  }, [isSet]);

  useEffect(() => {
    if (hasClosed) {
      setIsSet(false);
      fileInput.current.value = '';
      fileInput.current.files = null;
    }
  }, [hasClosed]);

  useEffect(() => {
    setFileInputRef(fileInput);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileInput]);

  return (
    <section className="photo-selector">
      <input
        onChange={() => setIsSet(true)}
        type="file"
        ref={fileInput}
        className="photo-selector__input"
        accept={acceptedFileExtensions.join(',')}
      />
    </section>
  );
};

PhotoSelector.propTypes = {
  setFileInputRef: PropTypes.func,
  onClick: PropTypes.func,
  hasClosed: PropTypes.bool,
};

export default PhotoSelector;
