import React, { useEffect, useState } from 'react';
import './KeywordsTable.scss';
import { useHistory, useLocation } from 'react-router-dom';
import Loader from '../../../Loader/Loader';
import KeywordTableEntry from './KeywordTableEntry/KeywordTableEntry';
import Notification from '../../../Notification/Notification';
import { getKeywordsList } from '../../../../utils/apiCalls';
import SortButtons from '../../../SortButtons/SortButtons';
import ListPagination from '../../../Contacts/ContactsList/ListPagination/ListPagination';
import SectionHeader from '../../../SectionHeader/SectionHeader';
import ListControls from '../../../Contacts/ContactsList/ListControls/ListControls';

const KeywordsTable = () => {
  const history = useHistory();
  const location = useLocation();

  const [isLoading, setIsLoading] = useState(true);

  const [keywordData, setKeywordData] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [recordsToShow, setRecordsToShow] = useState(10);
  const [sortCol, setSortCol] = useState(0);
  const [sortDirection, setSortDirection] = useState('asc');
  const [notification, setNotification] = useState(false);
  const [paginationResponse, setPaginationResponse] = useState();

  const [pageCount, setPageCount] = useState(
    Math.ceil(keywordData?.length / recordsToShow),
  );
  const [currentPage, setCurrentPage] = useState(0);

  const getUsers = async (page) => {
    setIsLoading(true);

    const response = await getKeywordsList(
      recordsToShow,
      (page ?? currentPage) + 1,
      searchQuery,
      sortCol,
      sortDirection,
    );

    setKeywordData(response.data);
    setPageCount(Math.ceil(response.data.length / recordsToShow));
    if (response?.meta?.pagination) {
      setPaginationResponse(response.meta.pagination);
    } else {
      setPaginationResponse(null);
    }
    setIsLoading(false);
  };

  const upClick = (colNumber) => {
    setSortCol(colNumber);
    setSortDirection('asc');
  };
  const downClick = (colNumber) => {
    setSortCol(colNumber);
    setSortDirection('desc');
  };

  useEffect(() => {
    getUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setPageCount(Math.ceil(keywordData?.length / recordsToShow));
    setCurrentPage(0);

    getUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recordsToShow]);

  useEffect(() => {
    if (!isLoading) {
      getUsers();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, sortCol, sortDirection]);

  useEffect(() => {
    setCurrentPage(0);
    getUsers(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchQuery]);

  useEffect(() => {
    if (location?.state?.message) {
      setNotification(true);
    }
  }, []);

  return (
    <section className="keywords-table">
      <SectionHeader text="DATABASE" />
      <main className="keywords-table__content">
        <ListControls
          searchValue={searchQuery}
          onSearchChange={(evt) => setSearchQuery(evt.target.value)}
        />
        <header className="content__header">
          <KeywordTableEntry
            id="#"
            keyword={
              <>
                <span className="content__header-text">Keyword</span>
              </>
            }
            shortCode={
              <>
                <span className="content__header-text">(short code)</span>
                <SortButtons
                  dark
                  vCenter
                  onUpClick={() => upClick(1)}
                  onDownClick={() => downClick(1)}
                  upActive={sortCol === 1 && sortDirection === 'asc'}
                  downActive={sortCol === 1 && sortDirection === 'desc'}
                />
              </>
            }
            userName={
              <>
                <span className="content__header-text">User name</span>
                <SortButtons
                  dark
                  vCenter
                  onUpClick={() => upClick(2)}
                  onDownClick={() => downClick(2)}
                  upActive={sortCol === 2 && sortDirection === 'asc'}
                  downActive={sortCol === 2 && sortDirection === 'desc'}
                />
              </>
            }
            email={
              <>
                <span className="content__header-text">Email</span>
                <SortButtons
                  dark
                  vCenter
                  onUpClick={() => upClick(1)}
                  onDownClick={() => downClick(1)}
                  upActive={sortCol === 1 && sortDirection === 'asc'}
                  downActive={sortCol === 1 && sortDirection === 'desc'}
                />
              </>
            }
          />
        </header>
        <section className="content__list">
          {keywordData.length > 0 ? (
            keywordData.map((keyword, index) => (
              <KeywordTableEntry
                {...keyword}
                id={currentPage * 10 + (index + 1)}
                key={keyword.uuid}
                keyword={keyword.keyword}
                userName={`${keyword.first_name} ${keyword.last_name}`}
                email={keyword.email}
                shortCode={keyword.short_code}
              />
            ))
          ) : (
            <p className="list__empty-message">There is no data to show</p>
          )}
          <Loader isLoading={isLoading} />
        </section>
      </main>
      {!isLoading && (
        <div style={{ marginLeft: 'auto', order: 2 }}>
          <ListPagination
            rowAmount={recordsToShow.toString()}
            setRowAmount={(evt) => setRecordsToShow(evt.target.value)}
            totalRecords={paginationResponse?.total || keywordData.length}
            firstRecord={currentPage * recordsToShow + 1}
            lastRecord={
              currentPage + 1 === paginationResponse?.total_pages
                ? paginationResponse?.total
                : paginationResponse?.count * (currentPage + 1)
            }
            onPreviousClick={() => {
              if (currentPage + 1 > 1) {
                setCurrentPage((prev) => prev - 1);
              }
            }}
            onNextClick={() => {
              if (
                currentPage + 1 <
                (paginationResponse?.total_pages || pageCount)
              ) {
                setCurrentPage((prev) => prev + 1);
              }
            }}
            selectOptions={[10, 25, 50]}
          />
        </div>
      )}
      {notification && (
        <Notification
          title="SUCCESS"
          message={location.state.message}
          visibility
          callback={() => setNotification(false)}
        />
      )}
    </section>
  );
};

export default KeywordsTable;
