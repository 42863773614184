export const usersFormSettings = {
  firstName: { visibility: true, order: 1, width: '33%', required: true },
  lastName: { visibility: true, order: 2, width: '33%', required: true },
  phone: {
    visibility: true,
    order: 3,
    width: '25%',
    minLength: 10,
    required: true,
  },
  company: { visibility: false, order: 4, width: '25%', required: true },
  email: { visibility: true, order: 5, width: '33%', required: true },
  street: {
    visibility: true,
    order: 6,
    width: '33%',
    required: false,
  },
  apt: {
    visibility: true,
    order: 7,
    width: '15%',
    required: false,
  },
  city: { visibility: true, order: 8, width: '50%', required: false },
  state: { visibility: true, order: 9, width: '33%', required: false },
  country: {
    visibility: true,
    order: 9,
    width: '15%',
    required: false,
  },
  zipCode: {
    visibility: true,
    order: 10,
    width: '15%',
    required: false,
  },

  title: { visibility: false },
  myWebsiteUrl: { visibility: false },
};

export const mapResponseToUserState = (data) => ({
  firstName: data.first_name ?? '',
  lastName: data.last_name ?? '',
  company: data.company ?? '',
  phone: data.phone ?? '',
  street: data.street ?? '',
  apt: data.apt ?? '',
  city: data.city ?? '',
  state: data.state ?? '',
  country: data.country ?? '',
  zipCode: data.zip_code ?? '',
  email: data.email ?? '',
  facebookUrl: data.facebook_url ?? '',
  twitterUrl: data.twitter_url ?? '',
  instagramUrl: data.instagram_url ?? '',
  linkedinUrl: data.linkedin_url ?? '',
  uuid: data.uuid ?? '',
  verified: data.verified ?? false,
});

export const emptyUserState = {
  firstName: '',
  lastName: '',
  company: '',
  phone: '',
  street: '',
  apt: '',
  city: '',
  state: '',
  country: '',
  zipCode: '',
  email: '',
  facebookUrl: '',
  twitterUrl: '',
  instagramUrl: '',
  linkedinUrl: '',
  verified: false,
};
