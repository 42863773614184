import React from 'react';
import './ListControls.scss';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faArrowDown,
  faArrowUp,
  faPlusCircle,
  faSearch,
} from '@fortawesome/free-solid-svg-icons';

const ListControls = ({
  onAddClick,
  onExportClick,
  onImportClick,
  searchValue,
  onSearchChange,
}) => (
  <aside className="list__controls">
    <button
      type="button"
      onClick={onAddClick}
      className="controls__button controls__button--add">
      <FontAwesomeIcon icon={faPlusCircle} className="button__icon" />
    </button>
    <button
      type="button"
      onClick={onExportClick}
      className="controls__button controls__button--export">
      <FontAwesomeIcon icon={faArrowDown} className="button__icon" />
    </button>
    <button
      type="button"
      onClick={onImportClick}
      className="controls__button controls__button--import">
      <FontAwesomeIcon icon={faArrowUp} className="button__icon" />
    </button>
    <div className="controls__search">
      <input
        type="text"
        className="search__input"
        name="contacts-search"
        id="contacts-search"
        placeholder="Search (min: 3 characters)"
        value={searchValue}
        onChange={onSearchChange}
      />
      <FontAwesomeIcon icon={faSearch} className="search__icon" />
    </div>
  </aside>
);

ListControls.propTypes = {
  onAddClick: PropTypes.func,
  onExportClick: PropTypes.func,
  onImportClick: PropTypes.func,
  searchValue: PropTypes.string,
  onSearchChange: PropTypes.func,
};

export default ListControls;
