import React, { useEffect, useState } from 'react';
import './UsersForm.scss';
import PropTypes from 'prop-types';
import { useHistory, useParams } from 'react-router-dom';
import SectionHeader from '../../../SectionHeader/SectionHeader';
import SocialForm from '../../../SocialForm/SocialForm';
import ContactForm from '../../../ContactForm/ContactForm';
import { useContactFields } from '../../../../hooks/ContactForm';
import { useSocialFields } from '../../../../hooks/SocialForm';
import Button from '../../../Button/Button';
import {
  getUserForAdmin,
  updateUserForAdmin,
  addUserForAdmin,
} from '../../../../utils/apiCalls';
import {
  prepareErrorMessage,
  prepareUserDataToSubmit,
} from '../../../../utils/helper';
import FormControl from '../../../FormControl/FormControl';
import store, { NotificationTypes } from '../../../../hooks/NotificationHub';
import {
  emptyUserState,
  mapResponseToUserState,
  usersFormSettings,
} from '../../../../data/UsersForm';

const UsersForm = ({ header }) => {
  const history = useHistory();
  const { id } = useParams();

  const [isLoading, setIsLoading] = useState(!!id);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [user, setUser] = useState(emptyUserState);
  const [isAdminChecked, setIsAdminChecked] = useState(false);
  const [sendEmailChecked, setSendEmailChecked] = useState(false);
  const contactFields = useContactFields(
    user,
    usersFormSettings,
    false,
    isLoading,
  );
  const socialFields = useSocialFields(user, false, isLoading);

  useEffect(() => {
    const getUser = async () => {
      setIsLoading(true);

      try {
        const response = await getUserForAdmin(id);

        setUser(mapResponseToUserState(response.data));
        setIsAdminChecked(!!response?.data?.is_admin);
        setIsLoading(false);
      } catch (err) {
        store.pushNotification(
          'Error',
          'Could not load user at the moment. Please try again later.',
          NotificationTypes.DANGER,
        );
        history.push('/admin/users');
      }
    };

    if (id) {
      getUser();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = async (evt) => {
    evt.preventDefault();
    setIsSubmitting(true);
    const [
      contactFieldsValid,
      contactFieldsErrors,
    ] = contactFields.validateOnSubmit();

    if (!contactFieldsValid) {
      store.pushNotification(
        'Error',
        <span style={{ whiteSpace: 'break-spaces' }}>
          {contactFieldsErrors.join('\n\n')}
        </span>,
        NotificationTypes.DANGER,
      );
      return;
    }

    const { dataToSend } = prepareUserDataToSubmit(
      contactFields.contactInfo,
      socialFields.socialLinks,
      '',
      '',
      '',
    );

    dataToSend.is_admin = Number(isAdminChecked) || 0;

    if (id) {
      try {
        const response = await updateUserForAdmin(id, dataToSend);

        store.pushNotification(
          'Success',
          response?.message ?? 'User data updated successfully.',
          NotificationTypes.SUCCESS,
        );
        history.push('/admin/users');
      } catch (err) {
        store.pushNotification(
          err.message ?? 'Error',
          prepareErrorMessage(err.errors) ??
            'Could not update user data at the moment. Please try again later.',
          NotificationTypes.DANGER,
        );
      }
    } else {
      dataToSend.send_email = Number(sendEmailChecked) || 0;

      try {
        const response = await addUserForAdmin(dataToSend);

        store.pushNotification(
          'Success',
          response?.message ?? 'User added successfully.',
          NotificationTypes.SUCCESS,
        );
        history.push('/admin/users');
      } catch (err) {
        store.pushNotification(
          err.message ?? 'Error',
          prepareErrorMessage(err.errors) ??
            'Could not add user at the moment. Please try again later.',
          NotificationTypes.DANGER,
        );
      }
    }

    setIsSubmitting(false);
  };

  return (
    <section className="user">
      <SectionHeader text={header} />
      <form className="user__form" onSubmit={handleSubmit}>
        <ContactForm inputs={contactFields.inputs} />
        <SocialForm inputs={socialFields.inputs} />
        <FormControl
          type="checkbox"
          name="isadmin"
          onChange={() => setIsAdminChecked((prev) => !prev)}
          value={isAdminChecked}
          label="Is admin?"
        />
        {(!id || !user.verified) && (
          <FormControl
            type="checkbox"
            name="send-email"
            onChange={() => setSendEmailChecked((prev) => !prev)}
            value={sendEmailChecked}
            label="Send email?"
          />
        )}
        <div className="form__buttons">
          <Button
            variant="secondary"
            text="Cancel"
            onClick={() => history.push('/admin/users')}
          />
          <Button
            variant="primary"
            text="Save"
            type="submit"
            disabled={isSubmitting}
            state={isSubmitting ? 'loading' : ''}
          />
        </div>
      </form>
    </section>
  );
};

UsersForm.propTypes = {
  header: PropTypes.string,
};

export default UsersForm;
