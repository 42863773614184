import React from 'react';
import PropTypes from 'prop-types';
import { Address } from './userStyles';

const UserAddress = ({ JobAddress, ...rest }) => (
  <Address {...rest}>{JobAddress}</Address>
);

UserAddress.propTypes = {
  JobAddress: PropTypes.string,
};

export default UserAddress;
