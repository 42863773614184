import { useState, useEffect } from 'react';

export const remapUserProperties = (userObject) => {
  const newUser = {
    uuid: userObject.uuid,
    FirstName: userObject.first_name,
    LastName: userObject.last_name,
    Title: userObject.title,
    EmailAddress: userObject.email ?? 'testemail@email.com',
    CompanyName: userObject.company,
    CellPhoneNumber: userObject.phone,
    JobAddress: userObject.job_address,
    AboutMeCopyTxt: userObject.about,
    FooterTxt: userObject.footer,
    PhotoUrl: userObject.photo_url,
    LogoUrl: userObject.logo_url,
    FbUrl: userObject.facebook_url,
    TwUrl: userObject.twitter_url,
    IgUrl: userObject.instagram_url,
    InUrl: userObject.linkedin_url,
    MyWebsiteUrl: userObject.website,
    // TODO Swap for proper theme id once that's available on the API
    ThemeId: 1, // userObject.mbc_theme_id
  };

  return newUser;
};

export const createComponentProps = (
  visibility,
  theme,
  user,
  userId,
  setIsModalOpen,
) => {
  const headerProps = {
    ...theme.components.filter((obj) => obj.name === 'Header')[0],
    isVisible: visibility.isHeaderVisible,
    logoUrl: user.LogoUrl,
    userId,
  };

  const footerProps = {
    ...theme.components.filter((obj) => obj.name === 'Footer')[0],
    isVisible: visibility.isFooterVisible,
    FooterTxt: user.FooterTxt,
    MyWebsiteUrl: user.MyWebsiteUrl,
  };

  const layoutProps = {
    ...theme.components.filter((obj) => obj.name === 'MainContainer')[0],
    headerProps,
    footerProps,
    fontFamily: theme.fontFamily,
    fontUrl: theme.fontUrl,
    FullName: `${user.FirstName} ${user.LastName}`,
    CompanyName: user.CompanyName,
    Title: user.Title,
  };

  const socialLinksGroupProps = {
    socialLinks: {
      links: theme.components.filter((obj) => obj.name === 'SocialLink'),
      fb: user.FbUrl,
      tw: user.TwUrl,
      ig: user.IgUrl,
      in: user.InUrl,
    },
    ...theme.components.filter((obj) => obj.name === 'SocialLinkGroup')[0],
  };

  const userPhotoProps = {
    ...theme.components.filter((obj) => obj.name === 'UserPhoto')[0],
    PhotoUrl: user.PhotoUrl,
  };

  const userInfoProps = {
    ...theme.components.filter((obj) => obj.name === 'UserInfo')[0],
    FirstName: user.FirstName,
    LastName: user.LastName,
    Title: user.Title,
  };

  const userAddressProps = {
    ...theme.components.filter((obj) => obj.name === 'UserAddress')[0],
    JobAddress: user.JobAddress,
  };

  const combinedUserInfoProps = {
    userPhotoProps,
    userInfoProps,
    userAddressProps,
    socialLinksGroupProps,
    isVisible: visibility.isCombinedInfoVisible,
    ...theme.components.filter((obj) => obj.name === 'CombinedUserInfo')[0],
  };

  const buttonGroupProps = {
    buttons: theme.components.filter((obj) => obj.name === 'Button'),
    CellPhoneNumber: user.CellPhoneNumber,
    EmailAddress: user.EmailAddress,
    uuid: user.uuid,
    FullName: `${user.FirstName}_${user.LastName}`,
    openModal: () => setIsModalOpen(true),
    ...theme.components.filter((obj) => obj.name === 'ButtonGroup')[0],
  };

  const aboutMeProps = {
    AboutMeCopyText: user.AboutMeCopyTxt,
    ...theme.components.filter((obj) => obj.name === 'AboutMeText')[0],
    isVisible: visibility.isAboutMeVisible,
  };

  const modalProps = {
    closeModal: () => setIsModalOpen(false),
    uuid: user.uuid,
    ...theme.components.filter((obj) => obj.name === 'SendModal')[0],
  };

  return {
    layoutProps,
    headerProps,
    footerProps,
    userPhotoProps,
    userInfoProps,
    userAddressProps,
    buttonGroupProps,
    socialLinksGroupProps,
    combinedUserInfoProps,
    aboutMeProps,
    modalProps,
  };
};

export const getWindowDimensions = () => {
  if (typeof window !== 'undefined') {
    const { innerWidth: width, innerHeight: height } = window;

    return { width, height };
  }

  return { width: 0, height: 0 };
};

export const useWindowDimensions = () => {
  const [dimensions, setDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    const handleResize = () => {
      setDimensions(getWindowDimensions());
    };

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return dimensions;
};

export const useResize = (myRef) => {
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

  const handleResize = () => {
    const style = getComputedStyle(myRef.current, null);

    setDimensions({
      width:
        parseFloat(style.getPropertyValue('width')) -
        parseFloat(style.getPropertyValue('padding-left')) -
        parseFloat(style.getPropertyValue('padding-right')),
      height: parseFloat(style.getPropertyValue('height')),
    });
  };

  useEffect(() => {
    if (
      document.readyState === 'complete' ||
      document.readyState === 'interactive'
    ) {
      handleResize();
    }
  }, []);

  useEffect(() => {
    handleResize();

    window.addEventListener('load', handleResize);
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('load', handleResize);
    };
  }, [myRef]);

  return dimensions;
};
