import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
    
    font-family: ${(props) => props.fontfamily};
  }

  * {
    box-sizing: border-box;
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }

  @keyframes slideInFromTop {
    from {
      top: -100%;
    }

    to {
      top: 50%;
    }
  }
`;

export default GlobalStyle;
