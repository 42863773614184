import React, { useEffect, useRef, useState } from 'react';
import './UserTable.scss';
import { useHistory } from 'react-router-dom';
import { saveAs } from 'file-saver';
import { faUserShield } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Loader from '../../../Loader/Loader';
import SectionHeader from '../../../SectionHeader/SectionHeader';
import UsersTableEntry from './UsersTableEntry/UsersTableEntry';
import ConfirmationDialog from '../../../ConfirmationDialog/ConfirmationDialog';
import FileInput from '../../../FileInput/FileInput';
import ActionButtons from './ActionButtons/ActionButtons';
import {
  changeUserStatus,
  downloadVcard,
  exportUsers,
  getUsersList,
} from '../../../../utils/apiCalls';
import SortButtons from '../../../SortButtons/SortButtons';
import ListPagination from '../../../Contacts/ContactsList/ListPagination/ListPagination';
import ListControls from '../../../Contacts/ContactsList/ListControls/ListControls';
import store, { NotificationTypes } from '../../../../hooks/NotificationHub';
import { prepareErrorMessage } from '../../../../utils/helper';

const UserTable = () => {
  const history = useHistory();
  const fileRef = useRef();

  const [isLoading, setIsLoading] = useState(true);

  const [userData, setUserData] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [searchInputValue, setSearchInputValue] = useState('');
  const [recordsToShow, setRecordsToShow] = useState(10);
  const [sortCol, setSortCol] = useState(0);
  const [sortDirection, setSortDirection] = useState('asc');
  const [dialog, setDialog] = useState(null);
  const [paginationResponse, setPaginationResponse] = useState();

  const [pageCount, setPageCount] = useState(
    Math.ceil(userData?.length / recordsToShow),
  );
  const [currentPage, setCurrentPage] = useState(0);

  const getUsers = async (page) => {
    setIsLoading(true);

    try {
      const response = await getUsersList(
        recordsToShow,
        (page ?? currentPage) + 1,
        searchQuery,
        sortCol,
        sortDirection,
      );

      setUserData(response.data.data);
      setPageCount(Math.ceil(response.data.data.length / recordsToShow));

      if (response?.data?.meta?.pagination) {
        setPaginationResponse(response.data.meta.pagination);
      } else {
        setPaginationResponse(null);
      }
    } catch (err) {
      store.pushNotification(
        err.message ?? 'Error',
        prepareErrorMessage(err.errors) ??
          'Could not load users at the moment. Please refresh the page or try again later.',
        NotificationTypes.DANGER,
      );
    }
    setIsLoading(false);
  };

  const onEdit = async (uuid) => history.push(`/admin/users/edit/${uuid}`);
  const onDownload = async (uuid, firstName, lastName) => {
    try {
      const vcard = await downloadVcard(uuid);
      const blob = new Blob([vcard.data.card], {
        type: vcard.data['content-type'],
      });
      saveAs(blob, `${firstName}_${lastName}.${vcard.data.ext}`);
    } catch (err) {
      store.pushNotification(
        'Error',
        `Could not download .vcf for ${firstName} ${lastName}`,
        NotificationTypes.DANGER,
      );
    }
  };
  const onStatusButtonClick = async (uuid) => {
    try {
      await changeUserStatus(uuid);
    } catch (err) {
      store.pushNotification(
        err.message ?? 'Error',
        prepareErrorMessage(err.errors) ??
          'Could not change user status at the moment. Please try again later.',
        NotificationTypes.DANGER,
      );
    }
    await getUsers();
  };

  const upClick = (colNumber) => {
    setSortCol(colNumber);
    setSortDirection('asc');
  };
  const downClick = (colNumber) => {
    setSortCol(colNumber);
    setSortDirection('desc');
  };

  useEffect(() => {
    getUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setPageCount(Math.ceil(userData?.length / recordsToShow));
    setCurrentPage(0);

    getUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recordsToShow]);

  useEffect(() => {
    if (!isLoading) {
      getUsers();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, sortCol, sortDirection]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setCurrentPage(0);
      getUsers(0);
    }, 300);

    return () => {
      clearTimeout(timeout);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchQuery]);

  useEffect(() => {
    if (searchInputValue.length >= 3) {
      setSearchQuery(searchInputValue);
    } else if (searchInputValue && searchInputValue.length < 3) {
      setSearchQuery('');
    }
  }, [searchInputValue]);

  const handleExport = async () => {
    try {
      const response = await exportUsers([]);
      saveAs(response.data.path, 'users');
    } catch (err) {
      store.pushNotification(
        'Error',
        'Could not export users at the moment. Please try again later.',
        NotificationTypes.DANGER,
      );
    }
  };

  return (
    <section className="user-table">
      {dialog}
      <SectionHeader text="USERS" />
      <div style={{ width: '100%', marginBottom: '0.8125rem' }}>
        <ListControls
          onAddClick={() => history.push('/admin/users/add')}
          onExportClick={handleExport}
          onImportClick={() => fileRef?.current?.click()}
          searchValue={searchInputValue}
          onSearchChange={(evt) => setSearchInputValue(evt.target.value)}
        />
        <FileInput
          inputRef={fileRef}
          fileExtensions={['.csv']}
          redirectUrl="/admin/users/import"
        />
      </div>
      <div className="user-table__wrapper">
        <main className="user-table__content">
          <header className="content__header">
            <UsersTableEntry
              id="#"
              uuid={
                <>
                  <span className="content__header-text">UUID</span>
                  <SortButtons
                    dark
                    vCenter
                    onUpClick={() => upClick(0)}
                    onDownClick={() => downClick(0)}
                    upActive={sortCol === 0 && sortDirection === 'asc'}
                    downActive={sortCol === 0 && sortDirection === 'desc'}
                  />
                </>
              }
              email={
                <>
                  <span className="content__header-text">Email</span>
                  <SortButtons
                    dark
                    vCenter
                    onUpClick={() => upClick(1)}
                    onDownClick={() => downClick(1)}
                    upActive={sortCol === 1 && sortDirection === 'asc'}
                    downActive={sortCol === 1 && sortDirection === 'desc'}
                  />
                </>
              }
              firstName={
                <>
                  <span className="content__header-text">First name</span>
                  <SortButtons
                    dark
                    vCenter
                    onUpClick={() => upClick(2)}
                    onDownClick={() => downClick(2)}
                    upActive={sortCol === 2 && sortDirection === 'asc'}
                    downActive={sortCol === 2 && sortDirection === 'desc'}
                  />
                </>
              }
              lastName={
                <>
                  <span className="content__header-text">Last name</span>
                  <SortButtons
                    dark
                    vCenter
                    onUpClick={() => upClick(3)}
                    onDownClick={() => downClick(3)}
                    upActive={sortCol === 3 && sortDirection === 'asc'}
                    downActive={sortCol === 3 && sortDirection === 'desc'}
                  />
                </>
              }
              isAdmin={
                <>
                  <span className="content__header-text">
                    <FontAwesomeIcon icon={faUserShield} />
                  </span>
                  <SortButtons
                    dark
                    vCenter
                    onUpClick={() => upClick(4)}
                    onDownClick={() => downClick(4)}
                    upActive={sortCol === 4 && sortDirection === 'asc'}
                    downActive={sortCol === 4 && sortDirection === 'desc'}
                  />
                </>
              }
              status={
                <>
                  <span className="content__header-text">Status</span>
                  <SortButtons
                    dark
                    vCenter
                    onUpClick={() => upClick(5)}
                    onDownClick={() => downClick(5)}
                    upActive={sortCol === 5 && sortDirection === 'asc'}
                    downActive={sortCol === 5 && sortDirection === 'desc'}
                  />
                </>
              }
              actions="Actions"
            />
          </header>
          <section className="content__list">
            {userData.length > 0 ? (
              userData.map((user, index) => (
                <UsersTableEntry
                  {...user}
                  id={currentPage * 10 + (index + 1)}
                  key={user.uuid}
                  firstName={user.first_name}
                  lastName={user.last_name}
                  isAdmin={user.is_admin}
                  status={user.status ? 'active' : 'inactive'}
                  actions={
                    <ActionButtons
                      isActive={user.status}
                      changeableStatus={user.changeable_status}
                      onEditClick={() => onEdit(user.uuid)}
                      onDownloadClick={async () =>
                        onDownload(user.uuid, user.first_name, user.last_name)
                      }
                      onStatusButtonClick={() => {
                        setDialog(
                          <ConfirmationDialog
                            message={`Are you sure you want to ${
                              user.status ? 'deactivate' : 'activate'
                            } user ${user.email}?`}
                            setDialog={setDialog}
                            onConfirm={() => onStatusButtonClick(user.uuid)}
                          />,
                        );
                      }}
                    />
                  }
                />
              ))
            ) : (
              <p className="list__empty-message">There is no data to show</p>
            )}
            <Loader isLoading={isLoading} />
          </section>
        </main>
      </div>
      <div style={{ marginLeft: 'auto', order: 2 }}>
        <ListPagination
          rowAmount={recordsToShow.toString()}
          setRowAmount={(evt) => setRecordsToShow(evt.target.value)}
          totalRecords={paginationResponse?.total || userData?.length}
          firstRecord={currentPage * recordsToShow + 1}
          lastRecord={
            currentPage + 1 === paginationResponse?.total_pages
              ? paginationResponse?.total
              : paginationResponse?.count * (currentPage + 1)
          }
          onPreviousClick={() => {
            if (currentPage + 1 > 1) {
              setCurrentPage((prev) => prev - 1);
            }
          }}
          onNextClick={() => {
            if (
              currentPage + 1 <
              (paginationResponse?.total_pages || pageCount)
            ) {
              setCurrentPage((prev) => prev + 1);
            }
          }}
          selectOptions={[10, 25, 50]}
        />
      </div>
    </section>
  );
};

export default UserTable;
