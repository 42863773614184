import React, { useState, useEffect } from 'react';

// eslint-disable-next-line import/prefer-default-export
export const useSocialFields = (userData, isResetting, isLoading) => {
  const [socialLinks, setSocialLinks] = useState({
    facebookUrl: userData.facebookUrl,
    twitterUrl: userData.twitterUrl,
    instagramUrl: userData.instagramUrl,
    linkedinUrl: userData.linkedinUrl,
  });

  useEffect(() => {
    if (isResetting || userData) {
      setSocialLinks({
        facebookUrl: userData.facebookUrl,
        twitterUrl: userData.twitterUrl,
        instagramUrl: userData.instagramUrl,
        linkedinUrl: userData.linkedinUrl,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isResetting, userData]);

  const inputs = [
    {
      name: 'Facebook',
      label: '',
      type: 'text',
      value: socialLinks.facebookUrl,
      onChange: (evt) => {
        setSocialLinks({
          ...socialLinks,
          facebookUrl: evt.target.value,
        });
      },
      width: '49%',
      icon: (className) => (
        <img
          src="/images/social/facebook.png"
          alt="Facebook"
          className={`main__icon ${className}`}
        />
      ),
      iconAlignment: 'left',
      isIconInside: true,
      placeholder: 'Enter URL',
      isLoading,
    },
    {
      name: 'Instagram',
      label: '',
      type: 'text',
      value: socialLinks.instagramUrl,
      onChange: (evt) => {
        setSocialLinks({
          ...socialLinks,
          instagramUrl: evt.target.value,
        });
      },
      width: '49%',
      icon: (className) => (
        <img
          src="/images/social/instagram.png"
          alt="Instagram"
          className={`main__icon ${className}`}
        />
      ),
      iconAlignment: 'left',
      isIconInside: true,
      placeholder: 'Enter URL',
      isLoading,
    },
    {
      name: 'Twitter',
      label: '',
      type: 'text',
      value: socialLinks.twitterUrl,
      onChange: (evt) => {
        setSocialLinks({
          ...socialLinks,
          twitterUrl: evt.target.value,
        });
      },
      width: '49%',
      icon: (className) => (
        <img
          src="/images/social/twitter.png"
          alt="Twitter"
          className={`main__icon ${className}`}
        />
      ),
      iconAlignment: 'left',
      isIconInside: true,
      placeholder: 'Enter URL',
      isLoading,
    },
    {
      name: 'LinkedIn',
      label: '',
      type: 'text',
      value: socialLinks.linkedinUrl,
      onChange: (evt) => {
        setSocialLinks({
          ...socialLinks,
          linkedinUrl: evt.target.value,
        });
      },
      width: '49%',
      icon: (className) => (
        <img
          src="/images/social/linkedin.png"
          alt="LinkedIn"
          className={`main__icon ${className}`}
        />
      ),
      iconAlignment: 'left',
      isIconInside: true,
      placeholder: 'Enter URL',
      isLoading,
    },
  ];

  return { inputs, socialLinks };
};
