import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCogs } from '@fortawesome/free-solid-svg-icons';
import { useHistory, useLocation } from 'react-router-dom';
import logo from '../../sharelocal.svg';
import Button from '../Button/Button';
import './MaintenancePage.scss';
import { verifyAccount } from '../../utils/apiCalls';
import store, { NotificationTypes } from '../../hooks/NotificationHub';

const MaintenancePage = () => {
  const location = useLocation();
  const history = useHistory();

  const [isLoading, setIsLoading] = useState(false);

  const handleRefresh = async () => {
    setIsLoading(true);

    try {
      // checking if the backend still returns 444, needed random endpoint for that
      await verifyAccount('123', '123');
    } catch (err) {
      history.push('/');
      return;
    }

    store.pushNotification(
      'Info',
      'This site is still under maintenance. Please visit later.',
      NotificationTypes.INFO,
    );
    setIsLoading(false);
  };

  return (
    <section className="maintenance-page">
      <main className="maintenance-page__content">
        <FontAwesomeIcon icon={faCogs} className="content__icon" />
        <div className="content__main">
          <img src={logo} alt="logo" className="main__logo" />
          <p className="main__message">
            {location?.data?.message ||
              'This website is undergoing maintenance.'}
          </p>
          <Button
            variant="secondary"
            state={isLoading ? 'loading' : ''}
            disabled={isLoading}
            onClick={handleRefresh}
            text="Refresh"
          />
        </div>
      </main>
    </section>
  );
};

export default MaintenancePage;
