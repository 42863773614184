import React, { useState, useEffect, useCallback } from 'react';
import './ColorInput.scss';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSlash } from '@fortawesome/free-solid-svg-icons';

const ColorInput = ({
  value,
  setValue,
  disabled,
  isEditable,
  onClick,
  isReset,
}) => {
  const [currentColor, setCurrentColor] = useState(value);

  const preventEvent = useCallback(
    (evt) => {
      if (!isEditable) {
        evt.stopPropagation();
        evt.preventDefault();
      }
    },
    [isEditable],
  );

  useEffect(() => {
    setCurrentColor(value);
  }, [value]);

  return (
    <label
      className={`color-input__wrapper ${
        isReset ? 'color-input__wrapper--reset' : ''
      }`}
      style={{ background: currentColor }}
      htmlFor="color">
      <input
        name="color"
        className="color-input__picker"
        type="color"
        value={value}
        // all those events need to be stopped for it to work properly on mobile Safari
        onMouseUp={preventEvent}
        onMouseDown={preventEvent}
        onDoubleClick={preventEvent}
        onTouchStart={preventEvent}
        onTouchMove={preventEvent}
        onTouchCancel={preventEvent}
        onClick={(evt) => {
          if (!isEditable) {
            evt.stopPropagation();
            evt.preventDefault();
            if (onClick) onClick();
          }
          return false;
        }}
        onTouchEnd={(evt) => {
          if (!isEditable) {
            evt.stopPropagation();
            evt.preventDefault();
            if (onClick) onClick();
          }
          return false;
        }}
        onInput={(evt) => {
          if (isEditable) setCurrentColor(evt.target.value);
        }}
        onBlur={() => {
          if (isEditable) setValue(currentColor);
        }}
        onChange={() => {
          if (isEditable) setValue(currentColor);
        }}
        disabled={disabled}
      />
      {isReset ? (
        <FontAwesomeIcon icon={faSlash} className="color-input__icon" />
      ) : null}
    </label>
  );
};

ColorInput.propTypes = {
  value: PropTypes.string,
  setValue: PropTypes.func,
  disabled: PropTypes.bool,
  isEditable: PropTypes.bool,
  onClick: PropTypes.func,
  isReset: PropTypes.bool,
};

export default ColorInput;
