import React, { useEffect, useState } from 'react';
import './Autocomplete.scss';
import PropTypes from 'prop-types';
import { autocompleteAddress } from '../../utils/apiCalls';
import Button from '../Button/Button';

const Autocomplete = ({ query, setData, showResults, setShowResults }) => {
  const [options, setOptions] = useState([]);
  const [rendered, setRendered] = useState(null);

  useEffect(() => {
    if (query.trim().length > 0) {
      const autocomplete = async () => {
        const response = await autocompleteAddress(query);

        setOptions(response.suggestions);
      };

      autocomplete();
    }
  }, [query]);

  useEffect(() => {
    if (showResults) {
      setRendered(
        options.map((opt) => (
          <Button
            variant="secondary"
            text={opt.label}
            onClick={() => setData(opt)}
            key={opt.locationId}
          />
        )),
      );
    } else {
      setRendered(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showResults, options]);

  return (
    <section className="autocomplete" onBlur={() => setShowResults({})}>
      <main className="autocomplete__list">{rendered}</main>
    </section>
  );
};

Autocomplete.propTypes = {
  query: PropTypes.string,
  setData: PropTypes.func,
  showResults: PropTypes.bool,
  setShowResults: PropTypes.func,
};

export default Autocomplete;
