import React from 'react';
import PropTypes from 'prop-types';
import BackButton from './backButton';
import { SiteHeader, Logo } from './sharedStyles';

const Header = ({
  logoUrl,
  logoSize,
  userId,
  maxWidth,
  medium,
  large,
  onBackButtonClick,
  currentPage,
  ...rest
}) => (
  <SiteHeader {...rest} medium={medium} large={large}>
    <BackButton
      userId={userId}
      handleClick={onBackButtonClick}
      currentPage={currentPage}
    />
    <Logo
      src={logoUrl ?? '/images/logo_placeholder.svg'}
      logoSize={logoSize}
      maxWidth={maxWidth}
      medium={medium}
      large={large}
      alt="Card Logo"
    />
  </SiteHeader>
);

Header.propTypes = {
  logoUrl: PropTypes.string,
  logoSize: PropTypes.string,
  userId: PropTypes.string,
  maxWidth: PropTypes.string,
  medium: PropTypes.object,
  large: PropTypes.object,
  onBackButtonClick: PropTypes.func,
  currentPage: PropTypes.string,
};

export default Header;
