import React from 'react';
import PropTypes from 'prop-types';
import './PageHeader.scss';
import { Link } from 'react-router-dom';

// href and linkText optional for now
const PageHeader = ({ text, href, linkText }) => (
  <header className="page-header">
    <h1 className="page-header__text">{text}</h1>
    {href && linkText && href !== '' && linkText !== '' ? (
      <Link to={href}>{linkText}</Link>
    ) : null}
  </header>
);

PageHeader.propTypes = {
  text: PropTypes.string,
  href: PropTypes.string,
  linkText: PropTypes.string,
};

export default PageHeader;
