import { useEffect, useState } from 'react';
import { elementSizeDataList, justifyContentOptions } from '../ThemesForm';

const getState = (info) => ({
  order: info.order ?? 2,
  justifyContent: info.justifyContent ?? '',
  width: info.width ?? '',
  padding: info.padding ?? '',
  backgroundColor: info.backgroundColor ?? '',
  backgroundImage: info.backgroundImage ?? '',
  borderStyle: info.borderStyle ?? '',
  borderWidth: info.borderWidth ?? '',
  borderColor: info.borderColor ?? '',
  image: {
    size: info?.image?.size ?? '',
    maxWidth: info?.image?.maxWidth ?? '',
    shape: info?.image?.shape ?? 'rectangle',
    borderStyle: info?.image?.borderStyle ?? '',
    borderWidth: info?.image?.borderWidth ?? '',
    borderColor: info?.image?.borderColor ?? '',
    boxShadow: info?.image?.boxShadow ?? '',
  },

  medium: {
    order: info?.medium?.order ?? 2,
    justifyContent: info?.medium?.justifyContent ?? '',
    width: info?.medium?.width ?? '',
    padding: info?.medium?.padding ?? '',
    image: {
      size: info?.medium?.image?.size ?? '',
      maxWidth: info?.medium?.image?.size ?? '',
      shape: info?.medium?.image?.shape ?? 'rectangle',
    },
  },
  large: {
    order: info?.large?.order ?? 2,
    justifyContent: info?.large?.justifyContent ?? '',
    width: info?.large?.width ?? '',
    padding: info?.large?.padding ?? '',
    image: {
      size: info?.large?.image?.size ?? '',
      maxWidth: info?.large?.image?.size ?? '',
      shape: info?.large?.image?.shape ?? 'rectangle',
    },
  },
});

const useUserPhoto = (info) => {
  const [photoInfo, setPhotoInfo] = useState(getState(info));

  useEffect(() => {
    setPhotoInfo(getState(info));
  }, [info]);

  const handleChange = (propertyName, value, element = '', breakpoint = '') => {
    const newInfo = { ...photoInfo };

    if (breakpoint === 'medium' || breakpoint === 'large') {
      if (element && element !== '') {
        newInfo[breakpoint][element] = {
          ...newInfo[breakpoint][element],
          [propertyName]: value,
        };
      } else {
        newInfo[breakpoint] = {
          ...newInfo[breakpoint],
          [propertyName]: value,
        };
      }
    } else if (element && element !== '') {
      newInfo[element][propertyName] = value;
    } else {
      newInfo[propertyName] = value;
    }

    setPhotoInfo(newInfo);
  };

  const inputs = [
    {
      type: 'number',
      value: photoInfo.order,
      onChange: (evt) => handleChange('order', evt.target.value),
      label: 'ORDER',
      name: 'photoOrder',
      placeholder: '2',
    },
    {
      as: 'select',
      options: justifyContentOptions,
      value: photoInfo.justifyContent,
      onChange: (evt) => handleChange('justifyContent', evt.target.value),
      label: 'ELEMENTS ALIGNMENT',
      name: 'photoJustifyContent',
    },
    {
      type: 'text',
      value: photoInfo.width,
      onChange: (evt) => handleChange('width', evt.target.value),
      label: 'WIDTH',
      name: 'photoWidth',
      placeholder: '100%',
    },
    {
      type: 'text',
      value: photoInfo.padding,
      onChange: (evt) => handleChange('padding', evt.target.value),
      label: 'PADDING',
      name: 'photoPadding',
      placeholder: '0.95rem 0 1rem 0',
    },
    {
      type: 'text',
      value: photoInfo.backgroundColor,
      onChange: (evt) => handleChange('backgroundColor', evt.target.value),
      label: 'BACKGROUND COLOR',
      name: 'photoBackgroundColor',
      placeholder: '#d1d5d8',
    },
    {
      type: 'text',
      value: photoInfo.backgroundImage,
      onChange: (evt) => handleChange('backgroundImage', evt.target.value),
      label: 'BACKGROUND IMAGE',
      name: 'photoBackgroundImage',
      placeholder: '',
    },
    {
      type: 'text',
      value: photoInfo.borderStyle,
      onChange: (evt) => handleChange('borderStyle', evt.target.value),
      label: 'BORDER STYLE',
      name: 'photoBorderStyle',
      placeholder: 'solid',
    },
    {
      type: 'text',
      value: photoInfo.borderWidth,
      onChange: (evt) => handleChange('borderWidth', evt.target.value),
      label: 'BORDER WIDTH',
      name: 'photoBorderWidth',
      placeholder: '3px 0 3px 0',
    },
    {
      type: 'text',
      value: photoInfo.borderColor,
      onChange: (evt) => handleChange('borderColor', evt.target.value),
      label: 'BORDER COLOR',
      name: 'photoBorderColor',
      placeholder: '#d1d5d8 transparent #d1d5d8 transparent',
    },

    {
      type: 'text',
      value: photoInfo.image.size,
      onChange: (evt) => handleChange('size', evt.target.value, 'image'),
      label: 'IMAGE SIZE',
      name: 'photoImageSize',
      datalist: elementSizeDataList('photoImageSize'),
      placeholder: 'medium',
    },
    {
      type: 'text',
      value: photoInfo.image.maxWidth,
      onChange: (evt) => handleChange('maxWidth', evt.target.value, 'image'),
      label: 'IMAGE MAX WIDTH',
      name: 'photoImageMaxWidth',
      placeholder: '165px',
    },
    {
      type: 'text',
      value: photoInfo.image.shape,
      onChange: (evt) => handleChange('shape', evt.target.value, 'image'),
      label: 'IMAGE SHAPE',
      name: 'photoImageShape',
      placeholder: 'rectangle',
    },
    {
      type: 'text',
      value: photoInfo.image.borderStyle,
      onChange: (evt) => handleChange('borderStyle', evt.target.value, 'image'),
      label: 'IMAGE BORDER STYLE',
      name: 'photoImageBorderStyle',
      placeholder: 'solid',
    },
    {
      type: 'text',
      value: photoInfo.image.borderWidth,
      onChange: (evt) => handleChange('borderWidth', evt.target.value, 'image'),
      label: 'IMAGE BORDER WIDTH',
      name: 'photoImageBorderWidth',
      placeholder: '4px',
    },
    {
      type: 'text',
      value: photoInfo.image.borderColor,
      onChange: (evt) => handleChange('borderColor', evt.target.value, 'image'),
      label: 'IMAGE BORDER COLOR',
      name: 'photoImageBorderColor',
      placeholder: 'rgba(255,255,255,0.35)',
    },
    {
      type: 'text',
      value: photoInfo.image.boxShadow,
      onChange: (evt) => handleChange('boxShadow', evt.target.value, 'image'),
      label: 'IMAGE BOX SHADOW',
      name: 'photoImageBoxShadow',
      placeholder: '',
    },
  ];

  const mediumInputs = [
    {
      type: 'number',
      value: photoInfo.medium.order,
      onChange: (evt) =>
        handleChange('order', evt.target.value, undefined, 'medium'),
      label: 'ORDER',
      name: 'photoOrderMedium',
      placeholder: '2',
    },
    {
      as: 'select',
      options: justifyContentOptions,
      value: photoInfo.medium.justifyContent,
      onChange: (evt) =>
        handleChange('justifyContent', evt.target.value, undefined, 'medium'),
      label: 'ELEMENTS ALIGNMENT',
      name: 'photoJustifyContentMedium',
    },
    {
      type: 'text',
      value: photoInfo.medium.width,
      onChange: (evt) =>
        handleChange('width', evt.target.value, undefined, 'medium'),
      label: 'WIDTH',
      name: 'photoWidthMedium',
      placeholder: '100%',
    },
    {
      type: 'text',
      value: photoInfo.medium.padding,
      onChange: (evt) =>
        handleChange('padding', evt.target.value, undefined, 'medium'),
      label: 'PADDING',
      name: 'photoPaddingMedium',
      placeholder: '1.55rem 0 1.375rem 0',
    },

    {
      type: 'text',
      value: photoInfo.medium.image.size,
      onChange: (evt) =>
        handleChange('size', evt.target.value, 'image', 'medium'),
      label: 'IMAGE SIZE',
      name: 'photoImageSizeMedium',
      datalist: elementSizeDataList('photoImageSizeMedium'),
      placeholder: 'medium',
    },
    {
      type: 'text',
      value: photoInfo.medium.image.maxWidth,
      onChange: (evt) =>
        handleChange('maxWidth', evt.target.value, 'image', 'medium'),
      label: 'IMAGE MAX WIDTH',
      name: 'photoImageMaxWidthMedium',
      placeholder: '190',
    },
    {
      type: 'text',
      value: photoInfo.medium.image.shape,
      onChange: (evt) =>
        handleChange('shape', evt.target.value, 'image', 'medium'),
      label: 'IMAGE SHAPE',
      name: 'photoImageShapeMedium',
      placeholder: 'rectangle',
    },
  ];

  const largeInputs = [
    {
      type: 'number',
      value: photoInfo.large.order,
      onChange: (evt) =>
        handleChange('order', evt.target.value, undefined, 'large'),
      label: 'ORDER',
      name: 'photoOrderLarge',
      placeholder: '2',
    },
    {
      as: 'select',
      options: justifyContentOptions,
      value: photoInfo.large.justifyContent,
      onChange: (evt) =>
        handleChange('justifyContent', evt.target.value, undefined, 'large'),
      label: 'ELEMENTS ALIGNMENT',
      name: 'photoJustifyContentLarge',
    },
    {
      type: 'text',
      value: photoInfo.large.width,
      onChange: (evt) =>
        handleChange('width', evt.target.value, undefined, 'large'),
      label: 'WIDTH',
      name: 'photoWidthLarge',
      placeholder: '100%',
    },
    {
      type: 'text',
      value: photoInfo.large.padding,
      onChange: (evt) =>
        handleChange('padding', evt.target.value, undefined, 'large'),
      label: 'PADDING',
      name: 'photoPaddingLarge',
      placeholder: '1.55rem 0 1.06rem -',
    },

    {
      type: 'text',
      value: photoInfo.large.image.size,
      onChange: (evt) =>
        handleChange('size', evt.target.value, 'image', 'large'),
      label: 'IMAGE SIZE',
      name: 'photoImageSizeLarge',
      datalist: elementSizeDataList('photoImageSizeLarge'),
      placeholder: 'medium',
    },
    {
      type: 'text',
      value: photoInfo.large.image.maxWidth,
      onChange: (evt) =>
        handleChange('maxWidth', evt.target.value, 'image', 'large'),
      label: 'IMAGE MAX WIDTH',
      name: 'photoImageMaxWidthLarge',
      placeholder: '250px',
    },
    {
      type: 'text',
      value: photoInfo.large.image.shape,
      onChange: (evt) =>
        handleChange('shape', evt.target.value, 'image', 'large'),
      label: 'IMAGE SHAPE',
      name: 'photoImageShapeLarge',
      placeholder: 'rectangle',
    },
  ];

  return { info: photoInfo, inputs, mediumInputs, largeInputs };
};

export default useUserPhoto;
