import React from 'react';
import './ListEntry.scss';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCog } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import { formatNumber } from '../../../../utils/helper';
import ContactSourceIcon from '../../../ContactSourceIcon/ContactSourceIcon';

const ListEntry = ({
  isLoading,
  mobile,
  firstName,
  lastName,
  email,
  source,
  visitDate,
  visitTime,
  onEditClick,
  onCheckboxChange,
}) => {
  const formatTime = () => {
    const date = new Date();
    const time = visitTime.split(' ')[0].split(':');

    date.setHours(time[0]);
    date.setMinutes(time[1]);

    return moment(date).format('LT');
  };

  return (
    <div className={`list__entry ${isLoading ? 'list__entry--loading' : ''}`}>
      <div className="entry__col entry__col--checkbox">
        <input
          type="checkbox"
          className="col__input"
          onChange={onCheckboxChange}
        />
        <span className="col__input-replacement" />
      </div>
      <div className="entry__col entry__col--mobile">
        {formatNumber(mobile)}
      </div>
      <div className="entry__col entry__col--firstName">{firstName}</div>
      <div className="entry__col entry__col--lastName">{lastName}</div>
      <div className="entry__col entry__col--email">{email}</div>
      <div className="entry__col entry__col--source">
        <ContactSourceIcon source={source} />
      </div>
      <div className="entry__col entry__col--visitDate">{visitDate}</div>
      <div className="entry__col entry__col--visitTime">{formatTime()}</div>
      <div className="entry__col entry__col--edit">
        <button
          className="col__button col__button--edit"
          type="button"
          onClick={onEditClick}>
          <FontAwesomeIcon icon={faCog} className="button__icon" />
        </button>
      </div>
    </div>
  );
};

ListEntry.propTypes = {
  isLoading: PropTypes.bool,
  mobile: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  email: PropTypes.string,
  source: PropTypes.string,
  visitDate: PropTypes.string,
  visitTime: PropTypes.string,
  onEditClick: PropTypes.func,
  onCheckboxChange: PropTypes.func,
};

export default ListEntry;
