import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Overlay,
  Modal,
  CloseButton,
  Form,
  Input,
  TextArea,
  SmallText,
  SubmitButton,
  ErrorMessage,
} from './modalStyles';
import { sendModalForm } from '../../../utils/mbcApiCalls';

const initialStatus = {
  mobileNumber: true,
  firstName: true,
  lastName: true,
  message: true,
  errorMessage: '',
};

const SendModal = ({
  closeModal,
  input,
  textArea,
  smallText,
  submitButton,
  overlayOpacity,
  medium,
  large,
  uuid,
  ...rest
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [mobileNumber, setMobileNumber] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [message, setMessage] = useState('');
  const [validationStatus, setValidationStatus] = useState(initialStatus);

  const maxCharacters = 120;

  // field - name of currently used field eg. 'firstName'
  // returns new value of validationStatus
  const validateField = (newValue, field) => {
    switch (field) {
      case 'mobileNumber':
        return { ...validationStatus, mobileNumber: newValue !== '' };
      case 'firstName':
        return { ...validationStatus, firstName: newValue !== '' };
      case 'lastName':
        return { ...validationStatus, lastName: newValue !== '' };
      case 'message':
        return { ...validationStatus, message: newValue !== '' };
      default:
        return validationStatus;
    }
  };

  const updateMobileNumber = (evt) => {
    setValidationStatus(validateField(evt.target.value, 'mobileNumber'));
    setMobileNumber(evt.target.value);
  };
  const updateFirstName = (evt) => {
    setValidationStatus(validateField(evt.target.value, 'firstName'));
    setFirstName(evt.target.value);
  };
  const updateLastName = (evt) => {
    setValidationStatus(validateField(evt.target.value, 'lastName'));
    setLastName(evt.target.value);
  };
  const updateMessage = (evt) => {
    if (evt.target.value.length <= maxCharacters) {
      setValidationStatus(validateField(evt.target.value, 'message'));
      setMessage(evt.target.value);
    }
  };

  const submitForm = async (evt) => {
    evt.preventDefault();

    let valid = true;
    let status = initialStatus;

    if (mobileNumber === '') {
      status = { ...status, mobileNumber: false };
      valid = false;
    }
    if (firstName === '') {
      status = { ...status, firstName: false };
      valid = false;
    }
    if (lastName === '') {
      status = { ...status, lastName: false };
      valid = false;
    }
    if (message === '') {
      status = { ...status, message: false };
      valid = false;
    }

    setValidationStatus(status);

    if (!valid) return;

    setIsSubmitting(true);

    try {
      const response = await sendModalForm(
        uuid,
        firstName,
        lastName,
        mobileNumber,
        message,
        sessionStorage.getItem('mbc-source'),
      );

      if (response.first_name || response.last_name || response.phone) {
        status = {
          ...status,
          // !! to use below as boolean
          firstName: !!response.first_name,
          lastName: !!response.last_name,
          mobileNumber: !!response.phone,
        };
      }
      setIsSubmitting(false);
      closeModal();
    } catch (err) {
      setValidationStatus({
        ...validationStatus,
        errorMessage: 'Could not send business card. Please try again later',
      });
      setIsSubmitting(false);
    }
  };

  const inputs = [
    {
      type: 'text',
      placeholder: 'Mobile Number',
      width: input.mobileNumberWidth,
      value: mobileNumber,
      onChange: updateMobileNumber,
      className: !validationStatus.mobileNumber ? 'invalid' : '',
      medium: {
        ...medium.input,
        width: medium.input.mobileNumberWidth,
      },
      large: {
        ...medium.input,
        width: medium.input.mobileNumberWidth,
      },
    },
    {
      type: 'text',
      placeholder: 'First Name',
      width: input.firstNameWidth,
      value: firstName,
      onChange: updateFirstName,
      className: !validationStatus.firstName ? 'invalid' : '',
      medium: { ...medium.input, width: medium.input.firstNameWidth },
      large: { ...large.input, width: large.input.firstNameWidth },
    },
    {
      type: 'text',
      placeholder: 'Last Name',
      width: input.lastNameWidth,
      value: lastName,
      onChange: updateLastName,
      className: !validationStatus.lastName ? 'invalid' : '',
      medium: { ...medium.input, width: medium.input.lastNameWidth },
      large: { ...large.input, width: large.input.lastNameWidth },
    },
  ];

  return (
    <Overlay overlayOpacity={overlayOpacity}>
      <Modal {...rest} medium={medium} large={large}>
        <h3>Send Card</h3>
        <CloseButton onClick={closeModal}>{'\u2715'}</CloseButton>
        <Form onSubmit={submitForm}>
          {inputs.map((inp) => (
            <Input {...inp} {...input} key={inp.placeholder} />
          ))}
          <TextArea
            {...textArea}
            placeholder="Message"
            value={message}
            onChange={updateMessage}
            className={!validationStatus.message ? 'invalid' : ''}
            medium={medium.textArea}
            large={large.textArea}
          />
          {validationStatus.errorMessage ? (
            <ErrorMessage color={textArea.invalidColor}>
              {validationStatus.errorMessage}
            </ErrorMessage>
          ) : null}
          <SmallText
            medium={medium.smallText}
            large={large.smallText}
            {...smallText}>
            {`Characters left: ${maxCharacters - message.length}`}
          </SmallText>
          <SubmitButton {...submitButton} disabled={isSubmitting}>
            Send
          </SubmitButton>
        </Form>
      </Modal>
    </Overlay>
  );
};

SendModal.propTypes = {
  closeModal: PropTypes.func,
  input: PropTypes.object,
  textArea: PropTypes.object,
  smallText: PropTypes.object,
  submitButton: PropTypes.object,
  overlayOpacity: PropTypes.string,
  medium: PropTypes.object,
  large: PropTypes.object,
  uuid: PropTypes.string,
};

export default SendModal;
