import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBold,
  faItalic,
  faUnderline,
  faStrikethrough,
  faAlignLeft,
  faAlignRight,
  faAlignCenter,
  faAlignJustify,
} from '@fortawesome/free-solid-svg-icons';
import {
  availableFonts,
  DRAWING_MODES,
  TEXT_OBJECT_TYPE,
  TEXT_STYLES,
  POSITION_ORIGIN,
  ELEMENT_TYPES,
} from '../data/ImgEditor';
import { hexToRgba, isValidHexColor, rgbaToHex } from '../utils/helper';

// eslint-disable-next-line import/prefer-default-export
export const useTextColor = (
  activeObject,
  initialColor,
  initialOpacity,
  chosenElement,
  editorRef,
  changeTextStyle,
) => {
  const [color, setColor] = useState(initialColor);
  const [opacity, setOpacity] = useState(initialOpacity);

  useEffect(() => {
    const editor = editorRef.current.getInstance();
    const objectProps = editor.getObjectProperties(
      activeObject?.id,
      TEXT_STYLES.FILL,
    );

    if (
      !color ||
      objectProps?.fill === color ||
      objectProps?.fill === hexToRgba(color, opacity / 100)
    )
      return;

    if (activeObject && activeObject.type === TEXT_OBJECT_TYPE) {
      if (chosenElement === ELEMENT_TYPES.TEXT && isValidHexColor(color)) {
        changeTextStyle(TEXT_STYLES.FILL, hexToRgba(color, opacity / 100));
      } else if (
        color === 'rgba(0,0,0,0)' &&
        chosenElement === ELEMENT_TYPES.BACKGROUND
      ) {
        editor.setObjectProperties(activeObject?.id, {
          textBackgroundColor: color,
        });
      } else {
        changeTextStyle(
          TEXT_STYLES.BACKGROUND,
          hexToRgba(color, opacity / 100),
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [color]);

  useEffect(() => {
    if (
      activeObject &&
      activeObject.type === TEXT_OBJECT_TYPE &&
      isValidHexColor(color)
    ) {
      if (chosenElement === ELEMENT_TYPES.TEXT) {
        changeTextStyle(TEXT_STYLES.FILL, hexToRgba(color, opacity / 100));
      } else {
        editorRef.current.getInstance().setObjectProperties(activeObject?.id, {
          textBackgroundColor: hexToRgba(color, opacity / 100),
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [opacity]);

  const setNewColor = (newColor) => setColor(newColor);
  const setNewOpacity = (newOpacity) => setOpacity(newOpacity);

  return { color, opacity, setNewColor, setNewOpacity };
};

export const useTextStyle = (
  activeObject,
  initialWeightStyleButtonStatus,
  initialLineButtonStatus,
  editorRef,
  changeTextStyle,
) => {
  const [weightStyleButtonStatus, setWeightStyleButtonStatus] = useState(
    initialWeightStyleButtonStatus,
  );
  const [lineButtonStatus, setLineButtonStatus] = useState(
    initialLineButtonStatus,
  );

  // fontWeight - bold/normal
  useEffect(() => {
    const objectProps = editorRef.current
      .getInstance()
      .getObjectProperties(activeObject?.id, TEXT_STYLES.FONT_WEIGHT);

    if (objectProps?.fontWeight === 'bold' && weightStyleButtonStatus.bold)
      return;

    if (activeObject && activeObject.type === TEXT_OBJECT_TYPE) {
      changeTextStyle(
        TEXT_STYLES.FONT_WEIGHT,
        weightStyleButtonStatus.bold ? 'bold' : 'normal',
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [weightStyleButtonStatus.bold]);

  // fontStyle - italic/normal
  useEffect(() => {
    const objectProps = editorRef.current
      .getInstance()
      .getObjectProperties(activeObject?.id, TEXT_STYLES.FONT_STYLE);

    if (objectProps?.fontStyle === 'italic' && weightStyleButtonStatus.italic)
      return;

    if (activeObject && activeObject.type === TEXT_OBJECT_TYPE) {
      changeTextStyle(
        TEXT_STYLES.FONT_STYLE,
        weightStyleButtonStatus.italic ? 'italic' : 'normal',
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [weightStyleButtonStatus.italic]);

  // textDecoration - underline/line-through/normal
  useEffect(() => {
    const objectProps = editorRef.current
      .getInstance()
      .getObjectProperties(activeObject?.id, TEXT_STYLES.TEXT_DECORATION);

    const newTextDecoration =
      (lineButtonStatus.underline && 'underline') ||
      (lineButtonStatus.strikethrough && 'line-through') ||
      undefined;

    if (objectProps?.textDecoration === newTextDecoration) return;

    if (activeObject && activeObject.type === TEXT_OBJECT_TYPE) {
      changeTextStyle(TEXT_STYLES.TEXT_DECORATION, newTextDecoration);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lineButtonStatus]);

  // key - either 'bold' or 'italic'
  const onWeightStyleButtonClick = useCallback(
    (key) => {
      const newStatus = { ...weightStyleButtonStatus };

      newStatus[key] = !newStatus[key];

      setWeightStyleButtonStatus(newStatus);
    },
    [weightStyleButtonStatus],
  );

  // key - either 'bold' or 'italic', value - either true or false
  const onLineButtonClick = useCallback(
    (key, value) => {
      const newStatus = { ...lineButtonStatus };

      Object.keys(newStatus).forEach((k) => {
        newStatus[k] = false;
      });

      newStatus[key] = value;

      setLineButtonStatus(newStatus);
    },
    [lineButtonStatus],
  );

  return {
    weightStyleButtonStatus,
    lineButtonStatus,
    setWeightStyleButtonStatus,
    setLineButtonStatus,
    onWeightStyleButtonClick,
    onLineButtonClick,
  };
};

export const useFont = (
  activeObject,
  initialFontSize,
  initialFontFamily,
  editorRef,
  changeTextStyle,
) => {
  const [fontSize, setFontSize] = useState(initialFontSize);
  const [fontFamily, setFontFamily] = useState(initialFontFamily);

  useEffect(() => {
    const objectProps = editorRef.current
      .getInstance()
      .getObjectProperties(activeObject?.id, TEXT_STYLES.FONT_SIZE);

    if (objectProps?.fontSize === fontSize) return;

    if (
      activeObject &&
      activeObject.type === TEXT_OBJECT_TYPE &&
      fontSize > 0
    ) {
      changeTextStyle(TEXT_STYLES.FONT_SIZE, fontSize);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fontSize]);

  useEffect(() => {
    const objectProps = editorRef.current
      .getInstance()
      .getObjectProperties(activeObject?.id, TEXT_STYLES.FONT_FAMILY);

    if (objectProps?.fontFamily === fontFamily) return;

    if (
      activeObject &&
      activeObject.type === TEXT_OBJECT_TYPE &&
      availableFonts.includes(fontFamily)
    ) {
      changeTextStyle(TEXT_STYLES.FONT_FAMILY, fontFamily);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fontFamily]);

  return { fontFamily, fontSize, setFontFamily, setFontSize };
};

export const useTextAlign = (
  activeObject,
  initialAlign,
  intialAlignButtonStatus,
  editorRef,
) => {
  const [textAlign, setTextAlign] = useState(initialAlign);
  const [alignButtonStatus, setAlignButtonStatus] = useState(
    intialAlignButtonStatus,
  );

  const applyTextAlign = async (editor, currentPosition) => {
    const canvasSize = editor.getCanvasSize();

    if (textAlign === 'center' || textAlign === 'justify') {
      await editor.setObjectPosition(activeObject.id, {
        x: canvasSize.width / 2,
        y: currentPosition.y,
        originX: POSITION_ORIGIN.CENTER,
        originY: POSITION_ORIGIN.TOP,
      });
    } else if (textAlign === 'right') {
      await editor.setObjectPosition(activeObject.id, {
        x: canvasSize.width,
        y: currentPosition.y,
        originX: POSITION_ORIGIN.RIGHT,
        originY: POSITION_ORIGIN.TOP,
      });
    } else {
      await editor.setObjectPosition(activeObject.id, {
        x: 0,
        y: currentPosition.y,
        originX: POSITION_ORIGIN.LEFT,
        originY: POSITION_ORIGIN.TOP,
      });
    }
  };

  useEffect(() => {
    const editor = editorRef?.current?.getInstance();

    if (
      activeObject &&
      activeObject.type === TEXT_OBJECT_TYPE &&
      textAlign !== ''
    ) {
      const currentPosition = editor.getObjectPosition(
        activeObject.id,
        POSITION_ORIGIN.LEFT,
        POSITION_ORIGIN.TOP,
      );

      applyTextAlign(editor, currentPosition);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [textAlign]);

  // key - one of 'left', 'right', 'center' or 'justify'
  const onAlignButtonClick = useCallback(
    (key) => {
      const newStatus = { ...alignButtonStatus };

      newStatus[key] = !newStatus[key];

      setAlignButtonStatus(newStatus);
      setTextAlign(key);
    },
    [alignButtonStatus],
  );

  return {
    textAlign,
    setTextAlign,
    alignButtonStatus,
    setAlignButtonStatus,
    onAlignButtonClick,
  };
};

export const useEditorInitialization = (
  editorRef,
  path,
  activeObject,
  setActiveObject,
  setDrawingMode,
  fontHook,
  textStyleHook,
  textAlignHook,
  textColorHook,
) => {
  // removes added image, crops background image to size and readds chosen image
  const cropBackgroundToImageSize = async (editor, imageProps) => {
    await editor.removeObject(imageProps.id);

    await editor.loadImageFromURL(
      editor.toDataURL({
        top: 0,
        left: 0,
        width: imageProps.width,
        height: imageProps.height,
      }),
      typeof path === 'string' ? path : path.name,
    );

    await editor.addImageObject(
      typeof path === 'string' ? path : URL.createObjectURL(path),
    );
  };

  const addEventListeners = async (editor) => {
    editor.on({
      objectActivated: (objectProps) => {
        if (objectProps?.id === activeObject?.id) editor.deactivateAll();

        setActiveObject(objectProps);
        setDrawingMode(DRAWING_MODES.NORMAL);

        if (objectProps.type === TEXT_OBJECT_TYPE) {
          const textProps = editor.getObjectProperties(objectProps.id, [
            TEXT_STYLES.FONT_WEIGHT,
            TEXT_STYLES.FONT_STYLE,
            TEXT_STYLES.FONT_FAMILY,
            TEXT_STYLES.FONT_SIZE,
            TEXT_STYLES.TEXT_DECORATION,
            TEXT_STYLES.FILL,
          ]);

          fontHook.setFontFamily(textProps.fontFamily);
          fontHook.setFontSize(textProps.fontSize);

          textStyleHook.setWeightStyleButtonStatus({
            bold: textProps.fontWeight === 'bold',
            italic: textProps.fontStyle === 'italic',
          });
          textStyleHook.setLineButtonStatus({
            underline: textProps.textDecoration === 'underline',
            strikethrough: textProps.textDecoration === 'line-through',
          });

          textAlignHook.setTextAlign('');
          textAlignHook.setAlignButtonStatus({
            left: false,
            right: false,
            center: false,
            justify: false,
          });

          const textFill = rgbaToHex(textProps.fill);
          textColorHook.setNewColor(textFill.color);
        }
      },
    });
  };

  const initializeEditor = async () => {
    const editor = editorRef.current.getInstance();

    await editor.loadImageFromURL(
      `${process.env.PUBLIC_URL}/images/background-editor.png`,
      'background',
    );

    const objectProps = await editor.addImageObject(
      typeof path === 'string' ? path : URL.createObjectURL(path),
    );
    await cropBackgroundToImageSize(editor, objectProps);

    await addEventListeners(editor);
  };

  useEffect(() => {
    initializeEditor();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [path]);
};

export const useButtons = (
  onWeightStyleButtonClick,
  onLineButtonClick,
  onAlignButtonClick,
) => {
  const weightStyleButtons = useMemo(
    () => [
      {
        name: 'bold',
        icon: <FontAwesomeIcon icon={faBold} className="icon-toggle__icon" />,
        onClick: () => onWeightStyleButtonClick('bold'),
      },
      {
        name: 'italic',
        icon: <FontAwesomeIcon icon={faItalic} className="icon-toggle__icon" />,
        onClick: () => onWeightStyleButtonClick('italic'),
      },
    ],
    [onWeightStyleButtonClick],
  );

  const lineButtons = useMemo(
    () => [
      {
        name: 'underline',
        icon: (
          <FontAwesomeIcon icon={faUnderline} className="icon-toggle__icon" />
        ),
        onClick: (status) => onLineButtonClick('underline', status),
      },
      {
        name: 'strikethrough',
        icon: (
          <FontAwesomeIcon
            icon={faStrikethrough}
            className="icon-toggle__icon"
          />
        ),
        onClick: (status) => onLineButtonClick('strikethrough', status),
      },
    ],
    [onLineButtonClick],
  );

  const alignButtons = useMemo(
    () => [
      {
        name: 'alignLeft',
        icon: (
          <FontAwesomeIcon icon={faAlignLeft} className="icon-toggle__icon" />
        ),
        onClick: () => onAlignButtonClick('left'),
      },
      {
        name: 'alignRight',
        icon: (
          <FontAwesomeIcon icon={faAlignRight} className="icon-toggle__icon" />
        ),
        onClick: () => onAlignButtonClick('right'),
      },
      {
        name: 'alignCenter',
        icon: (
          <FontAwesomeIcon icon={faAlignCenter} className="icon-toggle__icon" />
        ),
        onClick: () => onAlignButtonClick('center'),
      },
      {
        name: 'alignJustify',
        icon: (
          <FontAwesomeIcon
            icon={faAlignJustify}
            className="icon-toggle__icon"
          />
        ),
        onClick: () => onAlignButtonClick('justify'),
      },
    ],
    [onAlignButtonClick],
  );

  return { weightStyleButtons, lineButtons, alignButtons };
};
