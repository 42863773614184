import React from 'react';
import './OnboardingTerms.scss';
import PropTypes from 'prop-types';
import FormControl from '../../FormControl/FormControl';

const OnboardingTerms = ({
  areTermsAccepted,
  setAreTermsAccepted,
  isVisible,
}) => {
  if (!isVisible) {
    return null;
  }

  return (
    <div className="onboarding-terms">
      <FormControl
        type="checkbox"
        required
        width="100%"
        value={areTermsAccepted}
        onChange={setAreTermsAccepted}
        label={
          <p className="onboarding-terms__message">
            I ACCEPT THE{' '}
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <a href="#" className="onboarding-terms__link">
              TERMS
            </a>
          </p>
        }
      />
    </div>
  );
};
OnboardingTerms.propTypes = {
  areTermsAccepted: PropTypes.bool,
  setAreTermsAccepted: PropTypes.func,
  isVisible: PropTypes.bool,
};

export default OnboardingTerms;
