import { useEffect, useState } from 'react';
import { textAlignOptions } from '../ThemesForm';

const getState = (info) => ({
  order: info.order ?? 7,
  padding: info.padding ?? '',
  backgroundColor: info.backgroundColor ?? '',
  link: {
    isVisible: info?.link?.isVisible ?? true,
    color: info?.link?.color ?? '',
    fontSize: info?.link?.fontSize ?? '',
    textAlign: info?.link?.textAlign ?? '',
  },
  legal: {
    isVisible: info?.legal?.isVisible ?? true,
    content: info?.legal?.content ?? '',
    color: info?.legal?.color ?? '',
    fontSize: info?.legal?.fontSize ?? '',
    textAlign: info?.legal?.textAlign ?? '',
  },
  medium: {
    order: info?.medium?.order ?? 7,
    padding: info?.medium?.padding ?? '',
    link: {
      fontSize: info?.medium?.link?.padding ?? '',
      textAlign: info?.medium?.link?.textAlign ?? '',
    },
    legal: {
      fontSize: info?.medium?.legal?.padding ?? '',
      textAlign: info?.medium?.legal?.textAlign ?? '',
    },
  },
  large: {
    order: info?.large?.order ?? 7,
    padding: info?.large?.padding ?? '',
    link: {
      fontSize: info?.large?.link?.padding ?? '',
      textAlign: info?.large?.link?.textAlign ?? '',
    },
    legal: {
      fontSize: info?.large?.legal?.padding ?? '',
      textAlign: info?.large?.legal?.textAlign ?? '',
    },
  },
});

const useFooterInfo = (info) => {
  const [footerInfo, setFooterInfo] = useState(getState(info));

  useEffect(() => {
    setFooterInfo(getState(info));
  }, [info]);

  const handleChange = (propertyName, value, element = '', breakpoint = '') => {
    const newInfo = { ...footerInfo };

    if (breakpoint === 'medium' || breakpoint === 'large') {
      if (element && element !== '') {
        newInfo[breakpoint][element] = {
          ...newInfo[breakpoint][element],
          [propertyName]: value,
        };
      } else {
        newInfo[breakpoint] = {
          ...newInfo[breakpoint],
          [propertyName]: value,
        };
      }
    } else if (element && element !== '') {
      newInfo[element][propertyName] = value;
    } else {
      newInfo[propertyName] = value;
    }

    setFooterInfo(newInfo);
  };

  const inputs = [
    {
      type: 'number',
      value: footerInfo.order,
      onChange: (evt) => handleChange('order', evt.target.value),
      label: 'ORDER',
      name: 'footerOrder',
      placeholder: '7',
    },
    {
      type: 'text',
      value: footerInfo.padding,
      onChange: (evt) => handleChange('padding', evt.target.value),
      label: 'PADDING',
      name: 'footerPadding',
      placeholder: '1.25rem',
    },
    {
      type: 'text',
      value: footerInfo.backgroundColor,
      onChange: (evt) => handleChange('backgroundColor', evt.target.value),
      label: 'BACKGROUND COLOR',
      name: 'footerBackground',
      placeholder: '#ffffff',
    },

    {
      type: 'checkbox',
      value: footerInfo.link.isVisible,
      onChange: (evt) => handleChange('isVisible', evt.target.checked, 'link'),
      label: 'IS LINK VISIBLE?',
      name: 'footerLinkVisible',
    },
    {
      type: 'text',
      value: footerInfo.link.color,
      onChange: (evt) => handleChange('color', evt.target.value, 'link'),
      label: 'LINK COLOR',
      name: 'footerLinkColor',
      placeholder: '#159ff7',
    },
    {
      type: 'text',
      value: footerInfo.link.fontSize,
      onChange: (evt) => handleChange('fontSize', evt.target.value, 'link'),
      label: 'LINK FONT SIZE',
      name: 'footerLinkFontSize',
      placeholder: '1.4rem',
    },
    {
      as: 'select',
      options: textAlignOptions,
      value: footerInfo.link.textAlign,
      onChange: (evt) => handleChange('textAlign', evt.target.value, 'link'),
      label: 'LINK TEXT ALIGN',
      name: 'footerLinkTextAlign',
    },

    {
      type: 'checkbox',
      value: footerInfo.legal.isVisible,
      onChange: (evt) => handleChange('isVisible', evt.target.checked, 'legal'),
      label: 'IS LEGAL TEXT VISIBLE?',
      name: 'footerLegalVisible',
    },
    {
      type: 'text',
      value: footerInfo.legal.content,
      onChange: (evt) => handleChange('content', evt.target.value, 'legal'),
      label: 'LEGAL TEXT CONTENT',
      name: 'footerLegalContent',
      placeholder: 'All rights reserved',
    },
    {
      type: 'text',
      value: footerInfo.legal.color,
      onChange: (evt) => handleChange('color', evt.target.value, 'legal'),
      label: 'LEGAL TEXT COLOR',
      name: 'footerLegalColor',
      placeholder: '#7f7f7f',
    },
    {
      type: 'text',
      value: footerInfo.legal.fontSize,
      onChange: (evt) => handleChange('fontSize', evt.target.value, 'legal'),
      label: 'LEGAL TEXT FONT SIZE',
      name: 'footerLegalFontSize',
      placeholder: '0.5rem',
    },
    {
      as: 'select',
      options: textAlignOptions,
      value: footerInfo.legal.textAlign,
      onChange: (evt) => handleChange('textAlign', evt.target.value, 'legal'),
      label: 'LEGAL TEXT TEXT ALIGN',
      name: 'footerLegalTextAlign',
    },
  ];

  const mediumInputs = [
    {
      type: 'number',
      value: footerInfo.medium.order,
      onChange: (evt) =>
        handleChange('order', evt.target.value, undefined, 'medium'),
      label: 'ORDER',
      name: 'footerOrderMedium',
      placeholder: '7',
    },
    {
      type: 'text',
      value: footerInfo.medium.padding,
      onChange: (evt) =>
        handleChange('padding', evt.target.value, undefined, 'medium'),
      label: 'PADDING',
      name: 'footerPaddingMedium',
      placeholder: '1.25rem',
    },

    {
      type: 'text',
      value: footerInfo.medium.link.fontSize,
      onChange: (evt) =>
        handleChange('fontSize', evt.target.value, 'link', 'medium'),
      label: 'LINK FONT SIZE',
      name: 'footerLinkFontSizeMedium',
      placeholder: '1.4',
    },
    {
      as: 'select',
      options: textAlignOptions,
      value: footerInfo.medium.link.textAlign,
      onChange: (evt) =>
        handleChange('textAlign', evt.target.value, 'link', 'medium'),
      label: 'LINK TEXT ALIGN',
      name: 'footerLinkTextAlignMedium',
    },

    {
      type: 'text',
      value: footerInfo.medium.legal.fontSize,
      onChange: (evt) =>
        handleChange('fontSize', evt.target.value, 'legal', 'medium'),
      label: 'LEGAL TEXT FONT SIZE',
      name: 'footerLegalFontSizeMedium',
      placeholder: '0.5rem',
    },
    {
      as: 'select',
      options: textAlignOptions,
      value: footerInfo.medium.legal.textAlign,
      onChange: (evt) =>
        handleChange('textAlign', evt.target.value, 'legal', 'medium'),
      label: 'LEGAL TEXT TEXT ALIGN',
      name: 'footerLegalTextAlignMedium',
    },
  ];

  const largeInputs = [
    {
      type: 'number',
      value: footerInfo.large.order,
      onChange: (evt) =>
        handleChange('order', evt.target.value, undefined, 'large'),
      label: 'ORDER',
      name: 'footerOrderLarge',
      placeholder: '7',
    },
    {
      type: 'text',
      value: footerInfo.large.padding,
      onChange: (evt) =>
        handleChange('padding', evt.target.value, undefined, 'large'),
      label: 'PADDING',
      name: 'footerPaddingLarge',
      placeholder: '1.25rem',
    },

    {
      type: 'text',
      value: footerInfo.large.link.fontSize,
      onChange: (evt) =>
        handleChange('fontSize', evt.target.value, 'link', 'large'),
      label: 'LINK FONT SIZE',
      name: 'footerLinkFontSizeLarge',
      placeholder: '1.4rem',
    },
    {
      as: 'select',
      options: textAlignOptions,
      value: footerInfo.large.link.textAlign,
      onChange: (evt) =>
        handleChange('textAlign', evt.target.value, 'link', 'large'),
      label: 'LINK TEXT ALIGN',
      name: 'footerLinkTextAlignLarge',
    },

    {
      type: 'text',
      value: footerInfo.large.legal.fontSize,
      onChange: (evt) =>
        handleChange('fontSize', evt.target.value, 'legal', 'large'),
      label: 'LEGAL TEXT FONT SIZE',
      name: 'footerLegalFontSizeLarge',
      placeholder: '0.5rem',
    },
    {
      as: 'select',
      options: textAlignOptions,
      value: footerInfo.large.legal.textAlign,
      onChange: (evt) =>
        handleChange('textAlign', evt.target.value, 'legal', 'large'),
      label: 'LEGAL TEXT TEXT ALIGN',
      name: 'footerLegalTextAlignLarge',
    },
  ];

  return { info: footerInfo, inputs, mediumInputs, largeInputs };
};

export default useFooterInfo;
