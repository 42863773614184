import React from 'react';
import PropTypes from 'prop-types';
import InputMask from 'react-input-mask';
import FormControl from '../FormControl/FormControl';

const MaskedFormControl = ({ mask, maskChar, visibility, ...rest }) => {
  if (visibility === false) return null;

  return (
    <InputMask
      mask={mask}
      maskChar={maskChar}
      value={rest.value}
      onChange={rest.onChange}>
      {() => <FormControl {...rest} />}
    </InputMask>
  );
};

MaskedFormControl.propTypes = {
  mask: PropTypes.string,
  maskChar: PropTypes.string,
  visibility: PropTypes.bool,
};

export default MaskedFormControl;
