import React, { useEffect, useState } from 'react';
import { flexFlowOptions, justifyContentOptions } from '../ThemesForm';

const getState = (info) => ({
  order: info.order ?? 4,
  flexFlow: info.flexFlow ?? '',
  justifyContent: info.justifyContent ?? '',
  width: info.width ?? '',
  padding: info.padding ?? '',
  linkHeight: info.linkHeight ?? '30px',
  backgroundColor: info.backgroundColor ?? '',
  facebook: {
    order: info?.facebook?.order ?? 1,
    padding: info?.facebook?.padding ?? '',
    margin: info?.facebok?.margin ?? '',
    imgSrc: info?.facebook?.imgSrc ?? '/images/facebook.png',
  },
  twitter: {
    order: info?.twitter?.order ?? 2,
    padding: info?.twitter?.padding ?? '',
    margin: info?.twitter?.margin ?? '',
    imgSrc: info?.twitter?.imgSrc ?? '/images/twitter.png',
  },
  instagram: {
    order: info?.instagram?.order ?? 3,
    padding: info?.instagram?.padding ?? '',
    margin: info?.instagram?.margin ?? '',
    imgSrc: info?.instagram?.imgSrc ?? '/images/instagram.png',
  },
  linkedin: {
    order: info?.linkedin?.order ?? 4,
    padding: info?.linkedin?.padding ?? '',
    margin: info?.linkedin?.margin ?? '',
    imgSrc: info?.linkedin?.imgSrc ?? '/images/linkedin.png',
  },
  medium: {
    order: info?.medium?.order ?? 4,
    flexFlow: info?.medium?.flexFlow ?? '',
    justifyContent: info?.medium?.justifyContent ?? '',
    width: info?.medium?.width ?? '',
    padding: info?.medium?.padding ?? '',
    linkHeight: info?.medium?.linkHeight ?? '',
    facebook: {
      order: info?.medium?.facebok?.order ?? 1,
      padding: info?.medium?.facebook?.padding ?? '',
      margin: info?.medium?.facebok?.margin ?? '',
    },
    twitter: {
      order: info?.medium?.twitter?.order ?? 2,
      padding: info?.medium?.twitter?.padding ?? '',
      margin: info?.medium?.twitter?.margin ?? '',
    },
    instagram: {
      order: info?.medium?.instagram?.order ?? 3,
      padding: info?.medium?.instagram?.padding ?? '',
      margin: info?.medium?.instagram?.margin ?? '',
    },
    linkedin: {
      order: info?.medium?.linkedin?.order ?? 4,
      padding: info?.medium?.linkedin?.padding ?? '',
      margin: info?.medium?.linkedin?.margin ?? '',
    },
  },
  large: {
    order: info?.large?.order ?? 4,
    flexFlow: info?.large?.flexFlow ?? '',
    justifyContent: info?.large?.justifyContent ?? '',
    width: info?.large?.width ?? '',
    padding: info?.large?.padding ?? '',
    linkHeight: info?.large?.linkHeight ?? '',
    facebook: {
      order: info?.large?.facebok?.order ?? 1,
      padding: info?.large?.facebook?.padding ?? '',
      margin: info?.large?.facebok?.margin ?? '',
    },
    twitter: {
      order: info?.large?.twitter?.order ?? 2,
      padding: info?.large?.twitter?.padding ?? '',
      margin: info?.large?.twitter?.margin ?? '',
    },
    instagram: {
      order: info?.large?.instagram?.order ?? 3,
      padding: info?.large?.instagram?.padding ?? '',
      margin: info?.large?.instagram?.margin ?? '',
    },
    linkedin: {
      order: info?.large?.linkedin?.order ?? 4,
      padding: info?.large?.linkedin?.padding ?? '',
      margin: info?.large?.linkedin?.margin ?? '',
    },
  },
});

const useSocialsInfo = (info) => {
  const [socialsInfo, setSocialsInfo] = useState(getState(info));

  useEffect(() => {
    setSocialsInfo(getState(info));
  }, [info]);

  const handleChange = (propertyName, value, element = '', breakpoint = '') => {
    const newInfo = { ...socialsInfo };

    if (breakpoint === 'medium' || breakpoint === 'large') {
      if (element && element !== '') {
        newInfo[breakpoint][element] = {
          ...newInfo[breakpoint][element],
          [propertyName]: value,
        };
      } else {
        newInfo[breakpoint] = {
          ...newInfo[breakpoint],
          [propertyName]: value,
        };
      }
    } else if (element && element !== '') {
      newInfo[element][propertyName] = value;
    } else {
      newInfo[propertyName] = value;
    }

    setSocialsInfo(newInfo);
  };

  const inputs = [
    {
      type: 'number',
      value: socialsInfo.order,
      onChange: (evt) => handleChange('order', evt.target.value),
      label: 'ORDER',
      name: 'socialsOrder',
      placeholder: '4',
    },
    {
      as: 'select',
      options: flexFlowOptions,
      value: socialsInfo.flexFlow,
      onChange: (evt) => handleChange('flexFlow', evt.target.value),
      label: (
        <abbr title="Horizontal - row, vertical - column">
          DIRECTION &amp; WRAPPING
        </abbr>
      ),
      name: 'socialsFlexFlow',
    },
    {
      as: 'select',
      options: justifyContentOptions,
      value: socialsInfo.justifyContent,
      onChange: (evt) => handleChange('justifyContent', evt.target.value),
      label: 'ELEMENTS ALIGNMENT',
      name: 'socialsJustifyContent',
    },
    {
      type: 'text',
      value: socialsInfo.width,
      onChange: (evt) => handleChange('width', evt.target.value),
      label: 'WIDTH',
      name: 'socialsWidth',
      placeholder: '100%',
    },
    {
      type: 'text',
      value: socialsInfo.padding,
      onChange: (evt) => handleChange('padding', evt.target.value),
      label: 'PADDING',
      name: 'socialsPadding',
      placeholder: '0.3rem 0 0.3rem 1.5rem',
    },
    {
      type: 'text',
      value: socialsInfo.linkHeight,
      onChange: (evt) => handleChange('linkHeight', evt.target.value),
      label: 'LINK HEIGHT',
      name: 'socialsLinkHeight',
      placeholder: '35px',
    },
    {
      type: 'text',
      value: socialsInfo.backgroundColor,
      onChange: (evt) => handleChange('backgroundColor', evt.target.value),
      label: 'BACKGROUND COLOR',
      name: 'socialsBackgroundColor',
      placeholder: '#ffffff',
    },

    {
      type: 'number',
      value: socialsInfo.facebook.order,
      onChange: (evt) => handleChange('order', evt.target.value, 'facebook'),
      label: 'FACEBOOK ORDER',
      name: 'socialsOrderFacebook',
      placeholder: '1',
    },
    {
      type: 'text',
      value: socialsInfo.facebook.padding,
      onChange: (evt) => handleChange('padding', evt.target.value, 'facebook'),
      label: 'FACEBOOK PADDING',
      name: 'socialsPaddingFacebook',
      placeholder: '0.3rem',
    },
    {
      type: 'text',
      value: socialsInfo.facebook.margin,
      onChange: (evt) => handleChange('margin', evt.target.value, 'facebook'),
      label: 'FACEBOOK MARGIN',
      name: 'socialsMarginFacebook',
      placeholder: '0 0.5rem 0 0',
    },
    {
      type: 'imgSrc',
      value: socialsInfo.facebook.imgSrc,
      onChange: (evt) => handleChange('imgSrc', evt.target.value, 'facebook'),
      label: 'FACEBOOK IMAGE SOURCE',
      name: 'socialsImgSrcFacebook',
      placeholder: '/images/facebook.png',
    },

    {
      type: 'number',
      value: socialsInfo.twitter.order,
      onChange: (evt) => handleChange('order', evt.target.value, 'twitter'),
      label: 'TWITTER ORDER',
      name: 'socialsOrderTwitter',
      placeholder: '2',
    },
    {
      type: 'text',
      value: socialsInfo.twitter.padding,
      onChange: (evt) => handleChange('padding', evt.target.value, 'twitter'),
      label: 'TWITTER PADDING',
      name: 'socialsPaddingTwitter',
      placeholder: '0.3rem',
    },
    {
      type: 'text',
      value: socialsInfo.twitter.margin,
      onChange: (evt) => handleChange('margin', evt.target.value, 'twitter'),
      label: 'TWITTER MARGIN',
      name: 'socialsMarginTwitter',
      placeholder: '0 0.5rem 0 0',
    },
    {
      type: 'imgSrc',
      value: socialsInfo.twitter.imgSrc,
      onChange: (evt) => handleChange('imgSrc', evt.target.value, 'twitter'),
      label: 'TWITTER IMAGE SOURCE',
      name: 'socialsImgSrcTwitter',
      placeholder: '/images/twitter.png',
    },

    {
      type: 'number',
      value: socialsInfo.instagram.order,
      onChange: (evt) => handleChange('order', evt.target.value, 'instagram'),
      label: 'INSTAGRAM ORDER',
      name: 'socialsOrderInstagram',
      placeholder: '3',
    },
    {
      type: 'text',
      value: socialsInfo.instagram.padding,
      onChange: (evt) => handleChange('padding', evt.target.value, 'instagram'),
      label: 'INSTAGRAM PADDING',
      name: 'socialsPaddingInstagram',
      placeholder: '0.3rem',
    },
    {
      type: 'text',
      value: socialsInfo.instagram.margin,
      onChange: (evt) => handleChange('margin', evt.target.value, 'instagram'),
      label: 'INSTAGRAM MARGIN',
      name: 'socialsMarginInstagram',
      placeholder: '0 0.5rem 0 0',
    },
    {
      type: 'imgSrc',
      value: socialsInfo.instagram.imgSrc,
      onChange: (evt) => handleChange('imgSrc', evt.target.value, 'instagram'),
      label: 'LINKEDIN IMAGE SOURCE',
      name: 'socialsImgSrcInstagram',
      placeholder: '/images/instagram.png',
    },

    {
      type: 'number',
      value: socialsInfo.linkedin.order,
      onChange: (evt) => handleChange('order', evt.target.value, 'linkedin'),
      label: 'LINKEDIN ORDER',
      name: 'socialsOrderLinkedin',
      placeholder: '4',
    },
    {
      type: 'text',
      value: socialsInfo.linkedin.padding,
      onChange: (evt) => handleChange('padding', evt.target.value, 'linkedin'),
      label: 'LINKEDIN PADDING',
      name: 'socialsPaddingLinkedin',
      placeholder: '0.3rem',
    },
    {
      type: 'text',
      value: socialsInfo.linkedin.margin,
      onChange: (evt) => handleChange('margin', evt.target.value, 'linkedin'),
      label: 'LINKEDIN MARGIN',
      name: 'socialsMarginLinkedin',
      placeholder: '0 0.5rem 0 0',
    },
    {
      type: 'imgSrc',
      value: socialsInfo.linkedin.imgSrc,
      onChange: (evt) => handleChange('imgSrc', evt.target.value, 'linkedin'),
      label: 'LINKEDIN IMAGE SOURCE',
      name: 'socialsImgSrcLinkedin',
      placeholder: '/images/linkedin.png',
    },
  ];

  const mediumInputs = [
    {
      type: 'number',
      value: socialsInfo.medium.order,
      onChange: (evt) =>
        handleChange('order', evt.target.value, undefined, 'medium'),
      label: 'ORDER',
      name: 'socialsOrderMedium',
      placeholder: '4',
    },
    {
      as: 'select',
      options: flexFlowOptions,
      value: socialsInfo.medium.flexFlow,
      onChange: (evt) =>
        handleChange('flexFlow', evt.target.value, undefined, 'medium'),
      label: (
        <abbr title="Horizontal - row, vertical - column">
          DIRECTION &amp; WRAPPING
        </abbr>
      ),
      name: 'socialsFlexFlowMedium',
    },
    {
      as: 'select',
      options: justifyContentOptions,
      value: socialsInfo.medium.justifyContent,
      onChange: (evt) =>
        handleChange('justifyContent', evt.target.value, undefined, 'medium'),
      label: 'ELEMENTS ALIGNMENT',
      name: 'socialsJustifyContentMedium',
    },
    {
      type: 'text',
      value: socialsInfo.medium.width,
      onChange: (evt) =>
        handleChange('width', evt.target.value, undefined, 'medium'),
      label: 'WIDTH',
      name: 'socialsWidthMedium',
      placeholder: '100%',
    },
    {
      type: 'text',
      value: socialsInfo.medium.padding,
      onChange: (evt) =>
        handleChange('padding', evt.target.value, undefined, 'medium'),
      label: 'PADDING',
      name: 'socialsPaddingMedium',
      placeholder: '0.3rem 0 0.3rem 1.5rem',
    },
    {
      type: 'text',
      value: socialsInfo.medium.linkHeight,
      onChange: (evt) =>
        handleChange('linkHeight', evt.target.value, undefined, 'medium'),
      label: 'LINK HEIGHT',
      name: 'socialsLinkHeightMedium',
      placeholder: '30px',
    },

    {
      type: 'number',
      value: socialsInfo.medium.facebook.order,
      onChange: (evt) =>
        handleChange('order', evt.target.value, 'facebook', 'medium'),
      label: 'FACEBOOK ORDER',
      name: 'socialsOrderFacebookMedium',
      placeholder: '1',
    },
    {
      type: 'text',
      value: socialsInfo.medium.facebook.padding,
      onChange: (evt) =>
        handleChange('padding', evt.target.value, 'facebook', 'medium'),
      label: 'FACEBOOK PADDING',
      name: 'socialsPaddingFacebookMedium',
      placeholder: '0.3rem',
    },
    {
      type: 'text',
      value: socialsInfo.medium.facebook.margin,
      onChange: (evt) =>
        handleChange('margin', evt.target.value, 'facebook', 'medium'),
      label: 'FACEBOOK MARGIN',
      name: 'socialsMarginFacebookMedium',
      placeholder: '0 0.5rem 0 0',
    },

    {
      type: 'number',
      value: socialsInfo.medium.twitter.order,
      onChange: (evt) =>
        handleChange('order', evt.target.value, 'twitter', 'medium'),
      label: 'TWITTER ORDER',
      name: 'socialsOrderTwitterMedium',
      placeholder: '2',
    },
    {
      type: 'text',
      value: socialsInfo.medium.twitter.padding,
      onChange: (evt) =>
        handleChange('padding', evt.target.value, 'twitter', 'medium'),
      label: 'TWITTER PADDING',
      name: 'socialsPaddingTwitterMedium',
      placeholder: '0.3rem',
    },
    {
      type: 'text',
      value: socialsInfo.medium.twitter.margin,
      onChange: (evt) =>
        handleChange('margin', evt.target.value, 'twitter', 'medium'),
      label: 'TWITTER MARGIN',
      name: 'socialsMarginTwitterMedium',
      placeholder: '0 0.5rem 0 0',
    },

    {
      type: 'number',
      value: socialsInfo.medium.instagram.order,
      onChange: (evt) =>
        handleChange('order', evt.target.value, 'instagram', 'medium'),
      label: 'INSTAGRAM ORDER',
      name: 'socialsOrderInstagramMedium',
      placeholder: '3',
    },
    {
      type: 'text',
      value: socialsInfo.medium.instagram.padding,
      onChange: (evt) =>
        handleChange('padding', evt.target.value, 'instagram', 'medium'),
      label: 'INSTAGRAM PADDING',
      name: 'socialsPaddingInstagramMedium',
      placeholder: '0.3rem',
    },
    {
      type: 'text',
      value: socialsInfo.medium.instagram.margin,
      onChange: (evt) =>
        handleChange('margin', evt.target.value, 'instagram', 'medium'),
      label: 'INSTAGRAM MARGIN',
      name: 'socialsMarginInstagramMedium',
      placeholder: '0 0.5rem 0 0',
    },

    {
      type: 'number',
      value: socialsInfo.medium.linkedin.order,
      onChange: (evt) =>
        handleChange('order', evt.target.value, 'linkedin', 'medium'),
      label: 'LINKEDIN ORDER',
      name: 'socialsOrderLinkedinMedium',
      placeholder: '4',
    },
    {
      type: 'text',
      value: socialsInfo.medium.linkedin.padding,
      onChange: (evt) =>
        handleChange('padding', evt.target.value, 'linkedin', 'medium'),
      label: 'LINKEDIN PADDING',
      name: 'socialsPaddingLinkedinMedium',
      placeholder: '0.3rem',
    },
    {
      type: 'text',
      value: socialsInfo.medium.linkedin.margin,
      onChange: (evt) =>
        handleChange('margin', evt.target.value, 'linkedin', 'medium'),
      label: 'LINKEDIN MARGIN',
      name: 'socialsMarginLinkedinMedium',
      placeholder: '0 0.5rem 0 0',
    },
  ];

  const largeInputs = [
    {
      type: 'number',
      value: socialsInfo.large.order,
      onChange: (evt) =>
        handleChange('order', evt.target.value, undefined, 'large'),
      label: 'ORDER',
      name: 'socialsOrderLarge',
      placeholder: '4',
    },
    {
      as: 'select',
      options: flexFlowOptions,
      value: socialsInfo.large.flexFlow,
      onChange: (evt) =>
        handleChange('flexFlow', evt.target.value, undefined, 'large'),
      label: (
        <abbr title="Horizontal - row, vertical - column">
          DIRECTION &amp; WRAPPING
        </abbr>
      ),
      name: 'socialsFlexFlowLarge',
    },
    {
      as: 'select',
      options: justifyContentOptions,
      value: socialsInfo.large.justifyContent,
      onChange: (evt) =>
        handleChange('justifyContent', evt.target.value, undefined, 'large'),
      label: 'ELEMENTS ALIGNMENT',
      name: 'socialsJustifyContentLarge',
    },
    {
      type: 'text',
      value: socialsInfo.large.width,
      onChange: (evt) =>
        handleChange('width', evt.target.value, undefined, 'large'),
      label: 'WIDTH',
      name: 'socialsWidthLarge',
      placeholder: '4',
    },
    {
      type: 'text',
      value: socialsInfo.large.padding,
      onChange: (evt) =>
        handleChange('padding', evt.target.value, undefined, 'large'),
      label: 'PADDING',
      name: 'socialsPaddingLarge',
      placeholder: '0.3rem 0 0.3rem 2.2rem',
    },
    {
      type: 'text',
      value: socialsInfo.large.linkHeight,
      onChange: (evt) =>
        handleChange('linkHeight', evt.target.value, undefined, 'large'),
      label: 'LINK HEIGHT',
      name: 'socialsLinkHeightLarge',
      placeholder: '40px',
    },

    {
      type: 'number',
      value: socialsInfo.large.facebook.order,
      onChange: (evt) =>
        handleChange('order', evt.target.value, 'facebook', 'large'),
      label: 'FACEBOOK ORDER',
      name: 'socialsOrderFacebookLarge',
      placeholder: '1',
    },
    {
      type: 'text',
      value: socialsInfo.large.facebook.padding,
      onChange: (evt) =>
        handleChange('padding', evt.target.value, 'facebook', 'large'),
      label: 'FACEBOOK PADDING',
      name: 'socialsPaddingFacebookLarge',
      placeholder: '0.3rem',
    },
    {
      type: 'text',
      value: socialsInfo.large.facebook.margin,
      onChange: (evt) =>
        handleChange('margin', evt.target.value, 'facebook', 'large'),
      label: 'FACEBOOK MARGIN',
      name: 'socialsMarginFacebookLarge',
      placeholder: '0 0.5rem 0 0',
    },

    {
      type: 'number',
      value: socialsInfo.large.twitter.order,
      onChange: (evt) =>
        handleChange('order', evt.target.value, 'twitter', 'large'),
      label: 'TWITTER ORDER',
      name: 'socialsOrderTwitterLarge',
      placeholder: '2',
    },
    {
      type: 'text',
      value: socialsInfo.large.twitter.padding,
      onChange: (evt) =>
        handleChange('padding', evt.target.value, 'twitter', 'large'),
      label: 'TWITTER PADDING',
      name: 'socialsPaddingTwitterLarge',
      placeholder: '0.3rem',
    },
    {
      type: 'text',
      value: socialsInfo.large.twitter.margin,
      onChange: (evt) =>
        handleChange('margin', evt.target.value, 'twitter', 'large'),
      label: 'TWITTER MARGIN',
      name: 'socialsMarginTwitterLarge',
      placeholder: '0 0.5rem 0 0',
    },

    {
      type: 'number',
      value: socialsInfo.large.instagram.order,
      onChange: (evt) =>
        handleChange('order', evt.target.value, 'instagram', 'large'),
      label: 'INSTAGRAM ORDER',
      name: 'socialsOrderInstagramLarge',
      placeholder: '3',
    },
    {
      type: 'text',
      value: socialsInfo.large.instagram.padding,
      onChange: (evt) =>
        handleChange('padding', evt.target.value, 'instagram', 'large'),
      label: 'INSTAGRAM PADDING',
      name: 'socialsPaddingInstagramLarge',
      placeholder: '0.3rem',
    },
    {
      type: 'text',
      value: socialsInfo.large.instagram.margin,
      onChange: (evt) =>
        handleChange('margin', evt.target.value, 'instagram', 'large'),
      label: 'INSTAGRAM MARGIN',
      name: 'socialsMarginInstagramLarge',
      placeholder: '0 0.5rem 0 0',
    },

    {
      type: 'number',
      value: socialsInfo.large.linkedin.order,
      onChange: (evt) =>
        handleChange('order', evt.target.value, 'linkedin', 'large'),
      label: 'LINKEDIN ORDER',
      name: 'socialsOrderLinkedinLarge',
      placeholder: '4',
    },
    {
      type: 'text',
      value: socialsInfo.large.linkedin.padding,
      onChange: (evt) =>
        handleChange('padding', evt.target.value, 'linkedin', 'large'),
      label: 'LINKEDIN PADDING',
      name: 'socialsPaddingLinkedinLarge',
    },
    {
      type: 'text',
      value: socialsInfo.large.linkedin.margin,
      onChange: (evt) =>
        handleChange('margin', evt.target.value, 'linkedin', 'large'),
      label: 'LINKEDIN MARGIN',
      name: 'socialsMarginLinkedinLarge',
      placeholder: '0 0.5rem 0 0',
    },
  ];

  return { info: socialsInfo, inputs, mediumInputs, largeInputs };
};

export default useSocialsInfo;
