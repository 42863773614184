import React, { useEffect, useState } from 'react';
import './PasswordChangeForm.scss';
import { useHistory, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import PropTypes from 'prop-types';
import FormControl from '../FormControl/FormControl';
import { prepareErrorMessage, validatePassword } from '../../utils/helper';
import Button from '../Button/Button';
import { assignPasswordToNewUser, changePassword } from '../../utils/apiCalls';
import store, { NotificationTypes } from '../../hooks/NotificationHub';
import { useAuth } from '../../hooks/Auth';

const PasswordChangeForm = ({ isActivatingAccount }) => {
  const { search } = useLocation();
  const history = useHistory();
  const auth = useAuth();

  const [isLoading, setIsLoading] = useState(false);

  const [emailParam, setEmail] = useState('');
  const [hashParam, setHash] = useState('');

  const [password, setPassword] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  const [passwordConfirmation, setPasswordConfirmation] = useState('');
  const [passwordConfirmationError, setPasswordConfirmationError] = useState(
    '',
  );
  const [
    isPasswordConfirmationVisible,
    setIsPasswordConfirmationVisible,
  ] = useState(false);

  useEffect(() => {
    // logging user out if they want to activate another account
    if (auth.data.isAuthenticated) {
      auth.logout();
    }

    if (!search) {
      history.push('/login');
    } else if (search) {
      const { email, hash } = queryString.parse(search);

      if (!email || !hash) {
        history.push('/login');
      } else {
        setEmail(email);
        setHash(hash);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onPasswordInput = (newPassword) => {
    const errorMessage = validatePassword(newPassword);

    if (errorMessage !== '') {
      setPasswordError(errorMessage);
    } else {
      setPasswordError('');
    }

    if (newPassword === passwordConfirmation) {
      setPasswordConfirmationError('');
    }

    setPassword(newPassword);
  };

  const onPasswordConfirmationInput = (newPasswordConfirmation) => {
    if (newPasswordConfirmation !== password) {
      setPasswordConfirmationError('Passwords have to match');
    } else {
      setPasswordConfirmationError('');
    }

    setPasswordConfirmation(newPasswordConfirmation);
  };

  const handleSubmit = async (evt) => {
    evt.preventDefault();
    setIsLoading(true);

    let valid = true;
    const errorMessage = validatePassword(password);

    if (password.trim().length === 0) {
      setPasswordError('Password cannot be empty');
      valid = false;
    } else {
      setPasswordError('');
    }

    if (errorMessage !== '') {
      valid = false;
      setPasswordError(errorMessage);
    } else {
      setPasswordError('');
    }

    if (passwordConfirmation !== password) {
      setPasswordConfirmationError('Passwords have to match');
      valid = false;
    } else {
      setPasswordConfirmationError('');
    }

    if (!valid) {
      setIsLoading(false);
      return;
    }

    try {
      const response = !isActivatingAccount
        ? await changePassword(
            emailParam,
            hashParam,
            password,
            passwordConfirmation,
          )
        : await assignPasswordToNewUser(
            emailParam,
            hashParam,
            password,
            passwordConfirmation,
          );

      if (response.status) {
        setIsLoading(false);
        store.pushNotification(
          'Success',
          response.message ?? 'Password saved successfully.',
          NotificationTypes.SUCCESS,
        );
        history.push('/login');
      }
    } catch (err) {
      setIsLoading(false);

      store.pushNotification(
        err.message ?? 'Error',
        prepareErrorMessage(err.errors) ??
          'Could not save your password. Please try again later.',
        NotificationTypes.DANGER,
      );
      history.push('/login');
    }
  };

  return (
    <form className="password-change" onSubmit={handleSubmit}>
      <h1 className="password-change__header">
        {isActivatingAccount ? 'Assign your password' : 'Change your password'}
      </h1>
      <FormControl
        type={!isPasswordVisible ? 'password' : 'text'}
        name="password"
        label="PASSWORD"
        value={password}
        onChange={(evt) => onPasswordInput(evt.target.value)}
        err={passwordError}
        icon={(className, onClick) => (
          <FontAwesomeIcon
            icon={!isPasswordVisible ? faEye : faEyeSlash}
            className={`main__icon ${className}`}
            onClick={onClick}
          />
        )}
        iconAlignment="right"
        isIconInside
        onIconClick={() => setIsPasswordVisible(!isPasswordVisible)}
        autocomplete="on"
      />
      <FormControl
        type={!isPasswordConfirmationVisible ? 'password' : 'text'}
        name="passwordConfirmation"
        label="CONFIRM PASSWORD"
        value={passwordConfirmation}
        onChange={(evt) => onPasswordConfirmationInput(evt.target.value)}
        err={passwordConfirmationError}
        icon={(className, onClick) => (
          <FontAwesomeIcon
            icon={!isPasswordConfirmationVisible ? faEye : faEyeSlash}
            className={`main__icon ${className}`}
            onClick={onClick}
          />
        )}
        iconAlignment="right"
        isIconInside
        onIconClick={() =>
          setIsPasswordConfirmationVisible(!isPasswordConfirmationVisible)
        }
      />
      <Button
        variant="secondary"
        text="RETURN"
        onClick={() => history.push('/login')}
      />
      <Button
        variant="primary"
        text="SAVE"
        type="submit"
        state={isLoading ? 'loading' : ''}
      />
    </form>
  );
};

PasswordChangeForm.propTypes = {
  isActivatingAccount: PropTypes.bool,
};

export default PasswordChangeForm;
