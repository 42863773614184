import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import logo from '../../sharelocal.svg';
import './Sidebar.scss';
import SidebarLink from './SidebarLink/SidebarLink';
import { useAuth } from '../../hooks/Auth';
import { links } from '../../data/Sidebar';
import SubNavigation from './SubNavigation/SubNavigation';

const Sidebar = ({ disabled }) => {
  const auth = useAuth();
  const history = useHistory();

  return (
    <nav className={`sidebar ${disabled ? 'sidebar--disabled' : ''}`}>
      <div className="sidebar__logo">
        <img src={logo} className="logo__image" alt="Site logo" />
      </div>
      <ul className="sidebar__nav">
        {links.map((link) => (
          <SidebarLink
            {...link}
            key={link.href}
            onClick={() => {
              if (link.onClick) {
                link.onClick(auth, history);
              }
            }}
          />
        ))}
        {String(auth.data.user.type) === '1' && <SubNavigation />}
        <SidebarLink
          style={{ pointerEvents: 'all', cursor: 'pointer', color: '#feffff' }}
          text="Log out"
          icon={faSignOutAlt}
          href="#"
          onClick={() => {
            auth.logout();
            history.push('/login');
          }}
        />
      </ul>
    </nav>
  );
};

Sidebar.propTypes = {
  disabled: PropTypes.bool,
};

export default Sidebar;
