import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { saveAs } from 'file-saver';
import SectionHeader from '../../SectionHeader/SectionHeader';
import ListControls from './ListControls/ListControls';
import ListEntry from './ListEntry/ListEntry';
import ListFilters from './ListFilters/ListFilters';
import ListHeader from './ListHeader/ListHeader';
import ListPagination from './ListPagination/ListPagination';
import './ContactsList.scss';
import {
  exportContacts,
  getContactsList,
  getDashboardContacts,
} from '../../../utils/apiCalls';
import FileInput from '../../FileInput/FileInput';
import Loader from '../../Loader/Loader';
import store, { NotificationTypes } from '../../../hooks/NotificationHub';

const ContactsList = ({
  toggleReload,
  setMenuVisibility,
  setContactId,
  isOnDashboard,
  dateFilter,
}) => {
  const [filters, setFilters] = useState({
    mobile: '',
    firstName: '',
    lastName: '',
    email: '',
    source: '',
    visitDate: '',
    visitTime: '',
  });
  const setMobile = (evt) =>
    setFilters({ ...filters, mobile: evt.target.value });
  const setFirstName = (evt) =>
    setFilters({ ...filters, firstName: evt.target.value });
  const setLastName = (evt) =>
    setFilters({ ...filters, lastName: evt.target.value });
  const setEmail = (evt) => setFilters({ ...filters, email: evt.target.value });
  const setSource = (evt) =>
    setFilters({ ...filters, source: evt.target.value });
  const setVisitDate = (evt) =>
    setFilters({ ...filters, visitDate: evt.target.value });
  const setVisitTime = (evt) =>
    setFilters({ ...filters, visitTime: evt.target.value });

  const importFileRef = useRef();

  const [contacts, setContacts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [searchInputValue, setSearchInputValue] = useState('');
  const [searchQuery, setSearchQuery] = useState('');
  const [checkedContacts, setCheckedContacts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentRecordCount, setCurrentRecordCount] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [rowCount, setRowCount] = useState(10);
  const [maxRowCount, setMaxRowCount] = useState(0);

  const getContacts = async () => {
    setIsLoading(true);

    try {
      const response = !isOnDashboard
        ? await getContactsList(
            currentPage,
            rowCount,
            filters.mobile,
            filters.firstName,
            filters.lastName,
            filters.email,
            filters.source,
            filters.visitDate,
            filters.visitTime,
            searchQuery,
          )
        : await getDashboardContacts(
            currentPage,
            rowCount,
            filters.mobile,
            filters.firstName,
            filters.lastName,
            filters.email,
            filters.source,
            filters.visitDate,
            filters.visitTime,
            dateFilter,
          );

      setContacts(response?.data?.data);
      setPageCount(response?.data?.meta?.pagination?.total_pages);
      setMaxRowCount(response?.data?.meta?.pagination?.total);
      setCurrentRecordCount(response?.data?.meta?.pagination?.count);
    } catch (err) {
      store.pushNotification(
        'Error',
        'Could not load your contacts at the moment. Please refresh the page or try again later.',
        NotificationTypes.DANGER,
      );
    }

    setIsLoading(false);
  };

  const handleExport = async () => {
    try {
      const response = await exportContacts(checkedContacts);
      saveAs(response.data.path, 'contacts');
    } catch (err) {
      store.pushNotification(
        'Error',
        'Could not export contacts at the moment. Please try again later.',
        NotificationTypes.DANGER,
      );
    }
  };

  useEffect(() => {
    getContacts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toggleReload, currentPage, rowCount, filters, searchQuery, dateFilter]);

  useEffect(() => {
    const timeout = setTimeout(() => getContacts(), 300);

    return () => {
      clearTimeout(timeout);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchQuery]);


   useEffect(() => {
     if (searchInputValue.length >= 3) {
       setSearchQuery(searchInputValue);
     } else if (searchInputValue && searchInputValue.length < 3) {
       setSearchQuery('');
     }
   }, [searchInputValue]);

  return (
    <main className="contacts__list">
      <SectionHeader text="DATABASE" />
      {!isOnDashboard ? (
        <>
          <ListControls
            onAddClick={() => setMenuVisibility(true)}
            onExportClick={handleExport}
            onImportClick={() => importFileRef?.current?.click()}
            searchValue={searchInputValue}
            onSearchChange={(evt) => setSearchInputValue(evt.target.value)}
          />
          <FileInput
            fileExtensions={['.csv']}
            inputRef={importFileRef}
            redirectUrl="/contacts/import"
          />
        </>
      ) : null}
      <ListHeader />
      <ListFilters
        filters={{
          ...filters,
          setMobile,
          setFirstName,
          setLastName,
          setEmail,
          setSource,
          setVisitDate,
          setVisitTime,
        }}
      />
      <main className="list__content">
        {contacts && contacts.length > 0 ? (
          contacts.map((el) => (
            <ListEntry
              key={el.uuid}
              mobile={el.phone}
              firstName={el.first_name}
              lastName={el.last_name}
              email={el.email}
              source={el.source}
              visitDate={el.visit_date}
              visitTime={el.visit_time}
              onCheckboxChange={(evt) => {
                if (evt.target.checked) {
                  setCheckedContacts(checkedContacts.concat(el.uuid));
                } else {
                  setCheckedContacts(
                    checkedContacts.filter((contact) => contact !== el.uuid),
                  );
                }
              }}
              onEditClick={() => {
                setMenuVisibility(true);
                setContactId(el.uuid);
              }}
            />
          ))
        ) : (
          <div className="content__message">There is no data to show</div>
        )}

        <Loader isLoading={isLoading} />
      </main>
      <ListPagination
        firstRecord={(currentPage - 1) * rowCount + 1}
        lastRecord={(currentPage - 1) * rowCount + currentRecordCount}
        totalRecords={maxRowCount || contacts?.length}
        setRowAmount={(evt) =>
          setRowCount(
            evt.target.value === 'All' ? maxRowCount : evt.target.value,
          )
        }
        rowAmount={rowCount}
        onNextClick={() => {
          if (currentPage < pageCount) {
            setCurrentPage(currentPage + 1);
          }
        }}
        onPreviousClick={() => {
          if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
          }
        }}
      />
    </main>
  );
};

ContactsList.propTypes = {
  toggleReload: PropTypes.number,
  setMenuVisibility: PropTypes.func,
  setContactId: PropTypes.func,
  isOnDashboard: PropTypes.bool,
  dateFilter: PropTypes.object,
};

export default ContactsList;
