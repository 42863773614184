import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import SectionHeader from '../../../SectionHeader/SectionHeader';
import './ImportMapping.scss';
import Button from '../../../Button/Button';
import ImportDropdown from '../../../ImportDropdown/ImportDropdown';
import {
  importUsersFile,
  importUsersMapping,
} from '../../../../utils/apiCalls';
import FormControl from '../../../FormControl/FormControl';
import store, { NotificationTypes } from '../../../../hooks/NotificationHub';
import { prepareErrorMessage } from '../../../../utils/helper';

const ImportMapping = () => {
  const { state } = useLocation();
  const history = useHistory();
  const [options, setOptions] = useState(null);
  const [mapping, setMapping] = useState({});
  const [filepath, setFilepath] = useState('');
  const [columns, setColumns] = useState([]);
  const [fileColumns, setFileColumns] = useState([]);
  const [sendEmailChecked, setSendEmailChecked] = useState(false);

  const getMapping = async (file) => {
    try {
      const response = await importUsersFile(file);

      setColumns(response?.data?.columns);
      setFileColumns(response?.data?.fileColumns);
      setFilepath(response?.data?.filePath);
    } catch (err) {
      store.pushNotification(
        'Error',
        'Could not perform user import. Please try again later.',
        NotificationTypes.DANGER,
      );
      history.push('/admin/users');
    }
  };

  const handleSubmit = async () => {
    if (mapping.email === '') {
      return;
    }

    try {
      const response = await importUsersMapping(
        filepath,
        mapping,
        Number(sendEmailChecked) || 0,
      );

      store.pushNotification(
        'Success',
        response.data.message,
        NotificationTypes.SUCCESS,
      );

      history.push('/admin/users');
    } catch (err) {
      store.pushNotification(
        err.message ?? 'Error',
        prepareErrorMessage(err.errors) ??
          'Could not perform user import. Please try again later.',
        NotificationTypes.DANGER,
      );
    }
  };

  useEffect(() => {
    if (!state?.file) {
      history.goBack();
    } else {
      getMapping(state.file);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const newMapping = {};
    columns.forEach((col) => {
      newMapping[col] = '';
    });

    setMapping(newMapping);

    const newOptions = [];
    Object.keys(fileColumns).forEach((key) =>
      newOptions.push({
        value: fileColumns[key],
        text: fileColumns[key],
      }),
    );

    setOptions(newOptions.reverse());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileColumns]);

  return (
    <section className="import-mapping">
      <SectionHeader text="Import users" />
      {columns.map((col) => (
        <ImportDropdown
          dbColumn={col}
          key={col}
          fileColumns={options}
          value={mapping[col]}
          setValue={(evt) =>
            setMapping({ ...mapping, [col]: evt.target.value })
          }
        />
      ))}
      <div className="main__button-wrapper">
        <FormControl
          type="checkbox"
          name="send-email"
          onChange={() => setSendEmailChecked((prev) => !prev)}
          value={sendEmailChecked}
          label="Send email?"
        />
        <Button variant="primary" text="IMPORT" onClick={handleSubmit} />
      </div>
    </section>
  );
};

export default ImportMapping;
