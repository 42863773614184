import React from 'react';
import './ThemesEntry.scss';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCheckCircle,
  faClone,
  faCog,
  faTimesCircle,
} from '@fortawesome/free-solid-svg-icons';
import { useHistory } from 'react-router-dom';

const ThemesEntry = ({ uuid, name, isAvailable }) => {
  const history = useHistory();

  return (
    <div className="themes-entry">
      <div className="themes-entry__col themes-entry__col--name">{name}</div>
      <div className="themes-entry__col themes-entry__col--available">
        <FontAwesomeIcon
          icon={isAvailable ? faCheckCircle : faTimesCircle}
          className={`col__icon ${
            isAvailable ? 'col__icon--available' : 'col__icon--unavailable'
          }`}
        />
      </div>
      <div className="themes-entry__col themes-entry__col--actions">
        <button
          type="button"
          className="col__button col__button--edit"
          onClick={() => history.push(`/admin/themes/edit/${uuid}`)}>
          <FontAwesomeIcon icon={faCog} className="button__icon" />
        </button>
        <button
          type="button"
          className="col__button col__button--clone"
          onClick={() => history.push(`/admin/themes/add/${uuid}`)}>
          <FontAwesomeIcon icon={faClone} className="button__icon" />
        </button>
      </div>
    </div>
  );
};

ThemesEntry.propTypes = {
  uuid: PropTypes.string,
  name: PropTypes.string,
  isAvailable: PropTypes.bool,
};

export default ThemesEntry;
