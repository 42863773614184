export const contactsFormSettings = {
  firstName: {
    visibility: true,
    width: '50%',
    order: 1,
    required: true,
  },
  lastName: {
    visibility: true,
    width: '50%',
    order: 2,
    required: true,
  },
  title: { visibility: true, width: '50%', order: 3, required: false },
  company: {
    visibility: true,
    width: '50%',
    order: 4,
    disabled: false,
    required: false,
  },
  phone: {
    visibility: true,
    width: '50%',
    order: 7,
    required: false,
    minLength: 10,
  },
  mobile: {
    visibility: true,
    width: '50%',
    order: 7,
    required: false,
    minLength: 10,
  },
  email: {
    visibility: true,
    width: '100%',
    order: 5,
    required: false,
  },
  myWebsiteUrl: {
    visibility: true,
    width: '100%',
    order: 6,
    required: false,
  },
  street: {
    visibility: true,
    width: '66%',
    order: 8,
    required: false,
  },
  apt: {
    visibility: true,
    width: '25%',
    order: 9,
    required: false,
  },
  city: { visibility: true, width: '66%', order: 10, required: false },
  state: {
    visibility: true,
    width: '25%',
    order: 11,
    required: false,
  },
  country: {
    visibility: true,
    width: '25%',
    order: 12,
    required: false,
  },
  zipCode: {
    visibility: true,
    width: '50%',
    order: 13,
    required: false,
  },
};

export const emptyContactsState = {
  firstName: '',
  lastName: '',
  company: '',
  phone: '',
  mobile: '',
  street: '',
  apt: '',
  city: '',
  state: '',
  country: '',
  zipCode: '',
  email: '',
  facebookUrl: '',
  twitterUrl: '',
  instagramUrl: '',
  linkedinUrl: '',
  visitDate: '',
  visitTime: '',
  message: '',
};
