import React from 'react';
import './Keywords.scss';
import { Route, Switch } from 'react-router-dom';
import PageHeader from '../../PageHeader/PageHeader';
import KeywordsTable from './KeywordsTable/KeywordsTable';

const Keywords = () => {
  return (
    <section className="keywords">
      <PageHeader text="Keywords" />
      <Switch>
        <Route exact path="/admin/keywords">
          <KeywordsTable />
        </Route>
        {/* <Route path="/admin/users/edit/:id">
          <UsersForm header="Edit user" />
        </Route> */}
      </Switch>
    </section>
  );
};

export default Keywords;
