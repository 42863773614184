import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import './PasswordReminder.scss';
import FormControl from '../FormControl/FormControl';
import Button from '../Button/Button';
import { sendPasswordRemider } from '../../utils/apiCalls';
import store, { NotificationTypes } from '../../hooks/NotificationHub';

const PasswordReminder = () => {
  const history = useHistory();

  const [isLoading, setIsLoading] = useState(false);

  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');

  const onSubmit = async (evt) => {
    evt.preventDefault();
    setIsLoading(true);

    if (email.trim().length === 0) {
      setEmailError('Email cannot be empty');
      setIsLoading(false);
      return;
    }

    setEmailError('');

    try {
      const response = await sendPasswordRemider(email);

      setIsLoading(false);
      store.pushNotification(
        'Success',
        response.data.message,
        NotificationTypes.SUCCESS,
      );
      history.push('/login');
    } catch (err) {
      setIsLoading(false);
      store.pushNotification(
        err?.message ?? 'Error',
        (err?.errors?.email && err?.errors?.email[0]) ??
          'Could not send password reminder at the moment. Please verify that your email is correct and try again later.',
        NotificationTypes.DANGER,
      );
    }
  };

  return (
    <form className="password-reminder" onSubmit={onSubmit}>
      <p className="password-reminder__text">
        Forgot your password or having trouble logging in? Enter your email
        address and you will receive a recovery link if that account exists.
      </p>
      <FormControl
        type="email"
        name="email"
        label="EMAIL"
        value={email}
        onChange={(evt) => setEmail(evt.target.value)}
        err={emailError}
        autocomplete="on"
      />
      <Button
        variant="secondary"
        text="RETURN"
        type="button"
        onClick={() => history.push('/login')}
      />
      <Button
        variant="primary"
        text="SEND"
        type="submit"
        state={isLoading ? 'loading' : ''}
        disabled={isLoading}
      />
    </form>
  );
};

export default PasswordReminder;
