import React, { useState, useEffect } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import './Login.scss';
import FormControl from '../FormControl/FormControl';
import Button from '../Button/Button';
import { LOGIN_ERROR_NOTIFICATION_MESSAGE } from '../../data/DefaultMessages';
import { useAuth } from '../../hooks/Auth';
import { changeEmail, verifyAccount } from '../../utils/apiCalls';
import store, { NotificationTypes } from '../../hooks/NotificationHub';
import { prepareErrorMessage } from '../../utils/helper';

export const loginBottomComponent = (
  <p className="login__registration-redirect">
    Don&apos;t have an account? Register&nbsp;
    <Link to="/register" className="registration-redirect__link">
      here
    </Link>
  </p>
);

const Login = () => {
  const { search, pathname } = useLocation();
  const history = useHistory();
  const auth = useAuth();

  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');

  const [password, setPassword] = useState('');
  const [passwordError, setPasswordError] = useState('');

  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = async (evt) => {
    evt.preventDefault();
    setIsLoading(true);

    let valid = true;

    if (email.trim().length === 0) {
      setEmailError('Email cannot be empty');
      valid = false;
    } else {
      setEmailError('');
    }

    if (password.trim().length === 0) {
      setPasswordError('Password cannot be empty');
      valid = false;
    } else {
      setPasswordError('');
    }

    if (!valid) {
      setIsLoading(false);
      return;
    }

    const result = await auth.login(email, password);

    if (!result?.status) {
      store.pushNotification(
        result.error.message ?? 'Error',
        ((result.error.errors?.email_verified &&
          result.error.errors?.email_verified[0]) ||
          (result.error.errors?.email && result.error.errors?.email[0])) ??
          LOGIN_ERROR_NOTIFICATION_MESSAGE,
        NotificationTypes.DANGER,
      );
    }

    setIsLoading(false);

    if (result?.status) {
      if (result.onboarded) {
        history.push('/');
      } else {
        history.push('/welcome');
      }
    }
  };

  useEffect(() => {
    if (search) {
      if (auth.data.isAuthenticated) {
        auth.logout();
      }

      const { id, hash } = queryString.parse(search);

      const verify = async () => {
        try {
          const response = await verifyAccount(id, hash);

          store.pushNotification(
            response.message ?? 'Success',
            response.message ??
              'Your account has been verified. You can now log in.',
            NotificationTypes.SUCCESS,
          );
        } catch (err) {
          store.pushNotification(
            err.message ?? 'Error',
            prepareErrorMessage(err.errors) ??
              'Could not verify your account. Your link may be invalid.',
            NotificationTypes.DANGER,
          );
        }
      };

      const changeEmailFunc = async () => {
        try {
          const response = await changeEmail(hash);

          store.pushNotification(
            'Success',
            response.message ?? 'Your email has been changed.',
            NotificationTypes.SUCCESS,
          );
        } catch (err) {
          store.pushNotification(
            'Error',
            prepareErrorMessage(err.errors) ??
              'Could not change your email. Your link may be invalid.',
            NotificationTypes.DANGER,
          );
        }
      };

      if (pathname === '/account/change-email') {
        changeEmailFunc();
      } else {
        verify();
      }
    } else if (auth.data.isAuthenticated) {
      history.goBack();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <form className="login-form" onSubmit={onSubmit}>
      <h1 className="login-form__header">Log In To Your account</h1>
      <FormControl
        type="email"
        name="email"
        label="EMAIL"
        value={email}
        onChange={(evt) => setEmail(evt.target.value)}
        err={emailError}
        autocomplete="on"
      />
      <FormControl
        type="password"
        name="password"
        label="PASSWORD"
        value={password}
        onChange={(evt) => setPassword(evt.target.value)}
        err={passwordError}
        autocomplete="on"
      />
      <p className="login-form__password-reminder-redirect">
        Forgot your password? Click&nbsp;
        <Link
          to="/password/remind"
          className="password-reminder-redirect__link">
          here
        </Link>
      </p>
      <Button
        variant="primary"
        text="LOGIN"
        type="submit"
        state={isLoading ? 'loading' : ''}
      />
    </form>
  );
};

export default Login;
