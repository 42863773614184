import React, { useEffect, useRef, useState } from 'react';
import './ContactsListForm.scss';
import PropTypes from 'prop-types';
import ContactForm from '../../ContactForm/ContactForm';
import SocialForm from '../../SocialForm/SocialForm';
import Button from '../../Button/Button';
import { useContactFields } from '../../../hooks/ContactForm';
import { useSocialFields } from '../../../hooks/SocialForm';
import SectionHeader from '../../SectionHeader/SectionHeader';
import { acceptedFileExtensions } from '../../../data/ImgEditor';
import FormControl from '../../FormControl/FormControl';
import {
  contactsFormSettings,
  emptyContactsState,
} from '../../../data/Contacts';
import {
  addContact,
  getContactDetails,
  updateContact,
} from '../../../utils/apiCalls';
import {
  mapResponseToContactState,
  prepareContactDataToSubmit,
} from '../../../utils/helper';
import store, { NotificationTypes } from '../../../hooks/NotificationHub';
import Loader from '../../Loader/Loader';

const ContactsListForm = ({
  contactId,
  onCancelClick,
  setImagePath,
  imagePath,
  imageUrl,
  setImageUrl,
}) => {
  const fileRef = useRef();

  const [isResetting, setIsResetting] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [contact, setContact] = useState(emptyContactsState);
  const [message, setMessage] = useState('');

  const contactFields = useContactFields(
    contactId ? contact : emptyContactsState,
    contactsFormSettings,
    isResetting,
    isLoading,
  );

  const socialFields = useSocialFields(contact, isResetting, isLoading);

  useEffect(() => {
    const getContact = async () => {
      setIsLoading(true);
      try {
        const response = await getContactDetails(contactId);
        const data = mapResponseToContactState(response?.data?.data);

        setContact(data);
        setMessage(data.message);
        setImageUrl(data.photoUrl);
      } catch (err) {
        store.pushNotification(
          'Error',
          'Could not load contact details. Please try again later.',
          NotificationTypes.DANGER,
        );
        onCancelClick();
      }
      setIsLoading(false);
    };

    if (contactId) {
      getContact();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contactId]);

  useEffect(() => {
    setMessage('');
    const timeout = setTimeout(() => setIsResetting(false), 100);
    return () => clearTimeout(timeout);
  }, [isResetting]);

  useEffect(() => {
    if (imagePath === null) {
      fileRef.current.value = '';
      fileRef.current.files = null;
    }
  }, [imagePath]);

  const handleSubmit = async (evt) => {
    evt.preventDefault();
    setIsSubmitting(true);
    const [
      contactFieldsValid,
      contactFieldsErrors,
    ] = contactFields.validateOnSubmit();

    if (!contactFieldsValid) {
      store.pushNotification(
        'Error',
        <span style={{ whiteSpace: 'break-spaces' }}>
          {contactFieldsErrors.join('\n\n')}
        </span>,
        NotificationTypes.DANGER,
      );
      return;
    }

    const data = prepareContactDataToSubmit(
      contactFields.contactInfo,
      socialFields.socialLinks,
      message,
      imageUrl || undefined,
    );

    let response = null;

    try {
      if (contactId) {
        response = await updateContact(contactId, data.toSend);
      } else {
        response = await addContact(data.toSend);
      }

      store.pushNotification(
        'Success',
        response.data.message,
        NotificationTypes.SUCCESS,
      );

      onCancelClick();
      setIsResetting(true);
    } catch (err) {
      store.pushNotification(
        'Error',
        `Could not ${
          contactId ? 'update' : 'add'
        } contact at the moment. Please try again later.`,
        NotificationTypes.DANGER,
      );
    }

    setIsSubmitting(false);
  };

  return (
    <section className="contacts-list-form__wrapper">
      <div className="contacts-list-form__photo">
        <button
          type="button"
          className="photo__wrapper"
          onClick={() => fileRef.current.click()}>
          {imageUrl === '' ? (
            <>
              <img
                src="/images/contacts/photo_placeholder.svg"
                className="photo__background"
                alt="Contact placeholder"
              />
              <img
                src="/images/upload.svg"
                className="photo__foreground"
                alt="Contact upload"
              />
            </>
          ) : (
            <img src={imageUrl} className="photo__background" alt="Contact" />
          )}
          <input
            type="file"
            className="photo__input"
            ref={fileRef}
            onChange={() => setImagePath(fileRef?.current?.files[0])}
            accept={acceptedFileExtensions.join(',')}
          />
        </button>
      </div>
      <form className="contacts-list-form" onSubmit={handleSubmit}>
        <ContactForm inputs={contactFields.inputs} />
        <SectionHeader text="SOCIAL LINKS" />
        <SocialForm inputs={socialFields.inputs} />
        <FormControl
          as="textarea"
          value={message}
          onChange={(evt) => setMessage(evt.target.value)}
          label="MESSAGE"
          name="contactMessage"
          className="contacts-list-form__message"
        />
        <div className="contacts-list-form__buttons">
          <Button
            variant="secondary"
            text="CANCEL"
            onClick={() => {
              onCancelClick();
              setIsResetting(true);
            }}
          />
          <Button
            variant="primary"
            text="SAVE"
            type="submit"
            disabled={isSubmitting}
            state={isSubmitting ? 'loading' : ''}
          />
        </div>
      </form>
      <Loader isLoading={isLoading} />
    </section>
  );
};

ContactsListForm.propTypes = {
  contactId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onCancelClick: PropTypes.func,
  setImagePath: PropTypes.func,
  imagePath: PropTypes.object,
  imageUrl: PropTypes.string,
  setImageUrl: PropTypes.func,
};

export default ContactsListForm;
