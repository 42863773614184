import React, { useEffect, useState } from 'react';

const getState = ({ home, about }) => ({
  homePageVisibility: {
    isHeaderVisible: home?.isHeaderVisible ?? false,
    isFooterVisible: home?.isFooterVisible ?? false,
    isUserPhotoVisible: home?.isUserPhotoVisible ?? false,
    isUserInfoVisible: home?.isUserInfoVisible ?? false,
    isUserAddressVisible: home?.isUserAddressVisible ?? false,
    isSocialVisible: home?.isSocialVisible ?? false,
    isButtonGroupVisible: home?.isButtonGroupVisible ?? false,
    isAboutMeVisible: home?.isAboutMeVisible ?? false,
    isCombinedInfoVisible: home?.isCombinedInfoVisible ?? false,
  },
  aboutPageVisibility: {
    isHeaderVisible: about?.isHeaderVisible ?? false,
    isFooterVisible: about?.isFooterVisible ?? false,
    isUserPhotoVisible: about?.isUserPhotoVisible ?? false,
    isUserInfoVisible: about?.isUserInfoVisible ?? false,
    isUserAddressVisible: about?.isUserAddressVisible ?? false,
    isSocialVisible: about?.isSocialVisible ?? false,
    isButtonGroupVisible: about?.isButtonGroupVisible ?? false,
    isAboutMeVisible: about?.isAboutMeVisible ?? false,
    isCombinedInfoVisible: about?.isCombinedInfoVisible ?? false,
  },
});

const useVisibility = (info) => {
  const { home, about } = info;

  const [homePageVisibility, setHomePageVisibility] = useState(
    getState(info).homePageVisibility,
  );

  const [aboutPageVisibility, setAboutPageVisibility] = useState(
    getState(info).aboutPageVisibility,
  );

  useEffect(() => {
    const newState = getState(info);

    setHomePageVisibility(newState.homePageVisibility);
    setAboutPageVisibility(newState.aboutPageVisibility);
  }, [info]);

  const handleHomeChange = (propertyName, value) => {
    const newInfo = { ...homePageVisibility };
    newInfo[propertyName] = value;
    setHomePageVisibility(newInfo);
  };

  const handleAboutChange = (propertyName, value) => {
    const newInfo = { ...aboutPageVisibility };
    newInfo[propertyName] = value;
    setAboutPageVisibility(newInfo);
  };

  const homeInputs = [
    {
      type: 'checkbox',
      value: homePageVisibility.isHeaderVisible,
      defaultChecked: home.isHeaderVisible,
      onChange: (evt) =>
        handleHomeChange('isHeaderVisible', evt.target.checked),
      label: 'IS HEADER VISIBLE?',
      name: 'homeHeader',
    },
    {
      type: 'checkbox',
      value: homePageVisibility.isFooterVisible,
      defaultChecked: home.isFooterVisible,
      onChange: (evt) =>
        handleHomeChange('isFooterVisible', evt.target.checked),
      label: 'IS FOOTER VISIBLE?',
      name: 'homeFooter',
    },
    {
      type: 'checkbox',
      value: homePageVisibility.isUserPhotoVisible,
      defaultChecked: home.isUserPhotoVisible,
      onChange: (evt) =>
        handleHomeChange('isUserPhotoVisible', evt.target.checked),
      label: 'IS USER PHOTO VISIBLE?',
      name: 'homePhoto',
    },
    {
      type: 'checkbox',
      value: homePageVisibility.isUserInfoVisible,
      defaultChecked: home.isUserInfoVisible,
      onChange: (evt) =>
        handleHomeChange('isUserInfoVisible', evt.target.checked),
      label: 'IS USER INFO VISIBLE?',
      name: 'homeInfo',
    },
    {
      type: 'checkbox',
      value: homePageVisibility.isUserAddressVisible,
      defaultChecked: home.isUserAddressVisible,
      onChange: (evt) =>
        handleHomeChange('isUserAddressVisible', evt.target.checked),
      label: 'IS USER ADDRESS VISIBLE?',
      name: 'homeAddress',
    },
    {
      type: 'checkbox',
      value: homePageVisibility.isSocialVisible,
      defaultChecked: home.isSocialVisible,
      onChange: (evt) =>
        handleHomeChange('isSocialVisible', evt.target.checked),
      label: 'ARE SOCIALS VISIBLE?',
      name: 'homeSocials',
    },
    {
      type: 'checkbox',
      value: homePageVisibility.isButtonGroupVisible,
      defaultChecked: home.isButtonGroupVisible,
      onChange: (evt) =>
        handleHomeChange('isButtonGroupVisible', evt.target.checked),
      label: 'ARE BUTTONS VISIBLE?',
      name: 'homeButtons',
    },
    {
      type: 'checkbox',
      value: homePageVisibility.isAboutMeVisible,
      defaultChecked: home.isAboutMeVisible,
      onChange: (evt) =>
        handleHomeChange('isAboutMeVisible', evt.target.checked),
      label: "IS 'ABOUT ME' VISIBLE?",
      name: 'homeAboutMe',
    },
    {
      type: 'checkbox',
      value: homePageVisibility.isCombinedInfoVisible,
      defaultChecked: home.isCombinedInfoVisible,
      onChange: (evt) =>
        handleHomeChange('isCombinedInfoVisible', evt.target.checked),
      label: (
        <abbr title="Used when you want to display photo, address & info side-to-side">
          IS COMBINED INFO VISIBLE?
        </abbr>
      ),
      name: 'homeCombined',
    },
  ];

  const aboutInputs = [
    {
      type: 'checkbox',
      value: aboutPageVisibility.isHeaderVisible,
      defaultChecked: about.isHeaderVisible,
      onChange: (evt) =>
        handleAboutChange('isHeaderVisible', evt.target.checked),
      label: 'IS HEADER VISIBLE?',
      name: 'aboutHeader',
    },
    {
      type: 'checkbox',
      value: aboutPageVisibility.isFooterVisible,
      defaultChecked: about.isFooterVisible,
      onChange: (evt) =>
        handleAboutChange('isFooterVisible', evt.target.checked),
      label: 'IS FOOTER VISIBLE?',
      name: 'aboutFooter',
    },
    {
      type: 'checkbox',
      value: aboutPageVisibility.isUserPhotoVisible,
      defaultChecked: about.isUserPhotoVisible,
      onChange: (evt) =>
        handleAboutChange('isUserPhotoVisible', evt.target.checked),
      label: 'IS USER PHOTO VISIBLE?',
      name: 'aboutPhoto',
    },
    {
      type: 'checkbox',
      value: aboutPageVisibility.isUserInfoVisible,
      defaultChecked: about.isUserInfoVisible,
      onChange: (evt) =>
        handleAboutChange('isUserInfoVisible', evt.target.checked),
      label: 'IS USER INFO VISIBLE?',
      name: 'aboutInfo',
    },
    {
      type: 'checkbox',
      value: aboutPageVisibility.isUserAddressVisible,
      defaultChecked: about.isUserAddressVisible,
      onChange: (evt) =>
        handleAboutChange('isUserAddressVisible', evt.target.checked),
      label: 'IS USER ADDRESS VISIBLE?',
      name: 'aboutAddress',
    },
    {
      type: 'checkbox',
      value: aboutPageVisibility.isSocialVisible,
      defaultChecked: about.isSocialVisible,
      onChange: (evt) =>
        handleAboutChange('isSocialVisible', evt.target.checked),
      label: 'ARE SOCIALS VISIBLE?',
      name: 'aboutSocials',
    },
    {
      type: 'checkbox',
      value: aboutPageVisibility.isButtonGroupVisible,
      defaultChecked: about.isButtonGroupVisible,
      onChange: (evt) =>
        handleAboutChange('isButtonGroupVisible', evt.target.checked),
      label: 'ARE BUTTONS VISIBLE?',
      name: 'aboutButtons',
    },
    {
      type: 'checkbox',
      value: aboutPageVisibility.isAboutMeVisible,
      defaultChecked: about.isAboutMeVisible,
      onChange: (evt) =>
        handleAboutChange('isAboutMeVisible', evt.target.checked),
      label: "IS 'ABOUT ME' VISIBLE?",
      name: 'aboutAboutMe',
    },
    {
      type: 'checkbox',
      value: aboutPageVisibility.false,
      onChange: (evt) =>
        handleAboutChange('isCombinedInfoVisible', evt.target.checked),
      label: (
        <abbr title="Used when you want to display photo, address & info side-to-side">
          IS COMBINED INFO VISIBLE?
        </abbr>
      ),
      name: 'aboutCombined',
    },
  ];

  return {
    aboutVisibility: aboutPageVisibility,
    homeVisibility: homePageVisibility,
    aboutInputs,
    homeInputs,
  };
};

export default useVisibility;
