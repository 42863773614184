import React from 'react';
import './ListFilters.scss';
import PropTypes from 'prop-types';

const ListFilters = ({ filters }) => {
  return (
    <div className="list__filters">
      <div className="filters__col filters__col--checkbox">
        <input type="checkbox" className="col__input" />
      </div>
      <div className="filters__col filters__col--mobile">
        <input
          className="filters__input"
          type="text"
          placeholder="Filter..."
          value={filters.mobile}
          onChange={filters.setMobile}
        />
      </div>
      <div className="filters__col filters__col--firstName">
        <input
          className="filters__input"
          type="text"
          placeholder="Filter..."
          value={filters.firstName}
          onChange={filters.setFirstName}
        />
      </div>
      <div className="filters__col filters__col--lastName">
        <input
          className="filters__input"
          type="text"
          placeholder="Filter..."
          value={filters.lastName}
          onChange={filters.setLastName}
        />
      </div>
      <div className="filters__col filters__col--email">
        <input
          className="filters__input"
          type="text"
          placeholder="Filter..."
          value={filters.email}
          onChange={filters.setEmail}
        />
      </div>
      <div className="filters__col filters__col--source">
        <input
          className="filters__input"
          type="text"
          placeholder="Filter..."
          value={filters.source}
          onChange={filters.setSource}
        />
      </div>
      <div className="filters__col filters__col--visitDate">
        <input
          className="filters__input"
          type="text"
          placeholder="Filter..."
          value={filters.visitDate}
          onChange={filters.setVisitDate}
        />
      </div>
      <div className="filters__col filters__col--visitTime">
        <input
          className="filters__input"
          type="text"
          placeholder="Filter..."
          value={filters.visitTime}
          onChange={filters.setVisitTime}
        />
      </div>
      <div className="filters__col filters__col--edit" />
    </div>
  );
};

ListFilters.propTypes = {
  filters: PropTypes.object,
};

export default ListFilters;
