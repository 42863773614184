import React from 'react';
import PropTypes from 'prop-types';
import './SidebarLink.scss';
import { Link, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const SidebarLink = ({ href, icon, text, onClick, ...rest }) => {
  const location = useLocation();

  return (
    <li
      className={
        location.pathname === href
          ? 'sidebar__link sidebar__link--active'
          : 'sidebar__link'
      }
      {...rest}>
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
      <Link to={href} className="link__anchor" onClick={onClick}>
        <span className="link__icon">
          <FontAwesomeIcon icon={icon} />
        </span>
        <span className="link__text">{text}</span>
      </Link>
    </li>
  );
};

SidebarLink.propTypes = {
  href: PropTypes.string,
  icon: PropTypes.object,
  text: PropTypes.string,
  onClick: PropTypes.func,
};

export default SidebarLink;
