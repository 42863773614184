import PropTypes from 'prop-types';
import React, { useEffect, useState, useRef } from 'react';
import Button from './button';
import { ButtonContainer } from './buttonStyles';
import { useResize } from '../helper';
import { getVcardForUser } from '../../../utils/mbcApiCalls';

const ButtonGroup = ({
  buttons,
  columnNumber,
  height,
  openModal,
  CellPhoneNumber,
  EmailAddress,
  uuid,
  FullName,
  onAboutClick,
  ...rest
}) => {
  const ref = useRef(null);
  const dimensions = useResize(ref);
  const [renderedButtons, setRenderedButtons] = useState([]);

  const getHrefForButton = (button) => {
    switch (button.action) {
      case 'call':
        return `tel:${CellPhoneNumber}`;
      case 'text':
        return `sms:${CellPhoneNumber}`;
      case 'email':
        return `mailto:${EmailAddress}`;
      case 'about':
        return '/about';
      default:
        return button.href;
    }
  };

  useEffect(() => {
    const newButtons = buttons.map((btn) => (
      <Button
        key={btn.action}
        {...btn}
        iconUrl={btn.icon.url}
        width={`${100 / columnNumber - 0.5}%`}
        openModal={openModal}
        downloadVcard={() => getVcardForUser(uuid)}
        fileName={`${FullName}`}
        parentWidth={dimensions.width}
        href={getHrefForButton(btn)}
        handleClick={btn.action === 'about' ? onAboutClick : undefined}
      />
    ));

    setRenderedButtons(newButtons);
  }, [dimensions, buttons]);

  return (
    <ButtonContainer ref={ref} height={height} {...rest}>
      {renderedButtons}
    </ButtonContainer>
  );
};

ButtonGroup.propTypes = {
  buttons: PropTypes.arrayOf(PropTypes.object),
  columnNumber: PropTypes.number,
  height: PropTypes.string,
  openModal: PropTypes.func,
  CellPhoneNumber: PropTypes.string,
  EmailAddress: PropTypes.string,
  uuid: PropTypes.string,
  FullName: PropTypes.string,
  onAboutClick: PropTypes.func,
};

export default ButtonGroup;
