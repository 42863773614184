import styled from 'styled-components';
import { breakpoints } from '../cssVariables';
import { getDefaultStylesForComponent } from '../defaultStyles';

const MainContainerDefaults = getDefaultStylesForComponent('MainContainer');

export const MainContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: center;

  position: relative;

  width: ${(props) => props.width || MainContainerDefaults.width};
  height: ${(props) => props.height || 'auto'};
  margin: 0;
  padding: ${(props) => props.padding || MainContainerDefaults.padding};

  background-color: ${(props) =>
    props.backgroundColor || MainContainerDefaults.backgroundColor};
  overflow-y: auto;

  @media (min-width: ${breakpoints.medium}) {
    width: ${(props) =>
      props.medium.width || MainContainerDefaults.medium.width};
    padding: ${(props) =>
      props.medium.padding || MainContainerDefaults.medium.padding};
  }

  @media (min-width: ${breakpoints.large}), (orientation: landscape) {
    margin: 1.25rem auto;
    width: ${(props) => props.large.width || MainContainerDefaults.large.width};
    padding: ${(props) =>
      props.large.padding || MainContainerDefaults.large.padding};

    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
  }
`;

const HeaderDefaults = getDefaultStylesForComponent('Header');

export const SiteHeader = styled.header`
  display: ${(props) => (!props.isVisible && 'none') || 'flex'};
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  order: ${(props) => props.order || HeaderDefaults.order};

  flex: 0 0 100%;
  height: ${(props) => props.height || HeaderDefaults.height};
  min-height: ${(props) => props.height || HeaderDefaults.height};
  padding: ${(props) => props.padding || HeaderDefaults.padding};

  background-color: ${(props) =>
    props.backgroundColor || HeaderDefaults.backgroundColor};

  @media (min-width: ${breakpoints.medium}) {
    order: ${(props) => props.medium.order || HeaderDefaults.medium.order};

    padding: ${(props) =>
      props.medium.padding || HeaderDefaults.medium.padding};
  }

  @media (min-width: ${breakpoints.large}), (orientation: landscape) {
    order: ${(props) => props.large.order || HeaderDefaults.large.order};

    padding: ${(props) => props.large.padding || HeaderDefaults.large.padding};
  }
`;

export const Logo = styled.img`
  width: ${(props) =>
    (props.logoSize === 'small' && '40%') ||
    (props.logoSize === 'medium' && '55%') ||
    (props.logoSize === 'large' && '70%') ||
    props.logoSize ||
    '60%'};
  flex-basis: ${(props) =>
    (props.logoSize === 'small' && '40%') ||
    (props.logoSize === 'medium' && '55%') ||
    (props.logoSize === 'large' && '70%') ||
    props.logoSize ||
    '60%'};

  max-width: ${(props) => props.maxWidth || HeaderDefaults.maxWidth};
  height: auto;
  margin: 0 auto;

  @media (min-width: ${breakpoints.medium}) {
    width: ${(props) =>
      (props.medium.logoSize === 'small' && '40%') ||
      (props.medium.logoSize === 'medium' && '55%') ||
      (props.medium.logoSize === 'large' && '70%') ||
      props.medium.logoSize ||
      '60%'};
    flex-basis: ${(props) =>
      (props.medium.logoSize === 'small' && '40%') ||
      (props.medium.logoSize === 'medium' && '55%') ||
      (props.medium.logoSize === 'large' && '70%') ||
      props.medium.logoSize ||
      '60%'};
    max-width: ${(props) =>
      props.medium.maxWidth || HeaderDefaults.medium.maxWidth};
  }

  @media (min-width: ${breakpoints.large}), (orientation: landscape) {
    width: ${(props) =>
      (props.large.logoSize === 'small' && '40%') ||
      (props.large.logoSize === 'medium' && '55%') ||
      (props.large.logoSize === 'large' && '70%') ||
      props.large.logoSize ||
      '60%'};
    flex-basis: ${(props) =>
      (props.large.logoSize === 'small' && '40%') ||
      (props.large.logoSize === 'medium' && '55%') ||
      (props.large.logoSize === 'large' && '70%') ||
      props.large.logoSize ||
      '60%'};
    max-width: ${(props) =>
      props.large.maxWidth || HeaderDefaults.large.maxWidth};
  }
`;

export const FixedLink = styled.span`
  display: block;
  position: absolute;

  width: 30px;
  height: 30px;

  & a {
    display: block;

    height: 100%;
    width: 100%;
  }

  @media (min-width: 600px) {
    width: 40px;
    height: 40px;
  }
`;

export const Button = styled.img`
  width: 100%;
  height: 100%;
`;

const FooterDefaults = getDefaultStylesForComponent('Footer');

export const SiteFooter = styled.footer`
  display: ${(props) => (!props.isVisible && 'none') || 'block'};
  order: ${(props) => props.order || FooterDefaults.order};

  width: 100%;
  height: ${(props) => props.height || FooterDefaults.height};
  flex: 0 0 100%;
  margin: 0;
  padding: ${(props) => props.padding || FooterDefaults.padding};

  background-color: ${(props) =>
    props.backgroundColor || FooterDefaults.backgroundColor};

  & h2 {
    display: ${(props) => (props.link.isVisible ? 'block' : 'none')};

    margin: 0;

    font-size: ${(props) =>
      props.link.fontSize || FooterDefaults.link.fontSize};
    text-align: ${(props) =>
      props.link.textAlign || FooterDefaults.link.textAlign};
    color: ${(props) => props.link.color || FooterDefaults.link.color};

    & a {
      &:link,
      &:visited,
      &:active,
      &:hover {
        color: ${(props) => props.link.color || FooterDefaults.link.color};
        text-decoration: none;
      }
    }
  }

  @media (min-width: ${breakpoints.medium}) {
    order: ${(props) => props.medium.order || FooterDefaults.medium.order};

    padding: ${(props) =>
      props.medium.padding || FooterDefaults.medium.padding};

    & h2 {
      font-size: ${(props) =>
        props.medium.link.fontSize || FooterDefaults.medium.link.fontSize};
      text-align: ${(props) =>
        props.medium.link.textAlign || FooterDefaults.medium.link.fontSize};
    }
  }

  @media (min-width: ${breakpoints.large}), (orientation: landscape) {
    height: auto;
    order: ${(props) => props.large.order || FooterDefaults.large.order};

    padding: ${(props) => props.large.padding || FooterDefaults.large.padding};

    & h2 {
      font-size: ${(props) =>
        props.large.link.fontSize || FooterDefaults.large.link.fontSize};
      text-align: ${(props) =>
        props.large.link.textAlign || FooterDefaults.large.link.fontSize};
    }
  }
`;

export const LegalText = styled.p`
  display: ${(props) => (props.isVisible ? 'block' : 'none')};

  margin: 0;

  font-size: ${(props) => props.fontSize || FooterDefaults.legal.fontSize};
  color: ${(props) => props.color || FooterDefaults.legal.color};
  text-align: ${(props) => props.textAlign || FooterDefaults.legal.textAlign};

  @media (min-width: ${breakpoints.medium}) {
    font-size: ${(props) =>
      props.medium.fontSize || FooterDefaults.medium.legal.fontSize};
    text-align: ${(props) =>
      props.medium.textAlign || FooterDefaults.medium.legal.textAlign};
  }

  @media (min-width: ${breakpoints.large}), (orientation: landscape) {
    font-size: ${(props) =>
      props.large.fontSize || FooterDefaults.large.legal.fontSize};
    text-align: ${(props) =>
      props.large.textAlign || FooterDefaults.large.legal.fontSize};
  }
`;

export const ErrorScreen = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;

  color: red;
  font-weight: bold;
  font-size: 1.75rem;
`;

export const ReloadButton = styled.button`
  padding: 0.5rem;

  border: none;
  border-radius: 0.15rem;
  background: rgba(0, 0, 0, 0.15);

  font-family: inherit;
  font-size: 1.25rem;

  cursor: pointer;
  transition: background 0.25s;

  &:hover {
    background: rgba(0, 0, 0, 0.2);
  }
`;
