import React from 'react';
import './LeadTableEntry.scss';
import PropTypes from 'prop-types';
import { getIcon } from '../../../../../utils/helper';
import ContactSourceIcon from '../../../../ContactSourceIcon/ContactSourceIcon';

const LeadsTableEntry = ({
  mobile,
  contactName,
  contactEmail,
  source,
  visitDateTime,
  userName,
  actions,
}) => (
  <div className="list__entry leads-table__entry">
    <div className="entry__column entry__column--user-name">{userName}</div>
    <div className="entry__column entry__column--mobile">{mobile}</div>
    <div className="entry__column entry__column--contact-name">
      {contactName}
    </div>
    <div className="entry__column entry__column--contact-email">
      {contactEmail}
    </div>
    <div className="entry__column entry__column--source">
      {typeof source === 'string' ? (
        <ContactSourceIcon source={source} />
      ) : (
        source
      )}
    </div>
    <div className="entry__column entry__column--visit-datetime">
      {visitDateTime}
    </div>
    <div className="entry__column entry__column--actions">{actions}</div>
  </div>
);

LeadsTableEntry.propTypes = {
  userName: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
    PropTypes.object,
  ]),
  mobile: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  contactName: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  contactEmail: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  source: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  visitDateTime: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  actions: PropTypes.oneOfType([PropTypes.object, PropTypes.node]),
};

export default LeadsTableEntry;
