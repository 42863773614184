import React, { useRef, useEffect, useState, useCallback } from 'react';
import './ImgEditor.scss';
import PropTypes from 'prop-types';
import 'tui-image-editor/dist/tui-image-editor.css';
import ImageEditor from '@toast-ui/react-image-editor';
import { faArrowDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from '../Button/Button';
import SectionHeader from '../SectionHeader/SectionHeader';
import FormControl from '../FormControl/FormControl';
import ButtonGroup from './ButtonGroup/ButtonGroup';
import ColorSelector from './ColorSelector/ColorSelector';
import { downloadImage } from '../../utils/helper';
import {
  availableFonts,
  DRAWING_MODES,
  INITIAL_FONT_SIZE,
  INITIAL_TEXT_ALIGNMENT,
  INITIAL_COLOR,
  INITIAL_OPACITY,
  INITIAL_WEIGHT_STYLE_BUTTONS_STATE,
  INITIAL_LINE_BUTTONS_STATE,
  INITIAL_ALIGN_BUTTONS_STATE,
  INITIAL_CHOSEN_ELEMENT,
} from '../../data/ImgEditor';
import {
  useTextColor,
  useTextStyle,
  useFont,
  useTextAlign,
  useEditorInitialization,
  useButtons,
} from '../../hooks/ImgEditor';
import { uploadLogo, uploadPhoto } from '../../utils/apiCalls';
import store, { NotificationTypes } from '../../hooks/NotificationHub';

const ImgEditor = ({ path, close, type, setLogoUrl, setImageUrl }) => {
  const editorRef = useRef(null);

  const [isImageCropped, setIsImageCropped] = useState(false);
  const [chosenElement, setChosenElement] = useState(INITIAL_CHOSEN_ELEMENT);
  const [activeObject, setActiveObject] = useState(null);
  const [currentDrawingMode, setCurrentDrawingMode] = useState(
    DRAWING_MODES.NORMAL,
  );
  const [saveButtonState, setSaveButtonState] = useState('');

  // key is either a string or an array of strings
  // value may be a single variable on an array of values
  const changeTextStyle = useCallback(
    async (key, value) => {
      const editor = editorRef.current.getInstance();
      const style = {};

      if (Array.isArray(key)) {
        key.forEach((k, index) => {
          style[k] = value[index];
        });
      } else {
        style[key] = value;
      }

      try {
        await editor.changeTextStyle(activeObject.id, style);
      } catch {}
    },
    [activeObject],
  );

  // hook for taking care of text/background colors and opacity
  // returns current color, opacity and functions for changing them
  const textColor = useTextColor(
    activeObject,
    INITIAL_COLOR,
    INITIAL_OPACITY,
    chosenElement,
    editorRef,
    changeTextStyle,
  );

  // hook for fontWeight, fontStyle and textDecoration
  // returns status of each style and functions for changing them
  const textStyle = useTextStyle(
    activeObject,
    INITIAL_WEIGHT_STYLE_BUTTONS_STATE,
    INITIAL_LINE_BUTTONS_STATE,
    editorRef,
    changeTextStyle,
  );

  // hook for fontSize and fontFamily
  // returns current fontSize and fontFamily and functions for changing them
  const font = useFont(
    activeObject,
    INITIAL_FONT_SIZE,
    availableFonts[0],
    editorRef,
    changeTextStyle,
  );

  // hook for textAlign
  // returns current textAlign and function for modifying it
  const align = useTextAlign(
    activeObject,
    INITIAL_TEXT_ALIGNMENT,
    INITIAL_ALIGN_BUTTONS_STATE,
    editorRef,
  );

  // hook for holding all button arrays
  const buttons = useButtons(
    textStyle.onWeightStyleButtonClick,
    textStyle.onLineButtonClick,
    align.onAlignButtonClick,
  );

  // hook for initializing the editor
  // doesn't return anything
  // eslint-disable-next-line no-unused-vars
  const editorInitializer = useEditorInitialization(
    editorRef,
    path,
    activeObject,
    setActiveObject,
    setCurrentDrawingMode,
    font,
    textStyle,
    align,
    textColor,
  );

  const addText = () => {
    const editor = editorRef.current.getInstance();
    editor.addText('Your text', {
      styles: {
        fontFamily: font.fontFamily,
        fontSize: font.fontSize,
        fill: textColor.color,
      },
      position: {
        x: 0,
        y: 0,
      },
    });
  };

  const deactivateObjects = () => {
    const editor = editorRef.current.getInstance();
    editor.deactivateAll();
  };

  const onConfirmButtonClick = async () => {
    const editor = editorRef.current.getInstance();
    if (!isImageCropped) {
      await editor.loadImageFromURL(await editor.toDataURL(), typeof path === 'string' ? path : path.name);
      setCurrentDrawingMode(DRAWING_MODES.TEXT);
      setIsImageCropped(true);
    } else {
      setSaveButtonState('loading');
      let response = {};
      switch (type) {
        case 'photo':
          try {
            response = await uploadPhoto(await editor.toDataURL());
          } catch (err) {
            store.pushNotification(
              'Error',
              'Could not upload photo. Please try again later.',
              NotificationTypes.DANGER,
            );
          }
          break;
        case 'logo':
          try {
            response = await uploadLogo(await editor.toDataURL());
            if (setLogoUrl) setLogoUrl(response.data.url);
          } catch (err) {
            store.pushNotification(
              'Error',
              'Could not upload logo. Please try again later.',
              NotificationTypes.DANGER,
            );
          }

          break;
        default:
          if (setImageUrl) setImageUrl(await editor.toDataURL());
      }

      if (response !== null) {
        close();
        setSaveButtonState('');
      }
    }
  };

  // set drawing mode - crop or text, based on the state
  useEffect(() => {
    const editor = editorRef.current.getInstance();
    editor.stopDrawingMode();
    editor.startDrawingMode(currentDrawingMode);
  }, [currentDrawingMode]);

  return (
    <div className="image-editor__wrapper">
      <section className="image-editor">
        <SectionHeader text="EDIT IMAGE" textAlign="center" />
        <main className="image-editor__main">
          <ImageEditor
            ref={editorRef}
            usageStatistics={false}
            selectionStyle={{
              cornerSize: 30,
              cornerColor: 'red',
            }}
          />
        </main>
        <aside className="image-editor__controls">
          <button
            disabled={!isImageCropped}
            type="button"
            onClick={() => {
              setCurrentDrawingMode(DRAWING_MODES.TEXT);
              addText();
            }}
            className="controls__top-text">
            Aa
          </button>
          <FormControl
            disabled={!isImageCropped}
            as="select"
            label="TEXT"
            options={availableFonts.map((f) => ({
              value: f,
              text: f,
            }))}
            value={font.fontFamily}
            onChange={(evt) => font.setFontFamily(evt.target.value)}
            width="100%"
            key={font.fontFamily}
          />
          <ButtonGroup
            disabled={!isImageCropped}
            hasBorder
            buttons={buttons.weightStyleButtons}
            buttonStatus={textStyle.weightStyleButtonStatus}
            setButtonStatus={textStyle.setWeightStyleButtonStatus}
          />
          <ButtonGroup
            disabled={!isImageCropped}
            hasBorder
            buttons={buttons.lineButtons}
            buttonStatus={textStyle.lineButtonStatus}
            setButtonStatus={textStyle.setLineButtonStatus}
            type="radio"
          />
          <FormControl
            disabled={!isImageCropped}
            as="input"
            value={font.fontSize}
            onFocus={deactivateObjects}
            onChange={(evt) => {
              if (
                !isNaN(evt.target.value) &&
                evt.target.value >= 0 &&
                evt.target.value <= 250
              )
                font.setFontSize(evt.target.value);
            }}
            type="text"
            width="33%"
            inputMode="numeric"
            pattern="[0-9]*"
          />
          <ColorSelector
            disabled={!isImageCropped}
            currentColor={textColor.color}
            setCurrentColor={textColor.setNewColor}
            chosenElement={chosenElement}
            setChosenElement={setChosenElement}
            canvas={editorRef?.current?.getInstance().getCanvas()}
            setCurrentDrawingMode={setCurrentDrawingMode}
          />
          <FormControl
            disabled={!isImageCropped}
            label="HEX COLOR"
            as="input"
            readonly
            value={
              // don't want to show rgba colors in a 'HEX' color input, and only one would be visible
              // when chosing transparent for background color
              textColor.color === 'rgba(0,0,0,0)' ? '#ffffff' : textColor.color
            }
            onFocus={deactivateObjects}
            type="text"
            width="33%"
          />
          <FormControl
            disabled={!isImageCropped}
            label="OPACITY"
            as="input"
            value={textColor.opacity}
            onFocus={deactivateObjects}
            onChange={(evt) => {
              if (
                !isNaN(evt.target.value) &&
                evt.target.value >= 0 &&
                evt.target.value <= 100
              )
                textColor.setNewOpacity(evt.target.value);
            }}
            type="text"
            inputMode="numeric"
            pattern="[0-9]*"
            width="33%"
          />
          <ButtonGroup
            disabled={!isImageCropped}
            label="ALIGN"
            buttons={buttons.alignButtons}
            buttonStatus={align.alignButtonStatus}
            setButtonStatus={align.setAlignButtonStatus}
            type="radio"
          />
          <div className="controls__button-wrapper">
            <button
              disabled={!isImageCropped}
              type="button"
              className="controls__download-button"
              onClick={() => downloadImage(editorRef)}>
              <FontAwesomeIcon
                icon={faArrowDown}
                className="download-button__icon"
              />
            </button>
            <Button text="CANCEL" variant="secondary" onClick={close} />
            <Button
              text={!isImageCropped ? 'CONFIRM' : 'SAVE'}
              variant="primary"
              state={saveButtonState}
              onClick={onConfirmButtonClick}
            />
          </div>
        </aside>
      </section>
    </div>
  );
};

ImgEditor.propTypes = {
  path: PropTypes.object,
  close: PropTypes.func,
  type: PropTypes.oneOf(['photo', 'logo']),
  setLogoUrl: PropTypes.func,
  setImageUrl: PropTypes.func,
};

export default ImgEditor;
