import React from 'react';
import PropTypes from 'prop-types';
import { SiteFooter, LegalText } from './sharedStyles';

const Footer = ({ FooterTxt, MyWebsiteUrl, legal, medium, large, ...rest }) => (
  <SiteFooter {...rest} medium={medium} large={large}>
    <h2>
      <a href={MyWebsiteUrl} target="_blank" rel="noopener noreferrer">
        {FooterTxt}
      </a>
    </h2>
    <LegalText {...legal} medium={medium.legal} large={large.legal}>
      &copy; {new Date().getFullYear()} {legal.content || 'All rights reserved'}
    </LegalText>
  </SiteFooter>
);

Footer.propTypes = {
  FooterTxt: PropTypes.string,
  MyWebsiteUrl: PropTypes.string,
  legal: PropTypes.object,
  medium: PropTypes.object,
  large: PropTypes.object,
};

export default Footer;
