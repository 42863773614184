import React from 'react';
import PropTypes from 'prop-types';
import './StatisticsEntry.scss';
import StatisticsIcon from '../StatisticsIcon/StatisticsIcon';

const StatisticsEntry = ({ text, currentValue, icon, color }) => (
  <div className="statistics__entry">
    <StatisticsIcon icon={icon} color={color} />
    <div className="entry__content">
      <p className="content__text">{text}</p>
      <p className="content__value">{currentValue}</p>
    </div>
  </div>
);

StatisticsEntry.propTypes = {
  text: PropTypes.string,
  currentValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  icon: PropTypes.object,
  color: PropTypes.string,
};

export default StatisticsEntry;
