/* eslint-disable camelcase */
import axios from 'axios';

const baseUrl = process.env.REACT_APP_BASE_API_URL;

export const login = async (email, password) => {
  const response = await axios.post(`${baseUrl}/user/login`, {
    email,
    password,
  });
  return response;
};

export const refreshToken = async (email, token) => {
  const response = await axios.post(`${baseUrl}/user/token/refresh`, {
    email,
    refresh_token: token,
  });
  return response;
};

export const register = async (email, password) => {
  const response = await axios.post(`${baseUrl}/user/register`, {
    email,
    password,
  });
  return response;
};

export const verifyAccount = async (id, hash) => {
  const response = await axios.post(`${baseUrl}/user/email/verify`, {
    id,
    hash,
  });
  return response;
};

export const changeEmail = async (hash) => {
  const response = await axios.post(`${baseUrl}/user/email/change`, {
    hash,
  });
  return response.data;
};

export const sendPasswordRemider = async (email) => {
  const response = await axios.post(`${baseUrl}/user/password/email`, {
    email,
  });
  return response;
};

export const changePassword = async (
  email,
  hash,
  password,
  password_confirmation,
) => {
  const response = await axios.post(`${baseUrl}/user/password/reset`, {
    email,
    hash,
    password,
    password_confirmation,
  });
  return response;
};

export const assignPasswordToNewUser = async (
  email,
  hash,
  password,
  password_confirmation,
) => {
  const response = await axios.post(`${baseUrl}/user/password/assign`, {
    email,
    hash,
    password,
    password_confirmation,
  });
  return response;
};

export const getUserMBCData = async () => {
  const response = await axios.get(`${baseUrl}/user/mbc-settings`);
  return response;
};

export const updateUserMBCData = async (user) => {
  const response = await axios.post(`${baseUrl}/user/mbc-settings`, user);
  return response;
};

export const getUserAccountData = async () => {
  try {
    const response = await axios.get(`${baseUrl}/user/account-settings`);
    return response.data;
  } catch (err) {
    return null;
  }
};

export const updateUserAccountData = async (user) => {
  try {
    const response = await axios.post(`${baseUrl}/user/account-settings`, user);
    return response;
  } catch (err) {
    return err.response;
  }
};

export const uploadPhoto = async (image) => {
  const response = await axios.post(`${baseUrl}/user/me/upload-photo`, {
    image,
  });
  return response;
};

export const uploadLogo = async (image) => {
  const response = await axios.post(`${baseUrl}/user/companies/upload-logo`, {
    image,
  });
  return response;
};

export const getKeywordsForUser = async () => {
  const response = await axios.get(`${baseUrl}/user/keywords`);
  return response;
};

export const reserveKeywordForUser = async (keyword) => {
  const response = await axios.post(`${baseUrl}/user/keywords`, {
    keyword,
  });
  return response;
};

export const downloadVcard = async (uuid) => {
  const response = await axios.post(`${baseUrl}/users/${uuid}/download-card`);
  return response;
};

export const getUsersList = async (
  recordsToShow,
  currentPage,
  searchQuery,
  sortColumn,
  sortDirection,
) => {
  const formData = new FormData();
  const sortColumnsMapping = [
    'uuid',
    'email',
    'first_name',
    'last_name',
    'is_admin',
    'status',
  ];

  formData.append('order[0][column]', sortColumnsMapping[sortColumn]);
  formData.append('order[0][dir]', sortDirection);

  formData.append('page', currentPage);
  formData.append('perPage', recordsToShow);

  formData.append('search', searchQuery);

  const response = await axios.post(`${baseUrl}/admin/users/list`, formData);
  return response;
};

export const changeUserStatus = async (uuid) => {
  const response = await axios.delete(`${baseUrl}/admin/users/${uuid}`);
  return response;
};

export const getUserForAdmin = async (uuid) => {
  const response = await axios.get(`${baseUrl}/admin/users/${uuid}`);
  return response;
};

export const updateUserForAdmin = async (uuid, user) => {
  const response = await axios.put(`${baseUrl}/admin/users/${uuid}`, user);
  return response;
};

export const addUserForAdmin = async (user) => {
  const response = await axios.post(`${baseUrl}/admin/users`, user);
  return response;
};

export const autocompleteAddress = async (query) => {
  // new instance needed because by default authorization header would be send
  const instance = axios.create();
  delete instance.defaults.headers.common.Authorization;

  try {
    const response = await instance.get(
      `${process.env.REACT_APP_HERE_API_URL}?query=${query}&apiKey=${process.env.REACT_APP_HERE_API_KEY}`,
    );
    return response.data;
  } catch (err) {
    return null;
  }
};

export const addCompany = async (company, payment_method) => {
  const response = await axios.post(`${baseUrl}/user/companies`, {
    ...company,
    payment_method,
  });
  return response;
};

export const getCompany = async (uuid) => {
  const response = await axios.get(`${baseUrl}/user/companies/${uuid}`);
  return response;
};

export const getCompanyForUser = async () => {
  const response = await axios.get(`${baseUrl}/user/me/company`);
  return response;
};

export const updateCompany = async (uuid, company, payment_method) => {
  const response = await axios.put(`${baseUrl}/user/companies/${uuid}`, {
    ...company,
    payment_method,
  });
  return response;
};

export const createMBCTheme = async (name, isEnabled, theme) => {
  const response = await axios.post(`${baseUrl}/admin/mbc-themes`, {
    name,
    status: isEnabled ? 1 : 0,
    settings: theme,
  });

  return response;
};

export const submitMBCThemePreview = async (name, isEnabled, theme, uuid) => {
  const response = await axios.post(`${baseUrl}/admin/mbc-themes/preview`, {
    name,
    status: isEnabled ? 1 : 0,
    settings: theme,
    uuid: uuid || undefined,
  });
  return response;
};

export const getCompanyMBCThemes = async () => {
  const response = await axios.get(`${baseUrl}/user/me/company/mbc-themes`);
  return response;
};

export const getMBCThemes = async (page, perPage, search) => {
  let slug = page ? `page=${page}` : '';
  slug += slug !== '' && perPage ? `&perPage=${perPage}` : '';
  slug += slug === '' && perPage ? `perPage=${perPage}` : '';

  const response = await axios.post(
    `${baseUrl}/admin/mbc-themes/list?${slug}`,
    { search },
  );
  return response;
};

export const updateMBCTheme = async (uuid, name, status, theme) => {
  const response = await axios.put(`${baseUrl}/admin/mbc-themes/${uuid}`, {
    name,
    status: status ? 1 : 0,
    settings: theme,
  });
  return response;
};

export const getDataForMBCTheme = async (uuid) => {
  const response = await axios.get(`${baseUrl}/admin/mbc-themes/${uuid}`);
  return response;
};

export const deleteMBCTheme = async (uuid) => {
  try {
    const response = await axios.delete(`${baseUrl}/admin/mbc-themes/${uuid}`);
    return response.data;
  } catch (err) {
    return null;
  }
};

export const getContactsList = async (
  page,
  perPage,
  mobile,
  first_name,
  last_name,
  email,
  source,
  visit_date,
  visit_time,
  search,
) => {
  const response = await axios.post(`${baseUrl}/user/contacts/list`, {
    page,
    perPage,
    filter: {
      mobile,
      first_name,
      last_name,
      email,
      source,
      visit_date,
      visit_time,
    },
    search,
  });
  return response;
};

export const addContact = async (contact) => {
  const response = await axios.post(`${baseUrl}/user/contacts`, contact);
  return response;
};

export const deleteContact = async (uuid) => {
  try {
    const response = await axios.delete(`${baseUrl}/user/contacts/${uuid}`);
    return response.data;
  } catch (err) {
    return null;
  }
};

export const updateContact = async (uuid, contact) => {
  const response = await axios.put(`${baseUrl}/user/contacts/${uuid}`, contact);
  return response;
};

export const getContactDetails = async (uuid) => {
  const response = await axios.get(`${baseUrl}/user/contacts/${uuid}`);
  return response;
};

export const getDashboardStatistics = async (
  startDate,
  endDate = new Date(),
) => {
  const response = await axios.post(`${baseUrl}/user/dashboard/blocks`, {
    start: startDate,
    end: endDate,
  });
  return response;
};

export const getDashboardChart = async (startDate, endDate = new Date()) => {
  const response = await axios.post(`${baseUrl}/user/dashboard/graph`, {
    start: startDate,
    end: endDate,
  });
  return response;
};

export const getDashboardContacts = async (
  page,
  perPage,
  mobile,
  first_name,
  last_name,
  email,
  source,
  visit_date,
  visit_time,
  startDate,
  endDate = new Date(),
) => {
  let slug = page ? `page=${page}` : '';
  slug += slug !== '' && perPage ? `&perPage=${perPage}` : '';
  slug += slug === '' && perPage ? `perPage=${perPage}` : '';

  const response = await axios.post(
    `${baseUrl}/user/dashboard/contacts?${slug}`,
    {
      filter: {
        mobile,
        first_name,
        last_name,
        email,
        source,
        visit_date,
        visit_time,
      },
      start: startDate,
      end: endDate,
    },
  );
  return response;
};

export const exportContacts = async (uuidArray) => {
  const formData = new FormData();

  uuidArray.forEach((uuid) => formData.append('uuid[]', uuid));

  const response = await axios.post(
    `${baseUrl}/user/contacts/export`,
    formData,
  );
  return response;
};

export const importContactsFile = async (csv) => {
  const formData = new FormData();
  formData.append('step', 'paring');
  formData.append('file', csv);

  const response = await axios.post(
    `${baseUrl}/user/contacts/import`,
    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    },
  );
  return response;
};

export const importContactsMapping = async (filePath, mapping) => {
  const formData = new FormData();
  formData.append('step', 'store');
  formData.append('table', 'contacts');
  formData.append('filePath', filePath);

  Object.keys(mapping).forEach((key) =>
    formData.append(`paring[${key}]`, mapping[key]),
  );

  const response = await axios.post(
    `${baseUrl}/user/contacts/import`,
    formData,
  );
  return response;
};

export const importUsersMapping = async (
  filePath,
  mapping,
  sendEmailChecked,
) => {
  const formData = new FormData();
  formData.append('step', 'store');
  formData.append('table', 'users');
  formData.append('send_email', sendEmailChecked);
  formData.append('filePath', filePath);

  Object.keys(mapping).forEach((key) =>
    formData.append(`paring[${key}]`, mapping[key]),
  );

  const response = await axios.post(`${baseUrl}/admin/users/import`, formData);
  return response;
};

export const importUsersFile = async (csv) => {
  const formData = new FormData();
  formData.append('step', 'paring');
  formData.append('file', csv);

  const response = await axios.post(`${baseUrl}/admin/users/import`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  return response;
};

export const exportUsers = async (uuidArray) => {
  const formData = new FormData();

  uuidArray.forEach((uuid) => formData.append('uuid[]', uuid));

  const response = await axios.post(`${baseUrl}/admin/users/export`, formData);
  return response;
};

export const getKeywordsList = async (
  recordsToShow,
  currentPage,
  searchQuery,
  sortColumn,
  sortDirection,
) => {
  const formData = new FormData();

  formData.append('order[0][column]', sortColumn);
  formData.append('order[0][dir]', sortDirection);

  formData.append('page', currentPage);
  formData.append('perPage', recordsToShow);

  formData.append('search', searchQuery);

  try {
    const response = await axios.post(
      `${baseUrl}/admin/keywords/list`,
      formData,
    );
    return response.data;
  } catch (err) {
    return null;
  }
};

export const getLeadsList = async (
  recordsToShow,
  currentPage,
  searchQuery,
  sortColumn,
  sortDirection,
) => {
  const formData = new FormData();

  formData.append('order[0][column]', sortColumn);
  formData.append('order[0][dir]', sortDirection);

  formData.append('page', currentPage);
  formData.append('perPage', recordsToShow);

  formData.append('search', searchQuery);

  const response = await axios.post(`${baseUrl}/admin/leads/list`, formData);
  return response;
};
