import styled from 'styled-components';
import { breakpoints } from '../cssVariables';
import { getDefaultStylesForComponent } from '../defaultStyles';

const SendModalDefaults = getDefaultStylesForComponent('SendModal');

export const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;

  background: rgba(
    0,
    0,
    0,
    ${(props) =>
      parseFloat(props.overlayOpacity || SendModalDefaults.overlayOpacity) /
      100}
  );
  animation: fadeIn 0.25s;

  text-align: center;
`;

export const Modal = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 10;

  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;

  width: ${(props) => props.width || SendModalDefaults.width};
  max-width: ${(props) =>
    (parseFloat(props.width || SendModalDefaults.width) / 100) * 600}px;
  padding: ${(props) => props.padding || SendModalDefaults.padding};

  background-color: ${(props) =>
    props.backgroundColor || SendModalDefaults.backgroundColor};
  color: ${(props) => props.color || SendModalDefaults.color};

  animation: slideInFromTop 0.5s;
  transform: translate(-50%, -50%);
  border-radius: ${(props) =>
    props.borderRadius || SendModalDefaults.borderRadius};

  & h3 {
    padding: ${(props) =>
      props.header.padding || SendModalDefaults.header.padding};
    margin: ${(props) =>
      props.header.margin || SendModalDefaults.header.margin};

    font-weight: ${(props) =>
      props.header.fontWeight || SendModalDefaults.header.fontWeight};
    font-size: ${(props) =>
      props.header.fontSize || SendModalDefaults.header.fontSize};
    text-align: center;
  }

  @media (min-width: ${breakpoints.medium}) {
    width: ${(props) => props.medium.width || SendModalDefaults.medium.width};
    max-width: ${(props) =>
      (parseFloat(props.medium.width || SendModalDefaults.medium.width) / 100) *
      600}px;
    padding: ${(props) =>
      props.medium.padding || SendModalDefaults.medium.padding};
  }
`;

export const CloseButton = styled.button`
  position: absolute;
  top: 0.75rem;
  right: 1.5rem;

  margin: 0;
  padding: 0;

  border: none;
  background: transparent;

  font-size: 2.5rem;
  font-weight: bolder;
  cursor: pointer;

  &:active {
    color: ${(props) => props.color || SendModalDefaults.closeButtonColor};
  }
`;

export const Form = styled.form`
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: space-between;

  width: 100%;
  flex: 0 0 100%;
  margin: 0;
  padding: ${(props) => props.padding || SendModalDefaults.formPadding};
`;

export const Input = styled.input`
  /* firefox required 'min-width' and chrome needed 'width' */
  min-width: ${(props) => props.width};
  width: ${(props) => props.width};
  flex: 0 0 ${(props) => props.width};
  padding: ${(props) => props.padding || SendModalDefaults.input.padding};
  margin: ${(props) => props.margin || SendModalDefaults.input.margin};

  font-size: ${(props) => props.fontSize || SendModalDefaults.input.fontSize};
  font-family: inherit;

  border-width: ${(props) =>
    props.borderWidth || SendModalDefaults.input.borderWidth};
  border-style: ${(props) =>
    props.borderStyle || SendModalDefaults.input.borderStyle};
  border-color: ${(props) =>
    props.borderColor || SendModalDefaults.input.borderColor};
  border-radius: ${(props) =>
    props.borderRadius || SendModalDefaults.input.borderRadius};

  &.invalid {
    border-color: ${(props) =>
      props.invalidColor || SendModalDefaults.input.invalidColor};

    color: ${(props) =>
      props.invalidColor || SendModalDefaults.input.invalidColor};

    &::placeholder {
      color: ${(props) =>
        props.invalidColor || SendModalDefaults.input.invalidColor};
    }
  }

  @media (min-width: ${breakpoints.medium}) {
    margin: ${(props) =>
      props.medium.margin || SendModalDefaults.medium.input.margin};
    width: ${(props) => props.medium.width};
    min-width: ${(props) => props.medium.width};
    padding: ${(props) =>
      props.medium.padding || SendModalDefaults.medium.input.padding};

    font-size: ${(props) =>
      props.medium.fontSize || SendModalDefaults.medium.input.fontSize};
  }

  @media (min-width: ${breakpoints.large}), (orientation: landscape) {
    margin: ${(props) =>
      props.large.margin || SendModalDefaults.large.input.margin};
    width: ${(props) => props.large.width};
    min-width: ${(props) => props.large.width};
    padding: ${(props) =>
      props.large.padding || SendModalDefaults.large.input.padding};

    font-size: ${(props) =>
      props.large.fontSize || SendModalDefaults.large.input.fontSize};
  }
`;

export const TextArea = styled.textarea`
  width: ${(props) => props.width || SendModalDefaults.textArea.width};
  flex: 0 1 ${(props) => props.width || SendModalDefaults.textArea.width};
  min-height: ${(props) =>
    props.minHeight || SendModalDefaults.textArea.minHeight};
  padding: ${(props) => props.padding || SendModalDefaults.textArea.padding};
  margin: ${(props) => props.margin || SendModalDefaults.textArea.margin};

  font-size: ${(props) =>
    props.fontSize || SendModalDefaults.textArea.fontSize};
  font-family: inherit;

  resize: none;
  border-width: ${(props) =>
    props.borderWidth || SendModalDefaults.textArea.borderWidth};
  border-style: ${(props) =>
    props.borderStyle || SendModalDefaults.textArea.borderStyle};
  border-color: ${(props) =>
    props.borderColor || SendModalDefaults.textArea.borderColor};
  border-radius: ${(props) =>
    props.borderRadius || SendModalDefaults.textArea.borderRadius};

  &.invalid {
    border-color: ${(props) =>
      props.invalidColor || SendModalDefaults.textArea.invalidColor};

    color: ${(props) =>
      props.invalidColor || SendModalDefaults.textArea.invalidColor};

    &::placeholder {
      color: ${(props) =>
        props.invalidColor || SendModalDefaults.textArea.invalidColor};
    }
  }

  @media (min-width: ${breakpoints.medium}) {
    margin: ${(props) =>
      props.medium.margin || SendModalDefaults.medium.textArea.margin};
    width: ${(props) =>
      props.medium.width || SendModalDefaults.medium.textArea.width};
    padding: ${(props) =>
      props.medium.padding || SendModalDefaults.medium.textArea.padding};
    min-height: ${(props) =>
      props.medium.minHeight || SendModalDefaults.medium.textArea.minHeight};

    font-size: ${(props) =>
      props.medium.fontSize || SendModalDefaults.medium.textArea.fontSize};
  }

  @media (min-width: ${breakpoints.large}), (orientation: landscape) {
    margin: ${(props) =>
      props.large.margin || SendModalDefaults.large.textArea.margin};
    width: ${(props) =>
      props.large.width || SendModalDefaults.large.textArea.width};
    padding: ${(props) =>
      props.large.padding || SendModalDefaults.large.textArea.padding};
    min-height: ${(props) =>
      props.large.minHeight || SendModalDefaults.large.textArea.minHeight};

    font-size: ${(props) =>
      props.large.fontSize || SendModalDefaults.large.textArea.fontSize};
  }
`;

export const SmallText = styled.p`
  width: ${(props) => props.width || SendModalDefaults.smallText.width};
  flex: 0 1 ${(props) => props.width || SendModalDefaults.smallText.width};
  margin: 0;

  text-align: ${(props) =>
    props.textAlign || SendModalDefaults.smallText.textAlign};
  font-size: ${(props) =>
    props.fontSize || SendModalDefaults.smallText.fontSize};

  @media (min-width: ${breakpoints.medium}) {
    width: ${(props) =>
      props.medium.width || SendModalDefaults.medium.smallText.width};

    font-size: ${(props) =>
      props.medium.fontSize || SendModalDefaults.medium.smallText.fontSize};
    text-align: ${(props) =>
      props.medium.textAlign || SendModalDefaults.medium.smallText.textAlign};
  }
`;

export const SubmitButton = styled.button`
  width: ${(props) => props.width || SendModalDefaults.submitButton.width};
  margin: ${(props) => props.margin || SendModalDefaults.submitButton.margin};
  padding: ${(props) =>
    props.padding || SendModalDefaults.submitButton.padding};

  background-color: ${(props) =>
    props.backgroundColor || SendModalDefaults.submitButton.backgroundColor};
  color: ${(props) => props.color || SendModalDefaults.submitButton.color};

  border-width: ${(props) =>
    props.borderWidth || SendModalDefaults.submitButton.borderWidth};
  border-style: ${(props) =>
    props.borderStyle || SendModalDefaults.submitButton.borderStyle};
  border-color: ${(props) =>
    props.borderColor || SendModalDefaults.submitButton.borderColor};

  font-size: ${(props) =>
    props.fontSize || SendModalDefaults.submitButton.fontSize};
  font-weight: 800;
  cursor: pointer;
`;

export const ErrorMessage = styled.p`
  width: 100%;
  margin: 0.15rem;

  color: ${(props) => props.color};

  animation: fadeIn 0.5s;

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`;
