import { useEffect, useState } from 'react';
import { textAlignOptions } from '../ThemesForm';

const getState = (info) => ({
  order: info.order ?? 5,
  padding: info.padding ?? '',
  title: {
    padding: info?.title?.padding ?? '',
    fontSize: info?.title?.fontSize ?? '',
    fontWeight: info?.title?.fontWeight ?? '',
    lineHeight: info?.title?.lineHeight ?? '',
    textAlign: info?.title?.textAlign ?? '',
  },
  text: {
    padding: info?.text?.padding ?? '',
    fontSize: info?.text?.fontSize ?? '',
    fontWeight: info?.text?.fontWeight ?? '',
    lineHeight: info?.text?.lineHeight ?? '',
    textAlign: info?.text?.textAlign ?? '',
  },
  medium: {
    order: info?.medium?.order ?? 5,
    padding: info?.medium?.padding ?? '',
    title: {
      padding: info?.medium?.title?.padding ?? '',
      fontSize: info?.medium?.title?.fontSize ?? '',
      fontWeight: info?.medium?.title?.fontWeight ?? '',
      lineHeight: info?.medium?.title?.lineHeight ?? '',
      textAlign: info?.medium?.title?.textAlign ?? '',
    },
    text: {
      padding: info?.medium?.text?.padding ?? '',
      fontSize: info?.medium?.text?.fontSize ?? '',
      fontWeight: info?.medium?.text?.fontWeight ?? '',
      lineHeight: info?.medium?.text?.lineHeight ?? '',
      textAlign: info?.medium?.text?.textAlign ?? '',
    },
  },
  large: {
    order: info?.large?.order ?? 5,
    padding: info?.large?.padding ?? '',
    title: {
      padding: info?.large?.title?.padding ?? '',
      fontSize: info?.large?.title?.fontSize ?? '',
      fontWeight: info?.large?.title?.fontWeight ?? '',
      lineHeight: info?.large?.title?.lineHeight ?? '',
      textAlign: info?.large?.title?.textAlign ?? '',
    },
    text: {
      padding: info?.large?.text?.padding ?? '',
      fontSize: info?.large?.text?.fontSize ?? '',
      fontWeight: info?.large?.text?.fontWeight ?? '',
      lineHeight: info?.large?.text?.lineHeight ?? '',
      textAlign: info?.large?.text?.textAlign ?? '',
    },
  },
});

const useAboutMe = (info) => {
  const [about, setAbout] = useState(getState(info));

  useEffect(() => {
    setAbout(getState(info));
  }, [info]);

  const handleChange = (propertyName, value, element = '', breakpoint = '') => {
    const newInfo = { ...about };

    if (breakpoint === 'medium' || breakpoint === 'large') {
      if (element && element !== '') {
        newInfo[breakpoint][element] = {
          ...newInfo[breakpoint][element],
          [propertyName]: value,
        };
      } else {
        newInfo[breakpoint] = {
          ...newInfo[breakpoint],
          [propertyName]: value,
        };
      }
    } else if (element && element !== '') {
      newInfo[element][propertyName] = value;
    } else {
      newInfo[propertyName] = value;
    }

    setAbout(newInfo);
  };

  const inputs = [
    {
      type: 'number',
      value: about.order,
      onChange: (evt) => handleChange('order', evt.target.value),
      label: 'ORDER',
      name: 'aboutOrder',
      placeholder: '5',
    },
    {
      type: 'text',
      value: about.padding,
      onChange: (evt) => handleChange('padding', evt.target.value),
      label: 'PADDING',
      name: 'aboutPadding',
      placeholder: '0.75rem 0.95rem 0.125rem 0.95rem',
    },

    {
      type: 'text',
      value: about.title.padding,
      onChange: (evt) => handleChange('padding', evt.target.value, 'title'),
      label: 'TITLE PADDING',
      name: 'aboutTitlePadding',
      placeholder: '0',
    },
    {
      type: 'text',
      value: about.title.fontSize,
      onChange: (evt) => handleChange('fontSize', evt.target.value, 'title'),
      label: 'TITLE FONT SIZE',
      name: 'aboutTitleFontSize',
      placeholder: '1.3rem',
    },
    {
      type: 'text',
      value: about.title.fontWeight,
      onChange: (evt) => handleChange('fontWeight', evt.target.value, 'title'),
      label: 'TITLE FONT WEIGHT',
      name: 'aboutTitleFontWeight',
      placeholder: '600',
    },
    {
      type: 'text',
      value: about.title.lineHeight,
      onChange: (evt) => handleChange('lineHeight', evt.target.value, 'title'),
      label: 'TITLE LINE HEIGHT',
      name: 'aboutTitleLineHeight',
      placeholder: '1.7rem',
    },
    {
      as: 'select',
      options: textAlignOptions,
      value: about.title.textAlign,
      onChange: (evt) => handleChange('textAlign', evt.target.value, 'title'),
      label: 'TITLE TEXT ALIGN',
      name: 'aboutTitleTextAlign',
    },

    {
      type: 'text',
      value: about.text.padding,
      onChange: (evt) => handleChange('padding', evt.target.value, 'text'),
      label: 'TEXT PADDING',
      name: 'aboutTextPadding',
      placeholder: '0',
    },
    {
      type: 'text',
      value: about.text.fontSize,
      onChange: (evt) => handleChange('fontSize', evt.target.value, 'text'),
      label: 'TEXT FONT SIZE',
      name: 'aboutTextFontSize',
      placeholder: '1rem',
    },
    {
      type: 'text',
      value: about.text.fontWeight,
      onChange: (evt) => handleChange('fontWeight', evt.target.value, 'text'),
      label: 'TEXT FONT WEIGHT',
      name: 'aboutTextFontWeight',
      placeholder: '400',
    },
    {
      type: 'text',
      value: about.text.lineHeight,
      onChange: (evt) => handleChange('lineHeight', evt.target.value, 'text'),
      label: 'TEXT LINE HEIGHT',
      name: 'aboutTextLineHeight',
      placeholder: '1.5rem',
    },
    {
      as: 'select',
      options: textAlignOptions,
      value: about.text.textAlign,
      onChange: (evt) => handleChange('textAlign', evt.target.value, 'text'),
      label: 'TEXT TEXT ALIGN',
      name: 'aboutTextTextAlign',
    },
  ];
  const mediumInputs = [
    {
      type: 'number',
      value: about.medium.order,
      onChange: (evt) =>
        handleChange('order', evt.target.value, undefined, 'medium'),
      label: 'ORDER',
      name: 'aboutOrderMedium',
      placeholder: '5',
    },
    {
      type: 'text',
      value: about.medium.padding,
      onChange: (evt) =>
        handleChange('padding', evt.target.value, undefined, 'medium'),
      label: 'PADDING',
      name: 'aboutPaddingMedium',
      placeholder: '1.1rem 0.95rem 0 0.95rem',
    },

    {
      type: 'text',
      value: about.medium.title.padding,
      onChange: (evt) =>
        handleChange('padding', evt.target.value, 'title', 'medium'),
      label: 'TITLE PADDING',
      name: 'aboutTitlePaddingMedium',
      placeholder: '0',
    },
    {
      type: 'text',
      value: about.medium.title.fontSize,
      onChange: (evt) =>
        handleChange('fontSize', evt.target.value, 'title', 'medium'),
      label: 'TITLE FONT SIZE',
      name: 'aboutTitleFontSizeMedium',
      placeholder: '1.4rem',
    },
    {
      type: 'text',
      value: about.medium.title.fontWeight,
      onChange: (evt) =>
        handleChange('fontWeight', evt.target.value, 'title', 'medium'),
      label: 'TITLE FONT WEIGHT',
      name: 'aboutTitleFontWeightMedium',
      placeholder: '600',
    },
    {
      type: 'text',
      value: about.medium.title.lineHeight,
      onChange: (evt) =>
        handleChange('lineHeight', evt.target.value, 'title', 'medium'),
      label: 'TITLE LINE HEIGHT',
      name: 'aboutTitleLineHeightMedium',
      placeholder: '1.8rem',
    },
    {
      as: 'select',
      options: textAlignOptions,
      value: about.medium.title.textAlign,
      onChange: (evt) =>
        handleChange('textAlign', evt.target.value, 'title', 'medium'),
      label: 'TITLE TEXT ALIGN',
      name: 'aboutTitleTextAlignMedium',
    },

    {
      type: 'text',
      value: about.medium.text.padding,
      onChange: (evt) =>
        handleChange('padding', evt.target.value, 'text', 'medium'),
      label: 'TEXT PADDING',
      name: 'aboutTextPaddingMedium',
      placeholder: '0',
    },
    {
      type: 'text',
      value: about.medium.text.fontSize,
      onChange: (evt) =>
        handleChange('fontSize', evt.target.value, 'text', 'medium'),
      label: 'TEXT FONT SIZE',
      name: 'aboutTextFontSizeMedium',
      placeholder: '1rem',
    },
    {
      type: 'text',
      value: about.medium.text.fontWeight,
      onChange: (evt) =>
        handleChange('fontWeight', evt.target.value, 'text', 'medium'),
      label: 'TEXT FONT WEIGHT',
      name: 'aboutTextFontWeightMedium',
      placeholder: '400',
    },
    {
      type: 'text',
      value: about.medium.text.lineHeight,
      onChange: (evt) =>
        handleChange('lineHeight', evt.target.value, 'text', 'medium'),
      label: 'TEXT LINE HEIGHT',
      name: 'aboutTextLineHeightMedium',
      placeholder: '1.5rem',
    },
    {
      as: 'select',
      options: textAlignOptions,
      value: about.medium.text.textAlign,
      onChange: (evt) =>
        handleChange('textAlign', evt.target.value, 'text', 'medium'),
      label: 'TEXT TEXT ALIGN',
      name: 'aboutTextTextAlignMedium',
    },
  ];
  const largeInputs = [
    {
      type: 'number',
      value: about.large.order,
      onChange: (evt) =>
        handleChange('order', evt.target.value, undefined, 'large'),
      label: 'ORDER',
      name: 'aboutOrderLarge',
      placeholder: '5',
    },
    {
      type: 'text',
      value: about.large.padding,
      onChange: (evt) =>
        handleChange('padding', evt.target.value, undefined, 'large'),
      label: 'PADDING',
      name: 'aboutPaddingLarge',
      placeholder: '1.25rem 2.2rem 0 2.2rem',
    },

    {
      type: 'text',
      value: about.large.title.padding,
      onChange: (evt) =>
        handleChange('padding', evt.target.value, 'title', 'large'),
      label: 'TITLE PADDING',
      name: 'aboutTitlePaddingLarge',
      placeholder: '0',
    },
    {
      type: 'text',
      value: about.large.title.fontSize,
      onChange: (evt) =>
        handleChange('fontSize', evt.target.value, 'title', 'large'),
      label: 'TITLE FONT SIZE',
      name: 'aboutTitleFontSizeLarge',
      placeholder: '1.7rem',
    },
    {
      type: 'text',
      value: about.large.title.fontWeight,
      onChange: (evt) =>
        handleChange('fontWeight', evt.target.value, 'title', 'large'),
      label: 'TITLE FONT WEIGHT',
      name: 'aboutTitleFontWeightLarge',
      placeholder: '600',
    },
    {
      type: 'text',
      value: about.large.title.lineHeight,
      onChange: (evt) =>
        handleChange('lineHeight', evt.target.value, 'title', 'large'),
      label: 'TITLE LINE HEIGHT',
      name: 'aboutTitleLineHeightLarge',
      placeholder: '2.2rem',
    },
    {
      as: 'select',
      options: textAlignOptions,
      value: about.large.title.textAlign,
      onChange: (evt) =>
        handleChange('textAlign', evt.target.value, 'title', 'large'),
      label: 'TITLE TEXT ALIGN',
      name: 'aboutTitleTextAlignLarge',
    },

    {
      type: 'text',
      value: about.large.text.padding,
      onChange: (evt) =>
        handleChange('padding', evt.target.value, 'text', 'large'),
      label: 'TEXT PADDING',
      name: 'aboutTextPaddingLarge',
      placeholder: '0',
    },
    {
      type: 'text',
      value: about.large.text.fontSize,
      onChange: (evt) =>
        handleChange('fontSize', evt.target.value, 'text', 'large'),
      label: 'TEXT FONT SIZE',
      name: 'aboutTextFontSizeLarge',
      placeholder: '1rem',
    },
    {
      type: 'text',
      value: about.large.text.fontWeight,
      onChange: (evt) =>
        handleChange('fontWeight', evt.target.value, 'text', 'large'),
      label: 'TEXT FONT WEIGHT',
      name: 'aboutTextFontWeightLarge',
      placeholder: '400',
    },
    {
      type: 'text',
      value: about.large.text.lineHeight,
      onChange: (evt) =>
        handleChange('lineHeight', evt.target.value, 'text', 'large'),
      label: 'TEXT LINE HEIGHT',
      name: 'aboutTextLineHeightLarge',
      placeholder: '1.5rem',
    },
    {
      as: 'select',
      options: textAlignOptions,
      value: about.large.text.textAlign,
      onChange: (evt) =>
        handleChange('textAlign', evt.target.value, 'text', 'large'),
      label: 'TEXT TEXT ALIGN',
      name: 'aboutTextTextAlignLarge',
    },
  ];

  return { info: about, inputs, mediumInputs, largeInputs };
};

export default useAboutMe;
