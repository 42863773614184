import { useEffect, useState } from 'react';
import { flexDirectionOptions } from '../ThemesForm';

const getState = (info) => ({
  order: info.order ?? 2,
  isAddressBelow: info.isAddresBelow ?? true,
  isAddressVisibleOnAboutMe: info.isAddressVisibleOnAboutMe ?? false,
  isOverPhoto: info.isOverPhoto ?? false,
  overPhotoPosition: info.overPhotoPosition ?? '',
  isSocialVisible: info.isSocialVisible ?? false,
  isUserInfoVisible: info.isUserInfoVisible ?? true,
  isAddresVisible: info.isAddresVisible ?? true,
  flexDirection: info.flexDirection ?? '',
  backgroundColor: info.backgroundColor ?? '',
});

const useCombinedInfo = (info) => {
  const [combinedInfo, setCombinedInfo] = useState(getState(info));

  useEffect(() => {
    setCombinedInfo(getState(info));
  }, [info]);

  const handleChange = (propertyName, value) => {
    const newInfo = { ...combinedInfo };
    newInfo[propertyName] = value;
    setCombinedInfo(newInfo);
  };

  const inputs = [
    {
      type: 'number',
      value: combinedInfo.order,
      onChange: (evt) => handleChange('order', evt.target.value),
      label: 'ORDER',
      name: 'combinedOrder',
      placeholder: '2',
    },
    {
      as: 'select',
      options: flexDirectionOptions,
      value: combinedInfo.flexDirection,
      onChange: (evt) => handleChange('flexDirection', evt.target.value),
      label: 'DIRECTION',
      name: 'combinedFlexDirection',
    },
    {
      type: 'text',
      value: combinedInfo.backgroundColor,
      onChange: (evt) => handleChange('backgroundColor', evt.target.value),
      label: 'BACKGROUND COLOR',
      name: 'combinedBackgroundColor',
      placeholder: '#eeeeee',
    },
    {
      type: 'checkbox',
      value: combinedInfo.isSocialVisible,
      onChange: (evt) => handleChange('isSocialVisible', evt.target.checked),
      label: 'ARE SOCIAL LINKS VISIBLE?',
      name: 'combinedOrderIsSocialVisible',
    },
    {
      type: 'checkbox',
      value: combinedInfo.isUserInfoVisible,
      onChange: (evt) => handleChange('isUserInfoVisible', evt.target.checked),
      label: 'IS USER INFO VISIBLE?',
      name: 'combinedOrderIsUserInfoVisible',
    },
    {
      type: 'checkbox',
      value: combinedInfo.isAddressBelow,
      onChange: (evt) => handleChange('isAddressBelow', evt.target.checked),
      label: 'IS ADDRESS BELOW THE PHOTO?',
      name: 'combinedOrderIsAddressBelow',
    },
    {
      type: 'checkbox',
      value: combinedInfo.isAddressVisibleOnAboutMe,
      onChange: (evt) =>
        handleChange('isAddressVisibleOnAboutMe', evt.target.checked),
      label: "IS ADDRESS VISIBLE ON 'ABOUT ME' PAGE?",
      name: 'combinedOrderIsAddressVisibleOnAboutMe',
    },
    {
      type: 'checkbox',
      value: combinedInfo.isOverPhoto,
      onChange: (evt) => handleChange('isOverPhoto', evt.target.checked),
      label: 'IS INFO OVERLAYED ON THE PHOTO?',
      name: 'combinedOrderIsOverPhoto',
    },
    {
      as: 'select',
      options: [
        { value: '', text: 'chooose an option' },
        { value: 'top', text: 'top' },
        { value: 'bottom', text: 'bottom' },
      ],
      value: combinedInfo.overPhotoPosition,
      onChange: (evt) => handleChange('overPhotoPosition', evt.target.value),
      label: 'POSITION ON THE PHOTO?',
      name: 'combinedOrderOverPhotoPosition',
    },
  ];

  return { info: combinedInfo, inputs };
};

export default useCombinedInfo;
