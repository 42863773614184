import React, { useState } from 'react';
import './Register.scss';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { useAuth } from '../../hooks/Auth';
import FormControl from '../FormControl/FormControl';
import Button from '../Button/Button';
import { validatePassword } from '../../utils/helper';
import store, { NotificationTypes } from '../../hooks/NotificationHub';

const Register = () => {
  const auth = useAuth();
  const history = useHistory();

  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');

  const [password, setPassword] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  const [passwordConfirmation, setPasswordConfirmation] = useState('');
  const [passwordConfirmationError, setPasswordConfirmationError] = useState(
    '',
  );
  const [
    isPasswordConfirmationVisible,
    setIsPasswordConfirmationVisible,
  ] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const onPasswordInput = (newPassword) => {
    const errorMessage = validatePassword(newPassword);

    if (errorMessage !== '') {
      setPasswordError(errorMessage);
    } else {
      setPasswordError('');
    }

    if (newPassword === passwordConfirmation) {
      setPasswordConfirmationError('');
    }

    setPassword(newPassword);
  };

  const onPasswordConfirmationInput = (newPasswordConfirmation) => {
    if (newPasswordConfirmation !== password) {
      setPasswordConfirmationError('Passwords have to match');
    } else {
      setPasswordConfirmationError('');
    }

    setPasswordConfirmation(newPasswordConfirmation);
  };

  const onSubmit = async (evt) => {
    evt.preventDefault();
    setIsLoading(true);

    let isValid = true;

    if (email.trim().length === 0) {
      setEmailError('Email cannot be empty');
      isValid = false;
    }

    if (password.trim().length === 0) {
      setPasswordError('Password cannot be empty');
      isValid = false;
    }

    if (password !== passwordConfirmation) {
      setPasswordConfirmationError('Passwords have to match');
      isValid = false;
    }

    if (passwordError !== '' || passwordConfirmationError !== '' || !isValid) {
      setIsLoading(false);
      return;
    }

    const result = await auth.register(email, password);

    if (!result.error) {
      if (!result.isEmailVerified) {
        store.pushNotification(
          'Account has been successfully created',
          'A verification link has been sent to you. Please check your inbox.',
          NotificationTypes.SUCCESS,
        );
      } else {
        store.pushNotification(
          'Account has been successfully created',
          'You can now log in to the site.',
          NotificationTypes.SUCCESS,
        );
      }

      setIsLoading(false);
      history.push('/login');
    } else {
      setIsLoading(false);
      store.pushNotification(
        result.error.message,
        (result.error.errors.email && result.error.errors.email[0]) ??
          'An error occured during registration. Please try again later.',
        NotificationTypes.DANGER,
      );
    }
  };

  return (
    <form className="register" onSubmit={onSubmit}>
      <h1 className="register__header">Create Your Account Now</h1>
      <FormControl
        type="email"
        name="email"
        label="EMAIL"
        value={email}
        onChange={(evt) => setEmail(evt.target.value)}
        err={emailError}
        autocomplete="on"
      />
      <FormControl
        type={!isPasswordVisible ? 'password' : 'text'}
        name="password"
        label="PASSWORD"
        value={password}
        onChange={(evt) => onPasswordInput(evt.target.value)}
        err={passwordError}
        icon={(className, onClick) => (
          <FontAwesomeIcon
            icon={!isPasswordVisible ? faEye : faEyeSlash}
            className={`main__icon ${className}`}
            onClick={onClick}
          />
        )}
        iconAlignment="right"
        isIconInside
        onIconClick={() => setIsPasswordVisible(!isPasswordVisible)}
        autocomplete="on"
      />
      <FormControl
        type={!isPasswordConfirmationVisible ? 'password' : 'text'}
        name="passwordConfirmation"
        label="CONFIRM PASSWORD"
        value={passwordConfirmation}
        onChange={(evt) => onPasswordConfirmationInput(evt.target.value)}
        err={passwordConfirmationError}
        icon={(className, onClick) => (
          <FontAwesomeIcon
            icon={!isPasswordConfirmationVisible ? faEye : faEyeSlash}
            className={`main__icon ${className}`}
            onClick={onClick}
          />
        )}
        iconAlignment="right"
        isIconInside
        onIconClick={() =>
          setIsPasswordConfirmationVisible(!isPasswordConfirmationVisible)
        }
      />
      <Button
        variant="secondary"
        text="RETURN"
        type="button"
        onClick={() => history.push('/login')}
      />
      <Button
        variant="primary"
        text="REGISTER"
        type="submit"
        state={isLoading ? 'loading' : ''}
        disabled={isLoading}
      />
    </form>
  );
};

export default Register;
