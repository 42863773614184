import React from 'react';
import { Route, Switch } from 'react-router-dom';
import PageHeader from '../../PageHeader/PageHeader';
import UserTable from './UsersTable/UserTable';
import UsersForm from './UsersForm/UsersForm';
import ImportMapping from './ImportMapping/ImportMapping';

const UsersManagement = () => {
  return (
    <section className="users-management">
      <PageHeader text="Users management" />
      <Switch>
        <Route exact path="/admin/users">
          <UserTable />
        </Route>
        <Route path="/admin/users/add">
          <UsersForm header="Add new user" />
        </Route>
        <Route path="/admin/users/edit/:id">
          <UsersForm header="Edit user" />
        </Route>
        <Route path="/admin/users/import">
          <ImportMapping />
        </Route>
      </Switch>
    </section>
  );
};

export default UsersManagement;
