import React, { useEffect, useState } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { getCurrentTheme, getCurrentUser } from '../../utils/mbcApiCalls';
import { defaultStyles } from './defaultStyles';
import { createComponentProps, remapUserProperties } from './helper';
import MBC from './MBC';
import { ErrorScreen, ReloadButton } from './shared/sharedStyles';

const MBCPage = () => {
  const location = useLocation();
  const params = useParams();

  // used to trigger refresh in case of errors
  const [reloadIndex, setReloadIndex] = useState(0);
  const [hasErrors, setHasErorrs] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const [user, setUser] = useState(null);
  const [theme, setTheme] = useState(null);
  const [props, setProps] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);

  const getUser = async () => {
    setIsLoading(true);

    try {
      let chosenUser = await getCurrentUser(params.id);
      let chosenTheme = {};

      try {
        const newTheme = await getCurrentTheme(chosenUser.data.mbc_theme_id);
        chosenTheme = newTheme.data.settings;
      } catch (err) {
        chosenTheme = defaultStyles;
      }

      chosenUser = remapUserProperties(chosenUser.data);

      setUser(chosenUser);
      setTheme(chosenTheme);
    } catch (err) {
      setIsLoading(false);
      setHasErorrs(true);
    }
  };

  useEffect(() => {
    // eslint-disable-next-line camelcase
    const { utm_source } = queryString.parse(location.search);
    sessionStorage.setItem('mbc-source', utm_source);
  }, []);

  useEffect(() => {
    getUser();
  }, [location.pathname, reloadIndex]);

  useEffect(() => {
    if (user && theme && !hasErrors) {
      setProps(
        createComponentProps(
          location.pathname.includes('about') ? theme.about : theme.home,
          theme,
          user,
          params.id,
          setIsModalOpen,
        ),
      );
      setIsLoading(false);
      setIsFirstLoad(false);
    }
  }, [user, theme]);

  if (hasErrors) {
    return (
      <ErrorScreen>
        <p>Oops... something went wrong</p>
        <ReloadButton
          type="button"
          onClick={() => {
            setReloadIndex(reloadIndex + 1);
            setHasErorrs(false);
          }}>
          Reload
        </ReloadButton>
      </ErrorScreen>
    );
  }

  return (
    <MBC
      isLoading={isLoading}
      isFirstLoad={isFirstLoad}
      isModalOpen={isModalOpen}
      visibility={
        location.pathname.includes('about') ? theme?.about : theme?.home
      }
      {...props}
    />
  );
};

export default MBCPage;
