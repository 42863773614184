import React from 'react';
import './SocialForm.scss';
import PropTypes from 'prop-types';
import SectionHeader from '../SectionHeader/SectionHeader';
import FormControl from '../FormControl/FormControl';

const SocialForm = ({ inputs }) => (
  <section className="social">
    <SectionHeader text="SOCIAL LINKS" />
    <div className="social__form">
      {inputs.map((inp) => (
        <FormControl {...inp} key={inp.name} />
      ))}
    </div>
  </section>
);

SocialForm.propTypes = {
  inputs: PropTypes.arrayOf(PropTypes.object),
};

export default SocialForm;
