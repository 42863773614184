import styled from 'styled-components';
import { breakpoints } from '../cssVariables';
import { getDefaultStylesForComponent } from '../defaultStyles';

const SocialLinkDefaults = getDefaultStylesForComponent('SocialLink');

export const Social = styled.a`
  display: ${(props) => (!props.href && 'none') || 'flex'};
  order: ${(props) => props.order || SocialLinkDefaults.order};

  padding: ${(props) => props.padding || SocialLinkDefaults.padding};
  margin: ${(props) => props.margin || SocialLinkDefaults.margin};

  & img {
    height: ${(props) => props.height || SocialLinkDefaults.height};
    width: auto;
  }

  @media (min-width: ${breakpoints.medium}) {
    order: ${(props) => props.medium.order || SocialLinkDefaults.medium.order};

    padding: ${(props) =>
      props.medium.padding || SocialLinkDefaults.medium.padding};
    margin: ${(props) =>
      props.medium.padding || SocialLinkDefaults.medium.padding};

    & img {
      height: ${(props) =>
        props.medium.height || SocialLinkDefaults.medium.height};
    }
  }

  @media (min-width: ${breakpoints.large}), (orientation: landscape) {
    order: ${(props) => props.large.order || SocialLinkDefaults.large.order};

    padding: ${(props) =>
      props.large.padding || SocialLinkDefaults.large.padding};
    margin: ${(props) =>
      props.large.padding || SocialLinkDefaults.large.padding};

    & img {
      height: ${(props) =>
        props.large.height || SocialLinkDefaults.large.height};
    }
  }
`;

const SocialLinkGroupDefaults = getDefaultStylesForComponent('SocialLinkGroup');

export const LinkContainer = styled.div`
  display: ${(props) => (!props.isVisible && 'none') || 'flex'};
  flex-flow: ${(props) => props.flexFlow || SocialLinkGroupDefaults.flexFlow};
  justify-content: ${(props) =>
    props.justifyContent || SocialLinkGroupDefaults.justifyContent};
  align-items: center;
  order: ${(props) => props.order || SocialLinkGroupDefaults.order};
  align-self: flex-end;

  width: ${(props) => props.width || SocialLinkGroupDefaults.width};
  min-width: ${(props) => props.width || SocialLinkGroupDefaults.width};
  flex: 0 1 ${(props) => props.width || SocialLinkGroupDefaults.width};
  height: ${(props) => props.height || SocialLinkGroupDefaults.height};
  padding: ${(props) => props.padding || SocialLinkGroupDefaults.padding};

  background-color: ${(props) =>
    props.backgroundColor || SocialLinkGroupDefaults.backgroundColor};

  @media (min-width: ${breakpoints.medium}) {
    flex-flow: ${(props) =>
      props.medium.flexFlow || SocialLinkGroupDefaults.medium.flexFlow};
    justify-content: ${(props) =>
      props.medium.justifyContent ||
      SocialLinkGroupDefaults.medium.justifyContent};
    order: ${(props) =>
      props.medium.order || SocialLinkGroupDefaults.medium.order};

    width: ${(props) =>
      props.medium.width || SocialLinkGroupDefaults.medium.width};
    padding: ${(props) =>
      props.medium.padding || SocialLinkGroupDefaults.medium.padding};
  }

  @media (min-width: ${breakpoints.large}), (orientation: landscape) {
    flex-flow: ${(props) =>
      props.large.flexFlow || SocialLinkGroupDefaults.large.flexFlow};
    justify-content: ${(props) =>
      props.large.justifyContent ||
      SocialLinkGroupDefaults.large.justifyContent};
    order: ${(props) =>
      props.large.order || SocialLinkGroupDefaults.large.order};

    width: ${(props) =>
      props.large.width || SocialLinkGroupDefaults.large.width};
    padding: ${(props) =>
      props.large.padding || SocialLinkGroupDefaults.large.padding};
  }
`;
