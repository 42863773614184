import { useEffect, useState } from 'react';
import { textAlignOptions } from '../ThemesForm';

const getState = (info) => ({
  order: info.order ?? 3,
  width: info.width ?? '',
  padding: info.padding ?? '',
  backgroundColor: info.backgroundColor ?? '',
  color: info.color ?? '',
  title: {
    padding: info?.title?.padding ?? '',
    fontSize: info?.title?.fontSize ?? '',
    fontWeight: info?.title?.fontWeight ?? '',
    lineHeight: info?.title?.lineHeight ?? '',
    textAlign: info?.title?.textAlign ?? '',
  },
  subtitle: {
    padding: info?.subtitle?.padding ?? '',
    fontSize: info?.subtitle?.fontSize ?? '',
    fontWeight: info?.subtitle?.fontWeight ?? '',
    lineHeight: info?.subtitle?.lineHeight ?? '',
    textAlign: info?.subtitle?.textAlign ?? '',
  },
  medium: {
    order: info?.medium?.order ?? 3,
    width: info?.medium?.width ?? '',
    padding: info?.medium?.padding ?? '',
    title: {
      padding: info?.medium?.title?.padding ?? '',
      fontSize: info?.medium?.title?.fontSize ?? '',
      fontWeight: info?.medium?.title?.fontWeight ?? '',
      lineHeight: info?.medium?.title?.lineHeight ?? '',
      textAlign: info?.medium?.title?.textAlign ?? '',
    },
    subtitle: {
      padding: info?.medium?.subtitle?.padding ?? '',
      fontSize: info?.medium?.subtitle?.fontSize ?? '',
      fontWeight: info?.medium?.subtitle?.fontWeight ?? '',
      lineHeight: info?.medium?.subtitle?.lineHeight ?? '',
      textAlign: info?.medium?.subtitle?.textAlign ?? '',
    },
  },
  large: {
    order: info?.large?.order ?? 3,
    width: info?.large?.width ?? '',
    padding: info?.large?.padding ?? '',
    title: {
      padding: info?.large?.title?.padding ?? '',
      fontSize: info?.large?.title?.fontSize ?? '',
      fontWeight: info?.large?.title?.fontWeight ?? '',
      lineHeight: info?.large?.title?.lineHeight ?? '',
      textAlign: info?.large?.title?.textAlign ?? '',
    },
    subtitle: {
      padding: info?.large?.subtitle?.padding ?? '',
      fontSize: info?.large?.subtitle?.fontSize ?? '',
      fontWeight: info?.large?.subtitle?.fontWeight ?? '',
      lineHeight: info?.large?.subtitle?.lineHeight ?? '',
      textAlign: info?.large?.subtitle?.textAlign ?? '',
    },
  },
});

const useUserInfo = (info) => {
  const [userInfo, setUserInfo] = useState(getState(info));

  useEffect(() => {
    setUserInfo(getState(info));
  }, [info]);

  const handleChange = (propertyName, value, element = '', breakpoint = '') => {
    const newInfo = { ...userInfo };

    if (breakpoint === 'medium' || breakpoint === 'large') {
      if (element && element !== '') {
        newInfo[breakpoint][element] = {
          ...newInfo[breakpoint][element],
          [propertyName]: value,
        };
      } else {
        newInfo[breakpoint] = {
          ...newInfo[breakpoint],
          [propertyName]: value,
        };
      }
    } else if (element && element !== '') {
      newInfo[element][propertyName] = value;
    } else {
      newInfo[propertyName] = value;
    }

    setUserInfo(newInfo);
  };

  const inputs = [
    {
      type: 'number',
      value: userInfo.order,
      onChange: (evt) => handleChange('order', evt.target.value),
      label: 'ORDER',
      name: 'userOrder',
      placeholder: '3',
    },
    {
      type: 'text',
      value: userInfo.width,
      onChange: (evt) => handleChange('width', evt.target.value),
      label: 'WIDTH',
      name: 'userWidth',
      placeholder: '100%',
    },
    {
      type: 'text',
      value: userInfo.padding,
      onChange: (evt) => handleChange('padding', evt.target.value),
      label: 'PADDING',
      name: 'userPadding',
      placeholder: '0.65rem 0 0.125rem 0.95rem',
    },
    {
      type: 'text',
      value: userInfo.backgroundColor,
      onChange: (evt) => handleChange('backgroundColor', evt.target.value),
      label: 'BACKGROUND COLOR',
      name: 'userBackgroundColor',
      placeholder: '#ffffff',
    },
    {
      type: 'text',
      value: userInfo.color,
      onChange: (evt) => handleChange('color', evt.target.value),
      label: 'COLOR',
      name: 'userColor',
      placeholder: '#000000',
    },

    {
      type: 'text',
      value: userInfo.title.padding,
      onChange: (evt) => handleChange('padding', evt.target.value, 'title'),
      label: 'TITLE PADDING',
      name: 'userPaddingTitle',
      placeholder: '0',
    },
    {
      type: 'text',
      value: userInfo.title.fontSize,
      onChange: (evt) => handleChange('fontSize', evt.target.value, 'title'),
      label: 'TITLE FONT SIZE',
      name: 'userFontSizeTitle',
      placeholder: '1.8rem',
    },
    {
      type: 'text',
      value: userInfo.title.fontWeight,
      onChange: (evt) => handleChange('fontWeight', evt.target.value, 'title'),
      label: 'TITLE FONT WEIGHT',
      name: 'userFontWeightTitle',
      placeholder: '600',
    },
    {
      type: 'text',
      value: userInfo.title.lineHeight,
      onChange: (evt) => handleChange('lineHeight', evt.target.value, 'title'),
      label: 'TITLE LINE HEIGHT',
      name: 'userLineHeightTitle',
      placeholder: '2.2rem',
    },
    {
      as: 'select',
      options: textAlignOptions,
      value: userInfo.title.textAlign,
      onChange: (evt) => handleChange('textAlign', evt.target.value, 'title'),
      label: 'TITLE TEXT ALIGN',
      name: 'userTextAlignTitle',
    },

    {
      type: 'text',
      value: userInfo.subtitle.padding,
      onChange: (evt) => handleChange('padding', evt.target.value, 'subtitle'),
      label: 'SUBTITLE PADDING',
      name: 'userPaddingSubtitle',
      placeholder: '0',
    },
    {
      type: 'text',
      value: userInfo.subtitle.fontSize,
      onChange: (evt) => handleChange('fontSize', evt.target.value, 'subtitle'),
      label: 'SUBTITLE FONT SIZE',
      name: 'userFontSizeSubtitle',
      placeholder: '1rem',
    },
    {
      type: 'text',
      value: userInfo.subtitle.fontWeight,
      onChange: (evt) =>
        handleChange('fontWeight', evt.target.value, 'subtitle'),
      label: 'SUBTITLE FONT WEIGHT',
      name: 'userFontWeightSubtitle',
      placeholder: '300',
    },
    {
      type: 'text',
      value: userInfo.subtitle.lineHeight,
      onChange: (evt) =>
        handleChange('lineHeight', evt.target.value, 'subtitle'),
      label: 'SUBTITLE LINE HEIGHT',
      name: 'userLineHeightSubtitle',
      placeholder: '1.6rem',
    },
    {
      as: 'select',
      options: textAlignOptions,
      value: userInfo.subtitle.textAlign,
      onChange: (evt) =>
        handleChange('textAlign', evt.target.value, 'subtitle'),
      label: 'SUBTITLE TEXT ALIGN',
      name: 'userTextAlignSubtitle',
    },
  ];

  const mediumInputs = [
    {
      type: 'number',
      value: userInfo.medium.order,
      onChange: (evt) =>
        handleChange('order', evt.target.value, undefined, 'medium'),
      label: 'ORDER',
      name: 'userOrderMedium',
      placeholder: '3',
    },
    {
      type: 'text',
      value: userInfo.medium.width,
      onChange: (evt) =>
        handleChange('width', evt.target.value, undefined, 'medium'),
      label: 'WIDTH',
      name: 'userWidthMedium',
      placeholder: '100%',
    },
    {
      type: 'text',
      value: userInfo.medium.padding,
      onChange: (evt) =>
        handleChange('padding', evt.target.value, undefined, 'medium'),
      label: 'PADDING',
      name: 'userPaddingMedium',
      placeholder: '1.2rem 0 0.3rem 0.95rem',
    },

    {
      type: 'text',
      value: userInfo.medium.title.padding,
      onChange: (evt) =>
        handleChange('padding', evt.target.value, 'title', 'medium'),
      label: 'TITLE PADDING',
      name: 'userPaddingTitleMedium',
      placeholder: '0',
    },
    {
      type: 'text',
      value: userInfo.medium.title.fontSize,
      onChange: (evt) =>
        handleChange('fontSize', evt.target.value, 'title', 'medium'),
      label: 'TITLE FONT SIZE',
      name: 'userFontSizeTitleMedium',
      placeholder: '2.3rem',
    },
    {
      type: 'text',
      value: userInfo.medium.title.fontWeight,
      onChange: (evt) =>
        handleChange('fontWeight', evt.target.value, 'title', 'medium'),
      label: 'TITLE FONT WEIGHT',
      name: 'userFontWeightTitleMedium',
      placeholder: '600',
    },
    {
      type: 'text',
      value: userInfo.medium.title.lineHeight,
      onChange: (evt) =>
        handleChange('lineHeight', evt.target.value, 'title', 'medium'),
      label: 'TITLE LINE HEIGHT',
      name: 'userLineHeightTitleMedium',
      placeholder: '2.5rem',
    },
    {
      as: 'select',
      options: textAlignOptions,
      value: userInfo.medium.title.textAlign,
      onChange: (evt) =>
        handleChange('textAlign', evt.target.value, 'title', 'medium'),
      label: 'TITLE TEXT ALIGN',
      name: 'userTextAlignTitleMedium',
    },

    {
      type: 'text',
      value: userInfo.medium.subtitle.padding,
      onChange: (evt) =>
        handleChange('padding', evt.target.value, 'subtitle', 'medium'),
      label: 'SUBTITLE PADDING',
      name: 'userPaddingSubtitleMedium',
      placeholder: '0.5rem 0 0 0',
    },
    {
      type: 'text',
      value: userInfo.medium.subtitle.fontSize,
      onChange: (evt) =>
        handleChange('fontSize', evt.target.value, 'subtitle', 'medium'),
      label: 'SUBTITLE FONT SIZE',
      name: 'userFontSizeSubtitleMedium',
      placeholder: '1rem',
    },
    {
      type: 'text',
      value: userInfo.medium.subtitle.fontWeight,
      onChange: (evt) =>
        handleChange('fontWeight', evt.target.value, 'subtitle', 'medium'),
      label: 'SUBTITLE FONT WEIGHT',
      name: 'userFontWeightSubtitleMedium',
      placeholder: '300',
    },
    {
      type: 'text',
      value: userInfo.medium.subtitle.lineHeight,
      onChange: (evt) =>
        handleChange('lineHeight', evt.target.value, 'subtitle', 'medium'),
      label: 'SUBTITLE LINE HEIGHT',
      name: 'userLineHeightSubtitleMedium',
      placeholder: '1.6remrem',
    },
    {
      as: 'select',
      options: textAlignOptions,
      value: userInfo.medium.subtitle.textAlign,
      onChange: (evt) =>
        handleChange('textAlign', evt.target.value, 'subtitle', 'medium'),
      label: 'SUBTITLE TEXT ALIGN',
      name: 'userTextAlignSubtitleMedium',
    },
  ];

  const largeInputs = [
    {
      type: 'number',
      value: userInfo.large.order,
      onChange: (evt) =>
        handleChange('order', evt.target.value, undefined, 'large'),
      label: 'ORDER',
      name: 'userOrderLarge',
      placeholder: '3',
    },
    {
      type: 'text',
      value: userInfo.large.width,
      onChange: (evt) =>
        handleChange('width', evt.target.value, undefined, 'large'),
      label: 'WIDTH',
      name: 'userWidthLarge',
      placeholder: '100%',
    },
    {
      type: 'text',
      value: userInfo.large.padding,
      onChange: (evt) =>
        handleChange('padding', evt.target.value, undefined, 'large'),
      label: 'PADDING',
      name: 'userPaddingLarge',
      placeholder: '0.75rem 0 0.3rem 2.15rem',
    },

    {
      type: 'text',
      value: userInfo.large.title.padding,
      onChange: (evt) =>
        handleChange('padding', evt.target.value, 'title', 'large'),
      label: 'TITLE PADDING',
      name: 'userPaddingTitleLarge',
      placeholder: '0',
    },
    {
      type: 'text',
      value: userInfo.large.title.fontSize,
      onChange: (evt) =>
        handleChange('fontSize', evt.target.value, 'title', 'large'),
      label: 'TITLE FONT SIZE',
      name: 'userFontSizeTitleLarge',
      placeholder: '2.2rem',
    },
    {
      type: 'text',
      value: userInfo.large.title.fontWeight,
      onChange: (evt) =>
        handleChange('fontWeight', evt.target.value, 'title', 'large'),
      label: 'TITLE FONT WEIGHT',
      name: 'userFontWeightTitleLarge',
      placeholder: '600',
    },
    {
      type: 'text',
      value: userInfo.large.title.lineHeight,
      onChange: (evt) =>
        handleChange('lineHeight', evt.target.value, 'title', 'large'),
      label: 'TITLE LINE HEIGHT',
      name: 'userLineHeightTitleLarge',
      placeholder: '2.8rem',
    },
    {
      as: 'select',
      options: textAlignOptions,
      value: userInfo.large.title.textAlign,
      onChange: (evt) =>
        handleChange('textAlign', evt.target.value, 'title', 'large'),
      label: 'TITLE TEXT ALIGN',
      name: 'userTextAlignTitleLarge',
    },

    {
      type: 'text',
      value: userInfo.large.subtitle.padding,
      onChange: (evt) =>
        handleChange('padding', evt.target.value, 'subtitle', 'large'),
      label: 'SUBTITLE PADDING',
      name: 'userPaddingSubtitleLarge',
      placeholder: '0.5rem 0 0 0',
    },
    {
      type: 'text',
      value: userInfo.large.subtitle.fontSize,
      onChange: (evt) =>
        handleChange('fontSize', evt.target.value, 'subtitle', 'large'),
      label: 'SUBTITLE FONT SIZE',
      name: 'userFontSizeSubtitleLarge',
      placeholder: '1rem',
    },
    {
      type: 'text',
      value: userInfo.large.subtitle.fontWeight,
      onChange: (evt) =>
        handleChange('fontWeight', evt.target.value, 'subtitle', 'large'),
      label: 'SUBTITLE FONT WEIGHT',
      name: 'userFontWeightSubtitleLarge',
      placeholder: '300',
    },
    {
      type: 'text',
      value: userInfo.large.subtitle.lineHeight,
      onChange: (evt) =>
        handleChange('lineHeight', evt.target.value, 'subtitle', 'large'),
      label: 'SUBTITLE LINE HEIGHT',
      name: 'userLineHeightSubtitleLarge',
      placeholder: '1.6rem',
    },
    {
      as: 'select',
      options: textAlignOptions,
      value: userInfo.large.subtitle.textAlign,
      onChange: (evt) =>
        handleChange('textAlign', evt.target.value, 'subtitle', 'large'),
      label: 'SUBTITLE TEXT ALIGN',
      name: 'userTextAlignSubtitleLarge',
    },
  ];

  return { info: userInfo, inputs, mediumInputs, largeInputs };
};

export default useUserInfo;
