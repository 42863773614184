import React from 'react';
import './Themes.scss';
import { Route, Switch } from 'react-router-dom';
import PageHeader from '../../PageHeader/PageHeader';
import ThemesList from './ThemesList/ThemesList';
import ThemesForm from './ThemesForm/ThemesForm';

const Themes = () => {
  return (
    <section className="themes">
      <PageHeader text="Themes" />
      <Switch>
        <Route exact path="/admin/themes">
          <ThemesList />
        </Route>
        <Route path="/admin/themes/edit/:uuid">
          <ThemesForm />
        </Route>
        <Route path="/admin/themes/add/:uuid?">
          <ThemesForm />
        </Route>
      </Switch>
    </section>
  );
};

export default Themes;
