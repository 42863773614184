import React, { useState, useEffect, useRef } from 'react';
import './LogoSelector.scss';
import PropTypes from 'prop-types';
import SectionHeader from '../../SectionHeader/SectionHeader';
import { getFileResolution } from '../../../utils/helper';
import {
  DEFAULT_SIZE_ALERT,
  DEFAULT_RESOLUTION_ALERT,
} from '../../../data/DefaultMessages';
import { acceptedFileExtensions } from '../../../data/ImgEditor';
import Modal from '../../ImgEditor/Modal';

const LogoSelector = ({ logoUrl, onClick, hasClosed, uploadRequired }) => {
  const fileInput = useRef(null);
  const [isSet, setIsSet] = useState('');
  const [modalVisible, setModalVisible] = useState(false);
  const logoSource = '/images/account/logo_placeholder.svg';

  useEffect(() => {
    if (
      isSet &&
      fileInput.current.value &&
      fileInput.current.value !== '' &&
      hasClosed
    ) {
      if (fileInput.current.files[0].size / (1024 * 1024) > 10) {
        // eslint-disable-next-line no-alert
        alert(DEFAULT_SIZE_ALERT);
        setIsSet(false);
        fileInput.current.value = '';
        fileInput.current.files = null;
      } else {
        getFileResolution(fileInput.current.files[0])
          .then((res) => res)
          .then((resolution) => {
            if (resolution.width > 1920 || resolution.height > 1080) {
              // eslint-disable-next-line no-alert
              alert(DEFAULT_RESOLUTION_ALERT);
              setIsSet(false);
              fileInput.current.value = '';
              fileInput.current.files = null;
            } else {
              setModalVisible(false);
              onClick(fileInput.current.files[0]);
            }
          });
      }
    }

    // eslint-disable-next-line
  }, [isSet]);

  useEffect(() => {
    if (hasClosed) {
      setIsSet(false);
      fileInput.current.value = '';
      fileInput.current.files = null;
    }
  }, [hasClosed]);

  return (
    <section className="logo-selector">
      <SectionHeader text="COMPANY LOGO" />
      <button
        type="button"
        className="logo-selector__image-wrapper"
        onClick={() =>
          !logoUrl ? fileInput.current.click() : setModalVisible(true)
        }>
        <div
          aria-label="Company logo"
          className="logo-selector__image"
          title="Company logo"
          style={{ backgroundImage: `url(${logoUrl ?? logoSource})` }}
        />
        <input
          onChange={() => setIsSet(true)}
          type="file"
          ref={fileInput}
          className="logo-selector__input"
          accept={acceptedFileExtensions.join(',')}
          required={uploadRequired}
        />
      </button>
      <Modal
        editFunc={() => {
          onClick(logoUrl);
          setModalVisible(false);
        }}
        modalVisible={modalVisible}
        setModalVisible={setModalVisible}
        uploadNewFunc={() => {
          fileInput.current.click();
        }}
      />
    </section>
  );
};

LogoSelector.propTypes = {
  logoUrl: PropTypes.string,
  onClick: PropTypes.func,
  hasClosed: PropTypes.bool,
  uploadRequired: PropTypes.bool,
};

export default LogoSelector;
