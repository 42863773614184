import styled from 'styled-components';
import { breakpoints } from '../cssVariables';
import { getDefaultStylesForComponent } from '../defaultStyles';

const AboutMeDefaults = getDefaultStylesForComponent('AboutMeText');

// eslint-disable-next-line import/prefer-default-export
export const Text = styled.div`
  display: ${(props) => (!props.isVisible ? 'none' : 'block')};
  flex-flow: column nowrap;
  justify-content: center;
  order: ${(props) => props.order || AboutMeDefaults.order};

  flex: 0 0 100%;
  height: ${(props) => props.height || AboutMeDefaults.height};
  margin: 0;
  padding: ${(props) => props.padding || AboutMeDefaults.padding};

  & h2 {
    margin: 0;
    padding: ${(props) => props.title.padding || AboutMeDefaults.title.padding};

    text-align: ${(props) =>
      props.title.textAlign || AboutMeDefaults.title.textAlign};
    font-size: ${(props) =>
      props.title.fontSize || AboutMeDefaults.title.fontSize};
    line-height: ${(props) =>
      props.title.lineHeight || AboutMeDefaults.title.lineHeight};
    font-weight: ${(props) =>
      props.title.fontWeight || AboutMeDefaults.title.fontWeight};
  }

  & p {
    margin: 0;
    padding: ${(props) => props.text.padding || AboutMeDefaults.text.padding};

    text-align: ${(props) =>
      props.text.textAlign || AboutMeDefaults.text.textAlign};
    font-size: ${(props) =>
      props.text.fontSize || AboutMeDefaults.text.fontSize};
    line-height: ${(props) =>
      props.text.lineHeight || AboutMeDefaults.text.lineHeight};
    font-weight: ${(props) =>
      props.text.fontWeight || AboutMeDefaults.text.fontWeight};
  }

  @media (min-width: ${breakpoints.medium}) {
    order: ${(props) => props.medium.order || AboutMeDefaults.medium.order};

    padding: ${(props) =>
      props.medium.padding || AboutMeDefaults.medium.padding};

    & h2 {
      padding: ${(props) =>
        props.medium.title.padding || AboutMeDefaults.medium.title.padding};

      font-size: ${(props) =>
        props.medium.title.fontSize || AboutMeDefaults.medium.title.fontSize};
      text-align: ${(props) =>
        props.medium.title.textAlign || AboutMeDefaults.medium.title.textAlign};
      line-height: ${(props) =>
        props.medium.title.lineHeight ||
        AboutMeDefaults.medium.title.lineHeight};
      font-weight: ${(props) =>
        props.medium.title.fontWeight ||
        AboutMeDefaults.medium.title.fontWeight};
    }

    & p {
      padding: ${(props) =>
        props.medium.text.padding || AboutMeDefaults.medium.text.padding};

      font-size: ${(props) =>
        props.medium.text.fontSize || AboutMeDefaults.medium.text.fontSize};
      text-align: ${(props) =>
        props.medium.text.textAlign || AboutMeDefaults.medium.text.textAlign};
      line-height: ${(props) =>
        props.medium.text.lineHeight || AboutMeDefaults.medium.text.lineHeight};
      font-weight: ${(props) =>
        props.medium.text.fontWeight || AboutMeDefaults.medium.text.fontWeight};
    }
  }

  @media (min-width: ${breakpoints.large}), (orientation: landscape) {
    order: ${(props) => props.large.order || AboutMeDefaults.large.order};

    padding: ${(props) => props.large.padding || AboutMeDefaults.large.padding};

    & h2 {
      padding: ${(props) =>
        props.large.title.padding || AboutMeDefaults.large.title.padding};

      font-size: ${(props) =>
        props.large.title.fontSize || AboutMeDefaults.large.title.fontSize};
      text-align: ${(props) =>
        props.large.title.textAlign || AboutMeDefaults.large.title.textAlign};
      line-height: ${(props) =>
        props.large.title.lineHeight || AboutMeDefaults.large.title.lineHeight};
      font-weight: ${(props) =>
        props.large.title.fontWeight || AboutMeDefaults.large.title.fontWeight};
    }

    & p {
      padding: ${(props) =>
        props.large.text.padding || AboutMeDefaults.medium.text.padding};

      font-size: ${(props) =>
        props.large.text.fontSize || AboutMeDefaults.large.text.fontSize};
      text-align: ${(props) =>
        props.large.text.textAlign || AboutMeDefaults.large.text.textAlign};
      line-height: ${(props) =>
        props.large.text.lineHeight || AboutMeDefaults.large.text.lineHeight};
      font-weight: ${(props) =>
        props.large.text.fontWeight || AboutMeDefaults.large.text.fontWeight};
    }
  }
`;
