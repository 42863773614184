import React, { useState, useEffect } from 'react';
import './Account.scss';
import { useHistory } from 'react-router-dom';
import {
  CardNumberElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import PageHeader from '../PageHeader/PageHeader';
import LogoSelector from './LogoSelector/LogoSelector';
import SocialForm from '../SocialForm/SocialForm';
import ContactForm from '../ContactForm/ContactForm';
import Button from '../Button/Button';
import BillingForm from './BillingForm/BillingForm';
import ImgEditor from '../ImgEditor/ImgEditor';
import { useContactFields } from '../../hooks/ContactForm';
import { useSocialFields } from '../../hooks/SocialForm';
import {
  prepareCompanyDataToSubmit,
  prepareErrorMessage,
  openUrlInNewTab,
} from '../../utils/helper';
import {
  addCompany,
  getCompanyForUser,
  updateCompany,
} from '../../utils/apiCalls';
import {
  accountBillingAddressSettings,
  accountContactFormSettings,
  emptyAccountState,
  mapResponseToAccountState,
} from '../../data/Account';
import { useAuth } from '../../hooks/Auth';
import BillingAddressForm from './BillingAddressForm/BillingAddressForm';
import OnboardingTerms from './OnboardingTerms/OnboardingTerms';
import SectionHeader from '../SectionHeader/SectionHeader';
import Loader from '../Loader/Loader';
import store, { NotificationTypes } from '../../hooks/NotificationHub';

const Account = () => {
  const auth = useAuth();
  const history = useHistory();
  const stripe = useStripe();
  const elements = useElements();

  const [logoUrl, setLogoUrl] = useState(undefined);
  const [companyData, setCompanyData] = useState(emptyAccountState);
  const [cardInfo, setCardInfo] = useState(null);
  const [isLoading, setIsLoading] = useState(auth.data.user.onboarded);
  const [isResetting, setIsResetting] = useState(false);
  const [areTermsAccepted, setAreTermsAccepted] = useState(false);
  const [isBillingAddressTheSame, setIsBillingAddressTheSame] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [isEditorVisible, setIsEditorVisible] = useState(false);
  const [path, setPath] = useState('');

  const contactFields = useContactFields(
    companyData.company,
    {
      ...accountContactFormSettings,
      company: {
        ...accountContactFormSettings.company,
        disabled: auth.data.user.onboarded,
      },
    },
    isResetting,
    isLoading,
  );

  const billingFields = useContactFields(
    companyData.billing,
    accountBillingAddressSettings(!isBillingAddressTheSame),
    isResetting,
    isLoading,
  );

  const socialFields = useSocialFields(
    companyData.company,
    isResetting,
    isLoading,
  );

  useEffect(() => {
    const getCompanyInfo = async () => {
      try {
        const response = await getCompanyForUser();

        setCompanyData(
          mapResponseToAccountState({
            ...response.data,
            email: auth?.data?.user?.email,
          }),
        );
        setLogoUrl(response.data.logo_url);
        setCardInfo(response.data.payment_method.card);
        setIsLoading(false);
      } catch (err) {
        store.pushNotification(
          err.message,
          prepareErrorMessage(err.errors) ??
            'Could not load company data at the moment. Please refresh the page or try again later.',
          NotificationTypes.DANGER,
        );
      }
    };

    if (auth.data.user.onboarded) {
      getCompanyInfo();
    } else {
      setCompanyData({
        ...companyData,
        company: {
          ...companyData.company,
          email: auth?.data?.user?.email ?? '',
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const timeout = setTimeout(() => setIsResetting(false), 100);
    return () => clearTimeout(timeout);
  }, [isResetting]);

  const validateInOnboarding = () => {
    if (!auth.data.user.onboarded && !logoUrl) {
      store.pushNotification(
        'Warning',
        'Please upload your logo before saving your information.',
        NotificationTypes.WARNING,
      );
      return false;
    }

    if (!auth.data.user.onboarded && !areTermsAccepted) {
      store.pushNotification(
        'Warning',
        'Please accept the terms.',
        NotificationTypes.WARNING,
      );
      return false;
    }

    return true;
  };

  const submitCompany = async (toSend, uuid, paymentMethod) => {
    return !auth.data.user.onboarded
      ? addCompany(toSend, paymentMethod)
      : updateCompany(uuid, toSend, paymentMethod);
  };

  const handleResponse = async (response, newData) => {
    setCompanyData({
      company: { ...companyData.company, ...newData },
      billing: { ...companyData.billing, ...newData.billing },
    });
    setLogoUrl(response.data.logo_url);

    store.pushNotification(
      'Success',
      'Data saved successfully.',
      NotificationTypes.SUCCESS,
    );

    if (response.data.redirect) {
      openUrlInNewTab(response.data.redirect);
    }

    if (!auth.data.user.onboarded) {
      auth.setData({
        ...auth.data,
        user: {
          ...auth.data.user,
          onboarded: true,
          companyUuid: response.data.uuid,
          companyName: response.data.company,
        },
      });

      history.replace('/account', 'false');
    }
  };

  const handleSubmit = async (evt) => {
    evt.preventDefault();
    setIsSubmitting(true);
    const [
      contactFieldsValid,
      contactFieldsErrors,
    ] = contactFields.validateOnSubmit();

    const [
      billingFieldsValid,
      billingFieldsErrors,
    ] = billingFields.validateOnSubmit();

    const InOnBoardingValid = validateInOnboarding();

    if (!contactFieldsValid || !billingFieldsValid || !InOnBoardingValid) {
      store.pushNotification(
        'Error',
        <span style={{ whiteSpace: 'break-spaces' }}>
          {[...contactFieldsErrors, ...billingFieldsErrors].join('\n\n')}
        </span>,
        NotificationTypes.DANGER,
      );
      return;
    }

    const cardElement = elements.getElement(CardNumberElement);

    const { error, paymentMethod } = cardElement
      ? await stripe.createPaymentMethod({
          type: 'card',
          card: cardElement,
        })
      : { error: null, paymentMethod: { ...cardInfo } };

    if (error && cardElement) {
      let title = error.code.split('_').join(' ');
      title = `${title.charAt(0).toUpperCase()}${title.substr(1)}`;
      store.pushNotification(
        title,
        'Please check your credit card information.',
        NotificationTypes.WARNING,
      );
      return;
    }

    if (cardElement) {
      setCardInfo({ ...paymentMethod.card });
    }

    const company = prepareCompanyDataToSubmit(
      contactFields.contactInfo,
      socialFields.socialLinks,
      isBillingAddressTheSame
        ? contactFields.contactInfo
        : billingFields.contactInfo,
      logoUrl,
    );

    try {
      const response = await submitCompany(
        company.toSend,
        companyData.company.uuid ?? '',
        cardElement
          ? {
              id: paymentMethod.id,
              card: {
                brand: paymentMethod.card.brand,
                exp_month: paymentMethod.card.exp_month,
                exp_year: paymentMethod.card.exp_year,
                last4: paymentMethod.card.last4,
              },
            }
          : undefined,
      );

      handleResponse(response, company.newCompanyData);
    } catch (err) {
      const message = prepareErrorMessage(err.errors);

      store.pushNotification(
        err.message,
        message ??
          'Could not save company data at the moment. Please try again later.',
        NotificationTypes.DANGER,
      );
    }

    setIsSubmitting(false);
  };

  return (
    <section className="account">
      {isEditorVisible && path !== '' ? (
        <ImgEditor
          path={path}
          close={() => {
            setIsEditorVisible(false);
            setPath('');
          }}
          setLogoUrl={setLogoUrl}
          type="logo"
        />
      ) : null}
      {auth.data.user.onboarded ? <PageHeader text="My Profile" /> : null}

      <main className="account__main">
        {!auth.data.user.onboarded ? (
          <div className="account__onboarding-header">
            <SectionHeader text="COMPLETE YOUR PROFILE INFO" />
            <SectionHeader text="This will allow us to make your experience easier." />
          </div>
        ) : null}
        <LogoSelector
          logoUrl={logoUrl}
          onClick={(filepath) => {
            setIsEditorVisible(true);
            setPath(filepath);
          }}
          hasClosed={!isEditorVisible && path === ''}
        />

        <form className="main__form" onSubmit={handleSubmit}>
          <ContactForm inputs={contactFields.inputs} />
          <SocialForm inputs={socialFields.inputs} />
          <BillingAddressForm
            inputs={billingFields.inputs}
            selects={billingFields.selects}
            isBillingTheSame={isBillingAddressTheSame}
            setIsBillingTheSame={(evt) =>
              setIsBillingAddressTheSame(evt.target.checked)
            }
          />
          <OnboardingTerms
            isVisible={!auth.data.user.onboarded}
            areTermsAccepted={areTermsAccepted}
            setAreTermsAccepted={(evt) =>
              setAreTermsAccepted(evt.target.checked)
            }
          />
          <div className="account__button-wrapper">
            <Button
              text="CANCEL"
              onClick={() => setIsResetting(true)}
              variant="secondary"
            />
            <Button
              text="SAVE"
              variant="primary"
              type="submit"
              disabled={isSubmitting}
              state={isSubmitting ? 'loading' : ''}
            />
          </div>
        </form>
      </main>
      <aside className="account__aside">
        <BillingForm
          cardLast4={cardInfo?.last4}
          cardExpiryMonth={cardInfo?.exp_month}
          cardExpiryYear={cardInfo?.exp_year}
          cardBrand={cardInfo?.brand}
        />
      </aside>
      <Loader isLoading={isLoading} isShowingProgress={false} />
    </section>
  );
};

export default Account;
