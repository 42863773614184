/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { ImageContainer } from './userStyles';

const handleAddClickListener = (e) => {
  if (e.origin !== process.env.panelUrl) {
    return;
  }

  if (e.data === 'addClickListenerToUserImage') {
    const newScript = document.createElement('script');
    const script = `
          var userImage = document.querySelector('img[data-userimage]');
          userImage.style.cursor = 'pointer';
          userImage.addEventListener('click', () => {
            window.parent.window.postMessage('userPhotoClicked', '*');
          });
        `;

    const inlineScript = document.createTextNode(script);
    newScript.appendChild(inlineScript);
    document.body.appendChild(newScript);
  }
};

const UserPhoto = ({ PhotoUrl, onPhotoClick, ...rest }) => {
  useEffect(() => {
    window.addEventListener('message', handleAddClickListener);

    return () => {
      window.removeEventListener('message', handleAddClickListener);
    };
  }, []);

  return (
    <ImageContainer {...rest} isOnClickPresent={onPhotoClick}>
      <img
        data-userimage
        src={PhotoUrl ?? '/images/MBC/photo_placeholder.svg'}
        alt="User"
        onClick={onPhotoClick}
      />
      {!PhotoUrl ? (
        <img
          data-userimage
          src="/images/upload.svg"
          alt="placeholder"
          style={{ pointerEvents: 'none' }}
          onClick={onPhotoClick}
        />
      ) : null}
    </ImageContainer>
  );
};

UserPhoto.propTypes = {
  PhotoUrl: PropTypes.string,
  onPhotoClick: PropTypes.func,
};

export default UserPhoto;
