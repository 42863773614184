/* eslint-disable import/prefer-default-export */
import { store } from 'react-notifications-component';

export const NotificationTypes = {
  SUCCESS: 'success',
  DANGER: 'danger',
  INFO: 'info',
  DEFAULT: 'default',
  WARNING: 'warning',
};

store.pushNotification = (title, message, type) => {
  store.addNotification({
    title,
    message,
    type,
    insert: 'top',
    container: 'bottom-center',
    animationIn: ['animate__animated', 'animate__fadeIn'],
    animationOut: ['animate__animated', 'animate__fadeOut'],
    dismiss: {
      duration: parseInt(process.env.REACT_APP_NOTIFICATION_DURATION, 10),
      onScreen: true,
      pauseOnHover: true,
    },
  });
};

export default store;
