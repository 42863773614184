/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import './ConfirmationDialog.scss';
import PropTypes from 'prop-types';
import Button from '../Button/Button';

const ConfirmationDialog = ({ message, onConfirm, setDialog }) => (
  <div className="confirmation-dialog__overlay" onClick={() => setDialog(null)}>
    <div className="confirmation-dialog">
      <p className="confirmation-dialog__message">{message}</p>
      <div className="confirmation-dialog__buttons">
        <Button
          variant="secondary"
          text="No"
          onClick={() => {
            setDialog(null);
          }}
        />
        <Button
          variant="primary"
          text="Yes"
          onClick={() => {
            onConfirm();
            setDialog(null);
          }}
        />
      </div>
    </div>
  </div>
);

ConfirmationDialog.propTypes = {
  message: PropTypes.string,
  onConfirm: PropTypes.func,
  setDialog: PropTypes.func,
};

export default ConfirmationDialog;
