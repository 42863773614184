import React from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import SVG from 'react-inlinesvg';
import { saveAs } from 'file-saver';
import { StyledButton, StyledLink } from './buttonStyles';
import { icon } from '@fortawesome/fontawesome-svg-core';

// Link leads to a subpage
const internal = (text, iconUrl, fileExtension, href, id, rest) => (
  <StyledLink {...rest} text={text} to={`/mbc/user/${id}${href}`}>
    {fileExtension === 'svg' ? (
      <div className="svg">
        <SVG src={iconUrl} />
      </div>
    ) : (
      <img src={iconUrl} alt="icon" />
    )}
    <p>{text.content}</p>
  </StyledLink>
);

const internalAction = (text, iconUrl, fileExtension, href, onClick, rest) => (
  <StyledButton {...rest} onClick={onClick} text={text}>
    {fileExtension === 'svg' ? (
      <div className="svg">
        <SVG src={iconUrl} />
      </div>
    ) : (
      <img src={iconUrl} alt="icon" />
    )}
    <p>{text.content}</p>
  </StyledButton>
);

// Link leads to an external page / program
const external = (text, iconUrl, fileExtension, href, rest) => (
  <StyledButton href={href} {...rest} text={text}>
    {fileExtension === 'svg' ? (
      <div className="svg">
        <SVG src={iconUrl} />
      </div>
    ) : (
      <img src={iconUrl} alt="icon" />
    )}
    <p>{text.content}</p>
  </StyledButton>
);

const Button = ({
  text,
  iconUrl,
  href,
  action,
  openModal,
  downloadVcard,
  fileName,
  handleClick,
  ...rest
}) => {
  const params = useParams();
  const splitUrl = iconUrl.split('.');
  const fileExtension = splitUrl[splitUrl.length - 1].toLowerCase();

  if (action === 'about') {
    if (handleClick) {
      return internalAction(
        text,
        iconUrl,
        fileExtension,
        href,
        handleClick,
        rest,
      );
    }

    return internal(text, iconUrl, fileExtension, href, params.id, rest);
  }

  if (action === 'send') {
    return internalAction(text, iconUrl, fileExtension, href, openModal, rest);
  }

  if (action === 'download') {
    return internalAction(
      text,
      iconUrl,
      fileExtension,
      href,
      async () => {
        const vcard = await downloadVcard();
        const blob = new Blob([vcard.data.card], { type: vcard.data['content-type'] });
        saveAs(blob, `${fileName}.${vcard.data.ext}`);
      },
      rest,
    );
  }

  return external(text, iconUrl, fileExtension, href, rest);
};

Button.propTypes = {
  action: PropTypes.string,
  text: PropTypes.object,
  href: PropTypes.string,
  iconUrl: PropTypes.string,
  openModal: PropTypes.func,
  downloadVcard: PropTypes.func,
  fileName: PropTypes.string,
};

export default Button;
