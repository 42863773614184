import React from 'react';
import './IdCodeForm.scss';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperPlane, faCopy } from '@fortawesome/free-solid-svg-icons';
import QRCode from 'react-qr-code';
import SectionHeader from '../../SectionHeader/SectionHeader';
import FormControl from '../../FormControl/FormControl';
import { useAuth } from '../../../hooks/Auth';

const IdCodeForm = ({ mobileId, setMobileId }) => {
  const auth = useAuth();

  const updateMobileId = (evt) => setMobileId(evt.target.value);

  return (
    <section className="id-code">
      <SectionHeader text="MOBILE ID, SHORT CODE, & QR CODE" />
      <div className="id-code__form">
        <FormControl
          as="input"
          type="text"
          value={mobileId}
          onChange={updateMobileId}
          name="Mobile id"
          label="CHOOSE YOUR MOBILE ID"
          icon={(className) => (
            <FontAwesomeIcon
              icon={faPaperPlane}
              className={`form-control__icon ${className}`}
            />
          )}
          width="100%"
        />
        <FormControl
          as="input"
          type="text"
          value={
            mobileId && mobileId.length > 0
              ? `Text ${mobileId} to phone number ${process.env.REACT_APP_SHORT_CODE}`
              : ''
          }
          onChange={() => {}}
          name="Short Code"
          label="YOUR MOBILE ID & SHORT CODE"
          icon={(className) => (
            <FontAwesomeIcon
              icon={faCopy}
              className={`form-control__icon ${className}`}
            />
          )}
          width="100%"
        />
      </div>
      <aside className="id-code__qr">
        <a
          href={`/mbc/user/${auth.data.user.uuid}`}
          target="_blank"
          rel="noopener noreferrer"
          className="qr__link">
          <QRCode
            size={128}
            value={`${window.location.href}/user/${auth.data.user.uuid}`}
          />
        </a>
      </aside>
    </section>
  );
};

IdCodeForm.propTypes = {
  mobileId: PropTypes.string,
  setMobileId: PropTypes.func,
};

export default IdCodeForm;
