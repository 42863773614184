import React from 'react';
import PropTypes from 'prop-types';
import SocialLink from './socialLink';
import { LinkContainer } from './socialLinkStyles';

const assignHrefs = (name, links) => {
  switch (name) {
    case 'Facebook':
      return links.fb;
    case 'Twitter':
      return links.tw;
    case 'Instagram':
      return links.ig;
    case 'LinkedIn':
      return links.in;
    default:
      return '';
  }
};

const SocialLinkGroup = ({ socialLinks, medium, large, ...rest }) => {
  const links = socialLinks.links.map((slink) => (
    <SocialLink
      key={slink.site}
      {...slink}
      height={rest.linkHeight}
      href={assignHrefs(slink.site, socialLinks)}
      medium={{ ...slink.medium, height: medium.linkHeight }}
      large={{ ...slink.large, height: large.linkHeight }}
    />
  ));

  return (
    <LinkContainer {...rest} medium={medium} large={large}>
      {links}
    </LinkContainer>
  );
};

SocialLinkGroup.propTypes = {
  socialLinks: PropTypes.object,
  medium: PropTypes.object,
  large: PropTypes.object,
};

export default SocialLinkGroup;
