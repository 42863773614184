export const DEFAULT_SIZE_ALERT =
  'File size is limited to 10 MBs. Please try another file.';

export const DEFAULT_RESOLUTION_ALERT =
  'Resolution is limited to 1920x1080 (FullHD). Please try another file.';

export const PASSWORD_REMINDER_NOTIFICATION_TITLE = 'Email sent';

export const PASSWORD_REMINDER_NOTIFICATION_MESSAGE =
  'An email with the recovery link has been sent. Please check your inbox.';

export const LOGIN_ERROR_NOTIFICATION_TITLE = 'Could not login';

export const LOGIN_ERROR_NOTIFICATION_MESSAGE =
  'Could not login at the moment. Please check your credentials and try again later.';

export const REGISTRATION_SUCCESSFULL_NOTIFICATION_TITLE =
  'Registration successful';

export const REGISTRATION_SUCCESSFULL_NOTIFICATION_MESSAGE =
  'Registration successful. Please check your inbox to verify your account.';

export const ACCOUNT_SAVED_SUCCESSFULL_NOTIFICATION_MESSAGE =
  'Data saved succesfully';
export const ACCOUNT_ERROR_NOTIFICATION_MESSAGE =
  'Could not save data at the moment. Please try again later.';
export const ACCOUNT_ONBOARDING_SAVED_SUCCESSFULL_NOTIFICATION_MESSAGE =
  'Data saved successfully. You can now use your Mobile Business Card and see new leads coming in!';
